import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { sponsorConverter } from './sponsorConverter';

/**
 * Returns a Firestore DocumentReference for the sponsor with the given ID in the event with the given ID.
 * @param eventId The ID of the event containing the sponsor.
 * @param sponsorId The ID of the sponsor to retrieve.
 * @returns A Firestore DocumentReference for the sponsor.
 */
export function getSponsorDocRef(eventId: string, sponsorId: string) {
  const firestore = getFirestore();
  const sponsorDocRef = doc(
    firestore,
    firestorePaths.sponsorPath(eventId, sponsorId),
  ).withConverter(sponsorConverter);
  return sponsorDocRef;
}
