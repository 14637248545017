import { firestorePaths } from '@livekatsomo/shared/config';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

/**
 * Deletes a pre-authorized user from the Firestore database.
 *
 * @param id - The ID of the pre-authorized user to delete.
 * @returns A Promise that resolves when the user has been deleted.
 */
export async function deletePreAuthorizedUser(id: string) {
  const db = getFirestore();
  const preAuthorizedUserRef = doc(db, firestorePaths.preAuthorizedUser(id));
  await deleteDoc(preAuthorizedUserRef);
}
