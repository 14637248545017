import { z } from 'zod';
import { AssetInDocument, assetInDocumentSchema } from './asset.model';
import { themeInDocumentSchema } from './theme.model';
import { visibilitySchema } from './visibility.model';
import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';
import { layoutConfigurationSchema } from './layout.model';

export const customerTypes = ['customer', 'shop'] as const;
export const customerTypeSchema = z.enum(customerTypes);
export type CustomerType = (typeof customerTypes)[number];

const customerAuthorizationSchema = z.object({
  /** An optional flag indicating whether the customer requires authorization. */
  passwordEnabled: z.boolean().optional(),
  /** An optional flag indicating whether the customer requires domain authorization. */
  domainEnabled: z.boolean().optional(),
  /** An optional flag indicating whether the customer requires moderator authorization. */
  invitationsEnabled: z.boolean().optional(),
});

/**
 * Defines the schema for a customer object.
 */
export const customerSchema = z.object({
  /** The name of the customer. */
  name: z.string().min(1),
  /** The lowercase version of the customer name. */
  nameLower: z.string().optional(),
  /** A description of the customer. */
  description: z.string().optional(),
  /** The slug of the customer. */
  slug: z.string().min(1),
  /** An optional theme object for the customer. */
  theme: themeInDocumentSchema.optional(),
  /** An optional logo for the customer. */
  logo: assetInDocumentSchema.optional(),
  /** An optional poster for the customer. */
  poster: assetInDocumentSchema.optional(),
  /** An optional banner for the customer. */
  banner: assetInDocumentSchema.optional(),
  /** The visibility of the customer. */
  visibility: visibilitySchema,
  /** The type of the customer. */
  customerType: customerTypeSchema,
  /** An optional object containing authorization settings for the customer. */
  authorization: customerAuthorizationSchema.optional(),
  /** An optional object containing the default layout configuration for the customer. */
  defaultLayout: layoutConfigurationSchema.optional(),
  /** An optional flag indicating whether the customer channel type is a system channel.
   * If true, the events of the customer may be set as a demo event.
   */
  systemChannel: z.boolean().optional(),
  categories: z
    .array(
      z.object({
        id: z.string(),
        name: z.string().min(1),
      }),
    )
    .optional(),
});

/**
 * Represents a customer object with properties defined by the {@link customerSchema}.
 */
export type Customer = z.infer<typeof customerSchema>;

/**
 * Defines the schema for the customer configuration object.
 */
export const customerConfigSchema = z.object({
  /** An optional email template ID for invitations. */
  invitationEmailTemplateId: z.string().optional(),
  /** An optional email template ID for moderator invitations. */
  moderatorInvitationTemplateId: z.string().optional(),
});

/**
 * Type definition for the configuration of a customer.
 *
 * @remarks
 * This type is inferred from the {@link customerConfigSchema} schema.
 */
export type CustomerConfig = z.infer<typeof customerConfigSchema>;

/**
 * This exports a customer ({@link customerSchema}) document schema that merges with a Firestore document schema
 * and extends it with a `docType` property set to 'customer'.
 */
export const customerDocSchema = customerSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is customer */
    docType: z.literal('customer'),
  });

/**
 * Represents the type of a customer document, inferred from the {@link customerDocSchema}.
 */
export type CustomerDoc = z.infer<typeof customerDocSchema>;

/**
 * Merges the customer document schema ({@link customerDocSchema}) with the used in property schema ({@link usedInPropertySchema})
 * to create a new schema for a customer in a document.
 */
export const customerInDocumentSchema =
  usedInPropertySchema.merge(customerDocSchema);

/**
 * Defines a schema for customer slugs, which includes a required customer ID.
 */
export const customerSlugSchema = z.object({
  /** The ID of the customer. */
  customerId: z.string().nonempty('Customer is required'),
});

/**
 * Represents the type of a customer slug, which is inferred from the {@link customerSlugSchema}.
 */
export type CustomerSlug = z.infer<typeof customerSlugSchema>;

/**
 * Represents the form values for a customer, excluding certain properties.
 */
export interface CustomerFormValues
  extends Omit<
    Customer,
    | 'id'
    | 'sourceDocRefPath'
    | 'theme'
    | 'logo'
    | 'poster'
    | 'banner'
    | 'docType'
  > {
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: any;
  /** The logo of the customer. */
  logo?: AssetInDocument | File[];
  /** The poster of the customer. */
  poster?: AssetInDocument | File[];
  /** The banner of the customer. */
  banner?: AssetInDocument | File[];
}

/**
 * Represents the form values used to update a customer, extending the {@link CustomerFormValues} interface
 * and adding required `id` property.
 */
export interface CustomerUpdateFormValues
  extends Omit<Partial<CustomerFormValues>, 'id'> {
  /** The ID of the customer. */
  id: string;
}

export type Categories = NonNullable<Customer['categories']>;
export type Category = Categories[number];
