import { DownloadFileDoc } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  UploadDownloadFileFunction,
  UploadFilesFunction,
  DeleteDownloadFileFunction,
  UpdateDownloadFileFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * The model for the downloadFiles context.
 */
export type DownloadFilesContextModel = {
  /** The store for the downloadFiles. */
  store: Store<DownloadFileDoc[] | null>;
  /** The path to the parent of the downloadFiles. */
  parentPath?: string;
  /** Function to set query constraints for the downloadFiles. */
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
  /** Function to upload an downloadFile. */
  uploadDownloadFile: UploadDownloadFileFunction;
  /** Function to upload multiple files. */
  uploadFiles: UploadFilesFunction;
  /** Function to delete an downloadFile. */
  deleteDownloadFile: DeleteDownloadFileFunction;
  /** Function to update an downloadFile. */
  updateDownloadFile: UpdateDownloadFileFunction;
};

/**
 * The context for customer downloadFiles.
 */
export const CustomerDownloadFilesContext = createContext<
  DownloadFilesContextModel | undefined
>(undefined);

/**
 * The context for event downloadFiles.
 */
export const EventDownloadFilesContext = createContext<
  DownloadFilesContextModel | undefined
>(undefined);
