import { useState, useEffect, startTransition } from 'react';

/**
 * A custom React hook that returns a boolean indicating whether the code is running on the client (browser) or the server (Node.js).
 * @returns {boolean} A boolean indicating whether the code is running on the client (browser) or the server (Node.js).
 */
export function useIsClient(): boolean {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setIsClient(true);
    });
  }, []);

  return isClient;
}

export default useIsClient;
