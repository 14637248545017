import { TrackDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { trackConverter } from './trackConverter';

/**
 * Retrieves a snapshot of the tracks for a given event ID.
 *
 * @param options - An object containing the event ID.
 * @param options.eventId - The ID of the event to retrieve tracks for.
 * @param observer - An observer to receive the snapshot.
 * @returns A function to unsubscribe from the observer.
 */
export function getTracks(
  options: { eventId: string },
  observer: Observer<TrackDoc[]>,
) {
  const { eventId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.trackPrivateCollectionPath(eventId),
    },
    trackConverter,
  );
}
