/**
 * An object containing Firestore paths for events.
 */
export const firestorePathsForEvent = {
  /**
   * Returns the path for a specific event.
   * @param eventId The ID of the event.
   * @returns The Firestore path for the event.
   */
  eventPath: (eventId: string) => `event/${eventId}`,

  /**
   * Returns the path for the private configuration of a specific event.
   * @param eventId The ID of the event.
   * @returns The Firestore path for the private configuration of the event.
   */
  eventConfigPath: (eventId: string) => `event/${eventId}/private/config`,

  /**
   * The ID of the Firestore collection for events.
   */
  eventCollectionId: 'event',

  /**
   * The path of the Firestore collection for events.
   */
  eventCollectionPath: `event`,

  /**
   * Returns the path for a specific event slug.
   * @param customerId The ID of the customer who owns the event.
   * @param slug The slug of the event.
   * @returns The Firestore path for the event slug.
   */
  eventSlugPath: (customerId: string, slug: string) =>
    `customer/${customerId}/event-slug/${slug}`,

  /**
   * The path of the Firestore collection for event slugs.
   * @param customerId The ID of the customer who owns the event.
   * @returns The Firestore path for the event slug collection.
   */
  eventSlugCollectionPath: (customerId: string) =>
    `customer/${customerId}/event-slug`,

  /**
   * The ID of the Firestore collection for event slugs.
   */
  eventSlugCollectionId: 'event-slug',

  /**
   * The path of the Firestore collection for event forms.
   */
  eventFormCollectionPath: (eventId: string) => `event/${eventId}/form`,
  /**
   * Returns the path for a specific event form.
   * @param eventId The ID of the event.
   * @param formId The ID of the form.
   */
  eventFormPath: (eventId: string, formId: string) =>
    `event/${eventId}/form/${formId}`,
  /**
   * Returns the path for a specific event form.
   * @param eventId The ID of the event.
   * @param formId The ID of the form.
   */
  eventFormSubmissionsCollectionPath: (eventId: string, formId: string) =>
    `event/${eventId}/form/${formId}/submission`,
  /**
   * Returns the path to submitted form state.
   * @param eventId The ID of the event.
   * @param formId The ID of the form.
   * @param uid The ID of the submission.
   */
  eventFormSubmissionPath: (eventId: string, formId: string, uid: string) =>
    `event/${eventId}/form/${formId}/submission/${uid}`,
} as const;
