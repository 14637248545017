import { User } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { userConverter } from './userConverter';

/**
 * Retrieves a list of users from Firestore based on the provided query constraints.
 *
 * @param options - An object containing the query constraints to apply.
 * @param options.queryConstraints - An array of query constraints to apply to the Firestore query.
 * @param observer - An observer that will be notified of any changes to the user data.
 * @returns A function that can be used to unsubscribe from the observer.
 */
export function getUsers(
  options: {
    queryConstraints: QueryConstraint[];
  },
  observer: Observer<User[]>,
) {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.userCollectionPath,
      queryConstraints: options.queryConstraints,
    },
    userConverter,
  );
}
