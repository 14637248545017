import { Agenda } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  AddAgendaItemFunction,
  DeleteAgendaItemFunction,
  SetAsCurrentAgendaItemFunction,
  UpdateAgendaItemFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * The model for the AgendaContext.
 */
export type AgendaContextModel = {
  /** The store for the agenda. */
  store: Store<Agenda>;
  /** Function to delete an agenda item. */
  deleteAgendaItem: DeleteAgendaItemFunction;
  /** Function to update an agenda item. */
  updateAgendaItem: UpdateAgendaItemFunction;
  /** Function to add an agenda item. */
  addAgendaItem: AddAgendaItemFunction;
  /** Optional function to update the order of agenda items. */
  updateOrder?: (agendaItems: Agenda) => Promise<void>;
  /** Function to set an agenda item as current. */
  setAsCurrent: SetAsCurrentAgendaItemFunction;
};

/**
 * The context for the Agenda.
 */
export const AgendaContext = createContext<AgendaContextModel | undefined>(
  undefined,
);
