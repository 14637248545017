import { AgendaItemFormValues } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getAgendaItemDocRef } from './getAgendaItemDocRef';

/**
 * Updates an agenda item for a given event.
 *
 * @param eventId - The ID of the event.
 * @param agendaItemId - The ID of the agenda item to update.
 * @param agendaItemFormValues - The updated values for the agenda item.
 * @returns A Promise that resolves when the agenda item has been updated.
 */
export async function updateAgendaItem(
  eventId: string,
  agendaItemId: string,
  agendaItemFormValues: Partial<AgendaItemFormValues>,
): Promise<void> {
  const agendaItemDocRef = getAgendaItemDocRef(eventId, agendaItemId);

  await updateDoc(agendaItemDocRef, {
    ...agendaItemFormValues,
  });
}
