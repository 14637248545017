import { Timestamp } from 'firebase/firestore';
import {
  ToFirestore,
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';

import * as z from 'zod';

const BaseVariant = ['default', 'error', 'success', 'warning', 'info'] as const;

export const notificationMessageSchema = z.object({
  message: z.string(),
  variant: z.enum(BaseVariant),
  createdAt: timestampOrDateSchema,
  senderId: z.string(),
  persist: z.boolean().optional(),
  autoHideDuration: z.number().optional(),
});

export type NotificationMessage = z.infer<typeof notificationMessageSchema>;

export const NotificationMessageFirestoreSchema = notificationMessageSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type id `notificationMessage` */
    docType: z.literal('notificationMessage'),
  });

export type NotificationMessageDoc = z.infer<
  typeof NotificationMessageFirestoreSchema
>;

/**
 * Represents a notification message in Firestore.
 */
export type NotificationMessageFirestore = Omit<
  NotificationMessageDoc,
  'createdAt'
> & { createdAt: Timestamp };

/**
 * Represents the form values of a notification message.
 */
export type NotificationMessageFormValues = Omit<
  ToFirestore<NotificationMessage>,
  'createdAt' | 'senderId' | 'docType'
>;
