import {
  Asset,
  AssetInDocument,
  Track,
  TrackFormValues,
} from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';

/**
 * Updates a track in Firestore.
 *
 * @param eventId - The ID of the event the track belongs to.
 * @param trackId - The ID of the track to update.
 * @param trackFormValues - The updated track data.
 * @param onUploadProgressChange - A function to call when the upload progress changes.
 * @returns A promise that resolves when the track has been updated.
 */
export async function updateTrack(
  eventId: string,
  trackId: string,
  trackFormValues: Partial<TrackFormValues> | Partial<Track>,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const { poster: posterField, ...trackData } = trackFormValues;
  const firestore = getFirestore();
  const trackDocRef = doc(
    firestore,
    firestorePaths.trackPrivatePath(eventId, trackId),
  );

  let poster: AssetInDocument | undefined;

  if (Array.isArray(posterField)) {
    const file = posterField[0];
    if (file) {
      const assetDoc = await uploadImageFile({
        file,
        filename: file.name,
        uploadDir: firestorePaths.assetCollectionPath(
          firestorePaths.eventPath(eventId),
        ),
        assetData: {
          alt: `${trackData.name} poster`,
          purpose: ['poster'],
        } satisfies Partial<Asset>,
        onUploadProgressChange: onUploadProgressChange,
      });
      const asset = assetDoc?.data();
      poster =
        asset && assetDoc
          ? ({
              ...asset,
            } satisfies AssetInDocument)
          : undefined;
    }
  } else {
    poster = posterField;
  }

  await updateDoc(trackDocRef, {
    ...trackData,
    poster,
  });
}
