import { SlideDeckDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint, orderBy } from 'firebase/firestore';
import { slideDeckConverter } from './slideDeckConverter';

/**
 * Retrieves an array of slide decks for a given event and track.
 *
 * @param options - The options for the slide deck retrieval.
 * @param options.eventId - The ID of the event.
 * @param options.trackId - The ID of the track.
 * @param options.queryConstraints - An optional array of query constraints to apply to the slide deck retrieval.
 * @param observer - The observer to notify of the slide deck retrieval results.
 * @returns A function to unsubscribe from the slide deck retrieval.
 */
export function getSlideDecks(
  options: {
    eventId: string;
    trackId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<SlideDeckDoc[]>,
) {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.slideDecksCollectionPath(
        options.eventId,
        options.trackId,
      ),
      queryConstraints: orderBy('filename', 'asc'),
    },
    slideDeckConverter,
  );
}
