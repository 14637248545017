import { User } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getChatMessageDocRef } from './getChatMessageDocRef';

/**
 * Accepts a chat message by updating its accepted status and assigning a moderator ID.
 * @param parentPath - The parent path of the chat message.
 * @param user - The user object of the moderator.
 * @param chatMessageId - The ID of the chat message to accept.
 * @returns A Promise that resolves when the chat message has been accepted.
 */
export function acceptChatMessage(
  parentPath: string,
  user: User,
  chatMessageId: string,
): Promise<void> {
  const chatMessageDocRef = getChatMessageDocRef(parentPath, chatMessageId);
  return updateDoc(chatMessageDocRef, {
    accepted: true,
    moderatorId: user.uid,
  });
}
