import { onSnapshot } from 'firebase/firestore';
import { SystemConfig } from '@livekatsomo/models';
import { Observer } from '@livekatsomo/types';
import { getSystemConfigDocRef } from './getSystemConfigDocRef';

/**
 * Retrieves the system configuration from Firestore and returns an Observable that emits the configuration object whenever it changes.
 *
 * @param options - Unused parameter.
 * @param observer - Observer object that will receive the configuration object.
 * @returns An unsubscribe function that can be called to stop receiving updates.
 */
export function getSystemConfig(
  options: undefined,
  observer: Observer<SystemConfig>,
) {
  const systemConfigDocRef = getSystemConfigDocRef();
  return onSnapshot(systemConfigDocRef, {
    next: (docSnapshot) => {
      const data = docSnapshot.data();
      observer.next(data ?? {});
    },
    error: (error) => observer.error(error),
    complete: observer.complete,
  });
}
