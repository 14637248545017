import { firestorePaths } from '@livekatsomo/shared/config';
import { AddCustomerFunction } from '@livekatsomo/types';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { newCustomerConverter } from './newCustomerConverter';
import { uploadCustomerImage } from './uploadCustomerImage';
import { setCustomerSlugDoc } from './setCustomerSlugDoc';

/**
 * Adds a new customer to the Firestore database.
 *
 * @param customerFormData - The data of the customer to be added.
 * @param onUploadProgressChange - A callback function to track the upload progress of customer images.
 * @returns A promise that resolves when the customer is successfully added to the database.
 */
export const addCustomer: AddCustomerFunction = async (
  customerFormData,
  onUploadProgressChange,
) => {
  const firestore = getFirestore();
  const customerCollection = collection(
    firestore,
    firestorePaths.customerCollectionPath,
  ).withConverter(newCustomerConverter);
  const { logo, poster, banner, ...customer } = customerFormData;
  const customerDocRef = await addDoc(customerCollection, {
    ...customer,
    ...(logo && !Array.isArray(logo) && { logo }),
    ...(poster && !Array.isArray(poster) && { poster }),
    ...(banner && !Array.isArray(banner) && { banner }),
  });

  const promises: Promise<unknown>[] = [];

  // Setting slug immediately so that navigation can happen
  promises.push(setCustomerSlugDoc(customer.slug, customerDocRef.id));

  if (Array.isArray(logo) && logo.length) {
    uploadCustomerImage(
      logo[0],
      { id: customerDocRef.id, name: customer.name },
      'logo',
      onUploadProgressChange,
    );
  }
  if (Array.isArray(poster) && poster.length) {
    uploadCustomerImage(
      poster[0],
      { id: customerDocRef.id, name: customer.name },
      'poster',
      onUploadProgressChange,
    );
  }
  if (Array.isArray(banner) && banner.length) {
    uploadCustomerImage(
      banner[0],
      { id: customerDocRef.id, name: customer.name },
      'banner',
      onUploadProgressChange,
    );
  }

  await Promise.all(promises);
};
