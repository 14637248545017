import { SlideDeckDoc, Store, UsedIn } from '@livekatsomo/models';
import {
  DeleteSlideDeckFunction,
  UpdateSlideDeckFunction,
  UploadProgressFunction,
  UploadSlideDeckFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * The model for the SlideDecksContext.
 */
export type SlideDecksContextModel = {
  store: Store<SlideDeckDoc[] | null>;
  uploadSlideDeck: UploadSlideDeckFunction;
  deleteSlideDeck: DeleteSlideDeckFunction;
  updateSlideDeck: UpdateSlideDeckFunction;
  uploadSlideDecks: (
    files: File[],
    setProgress?: UploadProgressFunction,
  ) => Promise<void>;
  onGetSlideDeckUsedIn: (slideDeckId: string) => Promise<UsedIn[]>;
};

/**
 * The context for the SlideDecksContext.
 */
export const SlideDecksContext = createContext<
  SlideDecksContextModel | undefined
>(undefined);
