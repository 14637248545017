import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import { doc, getFirestore, runTransaction } from 'firebase/firestore';
import { documentConverter } from './documentConverter';

/**
 * Saves the latest version of a Prosemirror document to Firestore.
 * @param docPath - The path to the document in Firestore.
 * @param contentAsJSON - The content of the document as a JSON string.
 * @param version - The version number of the document.
 * @throws {KatsomoError} If there was an error while saving the document.
 */
export function saveLatestDocument(
  docPath: string,
  contentAsJSON: string,
  version: number,
): void {
  const firestore = getFirestore();
  const docRef = doc(firestore, docPath).withConverter(documentConverter);
  try {
    runTransaction(firestore, async (transaction) => {
      transaction.set(docRef, {
        content: contentAsJSON,
        version,
        id: docRef.id,
        docType: 'document',
        sourceDocRefPath: docRef.path,
      });
    });
  } catch (error) {
    throw new KatsomoError(
      'Error while saving savepoint',
      KatsomoErrorCodes.GENERIC,
      undefined,
      error as Error,
    );
  }
}
