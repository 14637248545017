import { SponsorDoc } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  DeleteSponsorFunction,
  UpdateSponsorFunction,
  AddSponsorFunction,
  UpdateSponsorOrderFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the SponsorsContext.
 */
export type SponsorsContextModel = {
  store: Store<SponsorDoc[]>;
  deleteSponsor: DeleteSponsorFunction;
  updateSponsor: UpdateSponsorFunction;
  addSponsor: AddSponsorFunction;
  updateOrder: UpdateSponsorOrderFunction;
};

/**
 * The context for the SponsorsContext.
 */
export const SponsorsContext = createContext<SponsorsContextModel | undefined>(
  undefined,
);
