import { z } from 'zod';
import { timestampOrDateSchema } from './firestore-doc.model';

/**
 * Defines the schema for the Ticketmaster status object.
 */
export const ticketmasterStatusSchema = z.object({
  /** The timestamp of the last update to the status object. */
  updatedTimestamp: z.number().default(0),
  /** The timestamp of the last synchronization of the status object. */
  syncTimestamp: timestampOrDateSchema,
});

/**
 * Represents the inferred type of the Ticketmaster status schema ({@link ticketmasterStatusSchema}).
 */
export type TicketmasterStatus = z.infer<typeof ticketmasterStatusSchema>;

/**
 * Defines a schema for the possible transaction statuses of a Ticketmaster event.
 */
export const ticketmasterTransactionStatusSchema = z.union([
  z.literal('Sold'),
  z.literal('Sold reserved'),
  z.literal('Reserved'),
  z.literal('Cancelled Sale'),
  z.literal('Cancelled Reservation'),
]);

/**
 * Represents the inferred type of the {@link ticketmasterTransactionStatusSchema}.
 */
export type TicketmasterTransactionStatus = z.infer<
  typeof ticketmasterTransactionStatusSchema
>;

/**
 * Defines the schema for a Ticketmaster transaction.
 */
export const ticketmasterTransactionSchema = z.object({
  /** First name of the customer. */
  firstName: z.string(),
  /** Last name of the customer. */
  lastName: z.string(),
  /** Middle name of the customer. */
  middleName: z.string(),
  /** Email address of the customer. */
  email1: z.string(),
  /** Alternative email address of the customer. */
  email2: z.string(),
  /** Phone number of the customer. */
  phone1: z.string(),
  /** Alternative phone number of the customer. */
  phone2: z.string(),
  /** Alternative phone number of the customer. */
  phone3: z.string(),
  /** Alternative phone number of the customer. */
  phone4: z.string(),
  /** Address of the customer. */
  address1: z.string(),
  /** Alternative address of the customer. */
  address2: z.string(),
  /** Zip code of the customer. */
  zipCode: z.string(),
  /** City of the customer. */
  city: z.string(),
  /** Country of the customer. */
  country: z.string(),
  /** Language of the customer. */
  customerNumber: z.number(),
  /** Language of the customer. */
  preferredLang: z.string(),
  /** Language of the customer. */
  company: z.string(),
  /** Language of the customer. */
  department: z.string(),
  /** GDPR flags of the customer. */
  gdprFlags: z.array(
    z.union([
      z.literal('erased'),
      z.literal('full_restriction'),
      z.literal('partial_restriction'),
    ]),
  ),
  /** ID of the ticketmaster event. */
  eventId: z.number(),
  /** Code of the event. */
  eventCode: z.string(),
  /** Date of the event. */
  eventDate: z.string(),
  /** Time of the event. */
  eventTime: z.string(),
  /** Title of the event. */
  eventTitle: z.string(),
  /** ID of the venue. */
  venueId: z.number(),
  /** Name of the venue. */
  venue: z.string(),
  /** ID of the promoter. */
  promoter: z.string(),
  /** ID of the administrator. */
  administrator: z.string(),
  /** Keywords of the event. */
  keywords: z.string(),
  /** Level for ticket */
  level: z.string(),
  /** Section for ticket */
  section: z.string(),
  /** Row for ticket */
  row: z.string(),
  /** Seat number for ticket */
  seatNumber: z.number(),
  /** Price for ticket */
  price: z.string(),
  /** Price category for ticket */
  priceCategory: z.string(),
  /** Price type for ticket */
  priceType: z.string(),
  trxHeaderCode: z.string(),
  /** Transaction date */
  transactionDate: z.string(),
  /** Transaction time */
  transactionTime: z.string(),
  /** Order number */
  orderNumber: z.number(),
  /** Order note */
  orderNote: z.string(),
  /** Transcation status */
  status: ticketmasterTransactionStatusSchema,
  outletCode: z.string(),
  outletName: z.string(),
  cameFromCode: z.string(),
});

/**
 * Type definition for a Ticketmaster transaction, inferred from the {@link ticketmasterTransactionSchema}.
 */
export type TicketmasterTransaction = z.infer<
  typeof ticketmasterTransactionSchema
>;

/**
 * Defines the schema for the Ticketmaster API response.
 */
export const ticketmasterResponseSchema = z.object({
  /** Transactions returned by the Ticketmaster API. */
  transactions: z.array(ticketmasterTransactionSchema),
});

/**
 * Type definition for the response object returned by the Ticketmaster API call, inferred from the {@link ticketmasterResponseSchema}.
 */
export type TicketmasterResponse = z.infer<typeof ticketmasterResponseSchema>;

/**
 * Defines the schema for the possible request body of Ticketmaster API.
 */
export const ticketmasterRequestBodySchema = z.object({
  /** Start date of the query. */
  from: z.string(),
  /** End date of the query. */
  to: z.string(),
  /** Event IDs to query. */
  events: z.array(z.string()).optional(),
  /** Venue IDs to query. */
  venues: z.array(z.string()).optional(),
  admin: z.array(z.string()).optional(),
  /** Promoter IDs to query. */
  promoter: z.array(z.string()).optional(),
});

/**
 * Type definition for the request body of Ticketmaster API call, inferred from the {@link ticketmasterRequestBodySchema}.
 */
export type TicketmasterRequestBody = z.infer<
  typeof ticketmasterRequestBodySchema
>;

/**
 * Defines the schema for the Ticketmaster session response.
 */
export const ticketmasterSessionResponseSchema = z.object({
  /** The session ID returned by the Ticketmaster API. */
  sessionId: z.string(),
});
