import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { eventConfigConverter } from './eventConfigConverter';

/**
 * Returns a Firestore document reference for the event configuration with the given ID.
 * @param eventId - The ID of the event.
 * @returns A Firestore document reference for the event configuration.
 */
export function getEventConfigDocRef(eventId: string) {
  const firestore = getFirestore();
  const eventDocRef = doc(
    firestore,
    firestorePaths.eventConfigPath(eventId),
  ).withConverter(eventConfigConverter);
  return eventDocRef;
}
