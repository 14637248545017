import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Calls the cloud function responsible for generating slugs for all the content in the database.
 * @returns A Promise that resolves with the response from the cloud function.
 */
export async function generateSlugsFunction() {
  const functions = getFunctions(getApp(), REGION);
  return httpsCallable<undefined, CallableFunctionResponse>(
    functions,
    `migrations-${CloudFunctionNames.GENERATE_SLUGS}`,
  )(undefined);
}
