import { UsedIn } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

/**
 * Retrieves a list of {@link UsedIn} objects that reference the specified slide deck.
 * @param eventId The ID of the event that the slide deck belongs to.
 * @param trackId The ID of the track that the slide deck belongs to.
 * @param slideDeckId The ID of the slide deck to retrieve references for.
 * @returns A Promise that resolves with an array of `UsedIn` objects.
 */
export async function getSlideDeckUsedIn(
  eventId: string,
  trackId: string,
  slideDeckId: string,
) {
  const firestore = getFirestore();
  const usedInCollections = collectionGroup(
    firestore,
    firestorePaths.usedInCollectionId,
  );
  const path = firestorePaths.slideDeckPath(eventId, trackId, slideDeckId);
  const q = query(
    usedInCollections,
    where('sourceDocRefPath', '>=', path),
    where('sourceDocRefPath', '<=', `${path}/\uf8ff`),
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data() as UsedIn);
}
