import { deleteDoc } from 'firebase/firestore';
import { getSlideDeckDoc } from './getSlideDeckDoc';
import { getSlideDeckDocRef } from './getSlideDeckDocRef';

/**
 * Deletes a slide deck with the given ID from the specified event and track.
 *
 * @param eventId - The ID of the event that the slide deck belongs to.
 * @param trackId - The ID of the track that the slide deck belongs to.
 * @param slideDeckId - The ID of the slide deck to delete.
 * @returns A Promise that resolves when the slide deck has been deleted.
 * @throws If the slide deck with the given ID does not exist.
 */
export const deleteSlideDeck = async (
  eventId: string,
  trackId: string,
  slideDeckId: string,
): Promise<void> => {
  const docSnapshot = await getSlideDeckDoc(eventId, trackId, slideDeckId);
  const slideDeckRef = getSlideDeckDocRef(eventId, trackId, slideDeckId);
  if (docSnapshot.exists()) {
    await deleteDoc(slideDeckRef);
  } else {
    throw new Error('SlideDeck not found');
  }
};
