import {
  TrackPlaybackHistoryDoc,
  trackPlaybackHistorySchema,
} from '@livekatsomo/models';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';
import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

/**
 * Firestore data converter for converting Firestore documents to and from `TrackPlaybackHistoryDoc` objects.
 */
export const playbackHistoryConverter: FirestoreDataConverter<TrackPlaybackHistoryDoc> =
  {
    /**
     *
     * @param doc - The document to convert.
     * @param options - The snapshot options.
     * @returns A `TrackPlaybackHistoryDoc` object.
     */
    fromFirestore: (doc: QueryDocumentSnapshot, options: SnapshotOptions) => {
      const history = trackPlaybackHistorySchema.parse(
        doc.data(options),
        getFirestoreDocErrorMap(doc),
      );
      return {
        ...history,
        id: doc.id,
        docType: 'playbackHistory',
        sourceDocRefPath: doc.ref.path,
      };
    },
    /**
     * @param history - The `TrackPlaybackHistoryDoc` object to convert.
     * @returns Firestore document data.
     */
    toFirestore: (history) => {
      return {
        ...history,
        docType: 'playbackHistory',
      };
    },
  };
