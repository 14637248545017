import { firestorePaths } from '@livekatsomo/shared/config';
import { getCollection } from '@livekatsomo/web/firebase';
import { where } from 'firebase/firestore';
import { trackPublicConverter } from './trackPublicConverter';

/**
 * Validates if a track slug is unique within an event.
 * @param slug - The slug to validate.
 * @param track - The track object containing the eventId and optional id to exclude from the validation.
 * @param track.id - The optionl ID of the track to exclude from the validation.
 * @param track.eventId - The ID of the event to validate the slug against.
 * @returns Returns a Promise that resolves to 'Slug already in use' if the slug is already in use, otherwise undefined.
 */
export async function validateTrackSlug(
  slug: string,
  track: { id?: string; eventId: string },
): Promise<'Slug already in use' | undefined> {
  const tracksWithSameSlug = await getCollection(
    {
      collectionPath: firestorePaths.trackPublicCollectionPath,
      queryConstraints: [
        where('slug', '==', slug),
        where('eventId', '==', track.eventId),
      ],
    },
    trackPublicConverter,
  );
  if (tracksWithSameSlug.length > 0 && tracksWithSameSlug[0].id !== track.id) {
    return 'Slug already in use';
  }
  return;
}
