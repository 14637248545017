import { DownloadFileDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { downloadFileConverter } from './downloadFileConverter';

/**
 * Retrieves a list of downloadFiles from Firestore for a given parent path.
 * @param options - The options object.
 * @param options.parentPath - The parent path to retrieve downloadFiles for.
 * @param options.queryConstraints - An optional array of query constraints to apply to the query.
 * @param observer - The observer to notify of changes to the downloadFile list.
 * @returns A function to unsubscribe from the observer.
 */
export const getDownloadFiles = (
  options: { parentPath: string; queryConstraints?: QueryConstraint[] },
  observer: Observer<DownloadFileDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.downloadFileCollectionPath(
        options.parentPath,
      ),
      queryConstraints: orderBy('filename', 'asc'),
    },
    downloadFileConverter,
  );
};
