import { deleteDoc } from 'firebase/firestore';
import { getCustomerDocRef } from './getCustomerDocRef';
import { getCustomerDoc } from './getCustomerDoc';

/**
 * Deletes a customer with the given ID.
 * @param customerId - The ID of the customer to delete.
 * @returns A Promise that resolves when the customer is deleted.
 * @throws If the customer with the given ID is not found.
 */
export const deleteCustomer = async (customerId: string): Promise<void> => {
  const docSnapshot = await getCustomerDoc(customerId);
  const customerRef = getCustomerDocRef(customerId);
  if (docSnapshot.exists()) {
    await deleteDoc(customerRef);
  } else {
    throw new Error('Customer not found');
  }
};
