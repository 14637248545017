import { deleteDoc } from 'firebase/firestore';
import { getPreAuthorizedModeratorDoc } from './getPreAuthorizedModeratorDoc';

/**
 * Deletes a pre-authorized moderator document from Firestore.
 *
 * @param parentPath - The parent path of the pre-authorized moderator document.
 * @param preAuthorizedModeratorId - The ID of the pre-authorized moderator document to delete.
 * @returns A Promise that resolves when the document is successfully deleted.
 * @throws If the pre-authorized moderator document is not found.
 */
export const deletePreAuthorizedModerator = async (
  parentPath: string,
  preAuthorizedModeratorId: string,
): Promise<void> => {
  const docSnapshot = await getPreAuthorizedModeratorDoc(
    parentPath,
    preAuthorizedModeratorId,
  );
  if (docSnapshot.exists()) {
    await deleteDoc(docSnapshot.ref);
  } else {
    throw new Error('PreAuthorizedModerator not found');
  }
};
