import { AgendaItemDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { agendaItemConverter } from './agendaItemConverter';

/**
 * Retrieves an agenda item document from Firestore.
 * @param options - An object containing the `eventId` and `agendaItemId` of the agenda item to retrieve.
 * @param observer - An observer that listens for changes to the retrieved agenda item document.
 * @returns A function that can be called to unsubscribe from the query.
 * @throws An error if `agendaItemId` or `eventId` is not provided.
 */
export const getAgendaItem = (
  options: { eventId: string; agendaItemId: string },
  observer: Observer<AgendaItemDoc>,
) => {
  const { eventId, agendaItemId } = options;
  if (!agendaItemId) throw new Error('agendaItemId is required');
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.agendaItemPath(eventId, agendaItemId),
    },
    agendaItemConverter,
  );
};
