import {
  SlideQueueItemDoc,
  slideQueueItemSchema,
  SlideQueueItem,
} from '@livekatsomo/models';
import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Firestore data converter for SlideQueueItemDoc objects.
 */
export const slideQueueItemConverter = {
  /**
   *
   * @param doc - Firestore document snapshot.
   * @returns A SlideQueueItemDoc object.
   */
  fromFirestore: (doc: QueryDocumentSnapshot): SlideQueueItemDoc => {
    const data = slideQueueItemSchema.parse(
      doc.data(),
      getFirestoreDocErrorMap(doc),
    );
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'slideQueueItem',
    };
  },
  /**
   * @param data - A SlideQueueItemDoc object.
   * @returns A Firestore data object.
   */
  toFirestore: (data: PartialWithFieldValue<SlideQueueItem>): DocumentData => {
    return {
      ...data,
      docType: 'slideQueueItem',
    };
  },
};
