/**
 * An object containing Firestore paths for a user.
 */
export const firestorePathsForUser = {
  /**
   * Returns the path to the authorized user document for the given user ID.
   * @param parentPath - The parent path of the authorized user document.
   * @param userId - The ID of the authorized user.
   * @returns The path to the authorized user document.
   */
  authorizedUserPath: (parentPath: string, userId: string) =>
    `${parentPath}/authorized-user/${userId}`,

  /**
   * Returns the path to the authorized user collection.
   * @param parentPath - The parent path of the authorized user collection.
   * @returns The path to the authorized user collection.
   */
  authorizedUserCollectionPath: (parentPath: string) =>
    `${parentPath}/authorized-user`,

  /**
   * The ID of the authorized user collection.
   */
  authorizedUserCollectionId: 'authorized-user',

  /**
   * Returns the path to the user document for the given user ID.
   * @param userId - The ID of the user.
   * @returns The path to the user document.
   */
  userPath: (userId: string) => `user/${userId}`,

  /**
   * The path to the user collection.
   */
  userCollectionPath: 'user',

  /**
   * Returns the path to the pre-authorized user document for the given email.
   * @param email - The email of the pre-authorized user.
   * @returns The path to the pre-authorized user document.
   */
  preAuthorizedUser: (email: string) => `pre-authorized-user/${email}`,

  /**
   * The path to the pre-authorized user collection.
   */
  preAuthorizedUserCollectionPath: 'pre-authorized-user',

  /**
   * Returns the path to the playback history document for the given user ID and track ID.
   * @param userId - The ID of the user.
   * @param trackId - The ID of the track.
   * @returns The path to the playback history document.
   */
  trackPlaybackHistoryPath: (userId: string, trackId: string) =>
    `user/${userId}/playback-history/${trackId}`,

  /**
   * Returns the path to the playback history collection for the given user ID.
   * @param userId - The ID of the user.
   * @returns The path to the playback history collection.
   */
  playbackHistoryCollectionPath: (userId: string) =>
    `user/${userId}/playback-history`,
} as const;
