import { customerSchema } from './customer.model';
import { eventSchema } from './event.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { z } from 'zod';
import { themeOptionsSchema } from './theme.model';

/**
 * Defines the schema for an invitation object.
 */
export const invitationSchema = z.object({
  /** The email address of the invitee. */
  email: z.string().email(),
  /** The display name of the invitee. */
  displayName: z.string().nonempty('Please enter a name'),
  /** The ID of the user who sent the invitation. */
  inviterId: z.string(),
  /** The date the invitation was created. */
  createdAt: z.date(),
  /** The user ID of the invitee (filled when invitation is verified). */
  uid: z.string().optional(),
  /** The date the invitation was sent. */
  sentAt: timestampOrDateSchema.nullable(),
});

/**
 * Defines the schema for an invitation ({@link invitationSchema}) document in Firestore, extending the base {@link firestoreDocSchema}.
 * Includes a `docType` field with literal value of `'invitation'`.
 */
export const invitationDocSchema = invitationSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `invitation` */
    docType: z.literal('invitation'),
  });

/**
 * Represents the type of an invitation document, inferred from the {@link invitationDocSchema}.
 */
export type InvitationDoc = z.infer<typeof invitationDocSchema>;

/**
 * Schema for invitation form data.
 * Picks the `email` and `displayName` fields from the invitation schema.
 */
export const invitationFormSchema = invitationSchema.pick({
  email: true,
  displayName: true,
});

/**
 * Type definition for the values of an invitation form.
 *
 * @remarks
 * This type is inferred from the {@link invitationFormSchema} object.
 */
export type InvitationFormValues = z.infer<typeof invitationFormSchema>;

/**
 * Defines the schema for an invitation token, which contains information about a customer, event, and invitation.
 * Invitation can be either for a customer or an event.
 */
export const invitationTokenSchema = z.object({
  /** The ID of the customer associated with the invitation. */
  customerId: z.string(),
  /** The optional ID of the event associated with the invitation. */
  eventId: z.string().optional(),
  /** The ID of the invitation. */
  invitationId: z.string(),
  /** The timestamp of when the token was issued. */
  iat: z.number(),
});

export const moderatorInvitationEmailDataSchema = z.object({
  event: eventSchema.optional(),
  customer: customerSchema,
  user: z.object({
    email: z.string().email(),
  }),
});

export const invitationEmailDataSchema = z.object({
  event: eventSchema,
  customer: customerSchema,
  name: z.string(),
  invitationToken: z.string(),
  theme: themeOptionsSchema,
});
