/**
 * An object containing functions for constructing Firestore paths related to authorization.
 */
export const firestorePathsForAuthorization = {
  /**
   * Returns the path to the authorized user document for the given user ID.
   * @param parentPath - The path to the parent document or collection.
   * @param userId - The ID of the authorized user.
   * @returns The path to the authorized user document.
   */
  authorizationPath: (parentPath: string, userId: string) =>
    `${parentPath}/authorized-user/${userId}`,

  /**
   * Returns the path to the authorized user collection.
   * @param parentPath - The path to the parent document or collection.
   * @returns The path to the authorized user collection.
   */
  authorizationCollectionPath: (parentPath: string) =>
    `${parentPath}/authorized-user`,

  /**
   * The ID of the authorized user collection.
   */
  authorizationCollectionId: 'authorized-user',
} as const;
