import { FirestoreErrorCode } from 'firebase/firestore';
import { KatsomoAuthErrorCode } from './auth-error-codes';
import { KatsomoErrorCode, KatsomoErrorCodes } from './error-codes';
import { KatsomoPlayerErrorCode } from './player-errors';

/**
 * Defines a union type of error codes that can be used across the application.
 * Includes error codes for Katsomo, Katsomo authentication, Firestore, and Katsomo player.
 */
type ErrorCode =
  | KatsomoErrorCode
  | KatsomoAuthErrorCode
  | FirestoreErrorCode
  | KatsomoPlayerErrorCode;

/**
 * @class KatsomoError
 * @description Base class for all errors in the library.
 * @param message - Error message.
 * @param code - Error code.
 * @param {Error} [cause] - Error cause.
 *
 * @example
 * throw new KatsomoError('Something went wrong', KatsomoErrorCodes.NETWORK);
 *
 * @example
 * // Inspect error cause
 * if (error instanceof KatsomoError) {
 *  switch (error.code) {
 *    case KatsomoErrorCodes.GENERIC:
 *      console.log("It's a generic error", error.message);
 *      break;
 *
 *    case KatsomoErrorCodes.NETWORK:
 *      console.log("It's a network error", error.message);
 *      break;
 *
 *    default:
 *      break;
 *  }
 *}
 */
export class KatsomoError extends Error {
  private _onReset: (() => void)[] = [];
  constructor(
    message: string,
    public readonly code: ErrorCode = KatsomoErrorCodes.GENERIC,
    public readonly auth?: unknown,
    public readonly data?: unknown,
    public readonly cause?: Error,
  ) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this function is not available in lib.dom.d.ts
    if (Error.captureStackTrace) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore this function is not available in lib.dom.d.ts
      Error.captureStackTrace(this, KatsomoError);
    }

    this.name = 'KatsomoError';
  }
  // Add array of callbacks to be called when error is reset
  set onReset(callback: () => void) {
    this._onReset.push(callback);
  }

  // Call all registered callbacks to reset error
  reset() {
    while (this._onReset.length > 0) {
      const reset = this._onReset.pop();
      reset && reset();
    }
  }
}

export default KatsomoError;
