import { EventFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Sets an form document in Firestore with the provided form form data.
 * @param eventId - The event ID of the form document.
 * @param eventFormData - The event form data to set in the document.
 * @returns A Promise that resolves when the document has been successfully set.
 */
export async function setForm(
  eventId: string,
  formId: string,
  eventFormData: Partial<EventFormValues>,
): Promise<void> {
  const firestore = getFirestore();
  const formDocRef = doc(
    firestore,
    firestorePaths.eventFormPath(eventId, formId),
  );
  return setDoc(
    formDocRef,
    {
      ...eventFormData,
    },
    { merge: true },
  );
}
