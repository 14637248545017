import { ModeratorFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Adds a new moderator to the Firestore database.
 *
 * @param parentPath - The path of the parent document in Firestore.
 * @param moderatorFormData - The data of the moderator to be added.
 * @returns A promise that resolves when the moderator has been added to Firestore.
 */
export async function addModerator(
  parentPath: string,
  moderatorFormData: ModeratorFormValues,
): Promise<void> {
  const firestore = getFirestore();
  const auth = getAuth();
  const moderatorDocRef = doc(
    firestore,
    firestorePaths.preAuthorizedModerator(parentPath, moderatorFormData.email),
  );
  return setDoc(moderatorDocRef, {
    ...moderatorFormData,
    inviterId: auth.currentUser?.uid,
  });
}
