import { CustomerThemeEditorFormValues } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { ThemeOptions } from '@mui/material/styles';
import { createContext } from 'react';

/**
 * Represents the model for the ThemeEditorContext.
 */
export type ThemeEditorContextModel = {
  store: Store<ThemeOptions>;
  updateTheme: (theme: CustomerThemeEditorFormValues) => Promise<string>;
};

/**
 * The context for the ThemeEditor.
 */
export const ThemeEditorContext = createContext<
  ThemeEditorContextModel | undefined
>(undefined);
