import { FormDoc, Store } from '@livekatsomo/models';
import { DocumentData } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * The model for the survey form context.
 */
export type SurveyFormContextModel = {
  /** The store for the form. */
  store: Store<FormDoc | null>;
  /** Submission state */
  isSubmitted: boolean;
  /** Function to submit an form. */
  submitForm: (values: DocumentData) => Promise<void>;
};

/**
 * The context for event form.
 */
export const SurveyFormContext = createContext<
  SurveyFormContextModel | undefined
>(undefined);
