import {
  EventConfig,
  EventDoc,
  JournalEvent,
  LayoutConfiguration,
  Store,
} from '@livekatsomo/models';
import {
  UpdateEventFunction,
  UpdateEventThemeFunction,
  UploadEventPosterFunction,
  ValidateEventSlugFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the EventContext.
 */
export type EventContextModel = {
  store: Store<EventDoc>;
  uploadEventPoster: UploadEventPosterFunction;
  updateEvent: UpdateEventFunction;
  updateEventTheme: UpdateEventThemeFunction;
  updateEventLayout: (layout: LayoutConfiguration | null) => Promise<void>;
  validateEventSlug: ValidateEventSlugFunction;
  addJournalEvent: (journalEvent: JournalEvent) => Promise<void>;
};

/**
 * The context for the EventContext.
 */
export const EventContext = createContext<EventContextModel | undefined>(
  undefined,
);

/**
 * Represents the model for the EventAdminContext.
 */
export type EventAdminContextModel = {
  store: Store<EventConfig>;
  editConfig: (config: Partial<EventConfig>) => Promise<void>;
};

/**
 * The context for the EventAdminContext.
 */
export const EventAdminContext = createContext<
  EventAdminContextModel | undefined
>(undefined);
