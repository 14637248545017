import {
  CustomerComponentTypeSchema,
  LayoutConfiguration,
} from '@livekatsomo/models';

/**
 * The default layout configuration for the customer layout.
 */
export const defaultCustomerLayoutConfiguration = {
  layoutName: 'customerLayout',
  collections: {
    baseLayout: {
      type: 'area',
      sx: {
        gap: 4,
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'column',
      },
      items: ['topArea', 'featuredEventArea'],
    },
    topArea: {
      type: 'area',
      boxed: false,
      items: [CustomerComponentTypeSchema.enum.banner],
    },
    featuredEventArea: {
      type: 'area',
      boxed: false,
      items: ['featuredEventsCollection'],
    },
    [CustomerComponentTypeSchema.enum.banner]: {
      componentName: CustomerComponentTypeSchema.enum.banner,
      type: 'component',
      componentContainer: 'layout',
      boxed: true,
      sx: {
        padding: 0,
      },
    },
    [CustomerComponentTypeSchema.enum.events]: {
      componentName: CustomerComponentTypeSchema.enum.events,
      type: 'component',
      componentContainer: 'layout',
      sx: {
        padding: 2,
      },
    },
    [CustomerComponentTypeSchema.enum.featuredEvents]: {
      componentName: CustomerComponentTypeSchema.enum.featuredEvents,
      type: 'component',
      componentContainer: 'layout',
      sx: {
        padding: 2,
      },
    },
    [CustomerComponentTypeSchema.enum.vodEvents]: {
      componentName: CustomerComponentTypeSchema.enum.vodEvents,
      type: 'component',
      componentContainer: 'layout',
      sx: {
        padding: 2,
      },
    },
    [CustomerComponentTypeSchema.enum.categoryEvents]: {
      componentName: CustomerComponentTypeSchema.enum.categoryEvents,
      type: 'component',
      componentContainer: 'layout',
      sx: {
        padding: 2,
      },
    },
    featuredEventsCollection: {
      type: 'collection',
      boxed: false,
      items: [
        CustomerComponentTypeSchema.enum.featuredEvents,
        CustomerComponentTypeSchema.enum.categoryEvents,
        CustomerComponentTypeSchema.enum.events,
        CustomerComponentTypeSchema.enum.vodEvents,
      ],
    },
  },
} satisfies LayoutConfiguration;
