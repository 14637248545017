import { Asset } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadEventPosterFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getEventDocRef } from './getEventDocRef';

/**
 * Uploads a poster image file for an event to Firebase Storage and updates the event document in Firestore with the uploaded asset data.
 * @param file - The poster image file to upload.
 * @param event - The event document to update with the uploaded asset data.
 * @param setProgress - A function to update the upload progress.
 * @throws If there is an error during the upload process.
 */
export const uploadEventPoster: UploadEventPosterFunction = async (
  file,
  event,
  setProgress,
) => {
  const uploadDir = firestorePaths.assetCollectionPath(
    firestorePaths.eventPath(event.id),
  );

  const assetDoc = await uploadImageFile({
    file,
    filename: file.name,
    uploadDir,
    assetData: {
      purpose: ['poster'],
      alt: `${event.name} poster`,
    } satisfies Partial<Asset>,
    onUploadProgressChange: setProgress,
  });

  const eventDocRef = getEventDocRef(event.id);

  await updateDoc(eventDocRef, {
    poster: { ...assetDoc.data() },
  });
};
