/**
 * An object containing Firestore paths and collection IDs for tracks.
 */
export const firestorePathsForTrack = {
  /**
   * Returns the path for a private track document.
   * @param eventId The ID of the event the track belongs to.
   * @param trackId The ID of the track.
   * @returns The Firestore path for the private track document.
   */
  trackPrivatePath: (eventId: string, trackId: string) =>
    `event/${eventId}/track/${trackId}`,

  /**
   * The ID of the private track collection.
   */
  trackPrivateCollectionId: 'track',

  /**
   * Returns the path for the private track collection.
   * @param eventId The ID of the event the tracks belong to.
   * @returns The Firestore path for the private track collection.
   */
  trackPrivateCollectionPath: (eventId: string) => `event/${eventId}/track`,

  /**
   * Returns the path for a public track document.
   * @param trackId The ID of the track.
   * @returns The Firestore path for the public track document.
   */
  trackPublicPath: (trackId: string) => `/track-public/${trackId}`,

  /**
   * The ID of the public track collection.
   */
  trackPublicCollectionId: 'track-public',

  /**
   * The path for the public track collection.
   */
  trackPublicCollectionPath: `track-public`,
} as const;
