import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import { customerSlugSchema } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

/**
 * Retrieves the customer ID associated with the given slug.
 * @param slug - The slug to retrieve the customer ID for.
 * @returns A promise that resolves with the customer ID.
 * @throws If no customer is found for the given slug.
 */

export async function getCustomerIdFromSlug(slug: string) {
  const firestore = getFirestore();
  const customerSlugDoc = doc(firestore, firestorePaths.customerSlugPath(slug));

  const snapshot = await getDoc(customerSlugDoc);
  const data = customerSlugSchema.parse(snapshot.data());
  if (!data.customerId) {
    throw new KatsomoError(
      'No customer found for slug:' + slug,
      KatsomoErrorCodes.CUSTOMER_NOT_FOUND,
      undefined,
      {
        slug,
      },
    );
  }
  return data.customerId;
}
