/**
 * Object containing error codes used throughout the application.
 */
export const KatsomoErrorCodes = {
  GENERIC: 'app/generic-error',
  NETWORK: 'app/network-error',
  SLUG_NOT_FOUND: 'app/slug-not-found',
  CUSTOMER_NOT_FOUND: 'app/customer-not-found',
  EVENT_NOT_FOUND: 'app/event-not-found',
  NOT_FOUND: 'app/not-found',
  MULTIPLE_SLUG_RESULTS: 'app/multiple-slug-results',
  UNAUTHORIZED: 'app/unauthorized',
  INVALID_OR_EXPIRED_ACTION_CODE_FOR_PASSWORD_RESET:
    'app/password-reset-invalid-or-expired-action-code',
  FIRESTORE_ERROR: 'app/firestore-error',
  PERMISSION_DENIED: 'permission-denied',
  AUTHENTICATION_REQUIRED: 'app/authentication-required',
  MULTIPLE_SESSIONS_WITH_SAME_ID: 'app/multiple_sessions_with_same_id',
  MULTIPLE_SESSIONS_WITH_SAME_END_TIME:
    'app/multiple_sessions_with_same_end_time',
  MULTIPLE_SESSIONS_WITH_SAME_START_TIME:
    'app/multiple_sessions_with_same_start_time',
  NO_SESSION_TO_REPLACE_WITH: 'app/no_session_to_replace_with',
  USER_TOKEN_EXPIRED: 'app/user-token-expired',
} as const;

/**
 * Type alias for the values of the KatsomoErrorCodes object.
 */
export type KatsomoErrorCode =
  (typeof KatsomoErrorCodes)[keyof typeof KatsomoErrorCodes];
