import {
  FormValuesWithFiles,
  UpdateEventFormValues,
  UploadProgressFunction,
} from '@livekatsomo/types';
import { getDoc, updateDoc } from 'firebase/firestore';
import { getEventDocRef } from './getEventDocRef';
import { uploadEventPoster } from './uploadEventPoster';
import { updateEventSlug } from './updateEventSlug';

/**
 * Updates an event with the given form values.
 *
 * @param eventFormValues - The form values to update the event with.
 * @param onUploadProgressChange - A function to call when the upload progress changes.
 * @returns A promise that resolves when the event has been updated.
 */
export async function updateEvent(
  eventId: string,
  eventFormValues: FormValuesWithFiles<UpdateEventFormValues>,
  onUploadProgressChange?: UploadProgressFunction,
) {
  const { poster, ...eventData } = eventFormValues;
  const eventDocRef = getEventDocRef(eventId);
  const eventDoc = await getDoc(eventDocRef);
  const oldEvent = eventDoc.data();
  const promises: Promise<unknown>[] = [];

  if (Array.isArray(poster) && poster.length && eventData.name) {
    const { name } = eventData;
    uploadEventPoster(poster[0], { id: eventId, name }, onUploadProgressChange);
  }

  if (eventData.summary) {
    console.log(eventData.summary);
    if (eventData.summary.version === undefined) {
      throw new Error('Summary version is undefined');
    }
  }

  if (oldEvent && eventData.slug && oldEvent?.slug !== eventData.slug) {
    await updateEventSlug(
      oldEvent.slug,
      eventData.slug,
      oldEvent.customerId,
      eventId,
    );
  }

  promises.push(
    updateDoc(eventDocRef, {
      ...eventData,
      ...(poster && !Array.isArray(poster) && { poster }),
    }),
  );

  await Promise.all(promises);
}
