import { Asset } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadCustomerImageFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getCustomerDocRef } from './getCustomerDocRef';

/**
 * Uploads a customer image to the specified customer's asset collection in Firestore.
 * @param file - The image file to upload.
 * @param customer - The customer object to associate the image with.
 * @param purpose - The purpose of the image (e.g. "logo", "banner", etc.).
 * @param setProgress - A callback function to update the upload progress.
 * @param onUploadError - A callback function to handle upload errors.
 */
export const uploadCustomerImage: UploadCustomerImageFunction = async (
  file,
  customer,
  purpose,
  setProgress,
) => {
  const uploadDir = firestorePaths.assetCollectionPath(
    firestorePaths.customerPath(customer.id),
  );

  const assetDoc = await uploadImageFile({
    file,
    filename: file.name,
    uploadDir,
    assetData: {
      purpose: [purpose],
      alt: `${customer.name} ${purpose}`,
    } satisfies Partial<Asset>,
    onUploadProgressChange: setProgress,
  });
  await updateDoc(getCustomerDocRef(customer.id), {
    [purpose]: { ...assetDoc.data(), id: assetDoc.id },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
};
