import { EventDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { eventConverter } from './eventConverter';

/**
 * Get Event Data by providing event id.
 *
 * Will call given callback with event data for user when ever data is updated.
 *
 * @param options Options for getting event data.
 * @param options.eventId: id of the event to get.
 * @param observer Observer to call when data is updated.
 * @returns A function to unsubscribe from updates.
 */
export const getEventById = (
  options: { eventId: string },
  observer: Observer<EventDoc>,
) => {
  const { eventId } = options;
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.eventPath(eventId),
    },
    eventConverter,
  );
};
