import { NotificationMessageDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import {
  QueryConstraint,
  limitToLast,
  orderBy,
  where,
} from 'firebase/firestore';
import { notificationMessageConverter } from './notificationMessageConverter';

/**
 * Retrieves the latest notification messages for a given event and track.
 *
 * @param options - An object containing the eventId, trackId, and optional query constraints.
 * @param options.eventId - The ID of the event.
 * @param options.trackId - The ID of the track.
 * @param options.queryConstraints - Optional query constraints to apply to the query.
 * @param observer - An observer that listens for changes to the notification messages.
 * @returns A snapshot of the notification messages collection.
 */
export function getNewNotifications(
  options: {
    eventId: string;
    trackId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<NotificationMessageDoc[]>,
) {
  const { eventId, trackId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.notificationCollectionPath(
        eventId,
        trackId,
      ),
      queryConstraints: [
        where('createdAt', '>=', new Date()),
        orderBy('createdAt', 'asc'),
        limitToLast(1),
        ...(options.queryConstraints || []),
      ],
    },
    notificationMessageConverter,
  );
}
