import { EventDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getBySlug } from '@livekatsomo/web/firebase';
import { Unsubscribe, where } from 'firebase/firestore';
import { eventConverter } from './eventConverter';

/**
 * Get Event Data by providing event slug.
 *
 * Will call given callback with event data for user when ever data is updated.
 *
 * @param options Options for getting event data.
 * @param options.slug: slug of the event to get.
 * @param options.customerSlug: slug of the customer to get event from.
 * @param observer Observer to call when data is updated.
 * @returns A function to unsubscribe from updates.
 */

export const getEventBySlug = (
  options: {
    slug: string;
    customerSlug: string;
  },
  observer: Observer<EventDoc>,
): Unsubscribe => {
  const { slug, customerSlug } = options;
  if (!slug || !customerSlug)
    throw new Error('slug and customerSlug are required');

  const queryConstraints = [where('customer.slug', '==', customerSlug)];
  return getBySlug(
    observer,
    {
      collectionPath: firestorePaths.eventCollectionPath,
      queryConstraints,
      slug,
    },
    eventConverter,
  );
};
