/**
 * An object containing Firestore paths for downloadFiles.
 */
export const firestorePathsForDownloadFile = {
  /**
   * Returns the path for a specific downloadFile.
   * @param parentPath - The parent path of the downloadFile.
   * @param downloadFileId - The ID of the downloadFile.
   * @returns The path for the downloadFile.
   */
  downloadFilePath: (parentPath: string, downloadFileId: string) =>
    `${parentPath}/downloadFile/${downloadFileId}`,

  /**
   * The ID of the downloadFile collection.
   */
  downloadFileCollectionId: 'downloadFile',

  /**
   * Returns the path for the downloadFile collection.
   * @param parentPath - The parent path of the downloadFile collection.
   * @returns The path for the downloadFile collection.
   */
  downloadFileCollectionPath: (parentPath: string) =>
    `${parentPath}/downloadFile`,
} as const;
