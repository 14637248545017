import { getDoc } from 'firebase/firestore';
import { getDownloadFileDocRef } from './getDownloadFileDocRef';

/**
 * Retrieves the document snapshot for a specific downloadFile.
 * @param parentPath - The parent path of the downloadFile.
 * @param downloadFileId - The ID of the downloadFile.
 * @returns A promise that resolves with the document snapshot of the downloadFile.
 */
export async function getDownloadFileDoc(
  parentPath: string,
  downloadFileId: string,
) {
  const downloadFileRef = getDownloadFileDocRef(parentPath, downloadFileId);
  const docSnapshot = await getDoc(downloadFileRef);
  return docSnapshot;
}
