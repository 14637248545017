import { z } from 'zod';

/**
 * Defines the schema for a SendGrid email template.
 */
export const sendgridTemplateSchema = z.object({
  /** The ID of the template. */
  id: z.string(),
  /** The name of the template. */
  name: z.string(),
  /** The generation of the template. */
  generation: z.string(),
  /** The timestamp of when the template was last updated. */
  updated_at: z.string(),
  /** The versions of the template. */
  versions: z.array(z.object({})),
});

/**
 * Type definition for a Sendgrid email template inferred from the {@link sendgridTemplateSchema}.
 */
export type SendgridTemplate = z.infer<typeof sendgridTemplateSchema>;

/**
 * Defines a discriminated union schema for the response of a callable function that retrieves a Sendgrid template.
 * The response can either be a success object containing a message with the Sendgrid template schema,
 * or an error object containing a message with a string error description.
 */
export const getSendgridTemplateCallableResponseSchema = z.discriminatedUnion(
  'success',
  [
    z.object({
      /** literal success is `true` */
      success: z.literal(true),
      /** The Sendgrid template. */
      data: sendgridTemplateSchema,
    }),
    z.object({
      /** literal success is `false` */
      success: z.literal(false),
      /** The error message. */
      message: z.string(),
    }),
  ],
);

/**
 * Type definition for the response of a callable function that retrieves a Sendgrid template.
 * This type is inferred from the {@link getSendgridTemplateCallableResponseSchema}.
 */
export type GetSendgridTemplateCallableResponse = z.infer<
  typeof getSendgridTemplateCallableResponseSchema
>;
