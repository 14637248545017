import { NettilippuStatus, Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * The model for the NettilippuStatusContext.
 */
export type NettilippuStatusContextModel = {
  store: Store<NettilippuStatus | null>;
};

/**
 * The context for the NettilippuStatusContext.
 */
export const NettilippuStatusContext = createContext<
  NettilippuStatusContextModel | undefined
>(undefined);
