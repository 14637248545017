import { AssetDoc, AssetFormValues } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { uploadImageFile } from '../files/uploadImageFile';
import { updateDoc } from 'firebase/firestore';

/**
 * Uploads an asset to Firebase Storage and creates a corresponding document in Firestore.
 * @param assetFormData - The form data for the asset, including the file(s) to upload.
 * @param parentPath - The parent path for the asset in Firestore.
 * @param setProgress - An optional function to track the upload progress.
 * @returns A promise that resolves with the uploaded asset document.
 * @throws An error if the parent path is not provided.
 */
export const uploadAsset = async (
  assetFormData: AssetFormValues,
  parentPath: string,
  setProgress?: UploadProgressFunction,
): Promise<AssetDoc> => {
  const uploadDir = `${parentPath}/asset/`;
  const { files, ...formValues } = assetFormData;

  if (!parentPath) throw new Error('Parent path is required to upload asset');

  if (!files || !files.length) {
    throw new Error('No files provided to upload asset');
  }

  const assetDoc = await uploadImageFile({
    file: files[0],
    uploadDir,
    onUploadProgressChange: setProgress,
  });

  await updateDoc(assetDoc.ref, formValues);

  const data = assetDoc.data();
  if (!data) throw new Error('Asset document data is undefined');
  return data;
};
