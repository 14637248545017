import {
  CallableFunctionResponse,
  GetSendgridTemplateCallableResponse,
  Store,
  SystemConfig,
} from '@livekatsomo/models';
import { HttpsCallableResult } from 'firebase/functions';
import { createContext } from 'react';

/**
 * Represents the model for the SystemConfigContext.
 */
export type SystemConfigContextModel = {
  store: Store<SystemConfig>;
  updateSystemConfig: (config: Partial<SystemConfig>) => Promise<void>;
  getSendgridTemplate: (
    templateId: string,
  ) => Promise<HttpsCallableResult<GetSendgridTemplateCallableResponse>>;
  updateTicketmasterPassword: (
    password: string,
  ) => Promise<HttpsCallableResult<CallableFunctionResponse>>;
};

/**
 * The context for the SystemConfigContext.
 */
export const SystemConfigContext = createContext<
  SystemConfigContextModel | undefined
>(undefined);
