import { SystemConfig } from '@livekatsomo/models';
import { setDoc } from 'firebase/firestore';
import { getSystemConfigDocRef } from './getSystemConfigDocRef';

/**
 * Updates the system configuration document in Firestore with the provided data
 * @param systemConfig - The updated system configuration data.
 * @returns A promise that resolves when the system configuration has been updated.
 */
export function updateSystemConfig(systemConfig: SystemConfig): Promise<void> {
  const systemConfigDocRef = getSystemConfigDocRef();
  return setDoc(systemConfigDocRef, systemConfig, { merge: true });
}
