import { TicketmasterStatus, Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the TicketmasterStatusContext.
 */
export type TicketmasterStatusContextModel = {
  store: Store<TicketmasterStatus | null>;
};

/**
 * The context for the TicketmasterStatusContext.
 */
export const TicketmasterStatusContext = createContext<
  TicketmasterStatusContextModel | undefined
>(undefined);
