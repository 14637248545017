import { getDatabase, ref, serverTimestamp, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { CursorSession } from '@livekatsomo/models';
import { SendCurrentCursorPosition } from '@santicon/prosemirror-firebase-collaboration-cursor-plugin';

type SendCurrentCursorPositionWithDocPath = SendCurrentCursorPosition extends (
  ...args: infer Arguments
) => infer ReturnValue
  ? (docPath: string, ...options: Arguments) => ReturnValue
  : never;

/**
 * Updates the user's cursor position in the database for a specific document.
 * @param docPath - The path of the document in the database.
 * @param selection - The current selection of the user.
 * @param clientID - The ID of the client.
 * @param version - The version of the document.
 */
export const updateUserCursorPosition: SendCurrentCursorPositionWithDocPath =
  async (docPath, selection, clientID, version) => {
    const db = getDatabase();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) return;

    try {
      const sessionRef = ref(db, docPath + '/session/' + clientID);
      const userSession: CursorSession = {
        selection,
        clientID,
        version,
        user: { name: user.displayName || user.email || user.uid },
        lastActive:
          serverTimestamp() as unknown as number /*write-always server-set*/,
      };
      await set(sessionRef, userSession);
    } catch (error) {
      console.error(`Error writing User-Session: `, error);
    }
  };
