import { firestorePaths } from '@livekatsomo/shared/config';
import { DocumentData, doc, getDoc, getFirestore } from 'firebase/firestore';

export async function getSubmission(
  eventId: string,
  formId?: string | null,
  uid?: string,
): Promise<DocumentData | null> {
  if (!formId || !uid) return null;
  const firestore = getFirestore();
  const submmisionStatusRef = doc(
    firestore,
    firestorePaths.eventFormSubmissionPath(eventId, formId, uid),
  );
  const submissionDoc = await getDoc(submmisionStatusRef);
  if (submissionDoc.exists()) {
    return submissionDoc.data();
  }
  return null;
}
