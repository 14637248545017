import { Store, StreamDoc, UsedIn } from '@livekatsomo/models';
import {
  AddStreamFunction,
  DeleteStreamFunction,
  UpdateStreamFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the StreamsContext.
 */
export type StreamsContextModel = {
  store: Store<StreamDoc[]>;
  deleteStream: DeleteStreamFunction;
  updateStream: UpdateStreamFunction;
  addStream: AddStreamFunction;
  getStreamUsedIn: (streamId: string) => Promise<UsedIn[]>;
};

/**
 * The context for the StreamsContext.
 */
export const StreamsContext = createContext<StreamsContextModel | undefined>(
  undefined,
);
