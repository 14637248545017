import { Asset, AssetDoc } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { getApp } from 'firebase/app';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { getImageDimensions } from '../files/getImageDimensions';

const CHAT_IMAGES_BUCKET = 'rajucast-tv-chat-images';

/**
 * Uploads a chat image file to Google Cloud Storage and returns its asset data.
 * @param parentPath - The path of the parent document in Firestore.
 * @param file - The image file to upload.
 * @param setProgress - Optional function to update the upload progress.
 * @returns A Promise that resolves with the asset data of the uploaded image.
 */
export function uploadChatImage(
  parentPath: string,
  file: File,
  altText?: string,
  setProgress?: UploadProgressFunction,
) {
  const uploadDir = `${parentPath}`;
  const bucket = `gs://${CHAT_IMAGES_BUCKET}`;
  const storage = getStorage(getApp(), bucket);
  const filePath = `${uploadDir.replace(/\/+$/, '')}/${file.name}`;
  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file, {
    customMetadata: {
      parentDocPath: parentPath,
    },
  });
  return new Promise<Asset>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        // update progress
        setProgress &&
          setProgress((state) => ({
            ...state,
            [file.name]: { file, progress: percent },
          }));
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const assetData: Asset = {
            filename: file.name,
            type: file.type as AssetDoc['type'],
            originalUrl: downloadURL,
            filePath: filePath,
            alt: altText,
            bucket,
          };
          // If file is an image then save width and height
          if (file.type.startsWith('image/')) {
            const { width, height } = await getImageDimensions(file);
            assetData.width = width;
            assetData.height = height;
          }
          resolve(assetData);
        } catch (error) {
          reject(error);
        }
      },
    );
  });
}
