import {
  KatsomoError,
  KatsomoErrorCodes,
  KatsomoFirestoreError,
} from '@livekatsomo/custom-errors';
import { DocOptions, Observer } from '@livekatsomo/types';
import { getAuth } from 'firebase/auth';
import {
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';

/**
 * Get Firestore Data by documentId
 *
 * Will call given callback with data when ever data is updated.
 *
 * @param observer Observer to call callBack and onError with.
 * @param options Options for getting data.
 * - documentPath: path of the document to get.
 * @param converter FirestoreDataConverter to convert data.
 * @returns Unsubscribe function to unsubscribe from data.
 */
export function getById<Model, DbModelType extends DocumentData = DocumentData>(
  observer: Observer<Model>,
  options: DocOptions,
  converter?: FirestoreDataConverter<Model, DbModelType>,
): Unsubscribe {
  const firestore = getFirestore();

  let docRef = doc(firestore, options.documentPath) as DocumentReference<Model>;

  if (converter) docRef = docRef.withConverter(converter);

  console.log('calling onSnapshot', options.documentPath);

  return onSnapshot(
    docRef,
    (docSnapshot) => {
      console.log('getById', options, docSnapshot.data());
      if (!docSnapshot.exists())
        console.log('No data found', options),
          observer.error &&
            observer.error(
              new KatsomoError(
                `No data found ${options.documentPath}`,
                KatsomoErrorCodes.NOT_FOUND,
              ),
            );
      else {
        const data = docSnapshot.data();
        observer.next(data as Model);
      }
    },
    (error) => {
      observer.error &&
        observer.error(
          new KatsomoFirestoreError(
            'GetById error' + options.documentPath,
            error.code,
            getAuth(),
            error,
          ),
        );
    },
    observer.complete,
  );
}
