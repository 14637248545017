/**
 * An object containing Firestore paths for speakers.
 */
export const firestorePathsForSpeaker = {
  /**
   * Returns the Firestore path for a specific speaker.
   * @param eventId - The ID of the event.
   * @param speakerId - The ID of the speaker.
   * @returns The Firestore path for the speaker.
   */
  speakerPath: (eventId: string, speakerId: string) =>
    `event/${eventId}/speaker/${speakerId}`,

  /**
   * Returns the Firestore path for the collection of speakers for a specific event.
   * @param eventId - The ID of the event.
   * @returns The Firestore path for the collection of speakers.
   */
  speakerCollectionPath: (eventId: string) => `event/${eventId}/speaker`,
} as const;
