import { EventConfigDoc, FromFirestore } from '@livekatsomo/models';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

/**
 * Converts Firestore documents to and from `EventConfigDoc` objects.
 */
export const eventConfigConverter = {
  /**
   *
   * @param doc - Firestore document to convert.
   * @returns An `EventConfigDoc` object.
   */
  fromFirestore: (doc: QueryDocumentSnapshot): EventConfigDoc => {
    const { ...data } = doc.data() as FromFirestore<EventConfigDoc>;
    return {
      ...data,
      id: doc.id,
      docType: 'eventConfig',
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * Converts an `EventConfigDoc` object to a Firestore document.
   * @param event - The `EventConfigDoc` object to convert.
   * @returns A Firestore document.
   */
  toFirestore: (event: EventConfigDoc): DocumentData => {
    return { ...event, docType: 'eventConfig' };
  },
};
