import {
  LayoutConfiguration,
  Store,
  Track,
  TrackDoc,
  TrackFormValues,
} from '@livekatsomo/models';
import {
  ChangeTrackModeFunction,
  UpdateTrackThemeFunction,
  UploadProgressFunction,
  UploadTrackPosterFunction,
  ValidateTrackSlugFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the TrackContext.
 */
export type TrackContextModel = {
  store: Store<TrackDoc>;
  uploadTrackPoster: UploadTrackPosterFunction;
  updateTrack: (
    track: Partial<TrackFormValues> | Partial<Track>,
    setProgress?: UploadProgressFunction,
  ) => Promise<void>;
  updateTrackTheme: UpdateTrackThemeFunction;
  updateTrackLayout: (layout: LayoutConfiguration | null) => Promise<void>;
  changeTrackMode: ChangeTrackModeFunction;
  validateTrackSlug: ValidateTrackSlugFunction;
};

/**
 * The context for the TrackContext.
 */
export const TrackContext = createContext<TrackContextModel | undefined>(
  undefined,
);
