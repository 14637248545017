import {
  FormSubmissionDoc,
  formSubmissionDocumentSchema,
} from '@livekatsomo/models';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';
import {
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

/**
 * Firestore data converter for Submission objects.
 */
export const submissionConverter: FirestoreDataConverter<FormSubmissionDoc> = {
  fromFirestore: (doc, options) => {
    const data = formSubmissionDocumentSchema.parse(
      doc.data(options),
      getFirestoreDocErrorMap(doc),
    );

    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'formSubmission',
    };
  },
  toFirestore: (submission) => {
    const data: PartialWithFieldValue<FormSubmissionDoc> = {
      ...submission,
      docType: 'formSubmission',
    };
    return data;
  },
};
