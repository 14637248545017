import { SnapshotFunction } from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { getCustomerIdFromSlug } from './getCustomerIdFromSlug';

/**
 * Returns the customer ID associated with the given slug.
 *
 * @param options - The options object.
 * @param options.slug - The slug to search for.
 * @param options.queryConstraints - Optional query constraints to apply.
 * @param observer - The observer to notify of the result.
 * @param initialState - The initial state to use.
 * @returns A function that can be called to unsubscribe from the query.
 */
export const getCustomerIdBySlug: SnapshotFunction<
  { slug: string; queryConstraints?: QueryConstraint[] },
  string
> = (options, observer, initialState) => {
  if (initialState) {
    observer.next(initialState);
    return () => undefined;
  }
  const { slug } = options;
  getCustomerIdFromSlug(slug).then(observer.next).catch(observer.error);
  return () => undefined;
};
