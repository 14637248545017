import { AgendaItemFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  arrayUnion,
  collection,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { getTrackDocRef } from '../tracks';

/**
 * Adds a new agenda item to the specified track.
 * @param eventId - The ID of the event the track belongs to.
 * @param trackId - The ID of the track to add the agenda item to.
 * @param agendaItemFormData - The data for the new agenda item.
 * @returns A Promise that resolves when the agenda item has been added.
 */
export const addAgendaItem = async (
  eventId: string,
  trackId: string,
  agendaItemFormData: AgendaItemFormValues,
): Promise<void> => {
  const firestore = getFirestore();
  const agendaItemCollection = collection(
    firestore,
    firestorePaths.agendaItemsCollectionPath(eventId),
  );

  const agendaItemDocRef = await addDoc(
    agendaItemCollection,
    agendaItemFormData,
  );

  await updateDoc(getTrackDocRef(eventId, trackId), {
    agendaItems: arrayUnion(agendaItemDocRef.id),
  });
};
