/**
 * Returns the dimensions of an image file as a Promise.
 * @param file - The image file to get the dimensions of.
 * @returns A Promise that resolves with an object containing the width and height of the image.
 */
export const getImageDimensions = (file: File) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
};
