import { CustomerDoc, Store } from '@livekatsomo/models';
import {
  AddCustomerFunction,
  DeleteCustomerFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the CustomersContext.
 */
export type CustomersContextModel = {
  store: Store<CustomerDoc[]>;
  addCustomer: AddCustomerFunction;
  deleteCustomer: DeleteCustomerFunction;
  validateCustomerSlug: (
    slug: string,
    customer?: { id: string },
  ) => Promise<'Slug already in use' | undefined>;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
};

/**
 * The context for the CustomersContext.
 */
export const CustomersContext = createContext<
  CustomersContextModel | undefined
>(undefined);
