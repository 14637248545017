import { CustomerSlug } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

/**
 * Validates if a customer slug is already in use.
 * @param slug - The customer slug to validate.
 * @param customer - Optional customer object containing an id property when updating slug for existing customer.
 * @returns A promise that resolves to undefined if the slug is not in use, or to the string 'Slug already in use' if it is.
 */
export const validateCustomerSlug = async (
  slug: string,
  customer?: { id: string },
): Promise<'Slug already in use' | undefined> => {
  console.log('validateCustomerSlug', slug, customer);
  const firestore = getFirestore();
  const customerSlugRef = doc(firestore, firestorePaths.customerSlugPath(slug));
  const customerSlugDoc = await getDoc(customerSlugRef);
  if (!customerSlugDoc.exists()) {
    return;
  }
  const data = customerSlugDoc.data() as CustomerSlug;
  if (customer?.id && data.customerId === customer.id) {
    return;
  } else {
    return 'Slug already in use';
  }
};
