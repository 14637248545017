import { firestorePaths } from '@livekatsomo/shared/config';
import { collection, doc, getFirestore } from 'firebase/firestore';
import { eventConverter } from './eventConverter';

/**
 * Returns a Firestore document reference for the given event ID.
 * @param eventId - The ID of the event.
 * @returns The Firestore document reference for the event.
 */
export function getEventDocRef(eventId: string) {
  const firestore = getFirestore();
  const eventCollectionRef = collection(
    firestore,
    firestorePaths.eventCollectionPath,
  );
  const eventDocRef = doc(eventCollectionRef, eventId).withConverter(
    eventConverter,
  );
  return eventDocRef;
}
