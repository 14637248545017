import { ChatMessageDoc, FromFirestore } from '@livekatsomo/models';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

/**
 * Firestore data converter for ChatMessageDoc objects.
 */
export const chatMessageConverter: FirestoreDataConverter<ChatMessageDoc> = {
  /**
   * Converts a Firestore document to a ChatMessageDoc object.
   * @param doc The Firestore document to convert.
   * @returns The converted ChatMessageDoc object.
   */
  fromFirestore: (doc) => {
    const data = doc.data() as FromFirestore<
      Omit<ChatMessageDoc, 'createdAt'>
    > & {
      createdAt: Timestamp;
    };
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      createdAt: data.createdAt?.toDate(),
    };
  },
  /**
   * Converts a ChatMessageDoc object to a Firestore document.
   * @param chatMessage The ChatMessageDoc object to convert.
   * @returns The converted Firestore document.
   */
  toFirestore: (chatMessage) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, sourceDocRefPath: path, ...data } = chatMessage;
    return {
      ...data,
      docType: 'chatMessage',
    };
  },
};
