import { TrackDoc } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getEventDocRef } from '../events/getEventDocRef';

/**
 * Updates the order of tracks for a given event.
 * @param eventId - The ID of the event to update.
 * @param tracks - An array of TrackDoc objects representing the new order of tracks.
 * @returns A Promise that resolves when the update is complete.
 */
export function updateTrackOrder(
  eventId: string,
  tracks: TrackDoc[],
): Promise<void> {
  return updateDoc(getEventDocRef(eventId), {
    tracks: tracks.map((track) => track.id),
  });
}
