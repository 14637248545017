import { getDoc } from 'firebase/firestore';
import { getCustomerDocRef } from './getCustomerDocRef';

/**
 * Retrieves a customer document from Firestore.
 * @param customerId - The ID of the customer document to retrieve.
 * @returns A Promise that resolves with the DocumentSnapshot of the retrieved customer document.
 */

export async function getCustomerDoc(customerId: string) {
  const customerRef = getCustomerDocRef(customerId);
  const docSnapshot = await getDoc(customerRef);
  return docSnapshot;
}
