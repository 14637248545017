import { deleteDoc } from 'firebase/firestore';
import { getAssetDocRef } from './getAssetDocRef';
import { getAssetDoc } from './getAssetDoc';

/**
 * Deletes an asset with the given ID from the specified parent path.
 * @param assetId - The ID of the asset to delete.
 * @param parentPath - The parent path of the asset to delete.
 * @returns A Promise that resolves when the asset is deleted.
 * @throws An error if the asset is not found.
 */
export const deleteAsset = async (
  assetId: string,
  parentPath?: string,
): Promise<void> => {
  if (!parentPath) return Promise.resolve();

  const docSnapshot = await getAssetDoc(parentPath, assetId);
  const assetRef = getAssetDocRef(parentPath, assetId);
  if (docSnapshot.exists()) {
    await deleteDoc(assetRef);
  } else {
    throw new Error('Asset not found');
  }
};
