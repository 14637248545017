import Home from '@mui/icons-material/Home';
import Podcasts from '@mui/icons-material/Podcasts';
import Tv from '@mui/icons-material/Tv';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

/**
 * Renders the main menu items with icons and text.
 * @returns The main menu items component.
 */
export function MainMenuItems() {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <List>
        <ListItemButton LinkComponent={Link} href="/">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={t('Home')} />
        </ListItemButton>

        <ListItemButton LinkComponent={Link} href="/channels">
          <ListItemIcon>
            <Podcasts />
          </ListItemIcon>
          <ListItemText primary={t('Channels')} />
        </ListItemButton>

        <ListItemButton LinkComponent={Link} href="/events">
          <ListItemIcon>
            <Tv />
          </ListItemIcon>
          <ListItemText primary={t('Events')} />
        </ListItemButton>
      </List>
      <Divider />
    </>
  );
}

export default MainMenuItems;
