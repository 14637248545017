import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import StarOutline from '@mui/icons-material/StarOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'next-i18next';

/**
 * Props for the ListItemMenu component.
 * @typeParam Item The type of the item being displayed in the list.
 */
export interface ListItemMenuProps<Item> {
  /**
   * The currently selected item and its anchor element.
   */
  selectedItem: {
    item: Item;
    anchor: HTMLElement;
  } | null;
  /**
   *  The function to call when the menu is closed.
   */
  onClose: () => void;
  /**
   * The function to call when the "Edit" action is clicked.
   *
   * @param item - The item to edit.
   */
  onEdit?: (item: Item) => void;
  /**
   * The function to call when the "Delete" action is clicked.
   *
   * @param item - The item to delete.
   */
  onDelete?: (item: Item) => void;
  /**
   * The function to call when the "Set as current" action is clicked.
   *
   * @param item - The item to set as current.
   */
  onSetAsCurrent?: (item: Item) => void;
  /**
   * The function to call when the "Unset as current" action is clicked.
   *
   * @param item - The item to unset as current.
   */
  onUnSetAsCurrent?: (item: Item) => void;
  /**
   * The function to call when the "Copy" action is clicked.
   *
   * @param item - The item to copy.
   */
  onCopy?: (item: Item) => void;
  children?: React.ReactNode;
}

/**
 * A menu component that displays a list of actions that can be performed on a selected item.
 * @returns The rendered menu component.
 */
export function ListItemMenu<Item>({
  selectedItem,
  children,
  onClose,
  onEdit,
  onDelete,
  onSetAsCurrent,
  onUnSetAsCurrent,
  onCopy,
}: ListItemMenuProps<Item>) {
  const { t } = useTranslation();
  if (!selectedItem) {
    return null;
  }
  return (
    <Menu
      anchorEl={selectedItem.anchor}
      open={Boolean(selectedItem)}
      onClose={onClose}
      onClick={onClose}
    >
      {onEdit ? (
        <MenuItem onClick={() => onEdit(selectedItem.item)}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          {t('Edit')}
        </MenuItem>
      ) : null}

      {onDelete ? (
        <MenuItem onClick={() => onDelete(selectedItem.item)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          {t('Delete')}
        </MenuItem>
      ) : null}

      {onSetAsCurrent ? (
        <MenuItem onClick={() => onSetAsCurrent(selectedItem.item)}>
          <ListItemIcon>
            <StarOutline />
          </ListItemIcon>
          {t('Set as current')}
        </MenuItem>
      ) : null}

      {onUnSetAsCurrent ? (
        <MenuItem onClick={() => onUnSetAsCurrent(selectedItem.item)}>
          <ListItemIcon>
            <StarOutline />
          </ListItemIcon>
          {t('Unset as current')}
        </MenuItem>
      ) : null}
      {children}
    </Menu>
  );
}

export default ListItemMenu;
