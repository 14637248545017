import { CustomerDoc, customerSchema } from '@livekatsomo/models';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for CustomerDoc objects.
 */
export const customerConverter: FirestoreDataConverter<CustomerDoc> = {
  /**
   *
   * @param doc - The Firestore document to convert.
   * @param options - The options for the conversion.
   * @returns
   */
  fromFirestore: (doc, options) => {
    const customer = customerSchema.parse(
      doc.data(options),
      getFirestoreDocErrorMap(doc),
    );
    return {
      ...customer,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'customer',
    };
  },
  /**
   * Converts a CustomerDoc object to a Firestore document.
   * @param customer - The CustomerDoc object to convert.
   * @returns The Firestore document.
   */
  toFirestore: (customer) => ({
    ...customer,
    docType: 'customer',
  }),
};
