import { z } from 'zod';
import { roleSchema } from './role.model';
import { userSchema } from './user.model';

/**
 * Schema for an authorized user, which extends the base user schema with additional properties.
 * @extends userSchema
 */
export const authorizedUserSchema = userSchema.extend({
  /** literal document type is `authorizedUser` */
  docType: z.literal('authorizedUser'),
  /** The email address of the authorized user. */
  email: z.string().email(),
  /** The roles of the authorized user. */
  roles: z.array(roleSchema),
});

/**
 * Type definition for an authorized user, inferred from the {@link authorizedUserSchema}.
 */
export type AuthorizedUser = z.infer<typeof authorizedUserSchema>;
