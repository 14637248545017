/**
 * Batch an array into an array of arrays, where each sub-array is a batch of items from the input array.
 *
 * @remarks
 * Takes an array and a number as input arguments. The number represents the maximum size of each batch.
 * The function creates a new array called batchedArray and pushes the items from the input array into a new array called batch.
 * The batch array is pushed into the batchedArray array if the length of the batch array equals the input batch size.
 * The batch array is then emptied and the process is repeated until there are no items left in the input array.
 * The final batch array is pushed into the batchedArray array if the batch array is not empty.
 * @param array The input array to be batched.
 * @param batchSize The maximum size of each batch.
 * @returns An array of arrays, where each sub-array is a batch of items from the input array.
 */
export function batchArray<T>(array: T[], batchSize: number): T[][] {
  const batchedArray = [];
  let batch = [];
  for (const item of array) {
    batch.push(item);
    if (batch.length === batchSize) {
      batchedArray.push(batch);
      batch = [];
    }
  }
  if (batch.length > 0) {
    batchedArray.push(batch);
  }
  return batchedArray;
}
