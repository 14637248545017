import { SpeakerDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { speakerConverter } from './speakerConverter';

/**
 * Retrieves a speaker document from Firestore.
 *
 * @param options - An object containing the `eventId` and `speakerId` of the speaker document to retrieve.
 * @param options.eventId - The ID of the event the speaker belongs to.
 * @param options.speakerId - The ID of the speaker to retrieve.
 * @param observer - An observer that listens for changes to the retrieved speaker document.
 * @returns A Firestore document reference to the retrieved speaker document.
 * @throws An error if `speakerId` or `eventId` is not provided.
 */
export const getSpeaker = (
  options: { eventId: string; speakerId: string },
  observer: Observer<SpeakerDoc>,
) => {
  const { eventId, speakerId } = options;
  if (!speakerId) throw new Error('speakerId is required');
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.speakerPath(eventId, speakerId),
    },
    speakerConverter,
  );
};
