import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Generates missing images using a Cloud Function.
 * Migration function that generates missing resized images for assets in the database.
 * @returns A promise that resolves with the response from the Cloud Function.
 */
export async function generateMissingImagesFunction() {
  const functions = getFunctions(getApp(), REGION);
  return httpsCallable<undefined, CallableFunctionResponse>(
    functions,
    `migrations-${CloudFunctionNames.GENERATE_DOWNLOAD_URLS}`,
  )(null);
}
