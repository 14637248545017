import Chip, { ChipProps } from '@mui/material/Chip';
import { useTranslation } from 'next-i18next';

/**
 * A badge component that displays "Public" label.
 *
 * @param sx - The system style props.
 * @param props - The props passed to the Chip component.
 * @returns A PublicBadge component.
 */
export function PublicBadge({ sx, ...props }: ChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{
        ml: 1,
        ...sx,
      }}
      component="span"
      color="primary"
      label={t('Public')}
      size="small"
      {...props}
    />
  );
}

/**
 * A badge component that displays "Private" label.
 *
 * @param sx - The system style props.
 * @param props - The props passed to the Chip component.
 * @returns A PrivateBadge component.
 */
export function PrivateBadge({ sx, ...props }: ChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{
        ml: 1,
        ...sx,
      }}
      component="span"
      color="secondary"
      label={t('Private')}
      size="small"
      {...props}
    />
  );
}

/**
 * A badge component that displays "Channel Only" label.
 *
 * @param sx - The system style props.
 * @param props - The props passed to the Chip component.
 * @returns A ChannelOnlyBadge component.
 */
export function ChannelOnlyBadge({ sx, ...props }: ChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{
        ml: 1,
        ...sx,
      }}
      component="span"
      color="info"
      label={t('Channel Only')}
      size="small"
      {...props}
    />
  );
}
