import { z } from 'zod';

/**
 * An array of visibility options for a resource.
 * @readonly
 */
export const visibilityOptions = ['public', 'private', 'channel-only'] as const;

/**
 * Defines a schema for the visibility options available in the application ({@link visibilityOptions}).
 */
export const visibilitySchema = z.enum(visibilityOptions);

/**
 * Represents the type of a visibility option, inferred from the {@link visibilitySchema}.
 */
export type Visibility = z.infer<typeof visibilitySchema>;
