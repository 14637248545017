import { deleteDoc } from 'firebase/firestore';
import { getSubmissionDoc } from './getSubmissionDoc';

/**
 * Deletes an submission document from Firestore.
 * @param eventId - The ID of the event document.
 * @param formId - The ID of the form document.
 * @param submissionId - The ID of the submission document to delete.
 * @returns A Promise that resolves when the document is deleted.
 * @throws If the submission document is not found.
 */
export const deleteSubmission = async (
  eventId: string,
  formId: string,
  submissionId: string,
): Promise<void> => {
  const docSnapshot = await getSubmissionDoc(eventId, formId, submissionId);
  if (docSnapshot?.exists()) {
    await deleteDoc(docSnapshot.ref);
  } else {
    throw new Error('Submission not found');
  }
};
