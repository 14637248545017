import { FormSubmissionDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import {
  QueryConstraint,
  collection,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import { submissionConverter } from './submissionConverter';

export function getSubmissions(
  eventId: string,
  formId: string,
): Promise<FormSubmissionDoc[]>;
export function getSubmissions(
  options: {
    eventId: string;
    formId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<FormSubmissionDoc[]>,
): () => void;
export function getSubmissions(
  arg1:
    | string
    | {
        eventId: string;
        formId: string;
        queryConstraints?: QueryConstraint[];
      },
  arg2: Observer<FormSubmissionDoc[]> | string,
): Promise<FormSubmissionDoc[]> | (() => void) {
  if (typeof arg1 === 'object') {
    return getCollectionSnapshot(
      arg2 as Observer<FormSubmissionDoc[]>,
      {
        collectionPath: firestorePaths.eventFormSubmissionsCollectionPath(
          arg1.eventId,
          arg1.formId,
        ),
        queryConstraints: arg1.queryConstraints,
      },
      submissionConverter,
    );
  } else if (typeof arg1 === 'string' && typeof arg2 === 'string') {
    const firestore = getFirestore();
    const eventId = arg1;
    const formId = arg2;
    const collectionRef = collection(
      firestore,
      firestorePaths.eventFormSubmissionsCollectionPath(eventId, formId),
    ).withConverter(submissionConverter);
    const docs = getDocs(collectionRef);
    return docs.then((snapshot) => snapshot.docs.map((doc) => doc.data()));
  }
  throw new Error('Invalid arguments');
}
