import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { ServerConnectionContext } from '@livekatsomo/web/contexts';
import { getConnectionStatus } from '@livekatsomo/web/data';
import { Suspense } from 'react';

export type ServerConnectionProviderProps = {
  children: JSX.Element;
};

export function ServerConnectionProvider({
  children,
}: ServerConnectionProviderProps) {
  const store = getOrCreateMethodStore(getConnectionStatus, undefined, true);

  return (
    <ServerConnectionContext.Provider
      value={{
        store,
      }}
    >
      <Suspense>{children}</Suspense>
    </ServerConnectionContext.Provider>
  );
}
