import {
  Speaker,
  SpeakerDoc,
  SpeakerFormValues,
  speakerSchema,
} from '@livekatsomo/models';
import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Converts Firestore documents to and from `Speaker` objects.
 */
export const speakerConverter = {
  /**
   *
   * @param doc - The document to convert.
   * @param options - The snapshot options.
   * @returns A `SpeakerDoc` object.
   */
  fromFirestore: (
    doc: QueryDocumentSnapshot<SpeakerFormValues>,
    options: SnapshotOptions,
  ): SpeakerDoc => {
    const data = speakerSchema.parse(
      doc.data(options),
      getFirestoreDocErrorMap(doc),
    );
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'speaker',
    };
  },
  /**
   * Converts a `Speaker` object to a Firestore document.
   * @param speaker - The speaker to convert.
   * @returns A Firestore document.
   */
  toFirestore: (speaker: PartialWithFieldValue<Speaker>): DocumentData => {
    return {
      ...speaker,
      docType: 'speaker',
    };
  },
};
