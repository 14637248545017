import { FormSubmissionDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { DocumentReference, doc, getFirestore } from 'firebase/firestore';
import { submissionConverter } from './submissionConverter';

/**
 * Returns a Firestore DocumentReference for the form with the given ID.
 * The DocumentReference is configured with the formConverter to automatically
 * convert Firestore data to Submission objects.
 *
 * @param parentPath - The parent path of the form.
 * @param formId - The ID of the form.
 * @returns The DocumentReference for the form.
 */
export function getSubmissionDocRef(
  eventId: string,
  formId: string,
  submissionId: string,
): DocumentReference<FormSubmissionDoc> {
  const firestore = getFirestore();
  const submissionDocRef = doc(
    firestore,
    firestorePaths.eventFormSubmissionPath(eventId, formId, submissionId),
  ).withConverter(submissionConverter);
  return submissionDocRef;
}
