import { z } from 'zod';
import { firestoreDocSchema } from './firestore-doc.model';

/**
 * Defines the schema for a Tiptap document object.
 */
export const documentSchema = z.object({
  /** The content of the document */
  content: z.string(),
  /** The version number of the document */
  version: z.number(),
});

/**
 * This exports a documentDocSchema constant that extends the firestoreDocSchema
 * and adds a docType property with a value of 'document' to represent TipTap document.
 */
export const documentDocSchema = documentSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `document` */
    docType: z.literal('document'),
  });

/**
 * Represents a TipTap document firestore document object in the application.
 * This type is inferred from the {@link documentDocSchema}.
 */
export type DocumentDoc = z.infer<typeof documentDocSchema>;

/**
 * Represents a change made to a document by a client.
 */
export interface DocChange {
  /**
   * The ID of the client that made the change.
   */
  clientID: string | number;
  /**
   * The steps taken to make the change.
   */
  steps: string[];
  /**
   * The ID of the step in the document's history.
   */
  stepId: number;
}
