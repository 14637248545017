import { AgendaItemDoc, agendaItemSchema } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Firestore data converter for AgendaItemDoc.
 */
export const agendaItemConverter: FirestoreDataConverter<AgendaItemDoc> = {
  /**
   * Converts Firestore document to AgendaItemDoc object.
   * @param doc - Firestore document to convert.
   * @returns Converted AgendaItemDoc object.
   */
  fromFirestore: (doc) => {
    const data = agendaItemSchema.parse(
      doc.data(),
      getFirestoreDocErrorMap(doc),
    );
    return {
      ...data,
      docType: 'agendaItem',
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * Converts AgendaItemDoc object to Firestore document.
   * @param agendaItem - AgendaItemDoc object to convert.
   * @returns Converted Firestore document.
   */
  toFirestore: (agendaItem) => ({ ...agendaItem, docType: 'agendaItem' }),
};
