import { firestorePaths } from '@livekatsomo/shared/config';
import { AddPreAuthorizedUserFunction } from '@livekatsomo/types';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Adds a pre-authorized user to Firestore.
 *
 * @param userFormData - The user data to add.
 * @returns A Promise that resolves when the user data has been added.
 */
export const addPreAuthorizedUser: AddPreAuthorizedUserFunction = async (
  userFormData,
) => {
  const firestore = getFirestore();
  const preAuthorizedUserRef = doc(
    firestore,
    firestorePaths.preAuthorizedUser(userFormData.email),
  );
  await setDoc(preAuthorizedUserRef, userFormData);
};
