import { TrackPlaybackHistory } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { serverTimestamp, doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Stores a playback history event for a user in Firestore.
 * @param userId The ID of the user to store the history event for.
 * @param historyEvent The playback history event to store.
 * @returns A Promise that resolves when the history event has been stored.
 */
export function storeHistory(
  userId: string,
  historyEvent: Omit<TrackPlaybackHistory, 'timestamp'>,
) {
  const firestore = getFirestore();
  const historyRef = doc(
    firestore,
    firestorePaths.trackPlaybackHistoryPath(userId, historyEvent.trackId),
  );
  const historyEventWithTimestamp = {
    ...historyEvent,
    timestamp: serverTimestamp(),
  };
  return setDoc(historyRef, historyEventWithTimestamp, { merge: true });
}
