import { z } from 'zod';
import { firestoreDocSchema } from './firestore-doc.model';

/**
 * Defines the schema for a moderator user object.
 */
export const moderatorUserSchema = z.object({
  /** The unique identifier for the moderator user. */
  uid: z.string(),
  /** The display name for the moderator user. */
  displayName: z.string().nullish(),
  /** The email address for the moderator user. */
  email: z.string().email(),
  /** The URL for the moderator user's profile photo. */
  photoURL: z.string().nullish(),
});

/**
 * Defines the schema for a moderator document in Firestore.
 * The schema is a combination of the moderator user schema ({@link moderatorUserSchema})
 * and the Firestore document schema ({@link firestoreDocSchema})}),
 * with an additional `docType` property set to the literal value of `'moderator'`.
 */
export const moderatorDocSchema = moderatorUserSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `moderator` */
    docType: z.literal('moderator'),
  });

/**
 * Represents a moderator user object, inferred from the {@link moderatorUserSchema}.
 */
export type Moderator = z.infer<typeof moderatorUserSchema>;

/**
 * Type definition for a Moderator document, inferred from the {@link moderatorDocSchema}.
 */
export type ModeratorDoc = z.infer<typeof moderatorDocSchema>;

/**
 * Defines the schema for a pre-authorized moderator in Firestore.
 */
export const preAuthorizedModeratorSchema = firestoreDocSchema.extend({
  /** The email address for the pre-authorized moderator. */
  email: z.string().email(),
  /** The unique identifier of the user who invited the pre-authorized moderator. */
  inviterId: z.string(),
  /** The date when the invitation was sent. */
  sentAt: z.date().optional(),
  /** The literal document type is `preAuthorizedModerator`. */
  docType: z.literal('preAuthorizedModerator'),
});

/**
 * Represents a pre-authorized moderator in Firestore, inferred from the {@link preAuthorizedModeratorSchema}.
 */
export type PreAuthorizedModerator = z.infer<
  typeof preAuthorizedModeratorSchema
>;

/**
 * Represents the form values for creating a new moderator.
 * Only includes the email field.
 */
export type ModeratorFormValues = Pick<Moderator, 'email'>;
