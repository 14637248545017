import { Suspense, SuspenseProps } from 'react';
import { useIsClient } from '../useIsClient/useIsClient';

/**
 * Suspense component that only renders its children on the client-side.
 * @param children - The children to render.
 * @param fallback - The fallback component to render while the children are being loaded.
 * @returns The client-only suspense component.
 */
export function ClientOnlySuspense({ children, fallback }: SuspenseProps) {
  const isClient = useIsClient();

  if (!isClient) return null;

  return <Suspense fallback={fallback}>{children}</Suspense>;
}

export default ClientOnlySuspense;
