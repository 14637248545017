import { SponsorDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { sponsorConverter } from './sponsorConverter';

/**
 * Retrieves a snapshot of sponsors for a given event.
 *
 * @param options - An object containing the event ID.
 * @param options.eventId - The ID of the event to retrieve sponsors for.
 * @param observer - An observer that listens for changes to the snapshot.
 * @returns A function that can be called to unsubscribe from the query.
 */
export function getSponsors(
  options: { eventId: string },
  observer: Observer<SponsorDoc[]>,
) {
  const { eventId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.sponsorCollectionPath(eventId),
    },
    sponsorConverter,
  );
}
