import { FromFirestore, SponsorDoc, ToFirestore } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for SponsorDoc objects.
 */
export const sponsorConverter: FirestoreDataConverter<SponsorDoc> = {
  /**
   *
   * @param doc - The firestore document to convert.
   * @returns A `SponsorDoc` object.
   */
  fromFirestore: (doc): SponsorDoc => {
    const data = doc.data() as FromFirestore<SponsorDoc>;
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'sponsor',
    };
  },
  /**
   * Converts a `SponsorDoc` object to a Firestore document.
   * @param sponsor - The sponsor to convert.
   * @returns A Firestore document.
   */
  toFirestore: (sponsor: SponsorDoc): ToFirestore<SponsorDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = sponsor;
    return {
      ...data,
      docType: 'sponsor',
    };
  },
};
