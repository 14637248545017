import {
  DownloadFileDoc,
  FromFirestore,
  ToFirestore,
} from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for DownloadFileDoc.
 */
export const downloadFileConverter: FirestoreDataConverter<DownloadFileDoc> = {
  /**
   * Converts Firestore document to DownloadFileDoc.
   * @param doc - Firestore document to convert.
   * @returns Converted DownloadFileDoc.
   */
  fromFirestore: (doc): DownloadFileDoc => {
    const data = doc.data() as FromFirestore<DownloadFileDoc>;
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
    };
  },

  /**
   * Converts DownloadFileDoc to Firestore document.
   * @param downloadFile - DownloadFileDoc to convert.
   * @returns Converted Firestore document.
   */
  toFirestore: (
    downloadFile: DownloadFileDoc,
  ): ToFirestore<DownloadFileDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, sourceDocRefPath: path, ...data } = downloadFile;
    return {
      ...data,
      docType: 'downloadFile',
    };
  },
};
