import { firestorePaths } from '@livekatsomo/shared/config';
import { AddEventFunction } from '@livekatsomo/types';
import { addDoc, collection, getFirestore, setDoc } from 'firebase/firestore';
import { addTrack } from '../tracks';
import { eventConverter } from './eventConverter';
import { getAuthorizationDocRef } from '../authorization/getAuthorizationDocRef';
import { uploadEventPoster } from './uploadEventPoster';
import { setEventSlugDoc } from './setEventSlugDoc';

/**
 * Adds a new event to the Firestore database.
 *
 * @param eventFormData - The data for the new event.
 * @param onUploadProgressChange - A callback function to track the upload progress of the event poster.
 * @returns A Promise that resolves when the event has been successfully added to the database.
 */
export const addEvent: AddEventFunction = async (
  eventFormData,
  onUploadProgressChange,
) => {
  const firestore = getFirestore();
  const eventCollection = collection(
    firestore,
    firestorePaths.eventCollectionPath,
  ).withConverter(eventConverter);
  const { poster, ...event } = eventFormData;
  const eventDocRef = await addDoc(eventCollection, event);

  const promises: Promise<unknown>[] = [];

  promises.push(
    setEventSlugDoc(eventFormData.customerId, event.slug, eventDocRef.id),
  );

  if (Array.isArray(poster) && poster.length) {
    uploadEventPoster(
      poster[0],
      { id: eventDocRef.id, name: event.name },
      onUploadProgressChange,
    );
  }

  // create empty authorization
  promises.push(setDoc(getAuthorizationDocRef(eventDocRef.id), {}));

  promises.push(
    addTrack(eventDocRef.id, {
      name: 'Main track',
      eventId: eventDocRef.id,
      customerId: event.customerId,
      startTimeDelta: 0,
      mode: 'pre-show',
      slug: 'main-track',
    }),
  );

  await Promise.all(promises);
};
