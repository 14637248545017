import { SystemConfig, systemConfigSchema } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for SystemConfig objects.
 */
export const systemConfigConverter: FirestoreDataConverter<SystemConfig> = {
  /**
   *
   * @param doc - Firestore document to convert.
   * @returns A SystemConfig object.
   */
  fromFirestore: (doc) => {
    const data = systemConfigSchema.parse(doc.data());
    return data;
  },
  /**
   * @param systemConfig - SystemConfig object to convert.
   * @returns A Firestore document.
   */
  toFirestore: (systemConfig) => {
    return {
      ...systemConfig,
    };
  },
};
