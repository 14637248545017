import { doc, getFirestore } from 'firebase/firestore';
import { assetConverter } from '../assets/assetConverter';

/**
 * Returns a Firestore document reference for the asset file at the specified file path,
 * with a custom converter applied to the data.
 *
 * @param filePath - The path to the asset file in the project.
 * @returns A Firestore document reference for the asset file.
 */
export function getAssetDocRefFromFile(filePath: string) {
  const firestore = getFirestore();
  return doc(firestore, filePath).withConverter(assetConverter);
}
