import { DownloadFileDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collection,
  CollectionReference,
  doc,
  getFirestore,
} from 'firebase/firestore';
import { downloadFileConverter } from './downloadFileConverter';

/**
 * Returns a Firestore DocumentReference for the downloadFile with the given ID, located in the collection
 * with the given parent path.
 *
 * @param parentPath - The parent path of the downloadFile collection.
 * @param downloadFileId - The ID of the downloadFile to retrieve.
 * @returns A Firestore DocumentReference for the downloadFile.
 */
export function getDownloadFileDocRef(
  parentPath: string,
  downloadFileId: string,
) {
  const firestore = getFirestore();
  const downloadFileCollection = collection(
    firestore,
    firestorePaths.downloadFileCollectionPath(parentPath),
  ) as CollectionReference<DownloadFileDoc>;
  const downloadFileRef = doc(
    downloadFileCollection,
    downloadFileId,
  ).withConverter(downloadFileConverter);
  return downloadFileRef;
}
