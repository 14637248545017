import {
  where,
  FirestoreDataConverter,
  QueryConstraint,
} from 'firebase/firestore';
import { getCollectionSnapshot } from './getCollectionSnapshot';
import { DocBySlugOptions, Observer } from '@livekatsomo/types';
import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';

/**
 * Get Firestore Document Data by providing slug.
 *
 * Will call given callback with data when ever data is updated.
 *
 * @param observer Observer to call callBack and onError with.
 * @param options Options for getting data.
 * - slug: slug of the document to get.
 * @param converter FirestoreDataConverter to convert data.
 * @returns Unsubscribe function to unsubscribe from data.
 */
export function getBySlug<Model>(
  observer: Observer<Model>,
  options: DocBySlugOptions,
  converter?: FirestoreDataConverter<Model>,
) {
  if (!options.slug) throw new Error('slug is required');

  const { slug, ...rest } = options;

  let queryConstraints: QueryConstraint[] | undefined = [];

  if (Array.isArray(options.queryConstraints))
    queryConstraints = options.queryConstraints;
  else if (options.queryConstraints)
    queryConstraints = [options.queryConstraints];

  return getCollectionSnapshot(
    {
      next: (snapshot: Model[]) => {
        if (snapshot.length === 0) {
          observer.error &&
            observer.error(
              new KatsomoError(
                'No data found',
                KatsomoErrorCodes.SLUG_NOT_FOUND,
                {
                  slug,
                },
              ),
            );
        } else if (snapshot.length > 1) {
          observer.error &&
            observer.error(
              new KatsomoError(
                'Multiple data found',
                KatsomoErrorCodes.MULTIPLE_SLUG_RESULTS,
                { slug },
              ),
            );
        } else {
          observer.next(snapshot[0]);
        }
      },
      error: observer.error,
      complete: observer.complete,
    },
    {
      ...rest,
      queryConstraints: [where('slug', '==', slug), ...queryConstraints],
    },
    converter,
  );
}
