import {
  TicketmasterStatus,
  ticketmasterStatusSchema,
} from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for TicketmasterStatus objects.
 * @remarks This converter is used when reading and writing TicketmasterStatus objects to and from Firestore.
 */
export const ticketmasterStatusConverter: FirestoreDataConverter<TicketmasterStatus> =
  {
    /**
     *
     * @param snapshot - Firestore snapshot
     * @returns A TicketmasterStatus object
     */
    fromFirestore: (snapshot) => {
      const data = ticketmasterStatusSchema.parse(snapshot.data());
      return data;
    },
    /**
     * @param data - TicketmasterStatus object
     * @returns A TicketmasterStatus object
     */
    toFirestore: (data: TicketmasterStatus) => data,
  };
