import { deleteDoc } from 'firebase/firestore';
import { getChatMessageDocRef } from './getChatMessageDocRef';
import { getChatMessageDoc } from './getChatMessageDoc';

/**
 * Deletes a chat message with the given ID from the specified parent path.
 * @param chatMessageId - The ID of the chat message to delete.
 * @param parentPath - The parent path of the chat message to delete.
 * @returns A Promise that resolves when the chat message has been deleted.
 * @throws If the chat message is not found.
 */
export const deleteChatMessage = async (
  chatMessageId: string,
  parentPath: string,
): Promise<void> => {
  if (!parentPath) return Promise.resolve();

  const docSnapshot = await getChatMessageDoc(parentPath, chatMessageId);
  const chatMessageRef = getChatMessageDocRef(parentPath, chatMessageId);
  if (docSnapshot.exists()) {
    await deleteDoc(chatMessageRef);
  } else {
    throw new Error('ChatMessage not found');
  }
};
