/**
 * This file contains the schema definitions for a slide deck asset, which is a PDF file with additional metadata.
 * It defines the possible statuses for a slide deck object, the structure of a slide deck document in Firestore, and the form values schema.
 * @packageDocumentation
 */
import { z } from 'zod';
import { assetInDocumentSchema, assetSchema } from './asset.model';
import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';

/**
 * An array of slide deck statuses.
 * @readonly
 */
export const slideDeckStatus = [
  'uploaded',
  'processing',
  'processed',
  'error',
] as const;

export type SlideDeckStatus = (typeof slideDeckStatus)[number];

/**
 * A schema that defines the possible statuses for a slide deck object ({@link slideDeckStatus}).
 */
export const slideDeckStatusSchema = z.enum(slideDeckStatus);

/**
 * Schema for a slide deck asset, which is a PDF file with additional metadata.
 * Extends the base asset schema with a title, type, image (thumbnail), status, and page count.
 */
export const slideDeckSchema = assetSchema.extend({
  /** The title of the slide deck. */
  title: z.string(),
  /** literal content type is `application/pdf` */
  type: z.literal('application/pdf'),
  /** image is thumbnail created from pdf. Undefined until processing is complete. */
  image: assetInDocumentSchema.optional(),
  /** The status of the slide deck. */
  status: slideDeckStatusSchema,
  /** The number of pages in the slide deck.
   * Will be used to verify that when slidedeck is updated, the number of pages is the same.
   */
  pageCount: z.number(),
});

/**
 * This schema defines the structure of a slide deck document in Firestore.
 * It merges the {@link slideDeckSchema} and {@link firestoreDocSchema} schemas and extends it with a `docType` property
 * that is set to the literal string value `'slideDeck'`.
 */
export const slideDeckDocumentSchema = slideDeckSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `slideDeck` */
    docType: z.literal('slideDeck'),
  });

/**
 * This constant exports a schema for a slide deck in a document. It merges the {@link slideDeckSchema} and {@link usedInPropertySchema}
 * schemas and extends it with a `docType` property that has a literal value of `'slideDeck'`.
 */
export const slideDeckInDocumentSchema = slideDeckSchema
  .merge(usedInPropertySchema)
  .extend({
    /** literal document type is `slideDeck` */
    docType: z.literal('slideDeck'),
  });

/**
 * Type definition for a slide deck, inferred from the {@link slideDeckSchema}.
 */
export type SlideDeck = z.infer<typeof slideDeckSchema>;

/**
 * Type definition for a slide deck document, inferred from the {@link slideDeckDocumentSchema}.
 */
export type SlideDeckDoc = z.infer<typeof slideDeckDocumentSchema>;

/**
 * A schema for validating form values for a slide deck.
 */
export const slideDeckFormValuesSchema = z.object({
  /** The title of the slide deck. */
  title: z.string(),
  /** An optional array of files. */
  files: z.array(z.any()).optional(),
  /** The number of pages in the slide deck. */
  pageCount: z.number(),
  /** An optional filename for the slide deck. */
  filename: z.string().optional(),
});

/**
 * Type definition for the form values of a slide deck object, inferred from the {@link slideDeckFormValuesSchema}.
 */
export type SlideDeckFormValues = z.infer<typeof slideDeckFormValuesSchema>;
