import { Asset, AssetInDocument } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadTrackPosterFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getTrackDocRef } from './getTrackDocRef';
/**
 * Uploads a track poster image file to Firestore and updates the corresponding track document with the poster asset data.
 * @param file - The file to upload.
 * @param track - The track document to update with the poster asset data.
 * @param setProgress - A function to update the upload progress.
 * @returns A promise that resolves when the upload and update are complete.
 */
export const uploadTrackPoster: UploadTrackPosterFunction = async (
  file,
  track,
  setProgress,
) => {
  const uploadDir = `${firestorePaths.eventPath(track.eventId)}/asset/`;

  const assetDoc = await uploadImageFile({
    file,
    filename: file.name,
    uploadDir,
    assetData: {
      purpose: ['poster'],
      alt: `${track.name} poster`,
    } satisfies Partial<Asset>,
    onUploadProgressChange: setProgress,
  });

  const trackDocRef = getTrackDocRef(track.eventId, track.id);

  const data = assetDoc.data();

  if (!data) throw new Error('Asset document data is undefined');
  await updateDoc(trackDocRef, {
    poster: {
      ...data,
    } satisfies AssetInDocument,
  });
};
