import { InvitationFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { batchArray } from '@livekatsomo/shared/utils';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, writeBatch } from 'firebase/firestore';

/**
 * Adds invitations to Firestore.
 * @param parentPath - The parent path of the invitations.
 * @param invitations - The invitations to add.
 * @returns - A promise that resolves when the invitations have been added.
 */
export async function addInvitations(
  parentPath: string,
  invitations: InvitationFormValues[],
): Promise<void> {
  const firestore = getFirestore();
  const auth = getAuth();

  const batchedInvitations = batchArray(invitations, 500);
  for (const batch of batchedInvitations) {
    const firestoreBatch = writeBatch(firestore);
    for (const invitation of batch) {
      const invitationDocRef = doc(
        firestore,
        firestorePaths.invitationPath(parentPath, invitation.email),
      );
      firestoreBatch.set(
        invitationDocRef,
        {
          ...invitation,
          inviterId: auth.currentUser?.uid,
          sentAt: null,
        },
        { merge: true },
      );
    }
    await firestoreBatch.commit();
  }
}
