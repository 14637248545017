import { firestorePaths } from '@livekatsomo/shared/config';
import { DocumentReference, doc, getFirestore } from 'firebase/firestore';

/**
 * Returns a Firestore document reference for the authorization document of the given event.
 * @param eventId - The ID of the event.
 * @returns The Firestore document reference for the authorization document.
 */
export function getAuthorizationDocRef(eventId: string): DocumentReference {
  const firestore = getFirestore();
  const authorizationDocRef = doc(
    firestore,
    firestorePaths.eventConfigPath(eventId),
  );
  return authorizationDocRef;
}
