import { CustomFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { formConverter } from './formConverter';

/**
 * Adds forms to Firestore.
 * @param eventId - The parent path of the forms.
 * @param form - The form to add.
 * @returns - A promise that resolves when the forms have been added.
 */
export async function addForm(eventId: string, form: CustomFormValues) {
  const firestore = getFirestore();
  const formDocRef = collection(
    firestore,
    firestorePaths.eventFormCollectionPath(eventId),
  ).withConverter(formConverter);
  await addDoc(formDocRef, form);
}
