import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { setCustomerSlugDoc } from './setCustomerSlugDoc';
import { firestorePaths } from '@livekatsomo/shared/config';

/**
 * Updates the slug of a customer document in Firestore.
 *
 * @param oldSlug - The old slug of the customer document.
 * @param newSlug - The new slug to set for the customer document.
 * @param id - The ID of the customer document.
 * @returns A Promise that resolves when the slug has been updated.
 */
export async function updateCustomerSlug(
  oldSlug: string,
  newSlug: string,
  id: string,
) {
  const firestore = getFirestore();

  // delete old slug doc
  await deleteDoc(doc(firestore, firestorePaths.customerSlugPath(oldSlug)));
  // set new slug doc
  await setCustomerSlugDoc(newSlug, id);
}
