import { EnvVariablesFrontEnd } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * The model for the EnvironmentContext.
 */
export type EnvironmentContextModel = EnvVariablesFrontEnd & {
  development: boolean;
};

/**
 * The context for the EnvironmentContext.
 */
export const EnvironmentContext = createContext<
  EnvironmentContextModel | undefined
>(undefined);
