import { KatsomoFirestoreError } from '@livekatsomo/custom-errors';
import { CollectionGroupOptions, CollectionOptions } from '@livekatsomo/types';
import { getAuth } from 'firebase/auth';
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  OrderByDirection,
  Query,
  QueryConstraint,
  WhereFilterOp,
  collection,
  collectionGroup,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
} from 'firebase/firestore';

export type FirestoreFilters<Model> = {
  fieldPath: keyof Model extends string ? keyof Model : string;
  operator: WhereFilterOp;
  value: unknown;
}[];

export type OrderBy<Model> = {
  fieldPath: keyof Model extends string ? keyof Model : string;
  directionStr?: OrderByDirection;
};

/**
 * Get a collection of documents from a collection
 *
 * @param observer - An observer object with callback to call back
 * when the collection is updated.
 * @param options - An object with options for getting the collection.
 * - collectionPath: path of the collection to get.
 * - collectionId: id of the collection to get.
 * - queryConstraints: an array of query constraints to apply to the collection.
 * @param converter - A converter function to convert the data from the
 * firestore to the model and vice versa.
 * @returns unsubscribe function
 */
export function getCollectionSnapshot<
  Model,
  DbModelType extends DocumentData = DocumentData,
>(
  observer: {
    next: (record: Model[]) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
  options: {
    collectionPath?: string;
    collectionId?: string;
  } & (CollectionGroupOptions | CollectionOptions),
  converter?: FirestoreDataConverter<Model, DbModelType>,
) {
  const q = generateQuery<Model>(options, converter);

  // subscribe to query and return unsubscribe function
  return onSnapshot(
    q,
    (querySnapshot) => {
      // console.log('querySnapshot', querySnapshot);
      const objects: Model[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
        };
      });
      observer.next(objects);
    },
    (error) => {
      console.log('getCollectionSnapshot error', error);
      observer.error &&
        observer.error(
          new KatsomoFirestoreError(
            `getCollectionSnapshot error ${options.collectionPath}`,
            error.code,
            getAuth(),
            error,
          ),
        );
    },
    observer.complete,
  );
}

/**
 * Get a collection of documents from a collection.
 *
 * @param options - An object with options for getting the collection.
 * - collectionPath: path of the collection to get.
 * - collectionId: id of the collection to get.
 * - queryConstraints: an array of query constraints to apply to the collection.
 * @param converter - A converter function to convert the data from the
 * firestore to the model and vice versa.
 * @returns Promise with the collection of documents.
 */
export const getCollection = async <Model>(
  options: {
    collectionPath?: string;
    collectionId?: string;
  } & (CollectionGroupOptions | CollectionOptions),
  converter: FirestoreDataConverter<Model>,
): Promise<Model[]> => {
  const query = generateQuery<Model>(options, converter);

  const querySnapshot = await getDocs(query);

  const objects: Model[] = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });
  return objects;
};

export function generateQuery<Model>(
  options: {
    collectionPath?: string | undefined;
    collectionId?: string | undefined;
  } & (CollectionGroupOptions | CollectionOptions),
  converter?: FirestoreDataConverter<Model> | undefined,
) {
  const firestore = getFirestore();

  let collectionReference:
    | CollectionReference<Model>
    | Query<Model>
    | undefined = undefined;

  if (options.collectionPath) {
    collectionReference = collection(
      firestore,
      options.collectionPath,
    ) as CollectionReference<Model>;
  } else if (options.collectionId) {
    collectionReference = collectionGroup(
      firestore,
      options.collectionId,
    ) as Query<Model>;
  }

  if (!collectionReference) {
    throw new Error('collectionPath or collectionId is required');
  }

  let queryConstraints: QueryConstraint[] | undefined = [];

  if (Array.isArray(options.queryConstraints))
    queryConstraints = options.queryConstraints;
  else if (options.queryConstraints)
    queryConstraints = [options.queryConstraints];

  // create query
  let q = query(collectionReference, ...queryConstraints);

  // Set data converter if provided
  if (converter) q = q.withConverter(converter);
  return q;
}
