import { Link } from '@livekatsomo/web/ui-components/link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

/**
 * Props for a single breadcrumb item.
 */
export interface CrumbItemProps {
  /**
   * The text to display for the breadcrumb item.
   */
  text: string;
  /**
   * An optional function that generates the text to display for the breadcrumb item asynchronously.
   */
  textGenerator?: (() => Promise<string>) | null;
  /**
   * The URL to link to when the breadcrumb item is clicked.
   */
  href: string;
  /**
   * Whether this is the last breadcrumb item in the list.
   */
  last?: boolean;
}

/**
 * A component that represents a single breadcrumb item.
 *
 * @returns The rendered breadcrumb item.
 */
export function CrumbItem({
  text: defaultText,
  textGenerator,
  href,
  last = false,
}: CrumbItemProps) {
  const [text, setText] = useState(defaultText);
  const theme = useTheme();

  useEffect(() => {
    // If `textGenerator` is nonexistent, then don't do anything
    if (!textGenerator) {
      return;
    }
    // Run the text generator and set the text again
    textGenerator().then((text) => setText(text));
  }, [textGenerator]);

  if (last) {
    return (
      <Typography
        color={
          theme.katsomo?.breadcrumbs?.color
            ? theme.katsomo?.breadcrumbs?.color
            : 'text.primary'
        }
      >
        {text}
      </Typography>
    );
  }

  return (
    <Link
      underline="hover"
      color={
        theme.katsomo?.breadcrumbs?.color
          ? theme.katsomo?.breadcrumbs?.color
          : 'inherit'
      }
      href={href}
    >
      {text}
    </Link>
  );
}
