import { z } from 'zod';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';

export const FormFieldTypes = [
  'text',
  'textarea',
  'number',
  'email',
  'date',
  'select',
  'checkbox',
  'radio',
  'switch',
  // 'slider',
  // 'range',
  // 'rating',
  'autocomplete',
  // 'link',
] as const;

export const formItemSchema = z
  .object({
    name: z.string().nonempty(),
    label: z.string().nonempty(),
    defaultValue: z
      .union([z.string(), z.string().array(), z.boolean()])
      .optional(),
    placeholder: z.string().optional(),
    helperText: z.string().optional(),
    type: z.enum(FormFieldTypes),
    required: z.boolean().optional(),
    options: z.string().array().optional(),
  })
  .refine(
    (data) => {
      if (['select', 'checkbox', 'radio', 'autocomplete'].includes(data.type)) {
        return data.options && data.options.length > 0;
      }
      return true;
    },
    {
      params: {
        i18n: { key: 'There must be options' },
      },
    },
  );

export type FormField = z.infer<typeof formItemSchema>;

export const customFormSchema = z.object({
  name: z.string().nonempty(),
  description: z.string().optional(),
  items: z.array(formItemSchema).nonempty(),
});

export const formDocumentSchema = customFormSchema
  .merge(firestoreDocSchema)
  .extend({
    docType: z.literal('form'),
  });

export type FormDoc = z.infer<typeof formDocumentSchema>;

export type CustomFormValues = z.infer<typeof customFormSchema>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues = { [key: string]: any };

export const formSubmissionSchema = z.object({
  values: z.record(z.any()),
  createdAt: timestampOrDateSchema,
  updatedAt: timestampOrDateSchema.optional(),
});

export const formSubmissionDocumentSchema = formSubmissionSchema
  .merge(firestoreDocSchema)
  .extend({
    docType: z.literal('formSubmission'),
  });

export type FormSubmissionDoc = z.infer<typeof formSubmissionDocumentSchema>;
