import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { systemConfigConverter } from './systemConfigConverter';

/**
 * Returns a Firestore DocumentReference for the system configuration document.
 * A Firestore DocumentReference for the system configuration document.
 */
export function getSystemConfigDocRef() {
  const firestore = getFirestore();
  return doc(firestore, firestorePaths.systemConfigPath).withConverter(
    systemConfigConverter,
  );
}
