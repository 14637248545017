import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import { DocOptions, Observer } from '@livekatsomo/types';
import {
  DocumentReference,
  FirestoreDataConverter,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

export function getOnceById<Model>(
  observer: Observer<Model>,
  options: DocOptions,
  converter?: FirestoreDataConverter<Model>,
) {
  const firestore = getFirestore();

  let docRef = doc(firestore, options.documentPath) as DocumentReference<Model>;
  let isSubscribed = true;
  const unsubscribe = () => {
    isSubscribed = false;
  };

  if (converter) docRef = docRef.withConverter(converter);

  getDoc(docRef)
    .then((docSnapshot) => {
      if (!isSubscribed) return;
      if (!docSnapshot.exists())
        observer.error &&
          observer.error(
            new KatsomoError(
              `No data found ${options.documentPath}`,
              KatsomoErrorCodes.NOT_FOUND,
            ),
          );
      else {
        const data = docSnapshot.data();
        if (isSubscribed) observer.next(data as Model);
      }
    })
    .catch((error) => {
      if (!isSubscribed) return;
      observer.error &&
        observer.error(
          new KatsomoError(
            `Error getting data ${options.documentPath}`,
            KatsomoErrorCodes.NOT_FOUND,
            error,
          ),
        );
    });

  return unsubscribe;
}
