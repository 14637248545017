/**
 * This file contains functions related to authentication handlers using Firebase Auth.
 * It exports functions for signing in with email and password, signing in with social media providers (Google, Facebook, Twitter),
 * signing in anonymously, updating display name, logging out, requesting email verification, resetting password, and checking action codes.
 * It also exports a function for getting sign-in methods, and a Cloud Function name constant.
 * @packageDocumentation
 */
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import {
  ApplyActionCode,
  CheckActionCode,
  ConfirmPasswordReset,
  FacebookSignIn,
  GetSignInMethods,
  GoogleSignIn,
  IsSignInWithEmailLink,
  Logout,
  Method,
  ProfileUpdatedCallable,
  RequestEmailVerification,
  SendPasswordResetEmail,
  SignInAnonymously,
  SignInWithEmailAndPassword,
  SignInWithEmailLink,
  TwitterSignIn,
  VerifyPasswordResetCode,
} from '@livekatsomo/types';
import { getApp } from 'firebase/app';
import {
  ActionCodeInfo,
  AuthCredential,
  AuthError,
  AuthProvider,
  EmailAuthProvider,
  FacebookAuthProvider,
  User as FirebaseUser,
  GoogleAuthProvider,
  TwitterAuthProvider,
  applyActionCode as applyActionCodeWithFirebase,
  checkActionCode as checkActionCodeWithFirebase,
  confirmPasswordReset as confirmPasswordResetWithFirebase,
  fetchSignInMethodsForEmail,
  getAuth,
  isSignInWithEmailLink as isSignInWithEmailLinkWithFirebase,
  linkWithCredential as linkWithCredentialWithFirebase,
  linkWithPopup,
  reauthenticateWithCredential as reauthenticateWithCredentialWithFirebase,
  reauthenticateWithPopup as reauthenticateWithPopupWithFirebase,
  sendEmailVerification as sendEmailVerificationWithFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailWithFirebase,
  signInAnonymously as signInAnonymouslyWithFirebase,
  signInWithCredential,
  signInWithEmailAndPassword as signInWithEmailAndPasswordWithFirebase,
  signInWithEmailLink as signInWithEmailLinkWithFirebase,
  updateProfile,
  verifyPasswordResetCode as verifyPasswordResetCodeWithFirebase,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { reloadUser } from './authentication-observables';
import { signInWithProvider } from './signInWithProvider';

/**
 * Retrieves the sign-in methods associated with a given email address.
 * @param email The email address to retrieve sign-in methods for.
 * @returns A Promise that resolves with an array of sign-in methods.
 */
export const getSigninMethods: GetSignInMethods = async (email) => {
  const auth = getAuth();
  const methods = (await fetchSignInMethodsForEmail(auth, email)) as Method[];
  return methods;
};

/**
 * Signs in a user with their email and password using Firebase authentication.
 * @param email The user's email address.
 * @param password The user's password.
 */
export const signInWithEmailAndPassword: SignInWithEmailAndPassword = async (
  email,
  password,
) => {
  const auth = getAuth();
  const cred = await signInWithEmailAndPasswordWithFirebase(
    auth,
    email,
    password,
  );
  return cred.user;
};

/**
 * Signs in a user with an email link.
 * @param email The user's email address.
 * @param emailLink The email link to sign in the user.
 */
export const signInWithEmailLink: SignInWithEmailLink = async (
  email,
  emailLink,
) => {
  const auth = getAuth();
  window?.localStorage.removeItem('emailForSignIn');
  const cred = await signInWithEmailLinkWithFirebase(auth, email, emailLink);
  return cred.user;
};

/**
 * Signs in the user with Google authentication provider.
 * If there is already a signed-in user, it links the new account to the existing one.
 * @returns A promise that resolves when the sign-in process is complete.
 */
export const googleSignIn: GoogleSignIn = async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  if (auth.currentUser) {
    await linkWithProvider(auth.currentUser, provider);
    return auth.currentUser;
  } else {
    return signInWithProvider(provider);
  }
};

/**
 * Signs in the user with Facebook authentication provider.
 * If there is already a signed-in user, the provider is linked to the current user.
 * @returns A Promise that resolves when the sign-in process is complete.
 */
export const facebookSignIn: FacebookSignIn = async () => {
  const provider = new FacebookAuthProvider();
  const auth = getAuth();

  if (auth.currentUser) {
    await linkWithProvider(auth.currentUser, provider);
    return auth.currentUser;
  } else {
    return signInWithProvider(provider);
  }
};

/**
 * Signs in the user with Twitter authentication provider.
 * If there is a current user, it links the user with the Twitter provider.
 * Otherwise, it signs in the user with the Twitter provider.
 * @returns A Promise that resolves when the sign-in process is complete.
 */
export const twitterSignIn: TwitterSignIn = async () => {
  const provider = new TwitterAuthProvider();
  const auth = getAuth();
  if (auth.currentUser) {
    await linkWithProvider(auth.currentUser, provider);
    return auth.currentUser;
  } else {
    return signInWithProvider(provider);
  }
};

/**
 * Signs in the user anonymously and updates their display name if a nickname is provided.
 * @param nickname The nickname to update the user's display name with.
 * @returns A Promise that resolves when the sign-in process is complete.
 */
export const signInAnonymously: SignInAnonymously = async (
  nickname?: string,
) => {
  const auth = getAuth();
  const credentials = await signInAnonymouslyWithFirebase(auth);
  if (auth.currentUser && nickname) {
    await updateDisplayName(nickname);
    return auth.currentUser;
  }
  return credentials.user;
};

/**
 * Updates the display name of the current user's profile.
 * @param displayName The new display name to set.
 */
export const updateDisplayName: ProfileUpdatedCallable = async (
  displayName: string,
) => {
  const auth = getAuth();
  if (auth.currentUser) {
    await updateProfile(auth.currentUser, { displayName });
    reloadUser();
  }
};

/**
 * Logs out the current user.
 */
export const logout: Logout = async () => {
  const auth = getAuth();
  await auth.signOut();
};

/**
 * Sends an email verification link to the currently signed-in user's email address.
 *
 * @param email The email address of the currently signed-in user.
 * @returns A Promise that resolves when the email is sent.
 */
export const requestEmailVerification: RequestEmailVerification = async (
  email: string,
) => {
  const auth = getAuth();
  if (auth.currentUser) {
    try {
      await sendEmailVerificationWithFirebase(auth.currentUser, {
        url: window.location.href,
      });

      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      // Some error occurred, you can inspect the code: error.code
      console.error(error);
    }
  }
};

/**
 * Confirms a password reset with Firebase using the provided code and new password.
 * @param oobCode The password reset code sent to the user's email.
 * @param newPassword The new password to set for the user's account.
 * @returns A promise that resolves when the password reset is confirmed.
 */
export const confirmPasswordReset: ConfirmPasswordReset = (
  oobCode: string,
  newPassword: string,
) => {
  const auth = getAuth();
  return confirmPasswordResetWithFirebase(auth, oobCode, newPassword);
};

/**
 * Applies the given action code to the current user's account.
 * @param oobCode The action code to apply.
 * @returns A promise that resolves when the action code is applied.
 */
export const applyActionCode: ApplyActionCode = (oobCode: string) => {
  const auth = getAuth();
  return applyActionCodeWithFirebase(auth, oobCode);
};

/**
 * Checks the validity of a Firebase action code.
 * @param code The Firebase action code to check.
 * @returns Returns a Promise of ActionCodeInfo.
 */
export const checkActionCode: CheckActionCode = (
  code: string,
): Promise<ActionCodeInfo> => {
  const auth = getAuth();
  return checkActionCodeWithFirebase(auth, code);
};

/**
 * Verifies a password reset code with Firebase.
 * @param code The password reset code to verify.
 * @returns A Promise that resolves with email of the user if the code is valid,
 * or rejects with an error if the code is invalid.
 */
export const verifyPasswordResetCode: VerifyPasswordResetCode = (
  code: string,
): Promise<string> => {
  const auth = getAuth();
  return verifyPasswordResetCodeWithFirebase(auth, code);
};

/**
 * Sends a password reset email to the given email address using Firebase Authentication.
 * @param email The email address to send the password reset email to.
 * @returns A Promise that resolves when the password reset email has been sent.
 */
export const sendPasswordResetEmail: SendPasswordResetEmail = async (
  email: string,
) => {
  const auth = getAuth();
  await sendPasswordResetEmailWithFirebase(auth, email);
  return auth.currentUser;
};

/**
 * Calls the cloud function responsible for handling profile updates.
 * @param uid The user ID of the profile being updated.
 */
export const profileUpdatedCallable: ProfileUpdatedCallable = async (
  uid: string,
) => {
  const functions = getFunctions(getApp(), REGION);

  await httpsCallable<{ uid: string }>(
    functions,
    `userAuthFunctions-${CloudFunctionNames.PROFILE_UPDATED}`,
    {
      timeout: 140000,
    },
  )({ uid });
};

/**
 * Calls the Cloud Function responsible for handling user deletion requests.
 * @param uid The user ID of the user requesting deletion.
 */
export const requestDeletionCallable = async (uid: string) => {
  const functions = getFunctions(getApp(), REGION);

  await httpsCallable<{ uid: string }>(
    functions,
    `userAuthFunctions-${CloudFunctionNames.REQUEST_DELETION}`,
  )({ uid });
};

/**
 * Links a Firebase user account with an email authentication credential.
 * @param user The Firebase user account to link.
 * @param credential The authentication credential to link with the user account.
 * @returns A Promise that resolves when the user account is linked.
 */
export const linkWithCredential = async (
  user: FirebaseUser,
  credential: AuthCredential,
) => {
  const usercred = await linkWithCredentialWithFirebase(user, credential);
  console.log('Anonymous account successfully upgraded', usercred.user);
  // Update the user profile to match the provider account's profile
  await updateProfile(usercred.user, { ...usercred.user });
};

/**
 * Checks if the provided email link is a sign-in with email link.
 * @param emailLink The email link to check.
 * @returns A boolean indicating whether the email link is a sign-in with email link.
 */
export const isSignInWithEmailLink: IsSignInWithEmailLink = (emailLink) => {
  const auth = getAuth();
  return isSignInWithEmailLinkWithFirebase(auth, emailLink);
};

/**
 * Links the current user with the specified provider using a popup window.
 * If the user is anonymous, it will try to merge the anonymous account with the existing account.
 * @param currentUser The current Firebase user.
 * @param provider The provider to link with (Google, Facebook, or Twitter).
 * @returns A Promise that resolves when the user has been linked.
 */
export async function linkWithProvider(
  currentUser: FirebaseUser,
  provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider,
) {
  const auth = getAuth();
  try {
    const result = await linkWithPopup(currentUser, provider);
    console.log('Account successfully linked', result.user);
  } catch (error) {
    const authError = error as AuthError;
    if (authError.code !== 'auth/provider-already-linked') {
      if (currentUser.isAnonymous) {
        // Anonymous user is trying to link a provider with an existing account
        // TODO: Merge anonymous account with existing account
        const credential = GoogleAuthProvider.credentialFromError(authError);
        if (credential) {
          await signInWithCredential(auth, credential);
        }
      } else {
        throw error;
      }
    }
  }
}

/**
 * Reauthenticates the current user with the provided email and password credential.
 * @param email - The email of the user to reauthenticate.
 * @param password - The password of the user to reauthenticate.
 * @returns A Promise that resolves when the user has been reauthenticated, or rejects with an error message if there is no current user.
 */
export async function reauthenticateWithCredential(
  email: string,
  password: string,
) {
  const auth = getAuth();
  if (!auth.currentUser) {
    return Promise.reject('No user');
  }
  const credential = EmailAuthProvider.credential(email, password);
  const cred = await reauthenticateWithCredentialWithFirebase(
    auth.currentUser,
    credential,
  );
  return cred.user;
}

/**
 * Reauthenticates the current user with a popup using the specified provider.
 * @param provider The authentication provider to use (e.g. 'google.com', 'facebook.com', 'twitter.com').
 * @throws An error if the provider is invalid.
 * @throws An error if there is no current user.
 * @returns A Promise that resolves when the user has been reauthenticated.
 */
export async function reauthenticateWithPopup(
  provider: 'google.com' | 'facebook.com' | 'twitter.com',
) {
  const auth = getAuth();
  if (!auth.currentUser) {
    return Promise.reject('No user');
  }
  let providerInstance: AuthProvider;
  switch (provider) {
    case 'google.com':
      providerInstance = new GoogleAuthProvider();
      break;
    case 'facebook.com':
      providerInstance = new FacebookAuthProvider();
      break;
    case 'twitter.com':
      providerInstance = new TwitterAuthProvider();
      break;
    default:
      throw new Error('Invalid provider');
  }
  const cred = await reauthenticateWithPopupWithFirebase(
    auth.currentUser,
    providerInstance,
  );
  return cred.user;
}

/**
 * Force reloads the user's authentication token and updates the user's information.
 * @returns A Promise that resolves when the token has been reloaded and the user has been updated.
 */
export async function reloadUserToken(): Promise<void> {
  const auth = getAuth();
  await auth.currentUser?.getIdToken(true);
  reloadUser();
}

/**
 * Calls the cloud function to get the Tawk.to user hash.
 */
export const tawkToUserHashCallable = async () => {
  const functions = getFunctions(getApp(), REGION);

  return httpsCallable<
    never,
    { success: true; hash: string } | { success: false; message: string }
  >(
    functions,
    `authorizationFunctions-${CloudFunctionNames.ON_GET_TAWK_TO_USER_HASH}`,
  )();
};
