import type { QueryDocumentSnapshot } from 'firebase/firestore';
import type { QueryDocumentSnapshot as AdminQueryDocumentSnapshot } from 'firebase-admin/firestore';
import { ZodErrorMap } from 'zod';

/**
 * Returns an error map function that can be used with Zod to add a custom error message
 * for a Firestore document validation error.
 *
 * @param doc - The Firestore document to generate an error map for.
 * @returns An object containing the error map function.
 */
export function getFirestoreDocErrorMap(
  doc: QueryDocumentSnapshot | AdminQueryDocumentSnapshot,
) {
  const errorMap: ZodErrorMap = (_, ctx) => ({
    message: `Error in ${doc.ref.path}: ${ctx.defaultError}`,
  });
  return {
    errorMap,
  };
}
