import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';

/**
 * Updates the Ticketmaster password to Firestore.
 * @param password - The new password to set.
 * @returns A promise that resolves with the result of the callable function.
 * @throws An error if the password is not provided.
 */
export async function updateTicketmasterPassword(
  password: string,
): Promise<HttpsCallableResult<CallableFunctionResponse>> {
  if (!password) {
    throw new Error('Password is required');
  }
  const functions = getFunctions(getApp(), REGION);
  const result = await httpsCallable<
    {
      password: string;
    },
    CallableFunctionResponse
  >(
    functions,
    `authorizationFunctions-${CloudFunctionNames.ON_STORE_TICKETMASTER_PASSWORD}`,
  )({ password });
  return result;
}
