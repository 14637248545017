import type { ContainerTypeMap } from '@mui/material/Container';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { PaperTypeMap } from '@mui/material/Paper';
import type { Breakpoint } from '@mui/material/styles';
import type { BoxTypeMap } from '@mui/system/Box';
import type {
  CSSProperties,
  ForwardRefExoticComponent,
  ReactNode,
} from 'react';
import { z } from 'zod';

/**
 * Defines a type for a collection of React components that can be used in a layout.
 * The keys of the collection are either {@link EventComponentName} or {@link CustomerComponentName}.
 * The values of the collection are `ReactNode`s.
 */
export type Components = Partial<
  Record<EventComponentName | CustomerComponentName, ReactNode>
>;

/**
 * Defines the schema for the collection renderer, which can be one of the following:
 * - layout
 * - layoutWithCenterSlider
 */
export const collectionRendererSchema = z.enum([
  'layout',
  'layoutWithCenterSlider',
]);

/**
 * An array of available layout options for the application.
 */
export const layouts = ['eventLayout', 'customerLayout'] as const;

/**
 * Defines the schema for the layout type, which can be one of the following:
 * - eventLayout
 * - customerLayout
 */
export type LayoutType = (typeof layouts)[number];

/**
 * Defines the available layout renderers.
 */
export const layoutRenderers = {
  LAYOUT: 'layout',
  LAYOUT_WITH_CENTER_SLIDER: 'layoutWithCenterSlider',
} as const;

/**
 * Defines the layout renderer schema, which is an enumeration of two possible values:
 * - 'layout'
 * - 'layoutWithCenterSlider'
 */
export const layoutRendererSchema = z.enum([
  'layout',
  'layoutWithCenterSlider',
]);

/**
 * Type alias for a layout renderer function.
 */
export type LayoutRenderer =
  (typeof layoutRenderers)[keyof typeof layoutRenderers];

/**
 * Represents the configuration for rendering a layout, including the layout with a center slider.
 */
export type LayoutRenderingConfiguration = {
  [layoutRenderers.LAYOUT]:
    | OverridableComponent<
        | BoxTypeMap<LayoutProps & { boxed: false | undefined }, 'div'>
        | ContainerTypeMap<
            LayoutProps & {
              boxed: false | undefined;
              maxWitdh?: Breakpoint | false;
            },
            'div'
          >
        | PaperTypeMap<LayoutProps & { boxed: true }, 'div'>
      >
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ForwardRefExoticComponent<any>;
  [layoutRenderers.LAYOUT_WITH_CENTER_SLIDER]:
    | OverridableComponent<
        | BoxTypeMap<LayoutProps & { boxed: false | undefined }, 'div'>
        | ContainerTypeMap<
            LayoutProps & {
              boxed: false | undefined;
              maxWitdh?: Breakpoint | false;
              aspectRatio?: '16/9' | '24/9' | '32/9';
              children: ReactNode | [ReactNode, ReactNode];
            },
            'div'
          >
        | PaperTypeMap<LayoutProps & { boxed: true }, 'div'>
      >
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ForwardRefExoticComponent<any>;
};

/**
 * A type that maps the keys of a rendering configuration object to their corresponding values.
 *
 * @typeParam RenderingConfiguration - The type of the rendering configuration object.
 */
export type RenderingTypeMap<RenderingConfiguration> = {
  [key in keyof RenderingConfiguration]: RenderingConfiguration[key];
};

/**
 * Type alias for a rendering configuration object that maps rendering types to their respective configuration objects.
 */
export type RenderingConfiguration =
  RenderingTypeMap<LayoutRenderingConfiguration>;

/**
 * Represents a React component that serves as a container for layout elements.
 */
export type LayoutContainer = React.ComponentType;

/**
 * A type that represents the configuration for collections, components, and areas in a layout.
 *
 * It is a record that maps string keys to either a CollectionConfiguration, ComponentConfiguration, or AreaConfiguration.
 * Additionally, it includes a `baseLayout` property that represents the configuration for the base layout.
 */
export type CollectionsConfiguration = Record<
  string,
  CollectionConfiguration | ComponentConfiguration | AreaConfiguration
> & {
  baseLayout: AreaConfiguration;
};

/**
 * Defines the possible types of configurations for a layout.
 */
export const configurationTypeSchema = z.enum([
  'collection',
  'component',
  'area',
]);

/**
 * The type of configuration used in the application inferred from the {@link configurationTypeSchema}.
 */
export type ConfigurationType = z.infer<typeof configurationTypeSchema>;

/**
 * Enum representing the different types of components that can be used in an event layout.
 * @enum {string}
 */
export const EventComponentTypeSchema = z.enum([
  'player',
  'countdown',
  'details',
  'chat',
  'summary',
  'trackSummary',
  'slides',
  'slideThumbs',
  'agenda',
  'speakers',
  'sponsors',
  'wallsio',
  'slido',
  'speedTest',
  'browserTest',
  'surveyForm',
  'downloadFiles',
]);

/**
 * Represents the name of an event component.
 * It is inferred from the {@link EventComponentTypeSchema} schema.
 */
export type EventComponentName = z.infer<typeof EventComponentTypeSchema>;

/**
 * An enum representing the different types of customer components that can be used in the channel layout.
 *
 * @enum {string}
 * @readonly
 */
export const CustomerComponentTypeSchema = z.enum([
  'banner',
  'featuredEvents',
  'events',
  'vodEvents',
  'categoryEvents',
]);

/**
 * Represents the inferred type of the {@link CustomerComponentTypeSchema} schema.
 * Used as the type for the `CustomerComponentName` type alias.
 */
export type CustomerComponentName = z.infer<typeof CustomerComponentTypeSchema>;

/**
 * A schema for defining grid template columns for a layout.
 * It can be either a string or an object with optional properties for different screen sizes.
 * @example
 * // String value for grid template columns
 * const gridTemplateColumns = "1fr 1fr 1fr";
 *
 * // Object value with optional properties for different screen sizes
 * const gridTemplateColumns = {
 *   xs: "1fr",
 *   sm: "1fr 1fr",
 *   md: "2fr 1fr",
 *   lg: "3fr 1fr",
 *   xl: "3fr 1fr 1fr",
 * };
 */
export const gridTemplateColumnsSchema = z.union([
  z.string(),
  z.object({
    xs: z.string().optional(),
    sm: z.string().optional(),
    md: z.string().optional(),
    lg: z.string().optional(),
    xl: z.string().optional(),
  }),
]);

/**
 * Defines the schema for the `sx` property of a layout component.
 * The `sx` property is used to define styles for a layout component.
 */
export const sxSchema = z.object({
  /** The padding for the element. */
  padding: z.number().or(z.string()).optional(),
  /** The padding for the bottom of the element. */
  paddingBottom: z.number().or(z.string()).optional(),
  /** Direction for the flex layout */
  flexDirection: z.union([z.literal('row'), z.literal('column')]).optional(),
  /** Gap between elements in flex or grid layout */
  gap: z.number().or(z.string()).optional(),
  /** Display mode for the element */
  display: z.union([z.literal('flex'), z.literal('grid')]).optional(),
  /** How the content is aligned */
  alignContent: z
    .union([
      z.literal('center'),
      z.literal('flex-start'),
      z.literal('flex-end'),
    ])
    .optional(),
  /** How the items are aligned */
  alignItems: z
    .union([
      z.literal('center'),
      z.literal('flex-start'),
      z.literal('flex-end'),
      z.literal('stretch'),
    ])
    .optional(),
  /** How the content is justified */
  justifyContent: z
    .union([
      z.literal('center'),
      z.literal('flex-start'),
      z.literal('flex-end'),
      z.literal('space-between'),
      z.literal('space-around'),
      z.literal('space-evenly'),
    ])
    .optional(),
  /** Template for columns in grid display mode */
  gridTemplateColumns: gridTemplateColumnsSchema.optional(),
  /** Element width */
  width: z.string().optional(),
  /** Maximum width for the element */
  maxHeight: z.string().optional(),
  minWidth: z.string().or(z.number()).optional(),
});

/**
 * Defines a schema for maximum width values used in layout models.
 */
export const MaxWidthSchema = z.union([
  z.literal('xs'),
  z.literal('sm'),
  z.literal('md'),
  z.literal('lg'),
  z.literal('xl'),
  z.literal(false),
]);

/**
 * A schema for validating elevation values.
 * Elevation values must be non-negative integers less than or equal to 24.
 */
export const elevationSchema = z.number().nonnegative().int().lte(24);

/**
 * Available aspect ratio options for a layout.
 */
export const aspectRationOptions = ['16/9', '24/9', '32/9'] as const;

/**
 * Defines the schema for the layout options of a component.
 */
export const layoutSchema = z.object({
  /** An optional boolean value indicating whether the component should be boxed. */
  boxed: z.boolean().optional(),
  /** An optional number value indicating the elevation of the component. */
  elevation: elevationSchema.optional(),
  /** An optional boolean value indicating whether the component should be square. */
  square: z.boolean().optional(),
  /** An optional string value indicating the maximum width of the component. */
  maxWidth: MaxWidthSchema.optional(),
  /** An optional object value containing styles for the component. */
  sx: sxSchema.optional(),
  /** An optional boolean value indicating whether the last child of the component should be sticky. */
  stickyLastChild: z.boolean().optional(),
  /** An optional string value indicating the aspect ratio of the component. */
  aspectRatio: z.enum(aspectRationOptions).optional(),
});

/**
 * Type definition for the layout configuration object inferred from the {@link layoutConfigurationSchema}.
 */
export type LayoutConfiguration = z.infer<typeof layoutConfigurationSchema>;

/**
 * Type definition for LayoutProps, which is a combination of the inferred type from {@link layoutSchema}
 * and additional props for React components.
 */
export type LayoutProps = z.infer<typeof layoutSchema> & {
  /** The child components to be rendered within the layout. */
  children: ReactNode;
  /** Optional CSS class name to be applied to the layout. */
  className?: string;
  /** Optional inline styles to be applied to the layout. */
  style?: CSSProperties;
};

/**
 * A schema for defining the configuration of a collection layout.
 *
 * @remarks
 * This schema extends the {@link layoutSchema} and adds properties specific to a collection layout.
 */
export const collectionConfigurationSchema = layoutSchema.merge(
  z.object({
    /** The type of the configuration. */
    type: z.literal('collection'),
    /** Items to be rendered in the collection. */
    items: z.array(z.string()),
    /** The maximum number of children to be rendered in the collection. */
    maxChildCount: z.number().optional(),
    /** Flag indicating whether the collection should show a placeholder in empty slots. */
    showPlaceholderInEmptySlots: z.boolean().optional(),
    /** The optional layout renderer for the collection */
    collectionContainer: layoutRendererSchema.optional(),
  }),
);

/**
 * Type definition for the configuration of a collection layout inferred from the {@link collectionConfigurationSchema}.
 */
export type CollectionConfiguration = z.infer<
  typeof collectionConfigurationSchema
>;

/**
 * Defines the schema for the configuration of a component, including its container type, styling, and other properties.
 */
export const componentConfigurationSchema = z.object({
  /** The type of the configuration. */
  componentContainer: z.union([z.literal('layout'), z.literal('component')]),
  /** An optional object value containing styles for the component. */
  sx: sxSchema.optional().optional(),
  /** An optional boolean value indicating whether the component should be boxed. */
  boxed: z.boolean().optional(),
  /** An optional boolean value indicating whether the component should be square. */
  square: z.boolean().optional(),
  /** An optional number value indicating the elevation of the component. */
  elevation: elevationSchema.optional(),
  /** An optional string value indicating the maximum width of the component. */
  type: z.literal('component'),
  /** The name of the component. */
  componentName: z.string(),
});

/**
 * Type definition for the configuration of a component inferred from the {@link componentConfigurationSchema}.
 */
export type ComponentConfiguration = z.infer<
  typeof componentConfigurationSchema
>;

/**
 * A schema for defining the configuration of an area in a layout collection.
 * Extends the {@link collectionConfigurationSchema} and adds a `type` property with a value of `'area'`.
 */
export const areaConfigurationSchema = collectionConfigurationSchema.extend({
  type: z.literal('area'),
});

/**
 * Type definition for an area configuration object, inferred from the {@link areaConfigurationSchema}.
 */
export type AreaConfiguration = z.infer<typeof areaConfigurationSchema>;

/**
 * Defines the schema for the layout configuration object, which includes the layout name, layout container,
 * style properties, collections, and base layout.
 */
export const layoutConfigurationSchema = z.object({
  /** The name of the layout. */
  layoutName: z.enum(['eventLayout', 'customerLayout']),
  /** The layout container. */
  layoutContainer: layoutRendererSchema.optional(),
  /** An optional object value containing styles for the layout. */
  sx: sxSchema.optional(),
  /** Collections to be rendered in the layout. */
  collections: z
    .record(
      z.discriminatedUnion('type', [
        collectionConfigurationSchema,
        areaConfigurationSchema,
        componentConfigurationSchema,
      ]),
    )
    .and(
      z.object({
        baseLayout: areaConfigurationSchema,
      }),
    ),
});
