import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';
import { z } from 'zod';

const MAX_FILE_SIZE_IN_MB = 5; // 5mb

/**
 * An array of accepted image MIME types for assets.
 */
const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/svg+xml',
];

/**
 * An array of supported image formats for assets.
 */
export const formats = [
  'jpeg',
  'jpg',
  'png',
  'webp',
  'tif',
  'tiff',
  'gif',
  'svg',
] as const;

export const formatSchema = z.enum(formats);

export type Format = z.infer<typeof formatSchema>;

/**
 * A schema for validating an array of image files.
 *
 * The schema checks that all items in the array are instances of the File object,
 * that each file is less than or equal to a maximum file size (specified in {@link MAX_FILE_SIZE_IN_MB} ),
 * and that each file has an accepted image type (specified in {@link ACCEPTED_IMAGE_TYPES}).
 */
export const imageFileSchema = z
  .array(z.custom<File>())
  .refine(
    (files) => {
      // Check if all items in the array are instances of the File object
      return files.every((file) => file instanceof File);
    },
    {
      // If the refinement fails, show an error with this message
      params: { i18n: { key: 'Expected a file' } },
    },
  )
  .refine(
    (files) =>
      files.every((file) => file.size <= MAX_FILE_SIZE_IN_MB * 1024 * 1024),
    {
      // If the refinement fails, show an error with this message
      params: {
        i18n: {
          key: `File size should be less than {{MAX_FILE_SIZE_IN_MB}}mb.`,
          values: { MAX_FILE_SIZE_IN_MB: MAX_FILE_SIZE_IN_MB },
        },
      },
    },
  )
  .refine(
    (files) => files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file.type)),
    {
      params: {
        i18n: {
          key: 'Only these types are allowed .jpg, .jpeg, .png and .webp',
        },
      },
    },
  );

/**
 * An array of strings representing the available image sizes for assets.
 * The values are in the format of "{width}x{height}".
 * This sizes are used to generate the URLs for downloading the asset in different sizes.
 * with cloud function.
 */
export const usedImageSizes = [
  '200x200',
  '500x500',
  '1000x1000',
  '2000x2000',
] as const;

// Convert types to zod schema
export const UsedImageSizesSchema = z.enum(usedImageSizes);

export type UsedImageSizes = z.infer<typeof UsedImageSizesSchema>;

export const usedImageFormats = ['jpeg', 'png', 'webp'] as const;
export const usedImageFormatsSchema = z.enum(usedImageFormats);
export type UsedImageFormats = z.infer<typeof usedImageFormatsSchema>;

export type CombineFormatAndSize = `${UsedImageFormats}${UsedImageSizes}`;

export const downloadUrlsSchema = z.object({
  jpeg200x200: z.string().optional(),
  jpeg500x500: z.string().optional(),
  jpeg1000x1000: z.string().optional(),
  jpeg2000x2000: z.string().optional(),
  png200x200: z.string().optional(),
  png500x500: z.string().optional(),
  png1000x1000: z.string().optional(),
  png2000x2000: z.string().optional(),
  webp200x200: z.string().optional(),
  webp500x500: z.string().optional(),
  webp1000x1000: z.string().optional(),
  webp2000x2000: z.string().optional(),
});

export type DownloadUrls = z.infer<typeof downloadUrlsSchema>;

export const imagePurpose = [
  'logo',
  'background',
  'poster',
  'speaker',
  'banner',
] as const;

export const imagePurposeSchema = z.enum(imagePurpose);
export type ImagePurpose = z.infer<typeof imagePurposeSchema>;

export const mimeTypeSchema = z.union([
  z.literal('image/jpeg'),
  z.literal('image/svg+xml'),
  z.literal('image/png'),
  z.literal('image/webp'),
  z.literal('application/pdf'),
]);

/**
 * An array of possible status values for an asset.
 * The values are: 'uploading', 'uploaded', 'processing', 'ready', 'error'.
 */
const status = [
  'uploading',
  'uploaded',
  'processing',
  'processed',
  'error',
] as const;

/**
 * Defines a schema for the possible statuses of an asset ({@link status}).
 */
export const statusSchema = z.enum(status);

/**
 * The type definition for the AssetStatus, which is inferred from the {@link statusSchema}.
 */
export type AssetStatus = z.infer<typeof statusSchema>;

/**
 * Defines the schema for an asset object, which represents a file stored in a cloud storage bucket.
 */
export const assetSchema = z.object({
  /** The type of the asset (e.g. "image", "video", etc.). */
  type: z.string(),
  /** The name of the cloud storage bucket where the asset is stored. */
  bucket: z.string(),
  /** The name of the file that represents the asset. */
  filename: z.string(),
  /** An optional array of image purposes for the asset. */
  purpose: imagePurposeSchema.array().optional(),
  /** The path to the asset file within the cloud storage bucket. */
  filePath: z.string(),
  /** An optional alternative text description for the asset. */
  alt: z.string().optional(),
  /** An optional description of the asset. */
  description: z.string().optional(),
  /** The URL of the original asset file. */
  originalUrl: z.string(),
  /** An optional object containing URLs for downloading the asset in different formats. */
  downloadUrls: downloadUrlsSchema.optional(),
  /** An optional base64-encoded string representing a blurred version of the asset. */
  blurDataURL: z.string().optional(),
  /** An optional width value for the asset (in pixels). */
  width: z.number().optional(),
  /** An optional height value for the asset (in pixels). */
  height: z.number().optional(),
  /** An optional status value for the asset. */
  status: statusSchema.optional(),
});

/**
 * This schema defines the structure of an asset document in the database.
 * It merges the {@link assetSchema} and {@link usedInPropertySchema} and extends it with a `docType` field
 * set to the literal value of `'asset'`.
 */
export const assetInDocumentSchema = assetSchema
  .merge(usedInPropertySchema)
  .extend({
    docType: z.literal('asset'),
  });

/**
 * Represents the inferred type of an asset in a document ({@link assetInDocumentSchema}).
 */
export type AssetInDocument = z.infer<typeof assetInDocumentSchema>;

/**
 * Asset type inferred from the {@link assetSchema}.
 */
export type Asset = z.infer<typeof assetSchema>;

/**
 * Defines the schema for an asset document in Firestore, which includes the asset schema
 * and Firestore document schema, and extends it with a `docType` property set to `'asset'`.
 */
export const assetDocSchema = assetSchema.merge(firestoreDocSchema).extend({
  /** literal document type is asset */
  docType: z.literal('asset'),
});

/**
 * AssetDoc represents the inferred type of the {@link assetDocSchema}.
 * This type is used to define the shape of the asset documents in the firestore database.
 */
export type AssetDoc = z.infer<typeof assetDocSchema>;

/**
 * Defines a schema for the form values of an asset, which includes the asset's description, alt text, and purpose,
 * as well as an array of image files associated with the asset.
 */
export const assetFormValuesSchema = assetSchema
  .pick({
    description: true,
    alt: true,
    purpose: true,
  })
  .extend({
    /** An array of image files associated with the asset. */
    files: imageFileSchema,
  });

/**
 * Type definition for the form values of an asset.
 *
 * @remarks
 * This type is inferred from the {@link assetFormValuesSchema} schema.
 */
export type AssetFormValues = z.infer<typeof assetFormValuesSchema>;
