import { FormDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { DocumentReference, doc, getFirestore } from 'firebase/firestore';
import { formConverter } from './formConverter';

/**
 * Returns a Firestore DocumentReference for the form with the given ID.
 * The DocumentReference is configured with the formConverter to automatically
 * convert Firestore data to Form objects.
 *
 * @param parentPath - The parent path of the form.
 * @param formId - The ID of the form.
 * @returns The DocumentReference for the form.
 */
export function getFormDocRef(
  parentPath: string,
  formId: string,
): DocumentReference<FormDoc> {
  const firestore = getFirestore();
  const formDocRef = doc(
    firestore,
    firestorePaths.eventFormPath(parentPath, formId),
  ).withConverter(formConverter);
  return formDocRef;
}
