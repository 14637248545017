import { deleteDoc } from 'firebase/firestore';
import { getSponsorDoc } from './getSponsorDoc';
import { getSponsorDocRef } from './getSponsorDocRef';

/**
 * Deletes a sponsor from the database.
 * @param eventId - The ID of the event the sponsor belongs to.
 * @param sponsorId - The ID of the sponsor to delete.
 * @returns A Promise that resolves when the sponsor is deleted.
 * @throws If the sponsor is not found.
 */
export async function deleteSponsor(
  eventId: string,
  sponsorId: string,
): Promise<void> {
  const docSnapshot = await getSponsorDoc(eventId, sponsorId);
  const sponsorRef = getSponsorDocRef(eventId, sponsorId);
  if (docSnapshot.exists()) {
    await deleteDoc(sponsorRef);
  } else {
    throw new Error('Sponsor not found');
  }
}
