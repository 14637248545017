import { User } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { UserFormValues } from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the UsersContext.
 * This context is used to share the user data between components.
 */
export type UsersContextModel = {
  store: Store<User[]>;
  updateUser: (userFormData: UserFormValues) => Promise<void>;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
};

/**
 * The context for the UsersContext.
 */
export const UsersContext = createContext<UsersContextModel | undefined>(
  undefined,
);
