import { SlideQueueItemDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, QueryConstraint, where } from 'firebase/firestore';
import { slideQueueItemConverter } from './slideQueueItemConverter';

/**
 * Retrieves a snapshot of the slide queue for a given event and track.
 *
 * @param options - The options for the slide queue retrieval.
 * @param options.eventId - The ID of the event.
 * @param options.trackId - The ID of the track.
 * @param options.queryConstraints - The query constraints to apply to the slide queue retrieval.
 * @param observer - The observer to notify of changes to the slide queue.
 * @returns A function to unsubscribe from the slide queue observer.
 */
export const getSlideQueue = (
  options: {
    eventId: string;
    trackId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<SlideQueueItemDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.slideQueueCollectionPath(
        options.eventId,
        options.trackId,
      ),
      queryConstraints: [
        orderBy('timestamp', 'asc'),
        where('timestamp', '>', new Date(0)),
      ],
    },
    slideQueueItemConverter,
  );
};
