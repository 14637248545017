import { createContext } from 'react';
import type { CustomEventName } from 'firebase/analytics';

/**
 * The model for the AnalyticsContext.
 * @property logEvent - A function that logs an event with the given name and parameters.
 */
export type AnalyticsContextModel =
  | {
      logEvent: (
        /** The name of the event to log. */
        eventName: CustomEventName<string>,
        /** The parameters to log with the event. */
        eventParams?: Record<string, unknown>,
      ) => void;
    }
  | undefined;

/**
 * Context object for analytics tracking.
 */
export const AnalyticsContext = createContext<AnalyticsContextModel>(undefined);
