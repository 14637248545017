import { UpdateCustomerFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { getCustomerDocRef } from './getCustomerDocRef';
import { uploadCustomerImage } from './uploadCustomerImage';
import { getCustomerDoc } from './getCustomerDoc';
import { updateCustomerSlug } from './updateCustomerSlug';

/**
 * Updates a customer's data in the database and uploads any new images.
 *
 * @param customerFormValues - The updated customer data.
 * @param onUploadProgressChange - A callback function to track image upload progress.
 * @returns A promise that resolves when the update is complete.
 */
export const updateCustomer: UpdateCustomerFunction = async (
  customerFormValues,
  onUploadProgressChange,
) => {
  const { logo, poster, banner, ...customerData } = customerFormValues;
  const customerDoc = await getCustomerDoc(customerData.id);
  const oldCustomerData = customerDoc.data();

  if (Array.isArray(logo) && logo.length && customerFormValues.name) {
    const { id, name } = customerFormValues;
    uploadCustomerImage(logo[0], { id, name }, 'logo', onUploadProgressChange);
  }

  if (Array.isArray(poster) && poster.length && customerFormValues.name) {
    const { id, name } = customerFormValues;
    uploadCustomerImage(
      poster[0],
      { id, name },
      'poster',
      onUploadProgressChange,
    );
  }

  if (Array.isArray(banner) && banner.length && customerFormValues.name) {
    const { id, name } = customerFormValues;
    uploadCustomerImage(
      banner[0],
      { id, name },
      'banner',
      onUploadProgressChange,
    );
  }

  if (
    oldCustomerData &&
    customerData.slug &&
    oldCustomerData?.slug !== customerData.slug
  ) {
    await updateCustomerSlug(
      oldCustomerData.slug,
      customerData.slug,
      customerData.id,
    );
  }

  const customerDocRef = getCustomerDocRef(customerData.id);

  await updateDoc(customerDocRef, {
    ...customerData,
    ...(logo && !Array.isArray(logo) && { logo }),
    ...(poster && !Array.isArray(poster) && { poster }),
    ...(banner && !Array.isArray(banner) && { banner }),
  });
};
