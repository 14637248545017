import { UploadProgressFunction } from '@livekatsomo/types';
import { getAuth, reload, updateProfile } from 'firebase/auth';
import { uploadUserPhoto } from './uploadUserPhoto';
import { profileUpdatedCallable } from '../authentication';

/**
 * Updates the user's profile photo with the provided file.
 * @param file - The file to upload as the user's new profile photo.
 * @param setProgress - An optional function to track the upload progress.
 * @throws If the user is not authenticated.
 */
export async function updateUserPhoto(
  file: File,
  setProgress: UploadProgressFunction | undefined,
) {
  const auth = getAuth();
  if (!auth.currentUser) {
    throw new Error('User is not authenticated');
  }
  const asset = await uploadUserPhoto(auth.currentUser.uid, file, setProgress);
  updateProfile(auth.currentUser, {
    photoURL: asset.downloadUrls?.png500x500 || asset.originalUrl,
  });
  await profileUpdatedCallable(auth.currentUser.uid);
  await reload(auth.currentUser);
}
