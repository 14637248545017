/**
 * An object containing Firestore paths for streams.
 */
export const firestorePathsForStream = {
  /**
   * Returns the Firestore path for a specific stream.
   * @param customerId The ID of the customer.
   * @param streamId The ID of the stream.
   * @returns The Firestore path for the specified stream.
   */
  streamPath: (customerId: string, streamId: string) =>
    `customer/${customerId}/stream/${streamId}`,

  /**
   * Returns the Firestore path for the collection of streams for a specific customer.
   * @param customerId The ID of the customer.
   * @returns The Firestore path for the collection of streams for the specified customer.
   */
  streamCollectionPath: (customerId: string) => `customer/${customerId}/stream`,

  /**
   * The ID of the Firestore collection for streams.
   */
  streamCollectionId: `stream`,
} as const;
