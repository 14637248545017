/**
 * Firestore paths for sponsor.
 */
export const firestorePathsForSponsor = {
  /**
   * Returns the Firestore path for a specific sponsor.
   * @param eventId The ID of the event.
   * @param sponsorId The ID of the sponsor.
   * @returns The Firestore path for the sponsor.
   */
  sponsorPath: (eventId: string, sponsorId: string) =>
    `event/${eventId}/sponsor/${sponsorId}`,

  /**
   * Returns the Firestore path for the collection of sponsors for a specific event.
   * @param eventId The ID of the event.
   * @returns The Firestore path for the collection of sponsors.
   */
  sponsorCollectionPath: (eventId: string) => `event/${eventId}/sponsor`,
} as const;
