import { z } from 'zod';

/**
 * Defines the schema for the environment variables used in Firebase Functions.
 * These variables are used to store sensitive information such as passwords and API keys.
 */
export const firebaseFunctionsEnvVariablesSchema = z.object({
  NETTILIPPU_USERNAME: z.string(),
  NETTILIPPU_PASSWORD: z.string(),
  TICKETMASTER_USERNAME: z.string(),
  TICKETMASTER_APIKEY: z.string(),
  SENDGRID_API_KEY: z.string(),
  SENDGRID_GLOBAL_TEMPLATE_ID: z.string(),
  INVITATION_SECRET_KEY: z.string(),
  WOWZA_API_KEY: z.string(),
  USE_MOCK_NETTILIPPU_SERVER: z.string().optional(),
  USE_MOCK_TICKETMASTER_SERVER: z.string().optional(),
  USE_MOCK_SENDGRID_SERVER: z.string().optional(),
  DEFAULT_BUCKET: z.string(),
  PDF_BUCKET: z.string(),
  CHAT_IMAGES_BUCKET: z.string(),
  FUNCTION_REGION: z.string(),
  SENDER_EMAIL: z.string().email(),
});

/**
 * Defines the schema for environment variables used in the application.
 *
 * @remarks
 * This schema is used to validate the environment variables used in the application.
 */
export const envVariablesFrontEndSchema = z.object({
  FIREBASE_AUTH_EMULATOR_HOST: z.string().optional(),
  FIRESTORE_EMULATOR_HOST: z.string().optional(),
  FIREBASE_STORAGE_EMULATOR_HOST: z.string().optional(),
  FUNCTIONS_EMULATOR: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_API_KEY: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string().optional(),
  FIREBASE_CLIENT_EMAIL: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST: z.string().optional(),
  NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST: z.string().optional(),
  FIREBASE_PRIVATE_KEY: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_APP_ID: z.string().optional(),
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: z.string().optional(),
  NEXT_PUBLIC_TAWK_PROPERTY_ID: z.string().optional(),
  NEXT_PUBLIC_TAWK_WIDGET_ID: z.string().optional(),
  NODE_ENV: z
    .union([
      z.literal('development'),
      z.literal('production'),
      z.literal('test'),
    ])
    .optional(),
});

export type EnvVariablesFrontEnd = z.infer<typeof envVariablesFrontEndSchema>;

export const envVariablesNodeSchema = z.object({
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string(),
  FIREBASE_CLIENT_EMAIL: z.string(),
  FIREBASE_PRIVATE_KEY: z.string(),
  NO_DEBUG_DETAILS: z.string().optional(),
  STORYBOOK_CI: z.string().optional(),
  NEXT_PUBLIC_BITMOVIN_TOKEN: z.string(),
  NEXT_PUBLIC_GOOGLE_FONTS_API_KEY: z.string(),
  IMG_SIZES: z.string(),
  IMAGE_TYPE: z.string(),
  IS_ANIMATED: z.string().optional(),
  REVALIDATE_TOKEN: z.string(),
  NEXT_PUBLIC_VERCEL_URL: z.string(),
});
