import { KatsomoAuthErrorCodes } from './auth-error-codes';
import { KatsomoError } from './errors';
import { Method } from '@livekatsomo/types';
import { User } from '@livekatsomo/models';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';

/**
 * Type alias for the possible authentication error codes.
 */
export type AuthErrorCodes =
  (typeof KatsomoAuthErrorCodes)[keyof typeof KatsomoAuthErrorCodes];

/**
 * Custom error class for authentication errors.
 */
export class KatsomoAuthError extends KatsomoError {
  constructor(
    message: string,
    /**
     * The error code for this authentication error.
     */
    public override readonly code: AuthErrorCodes = KatsomoAuthErrorCodes.GENERIC_AUTHERROR,
    /**
     * Additional data associated with this authentication error.
     */
    public override readonly data: {
      email?: string | null;
      method?: Method;
      user?: User;
    } = {},
    cause?: Error,
  ) {
    super(message, code, data, cause);
  }
}

/**
 * Custom error class for when a user is already registered with another provider.
 */
export class UserAlreadyRegisteredWithOtherProviderError extends KatsomoAuthError {
  constructor(
    message: string,
    /**
     * Additional data associated with this error.
     */
    public override readonly data: {
      loginMethods: Method[];
      email: string;
      provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider;
    },
    cause?: Error,
  ) {
    super(message, KatsomoAuthErrorCodes.NEED_CONFIRMATION, data, cause);
  }
}

/**
 * Custom error class for when a user's email is not verified.
 */
export class EmailNotVerifiedError extends KatsomoAuthError {
  constructor(
    message: string,
    /**
     * Additional data associated with this error.
     */
    public override readonly data: {
      email: string;
    },
    cause?: Error,
  ) {
    super(message, KatsomoAuthErrorCodes.EMAIL_NOT_VERIFIED, data, cause);
  }
}

/**
 * Custom error class for when a user has no email associated with their account.
 */
export class NoEmailError extends KatsomoAuthError {
  constructor(
    message: string,
    /**
     * Additional data associated with this error.
     */
    public override readonly data: {
      user: User;
    },
    cause?: Error,
  ) {
    super(message, KatsomoAuthErrorCodes.NO_EMAIL, data, cause);
  }
}
