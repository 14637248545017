import {
  AuthorizationProvider,
  CallableFunctionResponse,
} from '@livekatsomo/models';
import { HttpsCallableResult } from 'firebase/functions';
import { createContext } from 'react';

/**
 * Authorization context model.
 */
export type AuthorizationContextModel = {
  /**
   * Validates the event code.
   * @param eventId - The event ID.
   * @param eventCode - The event code.
   * @param authorizationProvider - The authorization provider.
   * @returns A promise that resolves with the HTTPS callable result.
   */
  validateEventCode: (
    eventId: string,
    eventCode: string,
    authorizationProvider: AuthorizationProvider,
  ) => Promise<HttpsCallableResult<CallableFunctionResponse>>;

  /**
   * Verifies the invitation token.
   * @param token - The invitation token.
   * @returns A promise that resolves with the HTTPS callable result.
   */
  verifyInvitation: (
    token: string,
  ) => Promise<HttpsCallableResult<CallableFunctionResponse>>;
};

/**
 * Authorization context.
 */
export const AuthorizationContext = createContext<
  AuthorizationContextModel | undefined
>(undefined);
