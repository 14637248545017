import { User } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Requests the deletion of a user.
 * @param user The user to be deleted.
 */
export async function requestUserDeletion(user: User) {
  const functions = getFunctions(getApp(), REGION);

  await httpsCallable<{ uid: string }>(
    functions,
    `userAuthFunctions-${CloudFunctionNames.REQUEST_DELETION}`,
  )({ uid: user.uid });
}
