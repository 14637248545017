import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

export async function helloWorldFunction() {
  const functions = getFunctions(getApp(), REGION);
  return httpsCallable<undefined, CallableFunctionResponse>(
    functions,
    `migrations-${CloudFunctionNames.HELLO_WORLD}`,
  )(null);
}
