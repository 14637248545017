import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Drawer from '../Drawer/Drawer';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Head from 'next/head';
import { HeaderLogo } from './HeaderLogo';
import { HeaderTitle } from './HeaderTitle';
import { HeaderTabs } from './HeaderTabs';

/**
 * Props for the Header component.
 */
export interface HeaderProps {
  /**
   * The title to display in the header.
   */
  title: string;
  /**
   * The tabs to display in the header.
   */
  tabs?: React.ReactNode;
  /**
   * The children to display in the header.
   */
  children?: React.ReactNode;
  /**
   * The drawer menu items to display in the header.
   */
  drawerMenuItems?: React.ReactNode;
  /**
   * The user menu to display in the header.
   */
  userMenu?: React.ReactNode;
  /**
   * The badge to display in the header.
   */
  badge?: React.ReactNode;
  /**
   * The tabs menu to display in the header.
   */
  tabsMenu?: React.ReactNode;
}

/**
 * A header component that displays a logo, title, tabs, drawer menu, user menu, and language selector.
 * @returns The header component.
 */
export function Header({
  title,
  tabs,
  drawerMenuItems,
  userMenu,
  badge,
  tabsMenu,
}: HeaderProps) {
  const theme = useTheme();
  const themeColor =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (theme.components?.MuiAppBar?.styleOverrides?.colorPrimary as any)
      ?.backgroundColor || theme.palette.primary.main;

  const hasLogo = !!theme.katsomo?.header?.logo;

  return (
    <>
      <Head>
        <meta key="theme-color" name="theme-color" content={themeColor} />
      </Head>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '50px 1fr 50px',
              md: '50px 1fr auto',
            },
            alignItems: 'center',
          }}
        >
          <Drawer
            sx={{
              flexBasis: '50px',
            }}
          >
            <Box>{tabsMenu}</Box>
            {drawerMenuItems}
          </Drawer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              overflow: 'hidden',
              position: 'relative',
              alignItems: {
                xs: 'unset',
                md: 'center',
              },
            }}
          >
            {hasLogo ? (
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  flexBasis: {
                    xs: 'initial',
                    md: '200px',
                  },
                }}
                style={{
                  height: `calc(${
                    theme?.mixins?.toolbar?.minHeight || 56
                  }px - 16px`,
                }}
              >
                <HeaderLogo theme={theme} />
              </Box>
            ) : null}
            <HeaderTitle
              sx={{
                textAlign: hasLogo ? 'center' : 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title} {badge}
            </HeaderTitle>
            <HeaderTabs
              sx={{
                display: {
                  xs: 'none',
                  lg: 'flex',
                },
              }}
            >
              {tabs}
            </HeaderTabs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LanguageSelector />
            <Box>{userMenu}</Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
