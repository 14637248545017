/**
 * An object containing Firestore paths for assets.
 */
export const firestorePathsForAsset = {
  /**
   * Returns the path for a specific asset.
   * @param parentPath - The parent path of the asset.
   * @param assetId - The ID of the asset.
   * @returns The path for the asset.
   */
  assetPath: (parentPath: string, assetId: string) =>
    `${parentPath}/asset/${assetId}`,

  /**
   * The ID of the asset collection.
   */
  assetCollectionId: 'asset',

  /**
   * Returns the path for the asset collection.
   * @param parentPath - The parent path of the asset collection.
   * @returns The path for the asset collection.
   */
  assetCollectionPath: (parentPath: string) => `${parentPath}/asset`,
} as const;
