import { onSnapshot } from 'firebase/firestore';
import { TicketmasterStatus } from '@livekatsomo/models';
import { Observer } from '@livekatsomo/types';
import { getTicketmasterStatusDocRef } from './getTicketmasterStatusDocRef';

/**
 * Retrieves the current Ticketmaster status from Firestore and listens for changes.
 * @param options - This parameter is currently unused.
 * @param observer - The observer object that listens for changes to the Ticketmaster status.
 * @returns A function that can be called to unsubscribe from the listener.
 * @remarks The observer will emit null if the Ticketmaster status document does not exist.
 */
export function getTicketmasterStatus(
  options: undefined,
  observer: Observer<TicketmasterStatus | null>,
) {
  const systemConfigDocRef = getTicketmasterStatusDocRef();
  return onSnapshot(systemConfigDocRef, {
    next: (docSnapshot) => {
      const data = docSnapshot.data();
      observer.next(data ?? null);
    },
    error: (error) => observer.error(error),
    complete: observer.complete,
  });
}
