import { SlideSyncModel } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { slideQueueItemConverter } from './slideQueueItemConverter';

/**
 * Adds a slide queue item to the Firestore database.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param slideQueueItem - The slide queue item to add.
 * @returns A promise that resolves with the reference to the newly added document.
 */
export function addSlideQueueItem(
  eventId: string,
  trackId: string,
  slideQueueItem: SlideSyncModel,
) {
  const firestore = getFirestore();
  const slideQueueItemCollection = collection(
    firestore,
    firestorePaths.slideQueueCollectionPath(eventId, trackId),
  ).withConverter(slideQueueItemConverter);
  return addDoc(slideQueueItemCollection, {
    ...slideQueueItem,
    enabled: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
}
