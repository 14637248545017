import PlayIcon from '@mui/icons-material/PlayArrow';
import { BoxProps } from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

/**
 * Props for the VodBadge component.
 */
export interface VodBadgeProps {
  sx?: SxProps;
}

/**
 * A component that displays a badge for Video on Demand (VoD).
 * @param {BoxProps['component']} component - The HTML element or React component to use as the root node.
 * @param {SxProps<Theme>} sx - The inline CSS styles for the component.
 * @returns The JSX element for the component.
 */
export function VodBadge({
  sx,
  ...rest
}: VodBadgeProps & Pick<BoxProps, 'component'>) {
  const { t } = useTranslation();
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        width: 'min-content',
        border: `2px solid`,
        pr: 1,
        borderRadius: '100px',
        textTransform: 'uppercase',
        alignItems: 'center',
        height: '24px',
        fontSize: '0.8125rem',
        verticalAlign: 'middle',
        ...sx,
      }}
      {...rest}
    >
      <PlayIcon sx={{ color: 'green' }} />
      {t('VoD')}
    </Box>
  );
}

export default VodBadge;
