import { ChatMessageFormValues, User } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { getImageDimensions } from '../files/getImageDimensions';
import { uploadChatImage } from './uploadChatImage';

/**
 * Sends a chat message.
 *
 * @param parentPath - The parent path of the chat message collection.
 * @param user - The user sending the chat message.
 * @param chatMessage - The chat message to send.
 * @param onUploadProgressChange - A function to call when the upload progress changes.
 * @returns A promise that resolves when the chat message has been sent.
 */
export async function sendChatMessage(
  parentPath: string,
  user: User,
  chatMessage: ChatMessageFormValues,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const chatMessageCollectionPath =
    firestorePaths.chatMessageCollectionPath(parentPath);
  const firestore = getFirestore();
  const chatMessageCollectionRef = collection(
    firestore,
    chatMessageCollectionPath,
  );
  const { asset, text } = chatMessage;
  let assetData: { width: number; height: number } | null = null;
  const file = Array.isArray(asset) && asset.length ? asset[0] : null;
  if (file?.type.startsWith('image/')) {
    const { width, height } = await getImageDimensions(file);
    assetData = { width, height };
  }
  const chatMessageDocRef = await addDoc(chatMessageCollectionRef, {
    text,
    uid: user.uid,
    docType: 'chatMessage',
    displayName: user.displayName || undefined,
    photoURL: user.photoURL,
    createdAt: serverTimestamp(),
    accepted: null,
    moderatorId: null,
    asset: assetData,
  });
  if (file) {
    const image = await uploadChatImage(
      chatMessageDocRef.path,
      file,
      text,
      onUploadProgressChange,
    );
    await updateDoc(chatMessageDocRef, { asset: image });
  }
}
