import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { moderatorConverter } from './moderatorConverter';

/**
 * Returns a Firestore DocumentReference for a moderator with the given ID and parent path.
 * @param parentPath - The parent path of the moderator document.
 * @param moderatorId - The ID of the moderator document.
 * @returns A Firestore DocumentReference for the moderator document.
 */
export function getModeratorDocRef(parentPath: string, moderatorId: string) {
  const firestore = getFirestore();
  const moderatorDocRef = doc(
    firestore,
    firestorePaths.moderatorPath(parentPath, moderatorId),
  ).withConverter(moderatorConverter);
  return moderatorDocRef;
}
