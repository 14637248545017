import { z } from 'zod';

/**
 * List of available roles.
 * @readonly
 */
export const roles = ['admin', 'super-admin', 'active'] as const;

/**
 * Defines a schema for a role enum with the values defined in {@link roles}.
 */
export const roleSchema = z.enum(roles);

/**
 * Represents a role in the system inferred from the {@link roleSchema}.
 */
export type Role = z.infer<typeof roleSchema>;
