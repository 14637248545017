/**
 * Firestore paths for the application.
 *
 * @packageDocumentation
 */

import { firestorePathsForAgenda } from './firestorePathsForAgenda';
import { firestorePathsForAsset } from './firestorePathsForAsset';
import { firestorePathsForAuthorization } from './firestorePathsForAuthorization';
import { firestorePathsForChat } from './firestorePathsForChat';
import { firestorePathsForCustomer } from './firestorePathsForCustomer';
import { firestorePathsForDocument } from './firestorePathsForDocument';
import { firestorePathsForEvent } from './firestorePathsForEvent';
import { firestorePathsForInvitation } from './firestorePathsForInvitation';
import { firestorePathsForJournal } from './firestorePathsForJournal';
import { firestorePathsForModerator } from './firestorePathsForModerator';
import { firestorePathsForNettilippu } from './firestorePathsForNettilippu';
import { firestorePathsForSlide } from './firestorePathsForSlide';
import { firestorePathsForSpeaker } from './firestorePathsForSpeaker';
import { firestorePathsForSponsor } from './firestorePathsForSponsor';
import { firestorePathsForStream } from './firestorePathsForStream';
import { firestorePathsForSystem } from './firestorePathsForSystem';
import { firestorePathsForTheme } from './firestorePathsForTheme';
import { firestorePathsForTicketmaster } from './firestorePathsForTicketmaster';
import { firestorePathsForTrack } from './firestorePathsForTrack';
import { firestorePathsForUsedIn } from './firestorePathsForUsedIn';
import { firestorePathsForUser } from './firestorePathsForUser';
import { firestorePathsforNotification } from './firestorePathsforNotification';
import { firestorePathsForDownloadFile } from './firestorePathsForDownloadFile';

export const firestorePaths = {
  ...firestorePathsForEvent,
  ...firestorePathsForAgenda,
  ...firestorePathsForAuthorization,
  ...firestorePathsForAsset,
  ...firestorePathsForTrack,
  ...firestorePathsForCustomer,
  ...firestorePathsForSpeaker,
  ...firestorePathsForSlide,
  ...firestorePathsForUser,
  ...firestorePathsForChat,
  ...firestorePathsForStream,
  ...firestorePathsForJournal,
  ...firestorePathsForSponsor,
  ...firestorePathsForDocument,
  ...firestorePathsForTheme,
  ...firestorePathsforNotification,
  ...firestorePathsForModerator,
  ...firestorePathsForInvitation,
  ...firestorePathsForSystem,
  ...firestorePathsForNettilippu,
  ...firestorePathsForTicketmaster,
  ...firestorePathsForUsedIn,
  ...firestorePathsForDownloadFile,
} as const;

export default firestorePaths;
