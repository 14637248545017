import { Track } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Sets the slug for a given track in Firestore.
 *
 * @param trackId - The ID of the track to set the slug for.
 * @param track - The track object containing the slug to set.
 * @returns - A promise that resolves when the slug has been set.
 */
export async function setTrackSlug(
  trackId: string,
  track: Track,
): Promise<void> {
  const firestore = getFirestore();
  const trackPublicRef = doc(
    firestore,
    firestorePaths.trackPublicPath(trackId),
  );
  await setDoc(trackPublicRef, track, { merge: true });
}
