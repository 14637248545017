import slugify from 'slugify';

/**
 * Returns the file path for a given upload directory, filename, and file object.
 * If a filename is provided, it will be slugified and appended to the upload directory path.
 * Otherwise, the file's name property will be slugified and used instead.
 * @param uploadDir The directory where the file will be uploaded.
 * @param filename The name of the file, if provided.
 * @param file The file object.
 * @returns The file path.
 */
export function getFilePath(
  uploadDir: string,
  filename: string | undefined,
  file: File,
) {
  return `${uploadDir.replace(/\/+$/, '')}/${slugify(filename || file.name, {
    lower: true,
  })}`;
}
