import { CursorSession } from '@livekatsomo/models';
import { ListenToRemoteCursorUpdates } from '@santicon/prosemirror-firebase-collaboration-cursor-plugin';
import {
  getDatabase,
  onDisconnect,
  ref,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
  get,
} from 'firebase/database';

type ListenToRemoteCursorUpdatesWithDocPath =
  ListenToRemoteCursorUpdates extends (
    options: infer Options,
  ) => infer ReturnValue
    ? (options: { docPath: string } & Options) => ReturnValue
    : never;

/**
 * Listens to remote cursor updates for a given document path and client ID.
 * @param {ListenToRemoteCursorUpdatesWithDocPath} options - The options object.
 * @param options.clientID - The ID of the client.
 * @param options.docPath - The path of the document to listen to.
 * @param {(clientID: string, selection: Selection, version: number) => void} options.onClientUpdated - The callback function to be called when a client's cursor position is updated.
 * @param {(client: RemoteClient) => void} options.onClientJoined - The callback function to be called when a new client joins the session.
 * @param {(client: RemoteClient) => void} options.onClientRemoved - The callback function to be called when a client leaves the session.
 * @returns A function that can be called to unsubscribe from the listener.
 */
export const listenToRemoteCursorUpdates: ListenToRemoteCursorUpdatesWithDocPath =
  ({ clientID, docPath, onClientUpdated, onClientJoined, onClientRemoved }) => {
    const database = getDatabase();
    const sessionsRef = ref(database, docPath + '/session/');
    const subscriptions: (() => void)[] = [];

    // Get initial cursor positions
    get(sessionsRef)
      .then((sessionsSnapshot) => {
        if (sessionsSnapshot.exists()) {
          const sessions = sessionsSnapshot.val() as Record<
            string,
            CursorSession
          >;

          Object.values(sessions).forEach((session) => {
            onClientUpdated(
              session.clientID,
              session.selection,
              session.version,
            );
            onClientJoined({
              name: session.user.name,
              clientID: session.clientID,
              lastActive: session.lastActive,
            });
          });
        }
      })
      .catch((error) => {
        console.error('Unable to get initial cursor positions', error);
      });

    subscriptions.push(
      onChildChanged(sessionsRef, (snapshot) => {
        console.log('Received session update', snapshot.val());
        const {
          selection,
          clientID: sessionClientId,
          version,
        } = snapshot.val() as CursorSession;
        if (sessionClientId === clientID) {
          return;
        }
        onClientUpdated(sessionClientId, selection, version);
      }),
      onChildAdded(sessionsRef, (snapshot) => {
        console.log('Cursor session joined', snapshot.val());
        const {
          clientID: sessionClientId,
          user,
          lastActive,
        } = snapshot.val() as CursorSession;
        if (sessionClientId === clientID) {
          return;
        }
        onClientJoined({
          name: user.name,
          clientID: sessionClientId,
          lastActive,
        });
      }),
      onChildRemoved(sessionsRef, (snapshot) => {
        console.log('Cursor session left', snapshot.val());
        const { clientID: sessionClientId } = snapshot.val() as CursorSession;
        if (sessionClientId === clientID) {
          return;
        }
        onClientRemoved({ clientID: sessionClientId });
      }),
    );

    onDisconnect(ref(database, docPath + '/session/' + clientID))
      .remove()
      .catch((error) =>
        console.error(
          `Error while establishing on-disconnect handler. Reason: `,
          error,
        ),
      );

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  };
