import { firestorePaths } from '@livekatsomo/shared/config';
import { DocumentReference, doc, getFirestore } from 'firebase/firestore';
import { invitationConverter } from './invitationConverter';
import { InvitationDoc } from '@livekatsomo/models';

/**
 * Returns a Firestore DocumentReference for the invitation with the given ID.
 * The DocumentReference is configured with the invitationConverter to automatically
 * convert Firestore data to Invitation objects.
 *
 * @param parentPath - The parent path of the invitation.
 * @param invitationId - The ID of the invitation.
 * @returns The DocumentReference for the invitation.
 */
export function getInvitationDocRef(
  parentPath: string,
  invitationId: string,
): DocumentReference<InvitationDoc> {
  const firestore = getFirestore();
  const invitationDocRef = doc(
    firestore,
    firestorePaths.invitationPath(parentPath, invitationId),
  ).withConverter(invitationConverter);
  return invitationDocRef;
}
