import { SpeakerDoc, Store } from '@livekatsomo/models';
import {
  AddSpeakerFunction,
  DeleteSpeakerFunction,
  UpdateSpeakerFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * The model for the SpeakersContext.
 */
export type SpeakersContextModel = {
  store: Store<SpeakerDoc[]>;
  deleteSpeaker: DeleteSpeakerFunction;
  updateSpeaker: UpdateSpeakerFunction;
  addSpeaker: AddSpeakerFunction;
  /** Optional function to update the order of speakers. */
  updateOrder?: (speakers: SpeakerDoc[]) => Promise<void>;
};

/**
 * The context for the SpeakersContext.
 */
export const SpeakersContext = createContext<SpeakersContextModel | undefined>(
  undefined,
);
