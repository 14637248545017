import { Store } from '@livekatsomo/models';
import { useSyncExternalStore } from 'react';

/**
 * A hook that returns the current state of a store and any errors that occurred while retrieving it.
 * @typeParam Model - The type of the store's model.
 * @param store - The store to subscribe to.
 * @returns A tuple containing the current state of the store and any errors that occurred while retrieving it.
 */
export function useStore<Model>(
  store: Store<Model>,
): [Model | null, Error | null] {
  const { subscribe, getSnapshot, getServerSnapshot } = store;
  const result = useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot,
  );

  if (result instanceof Error) {
    return [null, result];
  }
  return [result, null];
}
