import { deleteDoc } from 'firebase/firestore';
import { getInvitationDoc } from './getInvitationDoc';

/**
 * Deletes an invitation document from Firestore.
 * @param parentPath - The path of the parent document.
 * @param invitationId - The ID of the invitation document to delete.
 * @returns A Promise that resolves when the document is deleted.
 * @throws If the invitation document is not found.
 */
export const deleteInvitation = async (
  parentPath: string,
  invitationId: string,
): Promise<void> => {
  const docSnapshot = await getInvitationDoc(parentPath, invitationId);
  if (docSnapshot.exists()) {
    await deleteDoc(docSnapshot.ref);
  } else {
    throw new Error('Invitation not found');
  }
};
