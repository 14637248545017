/**
 * @file This file exports the Breadcrumbs component which is used to display a breadcrumb trail for navigation.
 * @module Breadcrumbs
 */

import { titleize, useIsClient } from '@livekatsomo/web/utils';
import Crumbs, {
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material/Breadcrumbs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useMemo } from 'react';
import { CrumbItem } from './CrumbItem';

/**
 * The default function to generate text for a breadcrumb item.
 * @param param - The parameter of the current route.
 * @param query - The query parameters of the current route.
 * @param translation - The translation of the current route.
 * @returns null.
 */
const _defaultGetTextGenerator = (
  param: string,
  query: ParsedUrlQuery,
  translation?: string,
) => null;

/**
 * The default function to generate text for a breadcrumb item when no translation is available.
 * @param path - The path of the current route.
 * @param href - The href of the current route.
 * @param translation - The translation of the current route.
 * @returns The titleized version of the translation or path.
 */
const _defaultGetDefaultTextGenerator = (
  path: string,
  href: string,
  translation?: string,
) => titleize(translation ? translation : path);

/**
 * Breaks down a path string into an array of path parts.
 * @param pathStr - The path string to break down.
 * @returns An array of path parts.
 */
const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split('?')[0];
  return pathWithoutQuery.split('/').filter((v) => v.length > 0);
};

/**
 * The props for the Breadcrumbs component.
 */
export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  getTextGenerator?: (
    param: string,
    query: ParsedUrlQuery,
  ) => (() => Promise<string>) | null;
  getDefaultTextGenerator?: (
    path: string,
    href: string,
    translation?: string,
  ) => string;
}

/**
 * The Breadcrumbs component.
 * @param getTextGenerator - The function to generate text for a breadcrumb item.
 * @param getDefaultTextGenerator - The function to generate text for a breadcrumb item when no translation is available.
 * @param props - The props for the Breadcrumbs component.
 * @returns The Breadcrumbs component.
 */
export function Breadcrumbs({
  getTextGenerator = _defaultGetTextGenerator,
  getDefaultTextGenerator = _defaultGetDefaultTextGenerator,
  ...props
}: BreadcrumbsProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const isClient = useIsClient();

  const breadcrumbs = useMemo(
    function generateBreadcrumbs() {
      if (!router.isReady) return [];
      const asPathNestedRoutes = generatePathParts(router.asPath);
      const pathnameNestedRoutes = generatePathParts(router.pathname);

      const crumblist = asPathNestedRoutes.map((subpath, idx) => {
        // Pull out and convert "[post_id]" into "post_id"
        const param = pathnameNestedRoutes[idx] || '';

        // Translate if name is not dynamic
        const translation = subpath === param ? t(param) : undefined;

        // param = param.length > 0 ? param.replace('[', '').replace(']', '') : '';
        const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');
        return {
          href,
          textGenerator: getTextGenerator(param, router.query),
          text: getDefaultTextGenerator(subpath, href, translation),
        };
      });

      return [{ href: '/', text: t('Home') }, ...crumblist];
    },
    [
      getDefaultTextGenerator,
      getTextGenerator,
      router.asPath,
      router.isReady,
      router.pathname,
      router.query,
      t,
    ],
  );

  if (!isClient) return null;

  return (
    <Crumbs
      aria-label={t('breadcrumb') || 'breadcrumb'}
      sx={{
        backgroundColor: (theme) => theme.katsomo?.breadcrumbs?.backgroundColor,
        mx: (theme) => theme.spacing(-1),
        px: (theme) => theme.spacing(1),
        width: 'fit-content',
      }}
      {...props}
    >
      {breadcrumbs.map((crumb, index) => (
        <CrumbItem
          {...crumb}
          key={crumb.href}
          last={index === breadcrumbs.length - 1}
        />
      ))}
    </Crumbs>
  );
}

export default Breadcrumbs;
