import { Observer } from '@livekatsomo/types';
import { getDatabase, ref, onValue } from 'firebase/database';

/**
 * Returns the connection status of the Firebase Realtime Database.
 * @param options - Unused parameter.
 * @param observer - Observer that listens for changes in the connection status.
 * @returns A function that can be used to unsubscribe from the observer.
 */
export function getConnectionStatus(
  options: undefined,
  observer: Observer<boolean>,
) {
  const db = getDatabase();
  const connectionRef = ref(db, '.info/connected');
  return onValue(connectionRef, (snap) => {
    observer.next(snap.val());
  });
}
