import { SpeakerDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { speakerConverter } from './speakerConverter';

/**
 * Retrieves a snapshot of the speakers collection for a given event ID.
 * @param options - The options object.
 * @param options.eventId - The ID of the event to retrieve speakers for.
 * @param observer - The observer to notify of changes to the speakers collection.
 * @returns A promise that resolves with an unsubscribe function.
 */
export const getSpeakers = (
  options: { eventId: string },
  observer: Observer<SpeakerDoc[]>,
) => {
  const { eventId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.speakerCollectionPath(eventId),
    },
    speakerConverter,
  );
};
