import {
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { PDF_BUCKET } from '@livekatsomo/shared/config';

const nodeEnv = process.env['NODE_ENV'];

/**
 *
 * Use this for Quokka testing to initialise firebase with emulators
 */
export function initializeFirebaseWithEmulators() {
  const app = initializeApp({ projectId: 'rajucast-tv', apiKey: 'something' });

  const firestore = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });

  connectFirestoreEmulator(firestore, 'localhost', 8080);

  const auth = getAuth();
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: nodeEnv === 'test' ? true : false,
  });

  // Also connect custom storage to emulator
  const storage = getStorage(app);
  const pdfStorage = getStorage(app, `gs://${PDF_BUCKET}`);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectStorageEmulator(pdfStorage, 'localhost', 9199);
  return { firestore, auth, storage };
}
