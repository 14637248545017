import { Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the ServerTimeOffsetContext.
 */
export type ServerTimeOffsetContextModel = {
  store: Store<number>;
};

/**
 * The context for the ServerTimeOffsetContext.
 */
export const ServerTimeOffsetContext = createContext<
  ServerTimeOffsetContextModel | undefined
>(undefined);
