import { NettilippuStatus, nettilippuStatusSchema } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for NettilippuStatus objects.
 */
export const nettilippuStatusConverter: FirestoreDataConverter<NettilippuStatus> =
  {
    /**
     *
     * @param snapshot - Firestore document snapshot.
     * @returns A NettilippuStatus object.
     */
    fromFirestore: (snapshot) => {
      const data = nettilippuStatusSchema.parse(snapshot.data());
      return data;
    },
    /**
     * @param data - NettilippuStatus object.
     * @returns A Firestore data object.
     */
    toFirestore: (data: NettilippuStatus) => data,
  };
