import { zodResolver } from '@hookform/resolvers/zod';
import { Resolver } from 'react-hook-form';
import { z } from 'zod';

/**
 * A resolver function that logs the input data, context, and options before and after validation using Zod.
 * @param schema - The Zod schema to use for validation.
 * @returns A resolver function that returns the validation status.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function zodResolverWithLog<TSchema extends z.ZodType<any, any, any>>(
  schema: TSchema,
) {
  const resolver: Resolver = async (data, context, options) => {
    console.log('resolver', data, context, options);
    const validationStatus = await zodResolver(schema)(data, context, options);
    console.log('validationStatus', validationStatus);
    return validationStatus;
  };
  return resolver;
}
