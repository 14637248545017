import { StreamDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, where } from 'firebase/firestore';
import { streamConverter } from './streamConverter';

/**
 * Retrieves a list of streams for a given customer ID and optional track ID.
 * @param options - The options for retrieving the streams.
 * @param options.customerId - The ID of the customer to retrieve streams for.
 * @param options.trackId - The ID of the track to retrieve streams for (optional).
 * @param observer - The observer to notify of changes to the streams.
 * @returns A function to unsubscribe from the stream updates.
 */
export function getStreams(
  options: { customerId: string; trackId?: string },
  observer: Observer<StreamDoc[]>,
) {
  const { customerId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.streamCollectionPath(customerId),
      queryConstraints: [
        where('parentId', 'in', [
          customerId,
          ...(options.trackId ? [options.trackId] : []),
        ]),
        orderBy('name', 'asc'),
      ],
    },
    streamConverter,
  );
}
