import { UsedIn } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

/**
 * Retrieves a list of documents that reference the specified stream ID.
 * @param streamId - The ID of the stream to search for.
 * @param customerId - The customer Id of the stream.
 * @returns A promise that resolves to an array of {@link UsedIn} objects.
 */
export async function getStreamUsedIn(customerId: string, streamId: string) {
  const firestore = getFirestore();
  const usedInCollection = collection(
    firestore,
    firestorePaths.usedInCollectionPath(
      firestorePaths.streamPath(customerId, streamId),
    ),
  );
  const docs = await getDocs(usedInCollection);
  return docs.docs.map((doc) => doc.data() as UsedIn);
}
