import { AssetInDocument, SpeakerFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getSpeakerDocRef } from './getSpeakerDocRef';

/**
 * Updates a speaker document in Firestore.
 *
 * @param eventId - The ID of the event the speaker belongs to.
 * @param speakerFormValues - The updated speaker data with the speaker's ID.
 * @param onUploadProgressChange - A function to track the upload progress of the speaker's image.
 * @returns A Promise that resolves when the speaker document has been updated.
 */
export async function updateSpeaker(
  eventId: string,
  speakerFormValues: SpeakerFormValues & { id: string },
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const { image: imageField, ...speakerData } = speakerFormValues;

  const speakerDocRef = getSpeakerDocRef(eventId, speakerData.id);

  let image: AssetInDocument | undefined;

  if (Array.isArray(imageField)) {
    const file = imageField[0];
    if (file) {
      const assetDoc = await uploadImageFile({
        file,
        filename: file.name,
        uploadDir: firestorePaths.assetCollectionPath(
          firestorePaths.eventPath(eventId),
        ),
        assetData: {
          alt: `${speakerData.name} poster`,
          purpose: ['speaker'],
        } as Partial<AssetInDocument>,
        onUploadProgressChange: onUploadProgressChange,
      });
      const asset = assetDoc?.data();
      image =
        asset && assetDoc
          ? {
              ...asset,
              id: assetDoc.id,
              sourceDocRefPath: assetDoc.ref.path,
              originalUrl: asset.originalUrl,
            }
          : undefined;
    }
  } else if (typeof imageField !== 'string') {
    image = imageField;
  }

  await updateDoc(speakerDocRef, { ...speakerData, image });
}
