import { PreAuthorizedUserDoc } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  AddPreAuthorizedUserFunction,
  UpdatePreAuthorizedUserFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the PreAuthorizedUsersContext.
 */
export type PreAuthorizedUsersContextModel = {
  store: Store<PreAuthorizedUserDoc[]>;
  addPreAuthorizedUser: AddPreAuthorizedUserFunction;
  updatePreAuthorizedUser: UpdatePreAuthorizedUserFunction;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
  deletePreAuthorizedUser: (id: string) => Promise<void>;
};

/**
 * The context for the PreAuthorizedUsersContext.
 * This context is used to manage the pre-authorized users.
 */
export const PreAuthorizedUsersContext = createContext<
  PreAuthorizedUsersContextModel | undefined
>(undefined);
