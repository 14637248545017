import { z } from 'zod';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { speakerInDocumentSchema } from './speaker.model';

export type Minutes = number;
export type DeltaTime = number;
export const agendaTypes = ['talk', 'break', 'other'] as const;
export const agendaTypeSchema = z.enum(agendaTypes);
export type AgendaType = z.infer<typeof agendaTypeSchema>;

export const agendaItemSchema = z.object({
  /** The title of the agenda item */
  title: z.string().nonempty(),
  /** The duration of the agenda item in minutes */
  duration: z.coerce.number(),
  /** An optional description of the agenda item */
  description: z.string().optional(),
  /** An optional array of speakers associated with the agenda item */
  speakers: z.array(speakerInDocumentSchema).optional(),
  /** An optional location for the agenda item */
  location: z.string().optional(),
  /** An optional ID for the slides associated with the agenda item. */
  slidesId: z.string().optional(),
  /** An optional ID for the stream associated with the agenda item. */
  streamId: z.string().optional(),
  /** An optional timestamp or date for the agenda item. */
  timestamp: timestampOrDateSchema.nullish(),
  /** The type of the agenda item. */
  type: agendaTypeSchema,
  /** The ID of the track associated with the agenda item. */
  trackId: z.string(),
  /** An optional boolean indicating whether to show the time of the agenda item. */
  showTime: z.boolean().optional(),
  /** Show speakers */
  showSpeakers: z.boolean().optional(),
  /** Include speaker description */
  includeSpeakerDescription: z.boolean().optional(),
});

/**
 * Represents an agenda item.
 * Inferred type from {@link agendaItemSchema}
 */
export type AgendaItem = z.infer<typeof agendaItemSchema>;

/**
 * Defines the schema for an agenda item document in Firestore, which includes
 * the base {@link agendaItemSchema} and the {@link firestoreDocSchema}. Additionally,
 * it extends the schema to include a `docType` property with a value of `'agendaItem'`.
 */
export const agendaItemDocSchema = agendaItemSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is agendaItem */
    docType: z.literal('agendaItem'),
  });

/**
 * Type definition for the document representing an agenda item inferred from the {@link agendaItemDocSchema}
 */
export type AgendaItemDoc = z.infer<typeof agendaItemDocSchema>;

/**
 * Represents an array of agenda items ({@link AgendaItemDoc}).
 */
export type Agenda = AgendaItemDoc[];

/**
 * Represents an agenda item ({@link AgendaItemDoc}) with start and end times, and a disabled flag.
 * This type is used to represent the agenda items in the agenda table.
 * @property startTime - The start time of the agenda item.
 * @property endTime - The end time of the agenda item.
 * @property disabled - A boolean indicating whether the agenda item is disabled.
 */
export interface AgendaItemWithTimes extends AgendaItemDoc {
  startTime: Date;
  endTime: Date;
  disabled: boolean;
}

/**
 * A schema for the form values of an agenda item.
 * It includes the properties: title, description, duration, type, timestamp, speakers, and trackId from {@link agendaItemSchema}.
 */
export const agendaItemFormValuesSchema = agendaItemSchema.pick({
  title: true,
  duration: true,
  description: true,
  speakers: true,
  location: true,
  showTime: true,
  trackId: true,
  timestamp: true,
  type: true,
  showSpeakers: true,
  includeSpeakerDescription: true,
});

/**
 * Type definition for the form values of an agenda item.
 *
 * @remarks
 * This type is inferred from the {@link agendaItemFormValuesSchema} schema.
 */
export type AgendaItemFormValues = z.infer<typeof agendaItemFormValuesSchema>;
