import {
  FromFirestore,
  PreAuthorizedModerator,
  ToFirestore,
} from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for PreAuthorizedModerator objects.
 */
export const preAuthorizedModeratorConverter: FirestoreDataConverter<PreAuthorizedModerator> =
  {
    /**
     * @param doc - Firestore document snapshot.
     * @returns A PreAuthorizedModerator object.
     */
    fromFirestore: (doc): PreAuthorizedModerator => {
      const { ...data } = doc.data() as FromFirestore<PreAuthorizedModerator>;

      return {
        ...data,
        id: doc.id,
        docType: 'preAuthorizedModerator',
        sourceDocRefPath: doc.ref.path,
      };
    },
    /**
     * @param preAuthorizedModerator - PreAuthorizedModerator object.
     * @returns A PreAuthorizedModerator object.
     */
    toFirestore: (
      preAuthorizedModerator: PreAuthorizedModerator,
    ): ToFirestore<PreAuthorizedModerator> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...data } = preAuthorizedModerator;
      return {
        ...data,
        docType: 'preAuthorizedModerator',
      };
    },
  };
