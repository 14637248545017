import { User } from '@livekatsomo/models';
import { Observer } from '@livekatsomo/types';
import { getAuth } from 'firebase/auth';

/**
 * Retrieves the currently authenticated user, or null if there is no authenticated user.
 * @param options - Unused parameter, required for compatibility with Firebase API.
 * @param observer - Observer that will receive the user object or null.
 * @returns A function that can be called to unsubscribe from the observer.
 */
export function getUser(options: undefined, observer: Observer<User | null>) {
  const auth = getAuth();
  if (!auth.currentUser) observer.next(null);

  return auth.onAuthStateChanged((user) => {
    if (!user) observer.next(null);
    else {
      observer.next(user);
    }
  });
}
