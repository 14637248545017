import { ChatMessageDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collection,
  CollectionReference,
  doc,
  getFirestore,
} from 'firebase/firestore';
import { chatMessageConverter } from './chatMessageConverter';

/**
 * Returns a Firestore document reference for a chat message with the given ID.
 *
 * @param parentPath - The parent path of the chat message collection.
 * @param chatMessageId - The ID of the chat message.
 * @returns A Firestore document reference for the chat message.
 */
export function getChatMessageDocRef(
  parentPath: string,
  chatMessageId: string,
) {
  const firestore = getFirestore();
  const chatMessageCollection = collection(
    firestore,
    firestorePaths.chatMessageCollectionPath(parentPath),
  ) as CollectionReference<ChatMessageDoc>;
  const chatMessageRef = doc(
    chatMessageCollection,
    chatMessageId,
  ).withConverter(chatMessageConverter);
  return chatMessageRef;
}
