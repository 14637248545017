/**
 * An object containing Firestore paths for journal events.
 */
export const firestorePathsForJournal = {
  /**
   * Returns the collection path for a journal event.
   * @param eventId The ID of the event.
   * @returns The collection path for the journal event.
   */
  journalCollectionPath: (eventId: string) => `event/${eventId}/journal`,

  /**
   * Returns the path for a specific journal event.
   * @param eventId The ID of the event.
   * @param journalEventId The ID of the journal event.
   * @returns The path for the specific journal event.
   */
  journalEventPath: (eventId: string, journalEventId: string) =>
    `event/${eventId}/journal/${journalEventId}`,
} as const;
