import {
  NotificationMessage,
  NotificationMessageDoc,
  NotificationMessageFirestore,
  ToFirestore,
  notificationMessageSchema,
} from '@livekatsomo/models';
import { PartialWithFieldValue } from 'firebase-admin/firestore';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for NotificationMessage objects.
 */
export const notificationMessageConverter: FirestoreDataConverter<NotificationMessageDoc> =
  {
    /**
     *
     * @param doc - Firestore document snapshot.
     * @returns A NotificationMessageDoc object.
     */
    fromFirestore: (doc) => {
      const data = doc.data() as NotificationMessageFirestore;
      return {
        ...data,
        createdAt: data.createdAt?.toDate(),
        id: doc.id,
        sourceDocRefPath: doc.ref.path,
        docType: 'notificationMessage',
      };
    },
    /**
     * @param notificationMessage - NotificationMessage object.
     * @returns A Firestore data object.
     * @throws If the NotificationMessage object is invalid.
     */
    toFirestore: (
      notificationMessage: PartialWithFieldValue<NotificationMessage>,
    ): ToFirestore<NotificationMessageDoc> => {
      const { ...data } = notificationMessageSchema.parse(notificationMessage);
      return {
        ...data,
        docType: 'notificationMessage',
      };
    },
  };
