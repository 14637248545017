import {
  EventComponentTypeSchema,
  LayoutConfiguration,
} from '@livekatsomo/models';

/**
 * The default layout configuration for an event. This configuration defines the layout of various components
 * such as player, countdown, chat, agenda, speakers, sponsors, etc. It is used as a fallback when no other
 * configuration is provided.
 */
export const defaultEventLayoutConfiguration = {
  layoutName: 'eventLayout',
  collections: {
    baseLayout: {
      type: 'area',
      sx: {
        gap: 4,
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'column',
      },
      items: ['topArea', 'playerArea', 'mainArea', 'footerArea'],
    },
    playerArea: {
      type: 'area',
      boxed: true,
      sx: {
        padding: 4,
      },
      items: ['playerCollection'],
    },
    mainArea: {
      type: 'area',
      maxWidth: 'xl',
      items: ['grid'],
    },
    footerArea: {
      type: 'area',
      items: ['footer'],
    },
    topArea: {
      type: 'area',
      boxed: false,
      items: ['countdownCollection'],
    },
    countdownCollection: {
      type: 'collection',
      sx: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      },
      items: [EventComponentTypeSchema.enum.countdown],
    },
    playerCollection: {
      type: 'collection',
      collectionContainer: 'layoutWithCenterSlider',
      maxChildCount: 2,
      showPlaceholderInEmptySlots: true,
      items: [EventComponentTypeSchema.enum.player, 'slidesAndThumbs'],
    },
    grid: {
      type: 'collection',
      sx: {
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr 1fr',
          md: '2fr 1fr',
        },
        gap: 1,
      },
      items: ['contentCollection', 'sideCollection'],
    },
    footer: {
      type: 'collection',
      items: [EventComponentTypeSchema.enum.wallsio],
    },
    [EventComponentTypeSchema.enum.details]: {
      componentName: EventComponentTypeSchema.enum.details,
      type: 'component',
      componentContainer: 'layout',
      boxed: true,
      sx: {
        padding: 2,
      },
    },
    [EventComponentTypeSchema.enum.wallsio]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.wallsio,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.countdown]: {
      type: 'component',
      componentContainer: 'layout',
      boxed: true,
      sx: {
        padding: 4,
        // alignItems: 'center',
      },
      componentName: EventComponentTypeSchema.enum.countdown,
    },
    [EventComponentTypeSchema.enum.player]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.player,
      componentContainer: 'component',
    },
    slidesAndThumbs: {
      type: 'collection',
      sx: {
        width: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 1,
      },
      items: [
        EventComponentTypeSchema.enum.slides,
        EventComponentTypeSchema.enum.slideThumbs,
      ],
    },
    [EventComponentTypeSchema.enum.slides]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.slides,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.slideThumbs]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.slideThumbs,
      componentContainer: 'component',
    },
    contentCollection: {
      type: 'collection',
      items: [
        EventComponentTypeSchema.enum.details,
        EventComponentTypeSchema.enum.summary,
        EventComponentTypeSchema.enum.surveyForm,
        EventComponentTypeSchema.enum.trackSummary,
        EventComponentTypeSchema.enum.agenda,
        EventComponentTypeSchema.enum.speakers,
        EventComponentTypeSchema.enum.sponsors,
        EventComponentTypeSchema.enum.downloadFiles,
      ],
    },
    sideCollection: {
      type: 'collection',
      stickyLastChild: true,
      sx: {
        display: 'flex',
        flexDirection: 'column',
      },
      items: [
        EventComponentTypeSchema.enum.chat,
        EventComponentTypeSchema.enum.slido,
      ],
    },
    [EventComponentTypeSchema.enum.chat]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.chat,
      componentContainer: 'layout',
      boxed: true,
      sx: {
        padding: 1,
      },
    },
    [EventComponentTypeSchema.enum.slido]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.slido,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.agenda]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.agenda,
      componentContainer: 'layout',
      boxed: true,
      sx: { padding: 2 },
    },
    [EventComponentTypeSchema.enum.speakers]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.speakers,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.sponsors]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.sponsors,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.summary]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.summary,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.trackSummary]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.trackSummary,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.surveyForm]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.surveyForm,
      componentContainer: 'component',
    },
    [EventComponentTypeSchema.enum.downloadFiles]: {
      type: 'component',
      componentName: EventComponentTypeSchema.enum.downloadFiles,
      componentContainer: 'component',
      boxed: true,
      sx: {
        padding: 2,
      },
    },
  },
} satisfies LayoutConfiguration;
