import {
  User as FirebaseUser,
  getAuth,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  AuthError,
  updateProfile,
} from 'firebase/auth';
import {
  linkWithCredential,
  requestEmailVerification,
  profileUpdatedCallable,
} from './authentication-handlers';
import { CreateUser } from '@livekatsomo/types';
import { KatsomoAuthError } from '@livekatsomo/custom-errors';

/**
 * Creates a new user with the given email, password and name.
 * If the user is already authenticated, it links the new credential to the current user.
 * After creating the user, it updates the user profile and requests email verification.
 * Finally, it updates the user profile to firestore.
 * @param email The email of the user to create.
 * @param password The password of the user to create.
 * @param name The name of the user to create.
 * @returns A Promise that resolves when the user is created.
 */
export const createUser: CreateUser = async (email, password, name) => {
  const auth = getAuth();

  let newUser: FirebaseUser;

  if (auth.currentUser) {
    const credential = EmailAuthProvider.credential(email, password);
    try {
      // Try to link the credential to the current user
      await linkWithCredential(auth.currentUser, credential);
    } catch (error) {
      const authError = error as AuthError;
      switch (authError.code) {
        case 'auth/credential-already-in-use':
          {
            const credential = await createUserWithEmailAndPassword(
              auth,
              email,
              password,
            );
            newUser = credential.user;
          }
          break;
        default:
          throw error;
      }
    }
    newUser = auth.currentUser;
    await auth.currentUser.getIdToken(true);
  } else {
    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      newUser = credential.user;
    } catch (error) {
      console.error('createUserWithEmailAndPassword error', error);
      throw new KatsomoAuthError('Failed to create user');
    }
  }
  try {
    // There must be displayName before requesting email verification
    await updateProfile(newUser, {
      displayName: name,
    });
  } catch (error) {
    console.error('updateProfile error', error);
    throw new KatsomoAuthError('Failed to update user profile');
  }
  try {
    await requestEmailVerification(email);
  } catch (error) {
    console.error('requestEmailVerification error', error);
    throw new KatsomoAuthError('Failed to request email verification');
  }

  try {
    // If successful, update the user profile to firestore
    await profileUpdatedCallable(newUser.uid);
  } catch (error) {
    console.error('profileUpdatedCallable error', error);
    throw new KatsomoAuthError('Failed to update user profile');
  }

  console.log('Created user and signed in succesfully', auth.currentUser);
  return newUser;
};
