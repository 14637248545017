import { z } from 'zod';
import { assetInDocumentSchema } from './asset.model';
import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';

/**
 * A schema for validating color strings in the following formats:
 * - #nnn
 * - #nnnnnn
 * - rgb()
 * - rgba()
 * - hsl()
 * - hsla()
 * - color()
 */
export const colorStringSchema = z.string().regex(
  // #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
  /^(rgb\s*?\(\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?,\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?,\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?\))$|^(rgba\s*?\(\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?,\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?,\s*?(000|0?\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\s*?,\s*?(0|0\.\d*|1|1.0*)\s*?\))$|^(transparent)$|^(#([a-fA-F0-9]){3})$|^(#([a-fA-F0-9]){6}$)|(^hsl\s*?\(\s*?(000|0?\d{1,2}|[1-2]\d\d|3[0-5]\d|360)\s*?,\s*?(000|100|0?\d{2}|0?0?\d)%\s*?,\s*?(000|100|0?\d{2}|0?0?\d)%\s*?\)$)|(^hsla\s*?\(\s*?(000|0?\d{1,2}|[1-2]\d\d|3[0-5]\d|360)\s*?,\s*?(000|100|0?\d{2}|0?0?\d)%\s*?,\s*?(000|100|0?\d{2}|0?0?\d)%\s*?,\s*?(0|0\.\d*|1|1.0*)\s*?\)$)$/,
);

/**
 * Defines the schema for the palette options object, which contains color values for a theme's palette.
 */
export const paletteOptionsSchema = z.object({
  /** The main color value for the palette. */
  main: colorStringSchema,
  /** An optional lighter shade of the main color. */
  light: colorStringSchema.optional(),
  /** An optional darker shade of the main color. */
  dark: colorStringSchema.optional(),
  /** An optional color value for text that contrasts with the main color. */
  contrastText: colorStringSchema.optional(),
});

/**
 * Defines the schema for typography options
 */
export const typographyOptionsSchema = z.object({
  /** An optional font family. */
  fontFamily: z.string().optional(),
  /** An optional font weight. */
  fontWeight: z.number().optional(),
  /** An optional font size. */
  fontSize: z.number().or(z.string()).optional(),
  /** An optional line height. */
  lineHeight: z.number().optional(),
});

/**
 * An array of available options for the background blend mode property.
 * @readonly
 *
 * @remarks
 * This array is used to validate the background blend mode property of a theme.
 * {@link https://developer.mozilla.org/en-US/docs/Web/CSS/background-blend-mode}
 */
export const backgroundBlendModeOption = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity',
] as const;

/**
 * A schema for the background blend mode option, which can be one of the values in the {@link backgroundBlendModeOption} enum or an empty string.
 */
export const blendModeSchema = z
  .enum(backgroundBlendModeOption)
  .or(z.literal(''));

/**
 * The available options for the background blend mode property.
 */
export const backgroundBlendMode = blendModeSchema.options;

/**
 * Defines the schema for the components object in the theme model.
 */
const componentsSchema = z.object({
  /** The MuiAppBar object in the components schema. */
  MuiAppBar: z
    .object({
      /** The styleOverrides object in the MuiAppBar object. */
      styleOverrides: z
        .object({
          /** The colorPrimary object in the styleOverrides object. */
          colorPrimary: z.object({
            /** The backgroundColor property in the colorPrimary object. */
            backgroundColor: colorStringSchema.optional(),
            /** The color property in the colorPrimary object. */
            color: colorStringSchema.optional(),
          }),
        })
        .optional(),
    })
    .optional(),
});

/**
 * Defines the schema for the palette object used in the theme model.
 */
const paletteSchema = z.object({
  /** An optional object containing the common colors black and white. */
  common: z
    .object({
      /** An optional string specifying the black color. */
      black: colorStringSchema.optional(),
      /** An optional string specifying the white color. */
      white: colorStringSchema.optional(),
    })
    .optional(),
  /** An optional object containing the primary color options. */
  primary: paletteOptionsSchema.optional(),
  /** An optional object containing the secondary color options. */
  secondary: paletteOptionsSchema.optional(),
  /** An optional object containing the error color options. */
  error: paletteOptionsSchema.optional(),
  /** An optional object containing the warning color options. */
  warning: paletteOptionsSchema.optional(),
  /** An optional object containing the info color options. */
  info: paletteOptionsSchema.optional(),
  /** An optional object containing the success color options. */
  success: paletteOptionsSchema.optional(),
  /** An optional string literal specifying the theme mode. */
  mode: z.union([z.literal('light'), z.literal('dark')]).optional(),
  /** An optional object containing the background color options. */
  background: z
    .object({
      /** An optional string specifying the default background color. */
      default: colorStringSchema.optional(),
      /** An optional string specifying the paper background color. */
      paper: colorStringSchema.optional(),
    })
    .optional(),
  /** An optional string specifying the divider color. */
  divider: colorStringSchema.optional(),
  /** An optional object containing the text color options. */
  text: z
    .object({
      /** An optional string specifying the primary text color. */
      primary: colorStringSchema.optional(),
      /** An optional string specifying the secondary text color. */
      secondary: colorStringSchema.optional(),
      /** An optional string specifying the disabled text color. */
      disabled: colorStringSchema.optional(),
    })
    .optional(),
  /** An optional object containing the action color options. */
  action: z
    .object({
      /** An optional string specifying the active action color. */
      active: colorStringSchema.optional(),
      /** An optional string specifying the disabled action color. */
      disabled: colorStringSchema.optional(),
      /** An optional string specifying the disabled action background color. */
      disabledBackground: colorStringSchema.optional(),
    })
    .optional(),
});

/**
 * Defines the schema for typography options used in the theme.
 */
export const typographySchema = z.object({
  /** An optional string specifying the font family. */
  fontFamily: z.string().optional(),
  /** An optional number specifying the font size. */
  fontSize: z.number().optional(),
  /** An optional number specifying the font weight for light text. */
  fontWeightLight: z.number().optional(),
  /** An optional number specifying the font weight for regular text. */
  fontWeightRegular: z.number().optional(),
  /** An optional number specifying the font weight for medium text. */
  fontWeightMedium: z.number().optional(),
  /** An optional number specifying the font weight for bold text. */
  fontWeightBold: z.number().optional(),
  /** An optional object containing the options for the h1 typography variant. */
  h1: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the h2 typography variant. */
  h2: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the h3 typography variant. */
  h3: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the h4 typography variant. */
  h4: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the h5 typography variant. */
  h5: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the h6 typography variant. */
  h6: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the subtitle1 typography variant. */
  subtitle1: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the subtitle2 typography variant. */
  subtitle2: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the body1 typography variant. */
  body1: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the body2 typography variant. */
  body2: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the button typography variant. */
  button: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the caption typography variant. */
  caption: typographyOptionsSchema.optional(),
  /** An optional object containing the options for the overline typography variant. */
  overline: typographyOptionsSchema.optional(),
});

/**
 * Defines the schema for the header of a theme.
 */
const headerSchema = z.object({
  /** An optional asset in document schema for the logo. */
  logo: assetInDocumentSchema.optional(),
  /** An optional number specifying the height of the header. */
  height: z.number().optional(),
  /** An optional string specifying if the customer name should be hidden. */
  hideCustomerName: z.boolean().optional(),
});

/**
 * Defines the schema for a banner image, which includes an optional image and an optional position.
 */
export const bannerImageSchema = z.object({
  /** An optional asset in document schema for the banner image. */
  image: assetInDocumentSchema.optional(),
  /** An optional object containing the position of the banner image. */
  position: z
    .object({
      /** An optional number specifying the x position of the banner image. */
      x: z.number(),
      /** An optional number specifying the y position of the banner image. */
      y: z.number(),
    })
    .optional(),
});

/**
 * Schema for the channel object in the theme model.
 */
const channelSchema = z.object({
  /** An optional string specifying the channel name. */
  header: z.object({
    /** An optional string specifying the channel name. */
    color: colorStringSchema.optional(),
  }),
});

/** Schema for breadcrumb colors */
const breadcrumbSchema = z.object({
  /** An optional string specifying the breadcrumb background color. */
  backgroundColor: colorStringSchema.optional(),
  /** An optional string specifying the breadcrumb color. */
  color: colorStringSchema.optional(),
});

/** Schema for countdown colors */
const countdownSchema = z.object({
  /** An optional string specifying the countdown text color. */
  color: colorStringSchema.optional(),
});

/**
 * Defines the schema for a banner object, which can be used to represent a banner on a application.
 */
const bannerSchema = z.object({
  /** An optional string specifying the background color of the banner. */
  backgroundColor: colorStringSchema.optional(),
  /** An optional string specifying the color of the banner. */
  color: colorStringSchema.optional(),
  /** An optional object containing the banner image options. */
  bannerImage: bannerImageSchema.optional(),
  /** An optional string specifying the blend mode of the banner background image and color. */
  bannerBlendMode: blendModeSchema.optional(),
});

/**
 * Defines the schema for the background image of a theme.
 */
const backgroundImageSchema = z.object({
  /** An optional asset in document schema for the background image. */
  image: assetInDocumentSchema.optional(),
  /** An optional object containing the position of the background image. */
  position: z
    .object({
      /** An optional number specifying the x position of the background image. */
      x: z.number(),
      /** An optional number specifying the y position of the background image. */
      y: z.number(),
    })
    .optional(),
});

const positionSchema = backgroundImageSchema.pick({
  position: true,
});
export type Position = z.infer<typeof positionSchema>;

/**
 * Defines the schema for the Katsomo theme object.
 */
const katsomoSchema = z.object({
  /** An optional object containing the header options. */
  header: headerSchema.optional(),
  /** An optional object containing the banner options. */
  banner: bannerSchema.optional(),
  /** An optional object containing the background image options. */
  backgroundImage: backgroundImageSchema.optional(),
  /** An optional string specifying the background blend mode. */
  backgroundBlendMode: blendModeSchema.optional(),
  /** An optional object containing the channel options. */
  channel: channelSchema.optional(),
  /** An optional object containing the breadcrumb options. */
  breadcrumbs: breadcrumbSchema.optional(),
  /** An optional object containing the countdown options. */
  countdown: countdownSchema.optional(),
});

export type HeaderType = z.infer<typeof headerSchema>;
export type BannerType = z.infer<typeof bannerSchema>;
export type BackgroundImage = z.infer<typeof backgroundImageSchema>;

/**
 * Type definition for the background blend mode option, inferred from the {@link blendModeSchema}.
 */
export type BackgroundBlendMode = z.infer<typeof blendModeSchema>;

export type ChannelType = z.infer<typeof channelSchema>;

/**
 * Defines the schema for the `mixins` property of a theme object.
 */
const mixinsSchema = z.object({
  /** An optional object containing the toolbar options. */
  toolbar: z
    .object({
      /** An optional number specifying the minimum height of the toolbar. */
      minHeight: z.number().optional(),
    })
    .optional(),
});

/**
 * Defines the schema for a theme object.
 */
export const themeSchema = z.object({
  /** A string specifying the name of the theme. */
  name: z.string().nonempty('Theme name is required'),
  /** A string specifying the ID of the customer that owns the theme. */
  customerId: z.string().nonempty('Customer ID is required'),
  /** An optional object containing the Katsomo theme options. */
  katsomo: katsomoSchema.optional(),
  /** An optional object containing the palette options. */
  palette: paletteSchema.optional(),
  /** An optional object containing the components options. */
  components: componentsSchema.optional(),
  /** An optional array of strings specifying the custom fonts. */
  customFonts: z.array(z.string()).optional(),
  /** An optional object containing the typography options. */
  typography: typographySchema.optional(),
  /** An optional object containing the mixins options. */
  mixins: mixinsSchema.optional(),
});

export const themeOptionsSchema = themeSchema.omit({
  /** A string specifying the name of the theme. */
  name: true,
  /** A string specifying the ID of the customer that owns the theme. */
  customerId: true,
});

export type ThemeOptions = z.infer<typeof themeOptionsSchema>;

/**
 * A schema for a Firestore document representing a theme .
 *
 * This schema merges ({@link themeSchema}) with the {@link firestoreDocSchema} and extends it by adding a `docType` field
 * with a literal value of `'theme'`.
 */
export const themeDocSchema = themeSchema.merge(firestoreDocSchema).extend({
  /** literal document type is `theme` */
  docType: z.literal('theme'),
});

/**
 * Theme overrides schema for a Firestore document representing a theme.
 * This schema omits name and customerId from ({@link themeSchema}) and extends it by adding a baseTheme field.
 */
export const themeOverridesSchema = themeSchema
  .omit({
    /** A string specifying the name of the theme. */
    name: true,
    /** A string specifying the ID of the customer that owns the theme. */
    customerId: true,
  })
  .extend({
    baseTheme: themeDocSchema.nullable().optional(),
  });

/**
 * Type definition for theme overrides inferred from the {@link themeOverridesSchema}.
 */
export type ThemeOverrides = z.infer<typeof themeOverridesSchema>;

/**
 * Merges the {@link themeDocSchema} with the {@link usedInPropertySchema} while omitting the `docType` property.
 * This creates a new schema that represents a theme document that can be used in another document.
 */
export const themeInDocumentSchema = themeDocSchema.merge(
  usedInPropertySchema.omit({
    /** literal document type is `theme` */
    docType: true,
  }),
);

/**
 * Represents a theme object, which is inferred from the {@link themeSchema}.
 */
export type Theme = z.infer<typeof themeSchema>;

/**
 * Type definition for the ThemeDoc object, which is inferred from the {@link themeDocSchema}.
 */
export type ThemeDoc = z.infer<typeof themeDocSchema>;

/**
 * Type definition for the ThemeInDocument object, which is inferred from the {@link themeInDocumentSchema}.
 */
export type ThemeInDocument = z.infer<typeof themeInDocumentSchema>;

/**
 * Extends the `themeSchema` with a `name` property that is a non-empty string.
 * @returns A schema object representing the form values for creating new customer theme.
 */
export const customerThemeEditorFormValuesSchema = themeSchema.extend({
  name: z.string().nonempty('Name cannot be empty'),
});

/**
 * Type definition for the form values of the customer theme editor form inferred from the {@link customerThemeEditorFormValuesSchema}.
 */
export type CustomerThemeEditorFormValues = z.infer<
  typeof customerThemeEditorFormValuesSchema
>;

/**
 * Schema for the form values of the theme editor, which extends the theme schema ({@link themeSchema})
 * and includes an optional nullable base theme {@link themeInDocumentSchema}.
 */
export const themeEditorFormValuesSchema = themeSchema.extend({
  /** An optional base theme. */
  baseTheme: themeInDocumentSchema.nullable().optional(),
});

/**
 * Type definition for the form values of the theme editor inferred from the {@link themeEditorFormValuesSchema}.
 */
export type ThemeEditorFormValues = z.infer<typeof themeEditorFormValuesSchema>;

/**
 * Schema for adding new theme form values.
 */
export const addThemeFormValuesSchema = z.object({
  /** A string specifying the name of the theme. */
  name: z.string().nonempty('Name cannot be empty'),
});

/**
 * Type definition for the values of a form used to add a new theme inferred from the {@link addThemeFormValuesSchema}.
 */
export type AddThemeFormValues = z.infer<typeof addThemeFormValuesSchema>;

/**
 * Interface representing a theme object.
 */
interface ITheme {
  /** An object containing katsomo schema. */
  katsomo?: z.infer<typeof katsomoSchema>;
  /** An object containing components schema. */
  components?: z.infer<typeof componentsSchema>;
  /** An array of custom fonts. */
  customFonts?: string[];
}

/**
 * This module declares a module augmentation for the `@mui/material/styles` module.
 * It extends the `Theme` and `ThemeOptions` interfaces with the `ITheme` interface.
 * This allows configuration using `createTheme`.
 */
declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends ITheme {}
  // allow configuration using `createTheme`

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends ITheme {}
}
