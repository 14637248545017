import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';

/**
 * Retrieves the event ID associated with a given slug for a specific customer.
 * @param customerId The ID of the customer who owns the event.
 * @param slug The slug associated with the event.
 * @returns The ID of the event associated with the given slug.
 * @throws If no event is found for the given slug.
 */
export async function getEventIdFromSlug(customerId: string, slug: string) {
  const firestore = getFirestore();
  const eventSlugDoc = doc(
    firestore,
    firestorePaths.eventSlugPath(customerId, slug),
  );

  const snapshot = await getDoc(eventSlugDoc);
  const data = snapshot.data();
  if (!data && !data?.['eventId']) {
    throw new KatsomoError(
      'No event found for slug:' + slug,
      KatsomoErrorCodes.EVENT_NOT_FOUND,
      undefined,
      {
        slug,
      },
    );
  }
  return data['eventId'];
}
