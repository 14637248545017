import { getDoc } from 'firebase/firestore';
import { getChatMessageDocRef } from './getChatMessageDocRef';

/**
 * Retrieves a Firestore document snapshot for a chat message with the given ID.
 *
 * @param parentPath - The path of the parent document that contains the chat message.
 * @param chatMessageId - The ID of the chat message document to retrieve.
 * @returns A Promise that resolves with the Firestore document snapshot for the chat message.
 */
export async function getChatMessageDoc(
  parentPath: string,
  chatMessageId: string,
) {
  const chatMessageRef = getChatMessageDocRef(parentPath, chatMessageId);
  const docSnapshot = await getDoc(chatMessageRef);
  return docSnapshot;
}
