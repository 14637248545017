import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';

// Add support for the sx prop for consistency with the other branches.
export const Anchor = styled('a')({});

/**
 * Props for the NextLinkComposed component, which is a wrapper around Next.js's Link component
 * that allows for additional props to be passed in.
 */
export interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<
      NextLinkProps,
      'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'
    > {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

/**
 * A composed link component that wraps Next.js's `NextLink` and `Anchor` components.
 * Accepts all props from `NextLink` and `Anchor`.
 */
export const NextLinkComposed = forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } =
    props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      legacyBehavior
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  );
});
