/**
 * This file exports functions for creating and managing observable stores.
 * It exports functions for getting or creating a store from cache, getting or creating a store for a given method and options,
 * and creating an observable from a snapshot function.
 * @packageDocumentation
 */
import { Store } from '@livekatsomo/models';
import { Observable } from 'rxjs';
import { createObservableStore } from './createObservableStore';
import { Observer, Unsubscribe } from './types';

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis

  // eslint-disable-next-line no-var
  var _storeCache: Map<string, Store<unknown>>;
}

/**
 * A cache for storing instances of the Store class.
 */
const storeCache = globalThis._storeCache || new Map<string, Store<unknown>>();

if (!globalThis._storeCache) {
  // If the cache doesn't exist, create it on the global scope
  globalThis._storeCache = storeCache;
}

/**
 * Returns a store from cache if it exists, otherwise creates a new store and adds it to the cache.
 * @typeParam Model - The type of the store's model.
 * @param key - The key to identify the store in the cache.
 * @param observable$ - The observable that the store will subscribe to.
 * @param initialState - The initial state of the store.
 * @returns The store.
 */
function getStore<Model>(
  key: string,
  observable$: Observable<Model>,
  initialState?: Model | null,
): Store<Model> {
  const existingStore = getStoreFromCache<Model>(key);
  if (existingStore) return existingStore;
  else {
    const store = createObservableStore(observable$, initialState);
    storeCache.set(key, store);
    return store;
  }
}

/**
 * Returns the store from the cache if it exists.
 * @typeParam Model - The type of the store model.
 * @param key - The key of the store to retrieve from the cache.
 * @returns The store from the cache if it exists, otherwise undefined.
 */
function getStoreFromCache<Model>(key: string): Store<Model> | undefined {
  if (storeCache.has(key)) {
    return storeCache.get(key) as Store<Model>;
  }
  return;
}

/**
 * Returns an existing store from cache or creates a new one if it doesn't exist.
 * @typeParam Model - The type of the model that the store holds.
 * @param cacheKey - The key to use for the cache lookup. Can be a string or any other JSON-serializable value.
 * @param getter - A function that returns the initial state of the store, or an observable that emits the initial state.
 * @param initialState - The initial state of the store. Optional if `getter` is an observable that emits the initial state.
 * @returns The store object.
 */
export function getOrCreateStore<Model>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cacheKey: any,
  getter: GetSnapshotFunction<Model> | Observable<Model>,
  initialState?: Model | null,
): Store<Model> {
  const key =
    typeof cacheKey === 'string' ? cacheKey : JSON.stringify(cacheKey);
  let store = getStoreFromCache<Model>(key);

  if (!store) {
    if (typeof getter === 'function') {
      const observable$ = createObservableFromSnapshotFunction<Model>(getter);
      store = getStore(key, observable$, initialState);
    } else {
      store = getStore(key, getter, initialState);
    }
  }
  return store;
}

/**
 * A function that returns a snapshot function for a given set of options, observer and initial state.
 * @typeParam Options The type of options passed to the snapshot function.
 * @typeParam Model The type of the model returned by the observer.
 * @param options The options passed to the snapshot function.
 * @param observer The observer that returns the model.
 * @param initialState The initial state of the model.
 * @returns A function that can be called to unsubscribe from the observer.
 */
export type SnapshotFunction<Options, Model> = (
  options: Options,
  observer: Observer<Model>,
  initialState?: Model,
) => () => void;

/**
 * A WeakMap that stores a mapping between a SnapshotFunction and a Map of string keys to Store instances.
 */
const methodStore = new WeakMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SnapshotFunction<any, any>,
  Map<string, Store<unknown>>
>();

/**
 * Returns a store for a given method and options. If a store for the given method and options already exists, it returns the existing store. Otherwise, it creates a new store and returns it.
 * @typeParam Options The type of the options object passed to the method.
 * @typeParam Model The type of the model returned by the method.
 * @param method The method to create a store for.
 * @param options The options object to pass to the method.
 * @param initialState The initial state of the store.
 * @returns The store for the given method and options.
 */
export function getOrCreateMethodStore<Options, Model>(
  method: SnapshotFunction<Options, Model>,
  options: Options,
  initialState?: Model,
): Store<Model> {
  const key = typeof options === 'string' ? options : JSON.stringify(options);

  if (!method.name) console.error('method should not be anonymous', method);

  let map = methodStore.get(method);
  if (!map) {
    map = new Map<string, Store<Model>>();
    methodStore.set(method, map);
  }

  let store = map.get(key);
  if (!store) {
    // console.log('creating new store', method.name, options);
    const observable$ = createObservableFromSnapshotFunction<Model>(
      (observer) => method(options, observer, initialState),
    );
    store = createObservableStore(observable$, initialState);
    map.set(key, store);
  }

  return store as Store<Model>;
}

/**
 * Creates an observable from a snapshot function.
 * @typeParam Model The type of the model returned by the snapshot function.
 * @param getSnapshotFunction The snapshot function to create the observable from.
 * @returns An observable that emits the model returned by the snapshot function.
 */
function createObservableFromSnapshotFunction<Model>(
  getSnapshotFunction: GetSnapshotFunction<Model>,
): Observable<Model> {
  // console.log('createFirestoreObservable', getSnapshotFunction);
  const observable = new Observable<Model>((observer) => {
    const unsubscribe = getSnapshotFunction({
      next: (result) => observer.next(result),
      error: (error) => {
        console.log('observable received error', error);
        observer.error(error);
      },
      complete: () => observer.complete(),
    });

    return unsubscribe;
  });

  return observable;
}

/**
 * A function that takes an observer and returns an unsubscribe function.
 * @typeParam Model The type of the model being observed.
 * @param observer The observer to be passed to the function.
 * @returns The unsubscribe function.
 */
export type GetSnapshotFunction<Model> = (
  observer: Observer<Model>,
) => Unsubscribe;

/**
 * An empty store that can be used as a placeholder.
 */
export const emptyStore: Store<null> = {
  subscribe: () => () => undefined,
  getSnapshot: () => null,
  getServerSnapshot: () => null,
};
