/**
 * An object containing Firestore paths for a customer,
 * including paths for customer assets, customer configuration, and customer slugs.
 */
export const firestorePathsForCustomer = {
  /**
   * Returns the path to the asset collection for a given customer ID.
   * @param customerId - The ID of the customer.
   * @returns The path to the asset collection.
   */
  customerAssetCollectionPath: (customerId: string) =>
    `customer/${customerId}/asset`,

  /**
   * Returns the path to a customer document for a given customer ID.
   * @param customerId - The ID of the customer.
   * @returns The path to the customer document.
   */
  customerPath: (customerId: string) => `customer/${customerId}`,

  /**
   * The path to the customer collection.
   */
  customerCollectionPath: '/customer',

  /**
   * The ID of the customer collection.
   */
  customerCollectionId: 'customer',

  /**
   * Returns the path to the private config document for a given customer ID.
   * @param customerId - The ID of the customer.
   * @returns The path to the private config document.
   */
  customerConfigPath: (customerId: string) =>
    `customer/${customerId}/private/config`,

  /**
   * Returns the path to the customer slug document for a given slug.
   * @param slug - The slug of the customer.
   * @returns The path to the customer slug document.
   */
  customerSlugPath: (slug: string) => `customer-slug/${slug}`,

  /**
   * The ID of the customer slug collection.
   */
  customerSlugCollectionId: 'customer-slug',
} as const;
