import { deleteDoc } from 'firebase/firestore';
import { getDownloadFileDocRef } from './getDownloadFileDocRef';
import { getDownloadFileDoc } from './getDownloadFileDoc';
import { downloadFileConverter } from './downloadFileConverter';
import { getStorage, ref, deleteObject } from 'firebase/storage';
/**
 * Deletes an downloadFile with the given ID from the specified parent path.
 * @param downloadFileId - The ID of the downloadFile to delete.
 * @param parentPath - The parent path of the downloadFile to delete.
 * @returns A Promise that resolves when the downloadFile is deleted.
 * @throws An error if the downloadFile is not found.
 */
export const deleteDownloadFile = async (
  downloadFileId: string,
  parentPath?: string,
): Promise<void> => {
  if (!parentPath) return Promise.resolve();
  const docSnapshot = await getDownloadFileDoc(parentPath, downloadFileId);
  const downloadFileRef = getDownloadFileDocRef(
    parentPath,
    downloadFileId,
  ).withConverter(downloadFileConverter);

  if (docSnapshot.exists()) {
    const downloadFileData = docSnapshot.data();
    await deleteDoc(downloadFileRef);

    // Delete the storage file
    const storage = getStorage();
    await deleteObject(ref(storage, downloadFileData?.filePath));
  } else {
    throw new Error('DownloadFile not found');
  }
};
