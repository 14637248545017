import { getDoc } from 'firebase/firestore';
import { getModeratorDocRef } from './getModeratorDocRef';

/**
 * Retrieves the moderator document snapshot from Firestore.
 * @param parentPath - The parent path of the moderator document.
 * @param moderatorId - The ID of the moderator document.
 * @returns A promise that resolves with the moderator document snapshot.
 */
export async function getModeratorDoc(parentPath: string, moderatorId: string) {
  const moderatorRef = getModeratorDocRef(parentPath, moderatorId);
  const docSnapshot = await getDoc(moderatorRef);
  return docSnapshot;
}
