import { KatsomoError } from '@livekatsomo/custom-errors';
import { Store } from '@livekatsomo/models';
import { Observable, Subscription } from 'rxjs';

/**
 * Creates an observable store with the given observable and initial state.
 *
 * The store will be initialized when the first subscriber is added.
 * Store will be reset when the last subscriber is removed.
 * Store can be used with useSyncExternalState hook.
 * @typeParam Model The type of the model.
 * @param observable$ The observable to use for the store.
 * @param initialState The initial state of the store.
 * @returns The created store.
 */
export function createObservableStore<Model>(
  observable$: Observable<Model>,
  initialState?: Model | null,
): Store<Model> {
  let resolve: ((value?: void | PromiseLike<void>) => void) | undefined;
  let state: Model | Promise<void> =
    initialState || new Promise<void>((res) => (resolve = res));

  const subscribers = new Set<() => void>();

  let innerSubscription: Subscription | undefined;
  let initialized = false;

  const makeInitialSubscription = () => {
    if (!observable$) {
      throw new Error('observable$ is not defined');
    }
    if (initialized) return;
    initialized = true;
    innerSubscription = observable$.subscribe({
      next: (value) => {
        // console.log('next', value);
        state = value;
        if (resolve) resolve();
        for (const subscriber of subscribers) {
          subscriber();
        }
      },
      error: (error) => {
        // console.log('store received error', error);
        state = error;
        initialized = false;
        if (resolve) resolve();
        for (const subscriber of subscribers) {
          subscriber();
        }
      },
    });
  };

  /**
   * Subscribes to the store.
   * @param callback The callback to invoke when the store changes.
   * @returns A function to unsubscribe from the store.
   */
  const subscribe = (callback: () => void) => {
    subscribers.add(callback);
    return () => {
      subscribers.delete(callback);
      if (subscribers.size === 0) {
        innerSubscription?.unsubscribe();
        innerSubscription = undefined;
        initialized = false;
        state = new Promise<void>((res) => (resolve = res));
      }
    };
  };

  /**
   * Gets a snapshot of the store.
   * @returns The snapshot of the store.
   * @throws If the store is not yet initialized.
   * @throws If the store has encountered an error.
   * Error will be an instance of {@link KatsomoError} if the error is recoverable.
   * Error onReset callback can be used to reset the store from the error state.
   */
  const getSnapshot = () => {
    if (!innerSubscription) {
      makeInitialSubscription();
    }
    if (state instanceof Promise) throw state;
    if (state instanceof Error) {
      const error = state;
      if (error instanceof KatsomoError) {
        error.onReset = () => {
          if (state instanceof Error) {
            state = new Promise<void>((res) => (resolve = res));
            makeInitialSubscription();
          }
        };
      }
      return error;
    }
    return state;
  };

  /**
   * Gets the server snapshot of the store.
   * @returns The server snapshot of the store.
   */
  const getServerSnapshot = (): Model | null => {
    return initialState || null;
  };

  return {
    subscribe,
    getSnapshot,
    getServerSnapshot,
  };
}
