import { getDatabase, ref, remove } from 'firebase/database';

/**
 * Removes the cursor position of a client when they disconnect from a document.
 * @param docPath - The path of the document in the database.
 * @param clientID - The ID of the client whose cursor position needs to be removed.
 * @returns A Promise that resolves when the cursor position has been successfully removed.
 */
export async function removeCursorPositionOnDestroy(
  docPath: string,
  clientID: string,
) {
  const db = getDatabase();

  try {
    const sessionRef = ref(db, docPath + '/session/' + clientID);
    await remove(sessionRef);
  } catch (error) {
    console.error(
      `Error while establishing on-disconnect handler. Reason: `,
      error,
    );
  }
}
