import { AssetDoc, UsedIn } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  UploadAssetFunction,
  UploadFilesFunction,
  DeleteAssetFunction,
  UpdateAssetFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * The model for the assets context.
 */
export type AssetsContextModel = {
  /** The store for the assets. */
  store: Store<AssetDoc[] | null>;
  /** The path to the parent of the assets. */
  parentPath?: string;
  /** Function to set query constraints for the assets. */
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
  /** Function to upload an asset. */
  uploadAsset: UploadAssetFunction;
  /** Function to upload multiple files. */
  uploadFiles: UploadFilesFunction;
  /** Function to delete an asset. */
  deleteAsset: DeleteAssetFunction;
  /** Function to update an asset. */
  updateAsset: UpdateAssetFunction;
  /** Function to get the assets used in a specific asset. */
  getAssetUsedIn: (assetId: string) => Promise<UsedIn[]>;
};

/**
 * The context for customer assets.
 */
export const CustomerAssetsContext = createContext<
  AssetsContextModel | undefined
>(undefined);

/**
 * The context for event assets.
 */
export const EventAssetsContext = createContext<AssetsContextModel | undefined>(
  undefined,
);
