import { deleteDoc } from 'firebase/firestore';
import { getStreamDoc } from './getStreamDoc';
import { getStreamDocRef } from './getStreamDocRef';

/**
 * Deletes a stream with the given customer ID and stream ID.
 * @param customerId - The ID of the customer who owns the stream.
 * @param streamId - The ID of the stream to delete.
 * @returns A Promise that resolves when the stream is deleted.
 * @throws If the stream is not found.
 */
export async function deleteStream(
  customerId: string,
  streamId: string,
): Promise<void> {
  const docSnapshot = await getStreamDoc(customerId, streamId);
  const streamRef = getStreamDocRef(customerId, streamId);
  if (docSnapshot.exists()) {
    await deleteDoc(streamRef);
  } else {
    throw new Error('Stream not found');
  }
}
