import { SlideImageDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { slideConverter } from './slideConverter';

/**
 * Retrieves a list of slide images for a given slide deck.
 *
 * @param options - The options for retrieving the slide images.
 * @param options.eventId - The ID of the event the slide deck belongs to.
 * @param options.trackId - The ID of the track the slide deck belongs to.
 * @param options.slideDeckId - The ID of the slide deck to retrieve slide images for.
 * @param options.queryConstraints - Optional query constraints to apply to the slide images query.
 * @param observer - The observer to notify of changes to the slide images.
 * @returns A function to unsubscribe from the slide images observer.
 */
export function getSlides(
  options: {
    eventId: string;
    trackId: string;
    slideDeckId?: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<SlideImageDoc[]>,
) {
  if (!options.slideDeckId) {
    observer.next([]);
    return () => undefined;
  }
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.slidesCollectionPath(
        options.eventId,
        options.trackId,
        options.slideDeckId,
      ),
      queryConstraints: orderBy('index', 'asc'),
    },
    slideConverter,
  );
}
