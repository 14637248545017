import { z } from 'zod';

/**
 * Represents a schema for tracking where a property is used in a document.
 */
export const UsedInSchema = z.object({
  /** The path to the document where the property is used. */
  targetDocRefPath: z.string(),
  /** The path to the document where the property is defined. */
  sourceDocRefPath: z.string(),
  /** The name of the property being tracked. */
  property: z.string(),
});

/**
 * Type alias for the inferred type of the UsedInSchema schema ({@link UsedInSchema}).
 */
export type UsedIn = z.infer<typeof UsedInSchema>;

/**
 * Defines the schema for the `usedIn` property of a document.
 *
 * The `usedIn` property is used to track references to a document from other documents.
 */
export const usedInPropertySchema = z.object({
  /** The ID of the reference. */
  id: z.string(),
  /** The type of the document being referenced. */
  docType: z.string(),
  /** The path to the document that contains the reference.
   * This is filled by the firestore function after the usedIn document is created.
   */
  usedInDocRefPath: z.string().optional(),
  /** The path to the document being referenced. */
  sourceDocRefPath: z.string(),
});

/**
 * Represents a property that is used in a model ({@link UsedInProperty}).
 * @typeParam Model The type of the model that the property is used in.
 */
export type UsedInProperty<Model> = z.infer<typeof usedInPropertySchema> &
  Model;
