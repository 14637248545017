import { SlideDeck, SlideDeckDoc } from '@livekatsomo/models';

import { UploadProgressFunction } from '@livekatsomo/types';
import { getApp } from 'firebase/app';
import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageReference,
  uploadBytesResumable,
} from 'firebase/storage';
import slugify from 'slugify';
import { slideDeckConverter } from '../slides/slideDeckConverter';

/**
 * Uploads a PDF file to a specified directory in Google Cloud Storage and saves its metadata to Firestore.
 * @param options - The options object.
 * @param options.file - The PDF file to upload.
 * @param options.uploadDir - The directory in Google Cloud Storage to upload the file to.
 * @param options.filename - The name to give the uploaded file. If not provided, the original filename will be used.
 * @param options.bucket - The Google Cloud Storage bucket to use. If not provided, the default bucket will be used.
 * @param options.slideData - The metadata to save to Firestore for the uploaded file.
 * @param options.onUploadProgressChange - A function to call when the upload progress changes.
 * @param options.onComplete - A function to call when the upload is complete.
 * @param options.onError - A function to call if an error occurs during the upload.
 * @returns A promise that resolves with the Firestore document snapshot for the uploaded file.
 */
export async function uploadPDFFile({
  file,
  filename,
  uploadDir,
  bucket,
  slideData: slideData,
  onUploadProgressChange,
  onComplete,
  onError,
}: {
  file: File;
  uploadDir: string;
  filename?: string;
  bucket?: string;
  slideData: Partial<SlideDeck>;
  onUploadProgressChange?: UploadProgressFunction;
  onComplete?: ({
    storageRef,
    slideDocRef,
  }: {
    storageRef: StorageReference;
    slideDocRef: DocumentReference<SlideDeckDoc>;
  }) => Promise<void>;
  onError: (error: Error) => void;
}): Promise<DocumentSnapshot<SlideDeckDoc>> {
  const firestore = getFirestore();
  const storage = getStorage(getApp(), `gs://${bucket}`);
  const filePath = `${uploadDir.replace(/\/+$/, '')}/${slugify(
    filename || file.name,
    { lower: true },
  )}`;
  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        // update progress
        onUploadProgressChange &&
          onUploadProgressChange((state) => ({
            ...state,
            [file.name]: { file, progress: percent },
          }));
      },
      (error) => {
        onError && onError(error);
        reject(error);
      },
      async () => {
        try {
          // upload complete
          const slideDocRef = doc(firestore, filePath).withConverter(
            slideDeckConverter,
          );

          // Save asset to firestore
          const storageRef = uploadTask.snapshot.ref;
          const originalUrl = await getDownloadURL(storageRef);

          const slideDeck: Partial<SlideDeck> = {
            ...slideData,
            filename: file.name,
            filePath,
            originalUrl,
            bucket: storageRef.bucket,
            type: 'application/pdf',
            status: 'processing',
          };

          await setDoc(slideDocRef, slideDeck, { merge: true });
          onUploadProgressChange &&
            onUploadProgressChange((state) => ({
              ...state,
              [file.name]: { ...state[file.name], progress: 100 },
            }));
          onComplete && (await onComplete({ storageRef, slideDocRef }));
          const slideDeckDoc = await getDoc(slideDocRef);
          resolve(slideDeckDoc);
        } catch (error) {
          onError && onError(error as Error);
        }
      },
    );
  });
}
