import { SVGProps } from 'react';

export interface LogoProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

/**
 * Renders the RajuCast logo.
 * @param props The component props.
 * @param props.color The color of the logo, defaults to white.
 * @returns The rendered component.
 */
export const Logo = ({ color = '#fff', ...props }: LogoProps) => (
  <svg
    fill="none"
    viewBox="0 0 500 220"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="matrix(1,0,0,1,15.125,194.356)">
      <path
        d="M0,-7.127L17.091,-7.127C19.846,-7.127 22.273,-7.667 24.372,-8.75C26.472,-9.832 27.522,-11.562 27.522,-13.938C27.522,-16.313 26.472,-18.042 24.372,-19.125C22.273,-20.207 19.846,-20.748 17.091,-20.748L0,-20.748L0,-7.127ZM44.612,23.124C44.612,24.26 43.93,24.827 42.567,24.827L32.938,24.827C31.921,24.827 30.805,24.465 29.588,23.738C28.371,23.013 27.442,22.135 26.8,21.105L19.979,10.572C17.171,6.244 13.775,4.079 9.789,4.079L0,4.079L0,21.105C0,22.135 -0.367,23.013 -1.103,23.738C-1.838,24.465 -2.727,24.827 -3.771,24.827L-11.313,24.827C-12.356,24.827 -13.253,24.465 -14.001,23.738C-14.75,23.013 -15.125,22.135 -15.125,21.105L-15.125,-28.192C-15.125,-29.222 -14.763,-30.106 -14.041,-30.845C-13.319,-31.584 -12.436,-31.954 -11.393,-31.954L21.705,-31.954C27.829,-31.954 32.851,-30.33 36.769,-27.083C40.687,-23.837 42.647,-19.454 42.647,-13.938C42.647,-6.256 37.604,-1.438 27.522,0.515C29.127,1.096 30.611,2.093 31.975,3.505C33.339,4.917 34.904,6.969 36.669,9.662L43.971,21.105C44.398,21.844 44.612,22.518 44.612,23.124"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,82.766,187.308)">
      <path
        d="M0,6.969L25.555,6.969L25.555,-13.7L14.122,-13.7C11.554,-13.7 9.314,-12.981 7.402,-11.542C5.489,-10.103 4.198,-8.17 3.53,-5.741L0,6.969ZM-21.103,28.232L-11.032,-9.463C-8.304,-19.732 0.575,-24.879 15.606,-24.906L37.23,-24.906C38.219,-24.906 39.042,-24.536 39.697,-23.797C40.352,-23.058 40.68,-22.199 40.68,-21.223L40.68,28.232C40.68,29.262 40.305,30.127 39.557,30.826C38.808,31.526 37.912,31.875 36.869,31.875L29.327,31.875C28.23,31.875 27.327,31.526 26.618,30.826C25.91,30.127 25.555,29.236 25.555,28.153L25.555,18.175L-2.969,18.175L-5.657,28.153C-5.925,29.236 -6.526,30.127 -7.462,30.826C-8.398,31.526 -9.388,31.875 -10.431,31.875L-18.334,31.875C-19.377,31.875 -20.127,31.578 -20.581,30.984C-21.036,30.39 -21.257,29.869 -21.243,29.42C-21.229,28.972 -21.183,28.576 -21.103,28.232"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,134.277,174.321)">
      <path
        d="M0,32.944L6.74,33.657L9.629,33.657C13.346,33.657 16.04,32.904 17.712,31.4C19.383,29.895 20.22,27.361 20.22,23.797L20.22,-8.157C20.22,-9.186 20.587,-10.07 21.323,-10.81C22.058,-11.548 22.948,-11.918 23.991,-11.918L31.453,-11.918C32.549,-11.918 33.473,-11.548 34.221,-10.81C34.969,-10.07 35.344,-9.186 35.344,-8.157L35.344,23.679C35.344,30.437 33.071,35.656 28.524,39.339C23.977,43.021 17.812,44.862 10.03,44.862L-1.324,44.862C-2.367,44.862 -3.257,44.5 -3.991,43.774C-4.728,43.048 -5.095,42.17 -5.095,41.14L-5.095,36.666C-5.095,35.637 -4.741,34.759 -4.032,34.033C-3.323,33.307 -2.421,32.944 -1.324,32.944L0,32.944Z"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,179.452,185.17)">
      <path
        d="M0,11.562L0,-19.006C0,-20.036 0.367,-20.92 1.103,-21.659C1.838,-22.398 2.754,-22.768 3.852,-22.768L11.353,-22.768C12.37,-22.768 13.252,-22.398 14.001,-21.659C14.75,-20.92 15.125,-20.036 15.125,-19.006L15.125,11.641C15.125,15.522 15.987,18.412 17.712,20.313C19.438,22.213 22.106,23.164 25.716,23.164L32.135,23.164C35.745,23.164 38.413,22.213 40.139,20.313C41.864,18.412 42.726,15.522 42.726,11.641L42.726,-19.006C42.726,-20.036 43.093,-20.92 43.83,-21.659C44.564,-22.398 45.454,-22.768 46.498,-22.768L53.96,-22.768C55.055,-22.768 55.979,-22.398 56.727,-21.659C57.476,-20.92 57.851,-20.036 57.851,-19.006L57.851,11.562C57.851,18.795 55.698,24.398 51.392,28.371C47.086,32.344 40.801,34.33 32.536,34.33L25.315,34.33C17.023,34.33 10.732,32.344 6.439,28.371C2.146,24.398 0,18.795 0,11.562"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,252.126,169.094)">
      <path
        d="M0,43.397C-4.961,38.725 -7.442,31.498 -7.442,21.718C-7.442,11.938 -4.961,4.706 0,0.019C4.961,-4.666 12.403,-7.009 22.326,-7.009L31.273,-7.009C37.344,-7.009 42.712,-5.451 47.381,-2.337C52.047,0.779 54.381,5.042 54.381,10.453C54.381,12.011 53.913,13.126 52.977,13.799C52.04,14.472 51.131,14.809 50.248,14.809L45.474,14.809C43.013,14.809 41.102,13.357 39.738,10.453C37.758,6.283 34.468,4.197 29.868,4.197L22.727,4.197C16.87,4.197 12.898,5.438 10.812,7.919C8.726,10.401 7.683,14.994 7.683,21.698C7.683,28.404 8.726,33.003 10.812,35.498C12.898,37.992 16.87,39.239 22.727,39.239L30.711,39.239C35.311,39.239 38.587,37.155 40.54,32.983C41.904,30.053 43.83,28.588 46.318,28.588L51.091,28.588C52.348,28.588 53.344,29.05 54.08,29.974C54.815,30.898 55.183,31.901 55.183,32.983C55.183,38.395 52.857,42.652 48.202,45.753C43.549,48.855 38.186,50.406 32.115,50.406L22.326,50.406C12.403,50.406 4.961,48.069 0,43.397"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,331.461,187.308)">
      <path
        d="M0,6.969L25.555,6.969L25.555,-13.7L14.122,-13.7C11.554,-13.7 9.314,-12.981 7.402,-11.542C5.489,-10.103 4.198,-8.17 3.53,-5.741L0,6.969ZM-21.103,28.232L-11.032,-9.463C-8.304,-19.732 0.575,-24.879 15.606,-24.906L37.23,-24.906C38.219,-24.906 39.042,-24.536 39.697,-23.797C40.352,-23.058 40.68,-22.199 40.68,-21.223L40.68,28.232C40.68,29.262 40.305,30.127 39.557,30.826C38.808,31.526 37.912,31.875 36.869,31.875L29.327,31.875C28.23,31.875 27.327,31.526 26.618,30.826C25.91,30.127 25.555,29.236 25.555,28.153L25.555,18.175L-2.969,18.175L-5.657,28.153C-5.925,29.236 -6.526,30.127 -7.462,30.826C-8.398,31.526 -9.388,31.875 -10.431,31.875L-18.334,31.875C-19.377,31.875 -20.127,31.578 -20.581,30.984C-21.036,30.39 -21.257,29.869 -21.243,29.42C-21.229,28.972 -21.183,28.576 -21.103,28.232"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,380.325,176.935)">
      <path
        d="M0,27.717C0,26.16 0.468,25.044 1.405,24.371C2.34,23.698 3.25,23.361 4.133,23.361L8.906,23.361C11.314,23.361 13.145,24.655 14.403,27.242C15.659,29.829 18.481,31.122 22.868,31.122L36.91,31.399C41.617,31.399 43.97,29.882 43.97,26.846C43.97,24.549 41.095,22.742 35.345,21.421C32.777,20.814 29.942,20.214 26.839,19.62C23.737,19.026 20.654,18.28 17.593,17.382C14.53,16.486 11.715,15.429 9.148,14.215C6.58,13.001 4.5,11.331 2.908,9.206C1.318,7.081 0.522,4.58 0.522,1.702C0.522,-3.604 2.433,-7.688 6.259,-10.553C10.083,-13.416 16.663,-14.849 25.997,-14.849L36.187,-14.611C41.67,-14.611 46.612,-13.218 51.011,-10.434C55.411,-7.648 57.61,-4.171 57.61,0C57.61,1.558 57.142,2.679 56.207,3.365C55.27,4.052 54.374,4.395 53.518,4.395L48.745,4.395C46.337,4.395 44.505,3.095 43.248,0.495C41.991,-2.105 39.169,-3.406 34.783,-3.406L22.707,-3.643C17.999,-3.643 15.646,-2.125 15.646,0.91C15.646,2.573 17.137,3.934 20.12,4.989C23.101,6.045 26.713,6.976 30.951,7.78C35.19,8.586 39.45,9.556 43.73,10.691C48.008,11.826 51.639,13.694 54.622,16.294C57.603,18.894 59.095,22.134 59.095,26.014C59.095,31.32 57.176,35.406 53.338,38.269C49.5,41.134 42.927,42.566 33.62,42.566L21.464,42.328C15.981,42.328 11.032,40.936 6.62,38.151C2.207,35.366 0,31.888 0,27.717"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,441.467,211.699)">
      <path
        d="M0,-41.813L0,-45.535C0,-46.617 0.36,-47.515 1.083,-48.228C1.805,-48.94 2.714,-49.297 3.811,-49.297L54.722,-49.297C55.818,-49.297 56.727,-48.94 57.449,-48.228C58.172,-47.515 58.533,-46.617 58.533,-45.535L58.533,-41.813C58.533,-40.73 58.172,-39.839 57.449,-39.14C56.727,-38.44 55.818,-38.091 54.722,-38.091L36.828,-38.091L36.828,3.841C36.828,4.871 36.46,5.736 35.726,6.435C34.989,7.135 34.1,7.484 33.058,7.484L25.475,7.484C24.432,7.484 23.542,7.135 22.807,6.435C22.071,5.736 21.704,4.871 21.704,3.841L21.704,-38.091L3.811,-38.091C2.714,-38.091 1.805,-38.44 1.083,-39.14C0.36,-39.839 0,-40.73 0,-41.813"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,248.471,101.543)">
      <path
        d="M0,-82.928C21.166,-70.072 42.236,-57.274 63.77,-44.194C42.221,-31.07 21.154,-18.24 -0.003,-5.355C13.94,6.384 41.827,8.794 60.965,-9.9C80.191,-28.679 80.286,-59.354 61.239,-78.212C42.472,-96.791 14.291,-94.948 0,-82.928M-90.811,-44.192C-90.801,-17.625 -69.405,3.828 -42.89,3.857C-16.452,3.885 5.18,-17.656 5.261,-44.093C5.341,-70.387 -16.434,-92.176 -42.798,-92.182C-69.289,-92.189 -90.82,-70.667 -90.811,-44.192M32.628,-101.543C34.884,-101.1 37.143,-100.666 39.397,-100.212C67.582,-94.531 87.693,-67.013 84.506,-38.495C81.026,-7.359 55.2,14.642 23.909,13.127C13.351,12.616 3.894,9.106 -7.744,1.329C-14.965,6.629 -22.981,10.293 -31.884,12.145C-62.995,18.615 -94.489,-3.165 -99.412,-34.679C-104.517,-67.365 -82.707,-96.499 -49.777,-100.999C-49.259,-101.07 -48.77,-101.357 -48.267,-101.543L-37.658,-101.543C-35.942,-101.208 -34.229,-100.852 -32.509,-100.541C-23.368,-98.889 -15.151,-95.118 -8.576,-90.204C-1.489,-93.397 4.898,-96.535 11.497,-99.137C14.806,-100.442 18.499,-100.772 22.019,-101.543L32.628,-101.543Z"
        fill={color}
      />
    </g>
    <g transform="matrix(1,0,0,1,226.992,90.328)">
      <path
        d="M0,-65.905C-12.949,-43.835 -13.059,-22.369 -0.043,0C-19.353,-11.194 -37.85,-21.916 -56.917,-32.969C-37.817,-44.022 -19.231,-54.777 0,-65.905"
        fill="rgb(180,204,57)"
      />
    </g>
  </svg>
);

export default Logo;
