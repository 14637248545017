import { arrayRemove, deleteDoc, updateDoc } from 'firebase/firestore';
import { getTrackDocRef } from '../tracks';
import { getAgendaItemDoc, getAgendaItemDocRef } from './getAgendaItemDocRef';

/**
 * Deletes an agenda item from the specified event and track.
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param agendaItemId - The ID of the agenda item to delete.
 * @returns A Promise that resolves when the agenda item has been deleted.
 * @throws An error if the agenda item is not found.
 */
export const deleteAgendaItem = async (
  eventId: string,
  trackId: string,
  agendaItemId: string,
): Promise<void> => {
  const docSnapshot = await getAgendaItemDoc(eventId, agendaItemId);
  const agendaItemRef = getAgendaItemDocRef(eventId, agendaItemId);
  if (docSnapshot.exists()) {
    await deleteDoc(agendaItemRef);
  } else {
    throw new Error('AgendaItem not found');
  }
  await updateDoc(getTrackDocRef(eventId, trackId), {
    agendaItems: arrayRemove(agendaItemRef.id),
  });
};
