import { ThemeOptions } from '@mui/material/styles';

/**
 * The default theme options for the application.
 */
export const defaultTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    common: {
      white: '#f2f2f2',
      black: '#181818',
    },
    primary: {
      main: '#79ac37',
      contrastText: '#144030',
      light: '#93b86b',
      dark: '#4c6d22',
    },
    secondary: {
      main: '#6cc1b6',
    },
    background: {
      default: '#181818',
      paper: '#2d2d2d',
    },
    error: {
      main: '#ef5350',
    },
    action: {
      active: 'rgba(249,248,248,0.87)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    text: {
      primary: '#f2f2f2',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.5)',
  },
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 14,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 400,
    },
    caption: {
      fontWeight: 300,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#79ac37',
          color: '#144030',
        },
      },
    },
  },
};

export default defaultTheme;
