import FullscreenEnter from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'next-i18next';

export function FullscreenButton({
  fullscreen,
  location = 'bottom-right',
  requestFullscreen,
  exitFullscreen,
}: {
  fullscreen: boolean;
  requestFullscreen: () => void;
  exitFullscreen: () => void;
  location?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}) {
  let position: {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
  } = {};

  switch (location) {
    case 'top-left':
      position = {
        top: 0,
        left: 0,
      };
      break;
    case 'top-right':
      position = {
        top: 0,
        right: 0,
      };
      break;
    case 'bottom-left':
      position = {
        bottom: 0,
        left: 0,
      };
      break;
    case 'bottom-right':
      position = {
        bottom: 0,
        right: 0,
      };
      break;
  }

  const { t } = useTranslation();
  return (
    <Tooltip
      className="fullscreen-button"
      placement="left-start"
      title={fullscreen ? t('Exit fullscreen') : t('Show fullscreen')}
    >
      <IconButton
        size="large"
        onClick={fullscreen ? exitFullscreen : requestFullscreen}
        color="primary"
        sx={{
          position: 'absolute',
          zIndex: 100,
          opacity: 0,
          ...position,
        }}
      >
        {fullscreen ? <FullscreenExit /> : <FullscreenEnter />}
      </IconButton>
    </Tooltip>
  );
}
