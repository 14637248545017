import {
  Asset,
  AssetInDocument,
  Track,
  TrackFormValues,
} from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { defaultEventLayoutConfiguration } from '@livekatsomo/shared/config';
import {
  addDoc,
  arrayUnion,
  collection,
  DocumentReference,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { getEventDocRef } from '../events/getEventDocRef';
import { uploadImageFile } from '../files/uploadImageFile';
import { setTrackSlug } from './setTrackSlug';

/**
 * Adds a new track to a given event in Firestore.
 *
 * @param eventId - The ID of the event to add the track to.
 * @param trackFormData - The form data for the new track.
 * @param onUploadProgressChange - A function to call when the upload progress changes.
 * @returns A Promise that resolves when the track has been added.
 */
export async function addTrack(
  eventId: string,
  trackFormData: TrackFormValues,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const firestore = getFirestore();
  const trackCollection = collection(
    firestore,
    firestorePaths.trackPrivateCollectionPath(eventId),
  );
  const { poster: imageField, ...track } = trackFormData;

  let poster: AssetInDocument | undefined;

  if (Array.isArray(imageField) && imageField.length > 0) {
    const assetDoc = await uploadImageFile({
      file: imageField[0],
      uploadDir: firestorePaths.assetCollectionPath(
        firestorePaths.eventPath(eventId),
      ),
      assetData: {
        alt: `${track.name} poster`,
        purpose: ['poster'],
      } satisfies Partial<Asset>,
      onUploadProgressChange,
    });
    const asset = assetDoc?.data();
    poster = asset
      ? ({
          ...asset,
          id: assetDoc.id,
          sourceDocRefPath: assetDoc.ref.path,
          originalUrl: asset.originalUrl,
        } satisfies AssetInDocument)
      : undefined;
  } else if (imageField && !Array.isArray(imageField)) {
    poster = imageField;
  }

  const defaultFeatures: Partial<Track> = {
    streamEnabled: true,
    eventDetailsEnabled: true,
  };

  const trackDocRef = (await addDoc(trackCollection, {
    ...track,
    ...defaultFeatures,
    layout: defaultEventLayoutConfiguration,
    mode: 'pre-show',
    poster,
  })) as DocumentReference<Track>;

  const promises: Promise<unknown>[] = [];

  promises.push(setTrackSlug(trackDocRef.id, track));

  promises.push(
    updateDoc(getEventDocRef(eventId), {
      tracks: arrayUnion(trackDocRef.id),
    }),
  );

  await Promise.all(promises);
}
