import { FormSubmissionDoc, Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the SubmissionsContext.
 */
export type SubmissionsContextModel = {
  store: Store<FormSubmissionDoc[]>;
  deleteSubmission: (submissionId: string) => Promise<void>;
};

/**
 * The context for the SubmissionsContext.
 */
export const SubmissionsContext = createContext<
  SubmissionsContextModel | undefined
>(undefined);
