import { TrackDoc, trackSchema } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Firestore data converter for TrackDoc objects.
 */
export const trackConverter: FirestoreDataConverter<TrackDoc> = {
  /**
   *
   * @param doc - Firestore document to convert.
   * @returns A TrackDoc object.
   */
  fromFirestore: (doc) => {
    const data = trackSchema.parse(doc.data(), getFirestoreDocErrorMap(doc));
    return {
      ...data,
      id: doc.id,
      docType: 'track',
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * @param track - TrackDoc object to convert.
   * @returns Firestore DocumentData object.
   */
  toFirestore: (track) => ({ ...track, docType: 'track' }),
};
