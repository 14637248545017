import { DocChange } from '@livekatsomo/models';
import {
  collection,
  CollectionReference,
  doc,
  FirestoreError,
  getFirestore,
  runTransaction,
} from 'firebase/firestore';

let isSending = false;

/**
 * Sends a set of steps of Prosemirror document to a Firestore.
 * @param version - The version of the document to send the steps to.
 * @param steps - The steps to send to the document.
 * @param clientID - The ID of the client sending the steps.
 * @param docPath - The path of the Firestore document to send the steps to.
 * @returns A Promise that resolves when the steps have been sent to the document.
 */
export async function sendSteps(
  version: number,
  steps: string[],
  clientID: string | number,
  docPath: string,
): Promise<void> {
  const firestore = getFirestore();
  const docRef = doc(firestore, docPath);

  const changesRef = collection(
    docRef,
    'changes',
  ) as CollectionReference<DocChange>;
  if (isSending) {
    return;
  }
  isSending = true;

  try {
    await runTransaction(firestore, async (transaction) => {
      const changeRef = doc(changesRef, version.toString().padStart(10, '0'));

      const change = await transaction.get(changeRef);
      if (change.exists()) {
        // This change already exists. We should rebase our steps on top of it and send them again.
        // Exit early and let the next change event trigger this function again.
        return;
      }

      transaction.set(changeRef, {
        clientID,
        steps,
        stepId: version,
      });
    });
  } catch (error) {
    const firestoreError = error as FirestoreError;

    switch (firestoreError.code) {
      case 'already-exists':
        console.log('Change already exists, exiting early');
        break;
      default:
        console.error('Error sending steps', error);
        throw error;
    }
  } finally {
    isSending = false;
  }
}
