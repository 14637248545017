import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { trackConverter } from './trackConverter';

/**
 * Returns a Firestore DocumentReference for a track with the given ID and event ID.
 * @param eventId - The ID of the event that the track belongs to.
 * @param trackId - The ID of the track to retrieve.
 * @returns  A Firestore DocumentReference for the specified track.
 */
export function getTrackDocRef(eventId: string, trackId: string) {
  const firestore = getFirestore();
  const trackDocRef = doc(
    firestore,
    firestorePaths.trackPrivatePath(eventId, trackId),
  ).withConverter(trackConverter);
  return trackDocRef;
}
