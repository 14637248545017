import { ModeratorDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { moderatorConverter } from './moderatorConverter';

/**
 * Retrieves a snapshot of moderators from the Firestore database.
 *
 * @param options - The options for the query.
 * @param options.parentPath - The parent path of the collection to query.
 * @param options.queryConstraints - The query constraints to apply to the query.
 * @param observer - The observer to notify of the query results.
 * @returns A promise that resolves with an unsubscribe function.
 */
export const getModerators = (
  options: {
    parentPath: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<ModeratorDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.moderatorCollectionPath(
        options.parentPath,
      ),
      queryConstraints: options.queryConstraints,
    },
    moderatorConverter,
  );
};
