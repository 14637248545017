import { CustomerDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { customerConverter } from './customerConverter';

/**
 * Retrieves a list of customers from Firestore based on the provided query constraints.
 *
 * @param options - The options for the query.
 * @param options.queryConstraints - The query constraints to apply to the query.
 * @param observer - The observer to notify of query results.
 * @returns A function to unsubscribe from the query.
 */
export function getCustomers(
  options: {
    queryConstraints: QueryConstraint[];
  },
  observer: Observer<CustomerDoc[]>,
) {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.customerCollectionPath,
      queryConstraints: options.queryConstraints,
    },
    customerConverter,
  );
}
