import {
  FromFirestore,
  Moderator,
  ModeratorDoc,
  ToFirestore,
} from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for ModeratorDoc objects.
 */
export const moderatorConverter: FirestoreDataConverter<ModeratorDoc> = {
  /**
   *
   * @param doc - Firestore document snapshot.
   * @returns A ModeratorDoc object.
   */
  fromFirestore: (doc): ModeratorDoc => {
    const { ...data } = doc.data() as FromFirestore<Moderator>;

    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'moderator',
    };
  },
  /**
   * @param moderator - ModeratorDoc object.
   * @returns A ModeratorDoc object.
   */
  toFirestore: (moderator: ModeratorDoc): ToFirestore<ModeratorDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = moderator;
    return {
      ...data,
      docType: 'moderator',
    };
  },
};
