import { doc, getFirestore } from 'firebase/firestore';
import { downloadFileConverter } from './downloadFileConverter';

/**
 * Returns a Firestore document reference for the downloadFile file at the specified file path,
 * with a custom converter applied to the data.
 *
 * @param filePath - The path to the downloadFile file in the project.
 * @returns A Firestore document reference for the downloadFile file.
 */
export function getDownloadFileDocRefFromFile(filePath: string) {
  const firestore = getFirestore();
  return doc(firestore, filePath).withConverter(downloadFileConverter);
}
