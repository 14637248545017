import { CustomerDoc, LayoutConfiguration, Store } from '@livekatsomo/models';
import {
  UpdateCustomerFunction,
  UploadCustomerImageFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the CustomerContext.
 */
export type CustomerContextModel = {
  store: Store<CustomerDoc>;
  uploadCustomerLogo: UploadCustomerImageFunction;
  updateCustomer: UpdateCustomerFunction;
  updateCustomerLayout: (layout: LayoutConfiguration | null) => Promise<void>;
  validateCustomerSlug: (
    slug: string,
    customer?: { id: string },
  ) => Promise<'Slug already in use' | undefined>;
};

/**
 * The context for the CustomerContext.
 */
export const CustomerContext = createContext<CustomerContextModel | undefined>(
  undefined,
);
