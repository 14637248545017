import { z } from 'zod';
import { timestampOrDateSchema } from './firestore-doc.model';
import { JSONContent } from '@tiptap/core';

/**
 * Defines the schema for a JSON content object.
 * This schema is used to validate the content of a TipTap document.
 * The schema is recursive, so it can be used to validate nested content.
 * The schema is based on the JSON content schema of the TipTap editor.
 * @see https://tiptap.dev/guide/output#option-1-json
 */
export const jsonContentSchema: z.ZodType<JSONContent> = z.object({
  type: z.string().optional(),
  attrs: z.record(z.any()).optional(),
  content: z.lazy(() => z.any()).optional(),
  marks: z
    .array(
      z.object({
        type: z.string(),
        attrs: z.record(z.any()).optional(),
      }),
    )
    .optional(),
  text: z.string().optional(),
});

export type JSONContentSchema = z.infer<typeof jsonContentSchema>;

/**
 * Summary schema object that defines the structure of a event summary.
 */
export const summarySchema = z.object({
  /** The summary TipTap document. */
  document: jsonContentSchema,
  /** The version of the summary. */
  version: z.number().optional(),
  /** The date or timestamp when the summary was last updated. */
  updatedAt: timestampOrDateSchema.optional(),
  /** The name of the user who last updated the summary. */
  updatedBy: z.string().optional(),
});

/**
 * Represents a summary object with document content, version number, and update timestamp.
 */
export type Summary = z.infer<typeof summarySchema>;
