import { Agenda } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getTrackDocRef } from '../tracks';

/**
 * Updates the order of agenda items for a given track in an event.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param agendaItems - Array of the agenda items items in new order.
 * @returns A Promise that resolves when the update is complete.
 */
export function updateAgendaOrder(
  eventId: string,
  trackId: string,
  agendaItems: Agenda,
) {
  return updateDoc(getTrackDocRef(eventId, trackId), {
    agendaItems: agendaItems.map((agendaItem) => agendaItem.id),
  });
}
