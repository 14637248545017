import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Sets the `customerId` field of a Firestore document with the given `slug`.
 *
 * @param slug - The slug of the customer document to update.
 * @param customerId - The ID of the customer to set as the value of the `customerId` field.
 * @returns A Promise that resolves when the document has been successfully updated.
 */
export async function setCustomerSlugDoc(slug: string, customerId: string) {
  const firestore = getFirestore();
  const customerRef = doc(firestore, firestorePaths.customerSlugPath(slug));
  return setDoc(customerRef, { customerId });
}
