import { KatsomoFirestoreError } from '@livekatsomo/custom-errors';
import { DocChange } from '@livekatsomo/models';
import { getAuth } from 'firebase/auth';
import {
  CollectionReference,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

/**
 * Listens to updates on a Firestore document and calls the onUpdate callback with the changes.
 *
 * @param docPath - The path to the Firestore document to listen to.
 * @param version - The version number to start listening from.
 * @param onUpdate - The callback function to call when there are updates to the document.
 * @param onError - The callback function to call when there is an error listening to the document.
 * @returns A function that can be called to stop listening to updates on the document.
 */
export function listenFirestoreUpdates({
  docPath,
  version,
  onUpdate,
  onError,
}: {
  docPath: string;
  version: number;
  onUpdate: (changes: DocChange[]) => void;
  onError: (error: Error) => void;
}): () => void {
  const firestore = getFirestore();
  const docRef = doc(firestore, docPath);
  const changesRef = collection(
    docRef,
    'changes',
  ) as CollectionReference<DocChange>;

  const q = query(
    changesRef,
    orderBy('stepId', 'asc'),
    where('stepId', '>=', version),
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const changes: DocChange[] = [];
      console.log('Received changes', snapshot.docs.length);

      snapshot.docChanges().forEach((change) => {
        if (change.type !== 'added') {
          return;
        }
        changes.push(change.doc.data());
      });
      if (changes.length) {
        onUpdate(changes);
      }
    },
    (error) => {
      console.error('document listenFirestoreUpdates error', error);
      onError(
        new KatsomoFirestoreError(
          'document listenFirestoreUpdates error',
          error.code,
          getAuth(),
          error,
        ),
      );
    },
  );
}
