import { z } from 'zod';

/**
 * Defines the schema for a Slido event.
 */
export const slidoSchema = z.object({
  /** literal document type is `event` */
  type: z.literal('event'),
  /** The ID of the Slido event. */
  slidoId: z.string(),
});

/**
 * Represents a Slido object, which is inferred from the {@link slidoSchema}.
 */
export type Slido = z.infer<typeof slidoSchema>;
