import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { ticketmasterStatusConverter } from './ticketmasterStatusConverter';

/**
 * Returns a Firestore document reference for the Ticketmaster status document,
 * with a custom converter applied.
 *
 * @returns A Firestore document reference for the Ticketmaster status document.
 */
export function getTicketmasterStatusDocRef() {
  const firestore = getFirestore();
  return doc(firestore, firestorePaths.ticketmasterStatusPath).withConverter(
    ticketmasterStatusConverter,
  );
}
