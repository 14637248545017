import { EventConfig } from '@livekatsomo/models';
import { setDoc } from 'firebase/firestore';
import { getEventConfigDocRef } from './getEventConfigDocRef';

/**
 * Updates the configuration of an event in Firestore.
 * @param eventId - The ID of the event to update.
 * @param eventConfig - The updated configuration for the event.
 * @returns A Promise that resolves when the update is complete.
 */
export async function updateEventConfig(
  eventId: string,
  eventConfig: EventConfig,
): Promise<void> {
  const eventDocRef = getEventConfigDocRef(eventId);
  return setDoc(eventDocRef, eventConfig, { merge: true });
}
