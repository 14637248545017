import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { moderatorConverter } from './moderatorConverter';

/**
 * Returns a Firestore DocumentReference for a pre-authorized moderator.
 * @param parentPath - The parent path of the moderator document.
 * @param moderatorId - The ID of the moderator document.
 * @returns A Firestore DocumentReference for the moderator document.
 */
export function getPreAuthorizedModeratorDocRef(
  parentPath: string,
  moderatorId: string,
) {
  const firestore = getFirestore();
  const moderatorDocRef = doc(
    firestore,
    firestorePaths.preAuthorizedModerator(parentPath, moderatorId),
  ).withConverter(moderatorConverter);
  return moderatorDocRef;
}
