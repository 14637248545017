import { AddThemeFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { themeConverter } from './themeConverter';

/**
 * Adds a new theme to the Firestore database.
 *
 * @param customerId - The ID of the customer who owns the theme.
 * @param themeValues - The values of the new theme.
 * @returns A promise that resolves with the ID of the newly created theme document.
 */
export async function addTheme(
  customerId: string,
  themeValues: AddThemeFormValues,
): Promise<string> {
  const firestore = getFirestore();

  const newTheme = { ...themeValues, customerId };
  const newDoc = await addDoc(
    collection(firestore, firestorePaths.themeCollectionPath).withConverter(
      themeConverter,
    ),
    newTheme,
  );
  return newDoc.id;
}
