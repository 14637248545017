import { getDoc } from 'firebase/firestore';
import { getStreamDocRef } from './getStreamDocRef';

/**
 * Retrieves the document for a given stream ID and customer ID.
 * @param customerId - The ID of the customer who owns the stream.
 * @param streamId - The ID of the stream to retrieve.
 * @returns A Promise that resolves with the DocumentSnapshot for the stream.
 */
export function getStreamDoc(customerId: string, streamId: string) {
  return getDoc(getStreamDocRef(customerId, streamId));
}
