/**
 * An object containing Firestore paths for Nettilippu.
 */
export const firestorePathsForNettilippu = {
  /**
   * Returns the Firestore path for a Nettilippu ticket with the given barcode.
   * @param barcode - The barcode of the ticket.
   * @returns The Firestore path for the ticket.
   */
  nettilippuTicketPath: (barcode: string) => `/nettilippu-ticket/${barcode}`,

  /**
   * The Firestore path for the collection of Nettilippu tickets.
   */
  nettilippuTicketCollectionPath: '/nettilippu-ticket',

  /**
   * The Firestore path for the Nettilippu events.
   */
  nettilippuEventsPath: `nettilippu-event/`,

  /**
   * Returns the Firestore path for a Nettilippu event with the given ID.
   * @param eventId - The ID of the event.
   * @returns The Firestore path for the event.
   */
  nettilippuEventPath: (eventId: number) => `/nettilippu-event/${eventId}`,
} as const;
