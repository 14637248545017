import { QueryConstraint, where, WhereFilterOp } from 'firebase/firestore';

/**
 * Returns a Firestore QueryConstraint that filters documents based on the provided field, operator, and value.
 *
 * @param field The field to filter on.
 * @param operator The operator to use for the filter.
 * @param value The value to compare against.
 * @returns A Firestore QueryConstraint that filters documents based on the provided field, operator, and value.
 */
export function whereFilter<Model>(
  field: keyof Model & string,
  operator: WhereFilterOp,
  value: unknown,
): QueryConstraint {
  return where(field, operator, value);
}
