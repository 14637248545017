import { getDoc } from 'firebase/firestore';
import { getTrackDocRef } from './getTrackDocRef';

/**
 * Retrieves the Firestore document for a specific track within an event.
 * @param eventId - The ID of the event containing the track.
 * @param trackId - The ID of the track to retrieve.
 * @returns A Promise that resolves with the Firestore DocumentSnapshot for the specified track.
 */
export function getTrackDoc(eventId: string, trackId: string) {
  return getDoc(getTrackDocRef(eventId, trackId));
}
