import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

/**
 * Props for the ConfirmationDialog component.
 */
export interface ConfirmationDialogProps {
  /**
   * Whether the dialog is open or not.
   */
  open: boolean;
  /**
   * The title of the dialog.
   */
  title: string;
  /**
   * The description of the dialog.
   */
  description?: ReactNode;
  /**
   * Callback function that is called when the dialog is closed.
   * @param value The value passed to the callback function.
   */
  onClose: (value: boolean) => void;
}

/**
 * A dialog component for displaying a confirmation message with an optional description and two buttons for confirmation and cancellation.
 * @param props - The props for the ConfirmationDialog component.
 * @param props.open - Determines whether the dialog is open or not.
 * @param props.title - The title of the dialog.
 * @param props.description - The description of the dialog, can be any valid React node.
 * @param props.onClose - A callback function that is called when the dialog is closed. It receives a boolean value indicating whether the user confirmed or cancelled the action.
 * @returns The ConfirmationDialog component.
 */
export function ConfirmationDialog({
  title,
  description,
  open,
  onClose,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      aria-label={t('Confirmation Dialog') || 'Confirmation Dialog'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{description}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleOk}>{t('Ok')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
