import { ThemeDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { where } from 'firebase/firestore';
import { themeConverter } from './themeConverter';

/**
 * Retrieves an array of themes for a given customer ID.
 *
 * @param options - An object containing the customer ID.
 * @param options.customerId - The ID of the customer who owns the themes.
 * @param observer - An observer that listens for changes to the  customer themes collection.
 * @returns A function that unsubscribes the observer from the collection.
 */
export function getThemes(
  options: { customerId: string },
  observer: Observer<ThemeDoc[]>,
) {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.themeCollectionPath,
      queryConstraints: where('customerId', '==', options.customerId),
    },
    themeConverter,
  );
}
