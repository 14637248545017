import {
  CustomFormValues,
  FormDoc,
  FormSubmissionDoc,
  Store,
  UsedIn,
} from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * The model for the forms context.
 */
export type FormsContextModel = {
  /** The store for the forms. */
  store: Store<FormDoc[] | null>;
  /** Function to upload an form. */
  addForm: (form: CustomFormValues) => Promise<void>;
  /** Function to update form. */
  updateForm: (
    formId: string,
    form: Partial<CustomFormValues>,
  ) => Promise<void>;
  /** Function to delete an form. */
  deleteForm: (formId: string) => Promise<void>;
  /** Function to get the forms used in a specific form. */
  getFormUsedIn: (formId: string) => Promise<UsedIn[]>;
  /** Function to get all submissions */
  getSubmissions: (formId: string) => Promise<FormSubmissionDoc[]>;
};

/**
 * The context for customer forms.
 */
export const CustomerFormsContext = createContext<
  FormsContextModel | undefined
>(undefined);

/**
 * The context for event forms.
 */
export const EventFormsContext = createContext<FormsContextModel | undefined>(
  undefined,
);
