/**
 * An object containing Firestore paths for slide-related collections and documents.
 */
export const firestorePathsForSlide = {
  /**
   * Returns the path to a specific slide deck.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @param slideDeckId The ID of the slide deck.
   * @returns The Firestore path to the slide deck.
   */
  slideDeckPath: (eventId: string, trackId: string, slideDeckId: string) =>
    `event/${eventId}/track/${trackId}/slide-deck/${slideDeckId}`,

  /**
   * Returns the path to the collection of slide decks for a specific track.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @returns The Firestore path to the slide decks collection.
   */
  slideDecksCollectionPath: (eventId: string, trackId: string) =>
    `event/${eventId}/track/${trackId}/slide-deck`,

  /**
   * Returns the path to the collection of slides for a specific slide deck.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @param slideDeckId The ID of the slide deck.
   * @returns The Firestore path to the slides collection.
   */
  slidesCollectionPath: (
    eventId: string,
    trackId: string,
    slideDeckId: string,
  ) => `event/${eventId}/track/${trackId}/slide-deck/${slideDeckId}/image`,

  /**
   * Returns the path to the collection of slide queue items for a specific track.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @returns The Firestore path to the slide queue collection.
   */
  slideQueueCollectionPath: (eventId: string, trackId: string) =>
    `event/${eventId}/track/${trackId}/slide-queue`,

  /**
   * Returns the path to a specific slide queue item.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @param slideQueueItemId The ID of the slide queue item.
   * @returns The Firestore path to the slide queue item.
   */
  slideQueueItemPath: (
    eventId: string,
    trackId: string,
    slideQueueItemId: string,
  ) => `event/${eventId}/track/${trackId}/slide-queue/${slideQueueItemId}`,

  /**
   * The ID of the slide image collection.
   */
  slideImageCollectionId: 'image',
} as const;
