import { UsedIn } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

/**
 * Retrieves a list of documents that reference the specified asset ID.
 * @param assetId - The ID of the asset to search for.
 * @param parentPath - The parent path of the asset.
 * @returns - A promise that resolves to an array of UsedIn objects.
 */
export async function getAssetUsedIn(assetId: string, parentPath: string) {
  const firestore = getFirestore();
  const usedInCollection = collection(
    firestore,
    firestorePaths.usedInCollectionPath(
      firestorePaths.assetPath(parentPath, assetId),
    ),
  );
  const docs = await getDocs(usedInCollection);
  return docs.docs.map((doc) => doc.data() as UsedIn);
}
