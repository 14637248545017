import { UploadProgressFunction } from '@livekatsomo/types';
import { uploadSlideDeck } from './uploadSlideDeck';

/**
 * Uploads slide decks for a given event and track.
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param files - An array of files to upload.
 * @param setProgress - An optional function to track upload progress.
 * @returns A Promise that resolves when all slide decks have been uploaded.
 */
export const uploadSlideDecks = async (
  eventId: string,
  trackId: string,
  files: File[],
  setProgress?: UploadProgressFunction,
): Promise<void> => {
  if (files && files.length) {
    const promises = files.map((file) =>
      uploadSlideDeck(
        eventId,
        trackId,
        { files: [file], title: file.name },
        setProgress,
      ),
    );
    await Promise.all(promises);
  }
};
