import { UpdateTrackThemeFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { getTrackDocRef } from './getTrackDocRef';

/**
 * Updates the theme of a track for a given event.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param themeFormValues - The form values containing the new theme data.
 * @returns - A promise that resolves when the theme has been updated.
 */
export const updateTrackTheme: UpdateTrackThemeFunction = async (
  eventId,
  trackId,
  themeFormValues,
) => {
  const { baseTheme, ...themeOverrides } = themeFormValues;

  const trackDocRef = getTrackDocRef(eventId, trackId);

  await updateDoc(trackDocRef, { baseTheme, themeOverrides });
};
