import { getDoc } from 'firebase/firestore';
import { getPreAuthorizedModeratorDocRef } from './getPreAuthorizedModeratorDocRef';

/**
 * Retrieves the pre-authorized moderator document from Firestore.
 * @param parentPath - The parent path of the moderator document.
 * @param moderatorId - The ID of the moderator document.
 * @returns A promise that resolves with the moderator document snapshot.
 */
export async function getPreAuthorizedModeratorDoc(
  parentPath: string,
  moderatorId: string,
) {
  const moderatorRef = getPreAuthorizedModeratorDocRef(parentPath, moderatorId);
  const docSnapshot = await getDoc(moderatorRef);
  return docSnapshot;
}
