import { Stream } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { streamConverter } from './streamConverter';

/**
 * Retrieves a stream by its ID for a given customer.
 *
 * @param options - The options object.
 * @param options.customerId - The ID of the customer who owns the stream.
 * @param options.streamId - The ID of the stream to retrieve.
 * @param observer - The observer to notify of the stream's state changes.
 * @returns A function that can be called to unsubscribe from the stream.
 * @throws If either `streamId` or `customerId` is not provided.
 */
export function getStream(
  options: { customerId: string; streamId: string },
  observer: Observer<Stream>,
) {
  const { customerId, streamId } = options;
  if (!streamId) throw new Error('streamId is required');
  if (!customerId) throw new Error('customerId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.streamPath(customerId, streamId),
    },
    streamConverter,
  );
}
