/**
 * Object containing Firestore paths for agenda items.
 */
export const firestorePathsForAgenda = {
  /**
   * Returns the Firestore path for a specific agenda item.
   * @param eventId The ID of the event.
   * @param agendaItemId The ID of the agenda item.
   * @returns The Firestore path for the specified agenda item.
   */
  agendaItemPath: (eventId: string, agendaItemId: string) =>
    `event/${eventId}/agenda-item/${agendaItemId}`,

  /**
   * Returns the Firestore path for the collection of agenda items for a specific event.
   * @param eventId The ID of the event.
   * @returns The Firestore path for the collection of agenda items for the specified event.
   */
  agendaItemsCollectionPath: (eventId: string) =>
    `event/${eventId}/agenda-item`,

  /**
   * The ID of the Firestore collection for agenda items.
   */
  agendaItemsCollectionId: `agenda-item`,
} as const;
