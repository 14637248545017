import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { setEventSlugDoc } from './setEventSlugDoc';
import { firestorePaths } from '@livekatsomo/shared/config';

/**
 * Updates the slug of an event in Firestore.
 *
 * @param oldSlug - The old slug of the event.
 * @param newSlug - The new slug to set for the event.
 * @param customerId - The ID of the customer who owns the event.
 * @param eventId - The ID of the event to update.
 * @returns A promise that resolves when the update is complete.
 */
export async function updateEventSlug(
  oldSlug: string,
  newSlug: string,
  customerId: string,
  eventId: string,
) {
  const firestore = getFirestore();

  // delete old slug doc
  await deleteDoc(
    doc(firestore, firestorePaths.eventSlugPath(customerId, oldSlug)),
  );
  // set new slug doc
  await setEventSlugDoc(customerId, newSlug, eventId);
}
