import { createContext } from 'react';
import type { Locale } from 'date-fns';

/**
 * DateLike is a type that can be used to represent a date.
 * It can be either a Date or a number.
 **/
export type DateLike = Date | number;

/**
 * The context for the DateFnsContext.
 **/
export const DateFnsContext = createContext<
  | {
      locale?: Locale;
    }
  | undefined
>(undefined);
