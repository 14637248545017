import { arrayRemove, deleteDoc, updateDoc } from 'firebase/firestore';
import { getEventDocRef } from '../events/getEventDocRef';

import { getTrackDoc } from './getTrackDoc';
import { getTrackDocRef } from './getTrackDocRef';

/**
 * Deletes a track from an event and removes it from the event's list of tracks.
 * @param eventId - The ID of the event the track belongs to.
 * @param trackId - The ID of the track to delete.
 * @returns A Promise that resolves when the track has been deleted and removed from the event's list of tracks.
 * @throws If the track is not found.
 */
export async function deleteTrack(
  eventId: string,
  trackId: string,
): Promise<void> {
  const docSnapshot = await getTrackDoc(eventId, trackId);
  const trackRef = getTrackDocRef(eventId, trackId);
  if (docSnapshot.exists()) {
    await deleteDoc(trackRef);
  } else {
    throw new Error('Track not found');
  }
  await updateDoc(getEventDocRef(eventId), {
    tracks: arrayRemove(trackRef.id),
  });
}
