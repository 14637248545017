import { TrackPlaybackHistoryDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { playbackHistoryConverter } from './playbackHistoryConverter';

/**
 * Retrieves the playback history for a specific track and user.
 * @param options An object containing the user ID and track ID.
 * @param options.userId The ID of the user.
 * @param options.trackId The ID of the track.
 * @param observer An observer that will receive the playback history document or null if it doesn't exist.
 * @returns A function that can be called to unsubscribe from the query.
 */
export function getTrackPlaybackHistory(
  {
    userId,
    trackId,
  }: {
    userId: string;
    trackId: string;
  },
  observer: Observer<TrackPlaybackHistoryDoc | null>,
): () => void {
  let cancelled = false;
  const firestore = getFirestore();
  const historyRef = doc(
    firestore,
    firestorePaths.trackPlaybackHistoryPath(userId, trackId),
  ).withConverter(playbackHistoryConverter);
  getDoc(historyRef).then((historySnapshot) => {
    if (cancelled) return;
    if (historySnapshot.exists()) {
      observer.next(historySnapshot.data());
    } else {
      observer.next(null);
    }
  });
  return () => (cancelled = true);
}
