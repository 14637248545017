import { User } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UpdateUserFunction } from '@livekatsomo/types';
import {
  doc,
  DocumentReference,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';

/**
 * Updates a user document in Firestore with the provided user form data.
 * @param userFormData - The user form data to update the document with.
 * @returns A promise that resolves when the document has been successfully updated.
 */
export const updateUser: UpdateUserFunction = async (userFormData) => {
  const firestore = getFirestore();
  const UserDoc = doc(
    firestore,
    firestorePaths.userPath(userFormData.uid),
  ) as DocumentReference<User, User>;
  await updateDoc(UserDoc, userFormData);
};
