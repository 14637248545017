import { TrackPlaybackHistoryDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { playbackHistoryConverter } from './playbackHistoryConverter';
import { orderBy } from 'firebase/firestore';

/**
 * Retrieves the playback history for a given user.
 *
 * @param options - The options object.
 * @param options.userId - The ID of the user to retrieve the playback history for.
 * @param observer - The observer to notify of changes to the playback history.
 * @returns A function that can be called to unsubscribe from the observer.
 */
export function getPlaybackHistory(
  {
    userId,
  }: {
    userId: string;
  },
  observer: Observer<TrackPlaybackHistoryDoc[]>,
): () => void {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.playbackHistoryCollectionPath(userId),
      queryConstraints: [orderBy('timestamp', 'desc')],
    },
    playbackHistoryConverter,
  );
}
