import { z } from 'zod';

/**
 * An array of strings representing the types of documents that can be stored in Firestore.
 * These strings are used as values for the `docType` field in Firestore documents.
 */
const docType = [
  'theme',
  'customer',
  'user',
  'asset',
  'journalEvent',
  'event',
  'eventPublic',
  'eventConfig',
  'invitation',
  'chatMessage',
  'speaker',
  'sponsor',
  'stream',
  'summary',
  'track',
  'trackPublic',
  'preAuthorizedUser',
  'wallPost',
  'agendaItem',
  'chatMessageImage',
  'customerPublic',
  'document',
  'moderator',
  'preAuthorizedModerator',
  'slideQueueItem',
  'notificationMessage',
] as const;

/**
 * A schema for a Firestore document, including its ID, source document reference path, and document type.
 */
export const firestoreDocSchema = z.object({
  /** The ID of the Firestore document. */
  id: z.string(),
  /** The path to the source document reference in Firestore. */
  sourceDocRefPath: z.string(),
  /** The type of the Firestore document.
   * This is one of the values in {@link docType}.
   */
  docType: z.enum(docType),
});

/**
 * A schema for Firestore timestamps that converts Firestore timestamp objects to JavaScript Date objects.
 * A Zod schema that transforms Firestore timestamp objects to JavaScript Date objects.
 */
export const timestampSchema = z
  .object({
    seconds: z.number(),
    nanoseconds: z.number(),
  })
  .transform(
    ({ seconds, nanoseconds }) =>
      new Date(seconds * 1000 + nanoseconds / 1000000),
  );

/**
 * A schema that represents either a Date object or a Firestore Timestamp object ({@link timestampSchema}).
 */
export const timestampOrDateSchema = z.union([z.date(), timestampSchema]);

/**
 * Represents a Firestore document.
 * This type is inferred from the {@link firestoreDocSchema} schema.
 */
export type FirestoreDoc = z.infer<typeof firestoreDocSchema>;

/**
 * Represents the type of a Firestore document that can be converted to Firestore data.
 *
 * @typeParam Model The type of the Firestore document.
 */
export type ToFirestore<Model> = Omit<Model, 'id' | 'sourceDocRefPath'>;

/**
 * A type alias for `ToFirestore` that represents the conversion of a Firestore document to a model object.
 *
 * @typeParam Model The model type to convert to.
 */
export type FromFirestore<Model> = ToFirestore<Model>;
