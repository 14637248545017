/**
 * An object containing Firestore paths for chat messages.
 */
export const firestorePathsForChat = {
  /**
   * Returns the Firestore path for a specific chat message.
   * @param parentPath - The parent path of the chat message.
   * @param chatMessageId - The ID of the chat message.
   * @returns The Firestore path for the chat message.
   */
  chatMessagePath: (parentPath: string, chatMessageId: string) =>
    `${parentPath}/chat/${chatMessageId}`,

  /**
   * Returns the Firestore path for the collection of chat messages.
   * @param parentPath - The parent path of the chat messages collection.
   * @returns The Firestore path for the chat messages collection.
   */
  chatMessageCollectionPath: (parentPath: string) => `${parentPath}/chat`,
} as const;
