import { NotificationMessageDoc, Store } from '@livekatsomo/models';
import { SendNotificationMessageFunction } from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the NotificationContext.
 */
export type NotificationContextModel = {
  store: Store<NotificationMessageDoc[]>;
  sendNotificationMessage: SendNotificationMessageFunction;
};

/**
 * The context for the NotificationContext.
 */
export const NotificationContext = createContext<
  NotificationContextModel | undefined
>(undefined);
