import { Store, TrackPlaybackHistoryDoc } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the PlaybackHistoryContext.
 */
export type PlaybackHistoryContextModel = {
  store: Store<TrackPlaybackHistoryDoc[]> | null;
  deletePlaybackHistoryItem: (id: string) => Promise<void>;
};

/**
 * The context for the PlaybackHistoryContext.
 */
export const PlaybackHistoryContext = createContext<
  PlaybackHistoryContextModel | undefined
>(undefined);
