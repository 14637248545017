import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
  linkWithPopup,
  getAuth,
  AuthError,
  updateProfile,
  User,
} from 'firebase/auth';
import { profileUpdatedCallable } from './authentication-handlers';

/**
 * Signs in a user with a given provider (Google, Facebook, or Twitter).
 * If the user is already signed in, it links the new provider to the existing account.
 * If the user is not signed in, it signs them in with the new provider.
 *
 * @param provider - The provider to sign in with (GoogleAuthProvider, FacebookAuthProvider, or TwitterAuthProvider).
 * @returns A Promise that resolves when the user profile has been updated to firestore.
 * @throws An error if there was an issue with signing in or linking the provider.
 */
export const signInWithProvider = async (
  provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider,
): Promise<User> => {
  const auth = getAuth();
  if (auth.currentUser) {
    try {
      const linkedUser = await linkWithPopup(auth.currentUser, provider);

      const providerUser = linkedUser.user.providerData
        .filter((provider) => provider.providerId === linkedUser.providerId)
        .pop();
      if (providerUser) {
        await updateProfile(linkedUser.user, {
          ...(!linkedUser.user.email &&
            providerUser.email && { email: providerUser.email }),
          ...(!linkedUser.user.displayName &&
            providerUser.displayName && {
              displayName: providerUser.displayName,
            }),
          ...(!linkedUser.user.photoURL &&
            providerUser.photoURL && { photoURL: providerUser.photoURL }),
        });
      }
      await profileUpdatedCallable(auth.currentUser.uid);
      return auth.currentUser;
    } catch (error) {
      const authError = error as AuthError;
      switch (authError.code) {
        case 'auth/credential-already-in-use': {
          await signInWithPopup(auth, provider);
          return auth.currentUser;
        }
        default:
          throw error;
      }
    }
  } else {
    const cred = await signInWithPopup(auth, provider);
    return cred.user;
  }
};
