import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { streamConverter } from './streamConverter';

/**
 * Returns a Firestore DocumentReference for a given stream.
 *
 * @param customerId - The ID of the customer who owns the stream.
 * @param streamId - The ID of the stream.
 * @returns A Firestore DocumentReference for the stream.
 */
export function getStreamDocRef(customerId: string, streamId: string) {
  const firestore = getFirestore();
  const streamDocRef = doc(
    firestore,
    firestorePaths.streamPath(customerId, streamId),
  ).withConverter(streamConverter);
  return streamDocRef;
}
