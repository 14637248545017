import { Theme } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { themeConverter } from './themeConverter';

/**
 * Retrieves a theme by its ID.
 *
 * @param options - The options for retrieving the theme.
 * @param options.themeId - The ID of the theme to retrieve.
 * @param observer - The observer to notify of the theme's state changes.
 * @returns A function that can be called to unsubscribe from the query.
 * @throws An error if the theme ID is not provided.
 */
export function getTheme(
  options: { themeId: string },
  observer: Observer<Theme>,
) {
  const { themeId } = options;
  if (!themeId) throw new Error('themeId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.themePath(themeId),
    },
    themeConverter,
  );
}
