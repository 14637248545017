import { deleteDoc } from 'firebase/firestore';
import { getThemeDoc } from './getThemeDoc';

/**
 * Deletes a theme document from Firestore.
 * @param themeId - The ID of the theme document to delete.
 * @returns A Promise that resolves when the document has been deleted.
 */
export async function deleteTheme(themeId: string): Promise<void> {
  const docSnapshot = getThemeDoc(themeId);
  return deleteDoc(docSnapshot);
}
