import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box';
import { ReactNode } from 'react';

/**
 * Props for the Ribbon component.
 */
export interface RibbonProps extends Omit<BoxProps, 'position'> {
  /**
   * The position of the ribbon.
   */
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  /**
   * The content of the ribbon.
   */
  children: ReactNode;
  /**
   * The color of the ribbon.
   */
  color?: 'primary' | 'secondary';
}

/**
 * A ribbon component that can be positioned on any corner of a container.
 *
 * @param position - The position of the ribbon. Can be one of 'top-left', 'top-right', 'bottom-left', or 'bottom-right'.
 * @param color - The color of the ribbon. Can be either 'primary' or 'secondary'.
 * @param children - The content to be displayed inside the ribbon.
 * @returns A React component representing the ribbon.
 */
export function Ribbon({
  position = 'top-right',
  color = 'secondary',
  children,
}: RibbonProps) {
  return (
    <Box
      sx={[
        {
          width: '150px',
          height: '150px',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: 2,
          boxSizing: 'border-box',
          '&::before,::after': {
            position: 'absolute',
            zIndex: '-1',
            content: '""',
            display: 'block',
            border: (theme) =>
              `5px solid ${
                theme.palette[color === 'primary' ? 'primary' : 'secondary']
                  .dark
              }`,
          },
          '& .ribbon': {
            position: 'absolute',
            display: 'block',
            width: '225px',
            height: '48px',
            padding: '15px 0',
            backgroundColor: (theme) =>
              theme.palette[color === 'primary' ? 'primary' : 'secondary'].main,
            boxShadow: '0 5px 10px rgba(0,0,0,.1)',
            color: (theme) =>
              theme.palette[color === 'primary' ? 'primary' : 'secondary']
                .contrastText,
            font: '700 18px/1 "Lato", sans-serif',
            textShadow: '0 1px 1px rgba(0,0,0,.2)',
            textTransform: 'uppercase',
            textAlign: 'center',
          },
        },
        position === 'top-left' && {
          /* top left*/
          top: '-10px',
          left: '-10px',

          '&::before,::after': {
            borderTopColor: 'transparent',
            borderLeftColor: 'transparent',
          },
          '&::before': {
            top: 0,
            right: 0,
          },
          '&::after': {
            bottom: 0,
            left: 0,
          },
          '& .ribbon': {
            right: '-25px',
            top: '30px',
            transform: 'rotate(-45deg)',
          },
        },
        position === 'top-right' && {
          /* top right*/
          top: '-10px',
          right: '-10px',

          '&::before,::after': {
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
          },
          '&::before': {
            top: 0,
            left: 0,
          },
          '&::after': {
            bottom: 0,
            right: 0,
          },
          '& .ribbon': {
            left: '-25px',
            top: '30px',
            transform: 'rotate(45deg)',
          },
        },
        position === 'bottom-left' && {
          /* bottom left*/
          bottom: '-10px',
          left: '-10px',

          '&::before,::after': {
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
          },
          '&::before': {
            bottom: 0,
            right: 0,
          },
          '&::after': {
            top: 0,
            left: 0,
          },
          '& .ribbon': {
            right: '-25px',
            bottom: '30px',
            transform: 'rotate(225deg)',
          },
        },
        position === 'bottom-right' && {
          /* bottom right*/
          bottom: '-10px',
          right: '-10px',

          '&::before,::after': {
            borderBottomColor: 'transparent',
            borderRightColor: 'transparent',
          },
          '&::before': {
            bottom: 0,
            left: 0,
          },
          '&::after': {
            top: 0,
            right: 0,
          },
          '& .ribbon': {
            left: '-25px',
            bottom: '30px',
            transform: 'rotate(-225deg)',
          },
        },
      ]}
    >
      <div className="ribbon">{children}</div>
    </Box>
  );
}

export default Ribbon;
