import { FromFirestore, ToFirestore, SlideImageDoc } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for SlideImageDoc objects.
 */
export const slideConverter: FirestoreDataConverter<SlideImageDoc> = {
  /**
   *
   * @param doc - Firestore document snapshot.
   * @returns A SlideImageDoc object.
   */
  fromFirestore: (doc): SlideImageDoc => {
    const data = doc.data() as FromFirestore<SlideImageDoc>;
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'asset',
    };
  },
  /**
   * @param slideDeck - A SlideImageDoc object.
   * @returns A Firestore data object.
   */
  toFirestore: (slideDeck: SlideImageDoc): ToFirestore<SlideImageDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, sourceDocRefPath: path, ...data } = slideDeck;
    return {
      ...data,
      docType: 'asset',
    };
  },
};
