import { Track, TrackMode } from '@livekatsomo/models';
import { updateDoc, serverTimestamp } from 'firebase/firestore';
import { getTrackDocRef } from './getTrackDocRef';

/**
 * Changes the mode of a track and updates the corresponding Firestore document.
 * @param eventId - The ID of the event the track belongs to.
 * @param trackId - The ID of the track to update.
 * @param mode - The new mode of the track.
 */
export async function changeTrackMode(
  eventId: string,
  trackId: string,
  mode: TrackMode,
) {
  const trackDocRef = getTrackDocRef(eventId, trackId);
  const updateData: Pick<
    Track,
    'sessionNumber' | 'actualStartTime' | 'actualEndTime' | 'mode'
  > = {
    mode,
  };
  if (mode === 'live') {
    await updateDoc(trackDocRef, {
      ...updateData,
      liveModeStartTime: serverTimestamp(),
    });
  }
  await updateDoc(trackDocRef, updateData);
}
