import { AssetDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { assetConverter } from './assetConverter';

/**
 * Retrieves a list of assets from Firestore for a given parent path.
 * @param options - The options object.
 * @param options.parentPath - The parent path to retrieve assets for.
 * @param options.queryConstraints - An optional array of query constraints to apply to the query.
 * @param observer - The observer to notify of changes to the asset list.
 * @returns A function to unsubscribe from the observer.
 */
export const getAssets = (
  options: { parentPath: string; queryConstraints?: QueryConstraint[] },
  observer: Observer<AssetDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.assetCollectionPath(options.parentPath),
      queryConstraints: orderBy('filename', 'asc'),
    },
    assetConverter,
  );
};
