import { DownloadFile, DownloadFileDoc } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { getApp } from 'firebase/app';
import { DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { getDownloadFileDocRefFromFile } from './getDownnloadFileDocRefFromFile';
import { getFilePath } from '../files/getFilePath';
import { getImageDimensions } from '../files/getImageDimensions';
import { downloadFileConverter } from './downloadFileConverter';

interface uploadFileOptions {
  /**
   * The file to upload.
   */
  file: File;
  /**
   * The directory to upload the file to.
   */
  uploadDir: string;
  /**
   * The name to give the uploaded file. If not provided, the original filename will be used.
   */
  filename?: string;
  /**
   * The name of the Firebase Storage bucket to upload the file to. If not provided, the default bucket will be used.
   */
  bucket?: string;
  /**
   * Additional metadata to save with the uploaded file.
   */
  downloadFileData?: Partial<DownloadFile>;
  /**
   * A function that will be called with the upload progress.
   */
  onUploadProgressChange?: UploadProgressFunction;
}

/**
 * Uploads a file to Firebase Storage and saves its metadata to Firestore.
 *
 * @returns A Promise that resolves with the metadata of the uploaded file.
 */
export async function uploadDownloadFile({
  file,
  filename,
  uploadDir,
  bucket,
  downloadFileData,
  onUploadProgressChange,
}: uploadFileOptions): Promise<DocumentSnapshot<DownloadFileDoc>> {
  const storage = getStorage(getApp(), bucket);
  const filePath = getFilePath(uploadDir, filename, file);
  const downloadFileDocRef = getDownloadFileDocRefFromFile(
    filePath,
  ).withConverter(downloadFileConverter);

  const downloadFileDoc = await getDoc(downloadFileDocRef);
  if (downloadFileDoc.exists()) {
    const data = downloadFileDoc.data();
    if (data?.status && data.status !== 'processed') {
      throw new Error(
        `DownloadFile with path ${filePath} already exists and is still processing.`,
      );
    }
  }

  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        // update progress
        onUploadProgressChange &&
          onUploadProgressChange((state) => ({
            ...state,
            [file.name]: { file, progress: percent },
          }));
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          // upload complete
          // Save downloadFile to firestore
          const storageRef = uploadTask.snapshot.ref;

          const downloadFile = {
            filename: file.name,
            filePath,
            bucket: storageRef.bucket,
            downloadUrl: await getDownloadURL(storageRef),
            type: file.type,
            status: 'uploaded',
            // If file is an image then save width and height
            ...(file.type.startsWith('image/') &&
              (await getImageDimensions(file))),
            ...downloadFileData,
          } satisfies DownloadFile;

          await setDoc(downloadFileDocRef, downloadFile, { merge: true });
          onUploadProgressChange &&
            onUploadProgressChange((state) => ({
              ...state,
              [file.name]: { ...state[file.name], progress: 100 },
            }));
          const downloadFileDoc = await getDoc(downloadFileDocRef);
          resolve(downloadFileDoc);
        } catch (error) {
          reject(error);
        }
      },
    );
  });
}
