import { ChatMessageDoc } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import {
  DeleteChatMessageFunction,
  UpdateChatMessageFunction,
  SendChatMessageFunction,
  AcceptChatMessageFunction,
  RejectChatMessageFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the ChatContext.
 */
export type ChatContextModel = {
  store: Store<ChatMessageDoc[]>;
  sendChatMessage: SendChatMessageFunction;
  updateChatMessage: UpdateChatMessageFunction;
  deleteChatMessage: DeleteChatMessageFunction;
  acceptChatMessage: AcceptChatMessageFunction;
  rejectChatMessage: RejectChatMessageFunction;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
};

/**
 * The context for the ChatContext.
 */
export const ChatContext = createContext<ChatContextModel | undefined>(
  undefined,
);
