import { AssetDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collection,
  CollectionReference,
  doc,
  getFirestore,
} from 'firebase/firestore';
import { assetConverter } from './assetConverter';

/**
 * Returns a Firestore DocumentReference for the asset with the given ID, located in the collection
 * with the given parent path.
 *
 * @param parentPath - The parent path of the asset collection.
 * @param assetId - The ID of the asset to retrieve.
 * @returns A Firestore DocumentReference for the asset.
 */
export function getAssetDocRef(parentPath: string, assetId: string) {
  const firestore = getFirestore();
  const assetCollection = collection(
    firestore,
    firestorePaths.assetCollectionPath(parentPath),
  ) as CollectionReference<AssetDoc>;
  const assetRef = doc(assetCollection, assetId).withConverter(assetConverter);
  return assetRef;
}
