import { z } from 'zod';
import { assetInDocumentSchema, imageFileSchema } from './asset.model';
import { firestoreDocSchema } from './firestore-doc.model';

/**
 * Defines the schema for a sponsor object.
 */
export const sponsorSchema = z.object({
  /** The name of the sponsor. */
  name: z.string().nonempty(),
  /** A description of the sponsor. */
  description: z.string().min(1),
  /** The logo of the sponsor. */
  logo: assetInDocumentSchema.optional(),
  /** The URL of the sponsor's website. */
  url: z.string().url().optional().or(z.literal('')),
});

/**
 * Sponsor type inferred from the {@link sponsorSchema}.
 */
export type Sponsor = z.infer<typeof sponsorSchema>;

/**
 * Defines the sponsor document schema by merging the sponsor schema ({@link sponsorSchema}) with the firestore document schema
 * ({@link firestoreDocSchema}) and extending it with a literal `sponsor` docType.
 */
export const sponsorDocSchema = sponsorSchema.merge(firestoreDocSchema).extend({
  /** literal document type is `sponsor` */
  docType: z.literal('sponsor'),
});

/**
 * Type definition for a Sponsor document, inferred from the {@link sponsorDocSchema}.
 */
export type SponsorDoc = z.infer<typeof sponsorDocSchema>;

/**
 * Defines a schema for the form values of a sponsor, which extends the sponsor schema and includes
 * a logo property that can be either an image file or an asset in a document.
 */
export const sponsorFormValuesSchema = sponsorSchema.extend({
  /** The logo of the sponsor. */
  logo: imageFileSchema.or(assetInDocumentSchema),
});

/**
 * Type definition for the values of a sponsor form inferred from the {@link sponsorFormValuesSchema}.
 */
export type SponsorFormValues = z.infer<typeof sponsorFormValuesSchema>;
