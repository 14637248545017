import { getDoc } from 'firebase/firestore';
import { getSpeakerDocRef } from './getSpeakerDocRef';

/**
 * Retrieves the document for a specific speaker in an event.
 * @param eventId The ID of the event.
 * @param speakerId The ID of the speaker.
 * @returns A promise that resolves with the speaker document.
 */
export function getSpeakerDoc(eventId: string, speakerId: string) {
  return getDoc(getSpeakerDocRef(eventId, speakerId));
}
