import { EventDoc, eventSchema } from '@livekatsomo/models';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';
import {
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

/**
 * Firestore data converter for EventDoc objects.
 */
export const eventConverter: FirestoreDataConverter<EventDoc> = {
  /**
   *
   * @param doc - Firestore document to convert.
   * @param options - Snapshot options.
   * @returns An `EventDoc` object.
   */
  fromFirestore: (
    doc: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): EventDoc => {
    const event = eventSchema.parse(
      doc.data(options),
      getFirestoreDocErrorMap(doc),
    );
    return {
      ...event,
      id: doc.id,
      docType: 'event',
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * Converts an `EventDoc` object to a Firestore document.
   * @param event - The `EventDoc` object to convert.
   * @returns A Firestore document.
   */
  toFirestore: (
    event: PartialWithFieldValue<Omit<EventDoc, 'defaultLayout'>>,
  ) => {
    return {
      ...event,
      docType: 'event',
    };
  },
};
