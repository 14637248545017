/**
 * Firestore paths for Ticketmaster.
 */
export const firestorePathsForTicketmaster = {
  /**
   * Returns the path for a Ticketmaster ticket with the given customer number.
   * @param customerNumber The customer number for the ticket.
   * @returns The Firestore path for the ticket.
   */
  ticketmasterTicketPath: (customerNumber: number) =>
    `/ticketmaster-ticket/${customerNumber}`,

  /**
   * The Firestore path for the Ticketmaster ticket collection.
   */
  ticketmasterTicketCollectionPath: '/ticketmaster-ticket',

  /**
   * The Firestore path for Ticketmaster events.
   */
  ticketmasterEventsPath: `ticketmaster-event/`,

  /**
   * Returns the path for a Ticketmaster event with the given event ID.
   * @param eventId The ID of the event.
   * @returns The Firestore path for the event.
   */
  ticketmasterEventPath: (eventId: number) => `/ticketmaster-event/${eventId}`,
} as const;
