import LiveTvIcon from '@mui/icons-material/FiberManualRecord';
import { BoxProps } from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';

/**
 * Props for the LiveBadge component.
 */
export interface LiveBadgeProps extends Pick<BoxProps, 'component'> {
  sx?: SxProps;
}

/**
 * A badge component indicating that something is live.
 * @param {LiveBadgeProps} props - The props for the LiveBadge component.
 * @param {SxProps<Theme>} props.sx - The custom styling for the LiveBadge component.
 * @returns A LiveBadge component.
 */
export function LiveBadge({ sx, ...rest }: LiveBadgeProps) {
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        width: 'min-content',
        border: `2px solid`,
        pr: 1,
        borderRadius: '100px',
        textTransform: 'uppercase',
        alignItems: 'center',
        height: '24px',
        color: 'inherit',
        fontSize: '0.8125rem',
        verticalAlign: 'middle',
        ...sx,
      }}
      {...rest}
    >
      <LiveTvIcon sx={{ color: 'red' }} />
      Live
    </Box>
  );
}

export default LiveBadge;
