import { TrackDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { trackConverter } from './trackConverter';

/**
 * Retrieves a track document from Firestore.
 *
 * @param options - An object containing the `eventId` and `trackId` of the track to retrieve.
 * @param observer - An observer that will be notified of the retrieved track document.
 * @returns A function that can be called to unsubscribe from the query.
 * @throws If `trackId` or `eventId` is not provided.
 */
export function getTrack(
  options: { eventId: string; trackId: string },
  observer: Observer<TrackDoc>,
) {
  const { eventId, trackId } = options;
  if (!trackId) throw new Error('trackId is required');
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.trackPrivatePath(eventId, trackId),
    },
    trackConverter,
  );
}
