import { TrackPlaybackHistory, User } from '@livekatsomo/models';
import {
  UpdateProfileFunction,
  UploadUserPhotoFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the UserContext.
 */
export type UserContextModel = {
  user: User | null;
  updateUserProfile: UpdateProfileFunction;
  uploadUserPhoto: UploadUserPhotoFunction;
  deleteUser: (user: User) => Promise<void>;
  storeHistory: (
    history: Omit<TrackPlaybackHistory, 'timestamp'>,
  ) => Promise<void>;
};

/**
 * The context for the UserContext.
 */
export const UserContext = createContext<UserContextModel | undefined>(
  undefined,
);
