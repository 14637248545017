import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { EULogo } from '@livekatsomo/web/ui-components/logo';
import { useTranslation } from 'next-i18next';

/**
 * Renders the footer component with the company logo and copyright information.
 * @returns The JSX element of the footer component.
 */
export function Footer() {
  const { t } = useTranslation();
  return (
    <AppBar
      color="primary"
      component="footer"
      position="static"
      sx={{ marginTop: 'auto' }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <EULogo width={200} />

        <Typography variant="body2" align="center">
          {'Copyright © '}
          <MuiLink color="inherit" href="https://rajulive.fi">
            RajuLive Oy
          </MuiLink>{' '}
          {new Date().getFullYear()}.
        </Typography>
        <MuiLink color="inherit" href="/privacy-policy">
          {t('Privacy Policy')}
        </MuiLink>
      </Toolbar>
    </AppBar>
  );
}
