import { WallPost } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the WallsIOContext.
 */
export type WallsIOContextModel = {
  store: Store<WallPost[]>;
};

/**
 * The context for the WallsIOContext.
 */
export const WallsIOContext = createContext<WallsIOContextModel | undefined>(
  undefined,
);
