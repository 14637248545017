import { AssetDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { slideQueueItemConverter } from './slideQueueItemConverter';

/**
 * Adds a slide queue item to Firestore.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param asset - The asset document to add to the slide queue.
 * @param delay - The delay in seconds before the slide should be displayed.
 * @param sessionNumber - The session number of the slide.
 * @returns A promise that resolves with the DocumentReference of the added slide queue item.
 */
export const queueSlide = (
  eventId: string,
  trackId: string,
  asset: AssetDoc,
  sessionNumber: number,
) => {
  const firestore = getFirestore();
  const slideQueueItemCollection = collection(
    firestore,
    firestorePaths.slideQueueCollectionPath(eventId, trackId),
  ).withConverter(slideQueueItemConverter);
  const slideQueueItem = {
    docType: 'slideQueueItem',
    delay: 0,
    timestamp: serverTimestamp(),
    asset,
    sessionNumber,
    enabled: true,
  };
  return addDoc(slideQueueItemCollection, slideQueueItem);
};
