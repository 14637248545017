import { z } from 'zod';
import { AssetInDocument, assetInDocumentSchema } from './asset.model';
import { customerInDocumentSchema } from './customer.model';
import { eventAuthorizationSchema } from './event-authorization.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { layoutConfigurationSchema } from './layout.model';
import { summarySchema } from './summary.model';
import {
  Theme,
  ThemeInDocument,
  themeInDocumentSchema,
  themeOverridesSchema,
} from './theme.model';
import { usedInPropertySchema } from './used-in.model';
import { visibilitySchema } from './visibility.model';

/**
 * Represents the theme of an event.
 */
export interface EventTheme {
  /**
   * The base theme of the event.
   */
  baseTheme?: ThemeInDocument | null;
  /**
   * The theme overrides for the event.
   */
  themeOverrides?: Theme;
}

/**
 * Defines the schema for an event object.
 */
export const eventSchema = z.object({
  /** The slug of the event. */
  slug: z.string().min(1),
  /** The name of the event. */
  name: z.string().min(1),
  /** The lowercase name of the event. */
  nameLower: z.string().optional(),
  /** The description of the event. */
  description: z.string().min(1),
  /** The start date of the event. */
  startDate: timestampOrDateSchema,
  /** The end date of the event. */
  endDate: timestampOrDateSchema,
  /** The ID of the customer that owns the event. */
  customerId: z.string().nonempty('Customer is required'),
  /** The customer that owns the event. */
  customer: customerInDocumentSchema.optional(),
  /** The poster of the event. */
  poster: assetInDocumentSchema.optional(),
  /** The banner of the event. */
  banner: assetInDocumentSchema.optional(),
  /** An array of track IDs for the event. */
  tracks: z.string().array().optional(),
  /** An array of sponsor IDs for the event. */
  sponsors: z.string().array().optional(),
  /** The visibility of the event. */
  visibility: visibilitySchema,
  /** The summary of the event. */
  summary: summarySchema.optional(),
  /** Whether VOD is enabled for the event. */
  vodEnabled: z.boolean().optional(),
  /** The expiry date of the VOD for the event. */
  vodExpiryDate: timestampOrDateSchema.optional(),
  /** The authorization settings for the event. */
  authorization: eventAuthorizationSchema.optional(),
  /** The base theme of the event. */
  baseTheme: themeInDocumentSchema.nullable().optional(),
  /** The theme overrides for the event. */
  themeOverrides: themeOverridesSchema.optional(),
  /** The default layout for the event. */
  defaultLayout: layoutConfigurationSchema.optional(),
  /** Whether the event is a demo event.
   * If true, the event is a demo event and it's start and end dates are resetted every day.
   */
  demoEvent: z.boolean().optional(),
  /** Whether the event is pinned to featured events in home page and channel page. */
  pinToFeatured: z.boolean().optional(),
  /** Whether the event has Tawk.to chat support enabled */
  enableSupport: z.boolean().optional(),
  /** Categories */
  categories: z.array(z.string()).optional(),
  /** The speakers of the event. */
  speakers: z.array(z.string()).optional(),
});

/**
 * Represents an event object.
 * This type is inferred from the {@link eventSchema} object.
 */
export type Event = z.infer<typeof eventSchema>;

/**
 * Defines the schema for an event ({@link eventSchema}) document in Firestore,
 * which includes the base Firestore document schema ({@link firestoreDocSchema}})
 * and an additional `docType` field set to the literal string 'event'.
 */
export const eventDocSchema = eventSchema.merge(firestoreDocSchema).extend({
  /** literal document type is `event` */
  docType: z.literal('event'),
});

/**
 * Represents an event in a document schema.
 * This schema merges the {@link eventDocSchema} with the {@link usedInPropertySchema}.
 */
export const eventInDocumentSchema = usedInPropertySchema.merge(eventDocSchema);

/**
 * This exports the summary schema for an event.
 */
export const eventSummary = summarySchema;

/**
 * Represents a summary of an event.
 * This type is inferred from the {@link eventSummary} schema.
 */
export type EventSummary = z.infer<typeof eventSummary>;

/**
 * Type definition for the EventDoc object, which is inferred from the {@link eventDocSchema}.
 */
export type EventDoc = z.infer<typeof eventDocSchema>;

/**
 * Defines the schema for an event slug, which consists of an event ID.
 */
export const eventSlugSchema = z.object({
  /** The ID of the event. */
  eventId: z.string().nonempty('Event is required'),
});

/**
 * The type definition for the `EventSlug` type, which is inferred from the {@link eventSlugSchema}.
 */
export type EventSlug = z.infer<typeof eventSlugSchema>;

/**
 * Represents the form values for an event, excluding certain properties.
 */
export interface EventFormValues
  extends Omit<
    Event,
    | 'id'
    | 'sourceDocRefPath'
    | 'startDate'
    | 'customer'
    | 'baseTheme'
    | 'themeOverrides'
    | 'poster'
    | 'docType'
  > {
  id?: string;
  /** The start date of the event. */
  startDate?: Date;
  /** Poster file or asset for the event */
  poster?: AssetInDocument | File[];
  /** Base theme for the event */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseTheme?: any;
  /** Theme overrides for the event */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  themeOverrides?: any;
}

export const categoryEventsSchema = z.object({
  categoryName: z.string().min(1),
  events: z.array(eventDocSchema),
});

export type CategoryEvents = z.infer<typeof categoryEventsSchema>;
