import { Stream, StreamDoc, streamSchema } from '@livekatsomo/models';
import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Converts Firestore documents to and from `StreamDoc` objects.
 */
export const streamConverter = {
  /**
   *
   * @param doc - The firestore document to convert.
   * @returns A `StreamDoc` object.
   */
  fromFirestore: (doc: QueryDocumentSnapshot): StreamDoc => {
    const data = streamSchema.parse(doc.data(), getFirestoreDocErrorMap(doc));
    return {
      ...data,
      id: doc.id,
      docType: 'stream',
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * Converts a `StreamDoc` object to a Firestore document.
   * @param data - The stream to convert.
   * @returns A Firestore document.
   */
  toFirestore: (data: PartialWithFieldValue<Stream>): DocumentData => ({
    ...data,
    docType: 'stream',
  }),
};
