import { InvitationDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { invitationConverter } from './invitationConverter';

/**
 * Retrieves a snapshot of invitation documents from Firestore.
 *
 * @param options - The options for the query.
 * @param options.parentPath - The parent path of the invitation collection.
 * @param options.queryConstraints - The query constraints to apply to the query.
 * @param observer - The observer to notify of query results.
 * @returns A function to unsubscribe from the query.
 */
export const getInvitations = (
  options: {
    parentPath: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<InvitationDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.invitationCollectionPath(
        options.parentPath,
      ),
      queryConstraints: options.queryConstraints,
    },
    invitationConverter,
  );
};
