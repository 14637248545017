import { AssetDoc, SlideQueueItemDoc, Store } from '@livekatsomo/models';
import { UpdateSlideQueueFunction } from '@livekatsomo/types';
import { createContext } from 'react';
import { Swiper } from 'swiper/types';

/**
 * Represents the model for the SlideControllerContext.
 */
export type SlideControllerContextModel = {
  store: Store<SlideQueueItemDoc[] | null>;
  thumbsSwiper: Swiper | null;
  mainSwiper: Swiper | null;
  delay: number;
  queueSlide: (slide: AssetDoc) => void;
  updateSlideQueue: UpdateSlideQueueFunction;
  setDelay: (delay: number) => void;
  setThumbsSwiper?: (swiper: Swiper | null) => void;
  setMainSwiper?: (swiper: Swiper | null) => void;
  updateSlideQueueItem: (id: string, data: Partial<SlideQueueItemDoc>) => void;
  deleteSlideQueueItem: (id: string) => void;
};

/**
 * The context for the SlideControllerContext.
 */
export const SlideControllerContext = createContext<
  SlideControllerContextModel | undefined
>(undefined);
