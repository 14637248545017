import { ReactNode } from 'react';
import { DateFnsContext } from '@livekatsomo/web/contexts';
import { Locale } from 'date-fns';

/**
 * Provides a context for the default locale used to format dates.
 * @param locale - The default locale used to format dates.
 * @param children - React contents.
 * @returns A React component that provides a context for the default locale used to format dates.
 */
export function DateFnsProvider({
  locale,
  children,
}: {
  /** The default locale used to format dates */
  locale?: Locale;
  /** React contents */
  children: ReactNode;
}) {
  return (
    <DateFnsContext.Provider value={{ locale }}>
      {children}
    </DateFnsContext.Provider>
  );
}
