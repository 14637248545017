import { z } from 'zod';
import { firestoreDocSchema } from './firestore-doc.model';

/**
 * An array of authorization providers available for events.
 * @readonly
 */
export const authorizationProviders = [
  'password',
  'domain',
  'invitations',
  'ticketmaster',
  'nettilippu',
] as const;

/**
 * Type alias for an authorization provider.
 */
export type AuthorizationProvider = (typeof authorizationProviders)[number];

/**
 * Defines the schema for event authorization settings.
 */
export const eventAuthorizationSchema = z.object({
  /** An optional flag indicating whether password authorization is enabled for the event. */
  passwordEnabled: z.boolean().optional(),
  /** An optional flag indicating whether domain authorization is enabled for the event. */
  domainEnabled: z.boolean().optional(),
  /** An optional flag indicating whether invitations are enabled for the event. */
  invitationsEnabled: z.boolean().optional(),
  /** An optional flag indicating whether anonymous users are allowed to join the event. */
  anonymousUsersEnabled: z.boolean().optional(),
  /** An optional flag indicating whether Ticketmaster integration is enabled for the event. */
  ticketmasterEnabled: z.boolean().optional(),
  /** An optional flag indicating whether Nettilippu integration is enabled for the event. */
  nettilippuEnabled: z.boolean().optional(),
  /** The URL for purchasing tickets for the event. */
  purchaseUrl: z.string().optional(),
  /** An optional flag indicating whether survey form is enabled for the event. */
  surveyFormEnabled: z.boolean().optional(),
  /** Event requires user to submit survey */
  surveyFormId: z.string().optional(),
  /** An optional flag indicating whether the login and register buttons should be hidden from authorization */
  hideLoginAndRegister: z.boolean().optional(),
});

/**
 * Represents the type of an event authorization object.
 * This type is inferred from the {@link eventAuthorizationSchema} object.
 */
export type EventAuthorization = z.infer<typeof eventAuthorizationSchema>;

/**
 * Defines the schema for the configuration of an event's authorization settings.
 */
export const eventConfigSchema = z.object({
  /** The password for the event. */
  password: z.string().optional(),
  /** An array of authorized domains for the event. */
  authorizedDomains: z.array(z.string()).optional(),
  /** The ID of the Nettilippu event. */
  nettilippuEventId: z.number().optional(),
  /** The ID of the Ticketmaster event. */
  ticketmasterEventId: z.number().optional(),
  /** The ID of the sendgrid email template for invitations. */
  invitationEmailTemplateId: z.string().optional(),
  /** The ID of the sendgrid email template for moderator invitations. */
  moderatorInvitationTemplateId: z.string().optional(),
});

/**
 * Type definition for the EventConfig object, which is inferred from the {@link eventConfigSchema}.
 */
export type EventConfig = z.infer<typeof eventConfigSchema>;

/**
 * Defines the schema for an event configuration document in Firestore.
 * This schema is a combination of the {@link eventConfigSchema} and {@link firestoreDocSchema},
 * with an additional `docType` property set to the literal value `'eventConfig'`.
 */
export const eventConfigDocSchema = eventConfigSchema
  .merge(firestoreDocSchema)
  .extend({
    docType: z.literal('eventConfig'),
  });

/**
 * Type definition for the EventConfigDoc object, which is inferred from the {@link eventConfigDocSchema}.
 */
export type EventConfigDoc = z.infer<typeof eventConfigDocSchema>;

/**
 * This constant exports a schema that represents the form values for an event configuration,
 * including authorization settings. It merges the {@link eventConfigDocSchema} and {@link eventAuthorizationSchema}
 * schemas, and picks specific properties to include in the resulting schema.
 */
export const eventConfigFormValuesSchema = eventConfigDocSchema
  .merge(eventAuthorizationSchema)
  .pick({
    password: true,
    authorizedDomains: true,
    passwordEnabled: true,
    domainEnabled: true,
    invitationsEnabled: true,
    nettilippuEventId: true,
    ticketmasterEventId: true,
    purchaseUrl: true,
  });

/**
 * Type definition for the form values of an event authorization.
 * This type is inferred from the {@link eventConfigFormValuesSchema}.
 */
export type EventAuthorizationFormValues = z.infer<
  typeof eventConfigFormValuesSchema
>;

/**
 * Defines the schema for user authorization data for an event.
 */
export const eventUserAuthorizationSchema = z.object({
  /** The user ID. */
  uid: z.string(),
  /** The Nettilippu barcode. */
  nettilippuBarcode: z.string().optional(),
  /** The invitation ID. */
  invitationId: z.string().optional(),
  /** The Ticketmaster customer number. */
  ticketmasterCustomerNumber: z.number().optional(),
  /** The survey form ID. */
  surveyFormId: z.string().optional(),
});

/**
 * Represents the type of user authorization for an event.
 * This type is inferred from the {@link eventUserAuthorizationSchema}.
 */
export type EventUserAuthorization = z.infer<
  typeof eventUserAuthorizationSchema
>;
