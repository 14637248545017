import { SVGProps } from 'react';

export interface EULogoProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

/**
 * Renders the Funded by EU logo.
 * @param props The component props.
 * @param props.color The color of the logo, defaults to white.
 */
export const EULogo = ({ color = '#fff', ...props }: EULogoProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 4250 892"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="matrix(4.16667,0,0,4.16667,0,1)">
      <path
        d="M296.227,201.172L12.625,201.172L12.625,12.103L296.227,12.103L296.227,201.172ZM6.953,6.61L6.953,206.894L301.899,206.894L301.899,6.61L6.953,6.61Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M148.384,53.298L154.368,48.931L160.353,53.298L158.075,46.235L164.154,41.868L156.661,41.868L154.368,34.72L152.075,41.876L144.587,41.868L150.661,46.235L148.384,53.298Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M117.415,61.618L123.399,57.251L129.38,61.618L127.106,54.555L133.18,50.188L125.688,50.188L123.396,43.032L121.103,50.196L113.614,50.188L119.688,54.555L117.415,61.618Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M100.739,65.743L98.446,72.907L90.957,72.899L97.032,77.266L94.754,84.329L100.739,79.962L106.722,84.329L104.446,77.266L110.52,72.899L103.032,72.899L100.739,65.743Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M92.426,110.892L98.408,115.258L96.133,108.197L102.208,103.829L94.719,103.829L92.426,96.673L90.133,103.845L82.642,103.829L88.719,108.197L86.443,115.258L92.426,110.892Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M103.032,134.852L100.739,127.696L98.446,134.86L90.957,134.852L97.032,139.218L94.754,146.282L100.739,141.907L106.722,146.282L104.446,139.218L110.52,134.852L103.032,134.852Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M125.735,157.554L123.442,150.407L121.153,157.563L113.661,157.554L119.735,161.922L117.462,168.985L123.446,164.618L129.425,168.985L127.153,161.922L133.227,157.554L125.735,157.554Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M156.665,165.782L154.372,158.626L152.083,165.79L144.591,165.782L150.665,170.149L148.391,177.212L154.376,172.844L160.356,177.212L158.079,170.149L164.157,165.782L156.665,165.782Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M187.594,157.554L185.305,150.407L183.009,157.563L175.525,157.554L181.594,161.922L179.322,168.985L185.305,164.618L191.29,168.985L189.009,161.922L195.087,157.554L187.594,157.554Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M210.305,134.852L208.009,127.696L205.712,134.86L198.228,134.852L204.305,139.218L202.025,146.282L208.009,141.907L213.993,146.282L211.712,139.218L217.79,134.852L210.305,134.852Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M226.017,103.743L218.525,103.743L216.228,96.587L213.939,103.751L206.447,103.743L212.525,108.11L210.251,115.172L216.228,110.797L222.212,115.172L219.939,108.11L226.017,103.743Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M202.024,84.235L208.008,79.868L213.993,84.235L211.712,77.172L217.79,72.813L210.304,72.813L208.008,65.657L205.712,72.821L198.227,72.813L204.304,77.172L202.024,84.235Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M185.392,43.04L183.103,50.204L175.611,50.188L181.68,54.563L179.408,61.626L185.4,57.251L191.376,61.626L189.103,54.563L195.18,50.188L187.68,50.188L185.392,43.04Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M366.52,66.179L375.63,69.068C374.233,74.146 371.911,77.918 368.663,80.382C365.415,82.848 361.294,84.08 356.3,84.08C350.122,84.08 345.044,81.969 341.065,77.749C337.089,73.527 335.098,67.756 335.098,60.435C335.098,52.691 337.098,46.676 341.098,42.392C345.096,38.107 350.354,35.964 356.872,35.964C362.563,35.964 367.186,37.647 370.741,41.011C372.856,43.001 374.444,45.857 375.503,49.581L366.202,51.802C365.653,49.39 364.505,47.486 362.759,46.089C361.014,44.693 358.891,43.995 356.395,43.995C352.946,43.995 350.149,45.232 348.001,47.709C345.852,50.183 344.78,54.194 344.78,59.737C344.78,65.62 345.837,69.809 347.954,72.305C350.069,74.802 352.819,76.05 356.206,76.05C358.702,76.05 360.85,75.257 362.647,73.669C364.446,72.084 365.737,69.586 366.52,66.179Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M390.706,66.433C390.706,69.777 391.499,72.337 393.085,74.115C394.673,75.892 396.63,76.781 398.958,76.781C401.284,76.781 403.237,75.892 404.813,74.115C406.389,72.337 407.177,69.755 407.177,66.371C407.177,63.07 406.389,60.531 404.813,58.753C403.237,56.976 401.284,56.087 398.958,56.087C396.63,56.087 394.673,56.976 393.085,58.753C391.499,60.531 390.706,63.09 390.706,66.433ZM381.565,65.958C381.565,62.996 382.294,60.128 383.755,57.357C385.214,54.585 387.282,52.468 389.96,51.009C392.635,49.548 395.624,48.819 398.925,48.819C404.024,48.819 408.204,50.475 411.462,53.786C414.719,57.097 416.35,61.282 416.35,66.338C416.35,71.438 414.704,75.665 411.415,79.018C408.124,82.372 403.983,84.048 398.989,84.048C395.899,84.048 392.954,83.35 390.149,81.954C387.346,80.557 385.214,78.51 383.755,75.812C382.294,73.115 381.565,69.83 381.565,65.958Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <rect
        x="422.317"
        y="61.959"
        width="17.52"
        height="8.918"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M441.073,49.581L446.024,49.581L446.024,47.042C446.024,44.207 446.327,42.09 446.928,40.694C447.532,39.297 448.643,38.161 450.262,37.282C451.88,36.404 453.927,35.964 456.403,35.964C458.942,35.964 461.428,36.345 463.862,37.107L462.655,43.328C461.237,42.99 459.874,42.82 458.561,42.82C457.27,42.82 456.344,43.122 455.784,43.725C455.223,44.328 454.942,45.486 454.942,47.201L454.942,49.581L461.608,49.581L461.608,56.595L454.942,56.595L454.942,83.287L446.024,83.287L446.024,56.595L441.073,56.595L441.073,49.581Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M488.807,83.287L488.807,78.24C487.581,80.039 485.968,81.457 483.968,82.494C481.968,83.53 479.858,84.048 477.635,84.048C475.372,84.048 473.341,83.551 471.542,82.556C469.743,81.562 468.442,80.166 467.639,78.367C466.835,76.569 466.432,74.083 466.432,70.909L466.432,49.581L475.35,49.581L475.35,65.069C475.35,69.809 475.514,72.712 475.843,73.781C476.171,74.85 476.768,75.696 477.635,76.32C478.503,76.945 479.604,77.256 480.936,77.256C482.46,77.256 483.825,76.838 485.032,76.003C486.237,75.168 487.063,74.13 487.507,72.892C487.952,71.655 488.173,68.624 488.173,63.799L488.173,49.581L497.093,49.581L497.093,83.287L488.807,83.287Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M536.985,83.287L528.067,83.287L528.067,66.085C528.067,62.446 527.878,60.091 527.497,59.023C527.116,57.955 526.497,57.124 525.639,56.531C524.782,55.939 523.75,55.642 522.546,55.642C521,55.642 519.614,56.066 518.387,56.912C517.161,57.758 516.318,58.88 515.864,60.277C515.409,61.673 515.182,64.254 515.182,68.021L515.182,83.287L506.262,83.287L506.262,49.581L514.547,49.581L514.547,54.532C517.487,50.723 521.19,48.819 525.655,48.819C527.624,48.819 529.421,49.173 531.052,49.882C532.68,50.591 533.913,51.496 534.749,52.595C535.585,53.696 536.167,54.945 536.495,56.341C536.821,57.738 536.985,59.737 536.985,62.339L536.985,83.287Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M553.141,65.704C553.141,69.301 553.637,71.904 554.634,73.511C556.071,75.839 558.081,77.003 560.663,77.003C562.716,77.003 564.462,76.129 565.899,74.384C567.339,72.638 568.057,70.031 568.057,66.56C568.057,62.689 567.36,59.901 565.964,58.198C564.567,56.495 562.778,55.642 560.6,55.642C558.483,55.642 556.712,56.484 555.284,58.166C553.856,59.848 553.141,62.361 553.141,65.704ZM576.944,83.287L568.661,83.287L568.661,78.336C567.286,80.261 565.663,81.695 563.79,82.636C561.917,83.578 560.028,84.048 558.124,84.048C554.253,84.048 550.936,82.488 548.175,79.367C545.413,76.247 544.032,71.892 544.032,66.306C544.032,60.593 545.376,56.252 548.063,53.278C550.751,50.305 554.145,48.819 558.251,48.819C562.016,48.819 565.276,50.385 568.026,53.516L568.026,36.758L576.944,36.758L576.944,83.287Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M605.764,63.545C605.7,60.964 605.034,59.002 603.764,57.658C602.495,56.315 600.95,55.642 599.13,55.642C597.184,55.642 595.575,56.352 594.305,57.769C593.036,59.187 592.413,61.113 592.434,63.545L605.764,63.545ZM605.257,72.559L614.143,74.05C613.001,77.309 611.196,79.791 608.731,81.494C606.266,83.197 603.182,84.048 599.479,84.048C593.618,84.048 589.28,82.133 586.468,78.304C584.245,75.236 583.134,71.364 583.134,66.687C583.134,61.101 584.594,56.727 587.514,53.564C590.434,50.401 594.126,48.819 598.591,48.819C603.606,48.819 607.561,50.475 610.462,53.786C613.36,57.097 614.745,62.17 614.618,69.004L592.274,69.004C592.339,71.65 593.057,73.708 594.432,75.177C595.809,76.648 597.522,77.383 599.575,77.383C600.971,77.383 602.145,77.003 603.098,76.241C604.05,75.479 604.768,74.253 605.257,72.559Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M628.997,65.704C628.997,69.301 629.493,71.904 630.487,73.511C631.927,75.839 633.936,77.003 636.518,77.003C638.569,77.003 640.315,76.129 641.755,74.384C643.194,72.638 643.913,70.031 643.913,66.56C643.913,62.689 643.216,59.901 641.819,58.198C640.423,56.495 638.634,55.642 636.454,55.642C634.339,55.642 632.567,56.484 631.137,58.166C629.71,59.848 628.997,62.361 628.997,65.704ZM652.8,83.287L644.516,83.287L644.516,78.336C643.139,80.261 641.516,81.695 639.643,82.636C637.772,83.578 635.884,84.048 633.979,84.048C630.106,84.048 626.79,82.488 624.028,79.367C621.268,76.247 619.887,71.892 619.887,66.306C619.887,60.593 621.231,56.252 623.919,53.278C626.604,50.305 630.001,48.819 634.106,48.819C637.872,48.819 641.13,50.385 643.882,53.516L643.882,36.758L652.8,36.758L652.8,83.287Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M688.124,65.704C688.124,69.195 688.675,71.777 689.774,73.448C691.319,75.818 693.372,77.003 695.932,77.003C697.899,77.003 699.577,76.162 700.962,74.479C702.348,72.797 703.042,70.147 703.042,66.529C703.042,62.678 702.343,59.901 700.946,58.198C699.55,56.495 697.762,55.642 695.583,55.642C693.446,55.642 691.669,56.473 690.251,58.134C688.833,59.795 688.124,62.319 688.124,65.704ZM679.27,83.287L679.27,36.758L688.188,36.758L688.188,53.516C690.938,50.385 694.196,48.819 697.964,48.819C702.067,48.819 705.464,50.305 708.151,53.278C710.839,56.252 712.182,60.52 712.182,66.085C712.182,71.84 710.811,76.273 708.071,79.382C705.331,82.494 702.005,84.048 698.091,84.048C696.165,84.048 694.266,83.567 692.393,82.604C690.52,81.642 688.907,80.219 687.553,78.336L687.553,83.287L679.27,83.287Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M715.134,49.581L724.622,49.581L732.684,73.511L740.555,49.581L749.792,49.581L737.889,82.017L735.762,87.888C734.979,89.857 734.233,91.359 733.526,92.396C732.817,93.432 732.003,94.273 731.081,94.919C730.161,95.564 729.028,96.066 727.686,96.426C726.343,96.786 724.823,96.966 723.132,96.966C721.417,96.966 719.735,96.786 718.085,96.426L717.292,89.444C718.688,89.718 719.946,89.857 721.067,89.857C723.141,89.857 724.675,89.248 725.671,88.032C726.665,86.815 727.427,85.265 727.956,83.382L715.134,49.581Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M352.143,127.58L352.143,134.689L346.05,134.689L346.05,148.273C346.05,151.024 346.106,152.627 346.223,153.082C346.341,153.537 346.604,153.912 347.016,154.208C347.43,154.505 347.932,154.653 348.524,154.653C349.35,154.653 350.546,154.367 352.11,153.796L352.872,160.714C350.8,161.603 348.45,162.047 345.827,162.047C344.219,162.047 342.768,161.778 341.479,161.238C340.188,160.699 339.241,160.001 338.637,159.143C338.036,158.287 337.618,157.128 337.384,155.669C337.194,154.631 337.098,152.537 337.098,149.383L337.098,134.689L333.005,134.689L333.005,127.58L337.098,127.58L337.098,120.883L346.05,115.678L346.05,127.58L352.143,127.58Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M367.219,114.757L367.219,131.864C370.096,128.501 373.534,126.818 377.534,126.818C379.585,126.818 381.438,127.199 383.089,127.961C384.739,128.722 385.981,129.696 386.817,130.88C387.653,132.066 388.223,133.378 388.53,134.816C388.837,136.255 388.991,138.487 388.991,141.512L388.991,161.286L380.073,161.286L380.073,143.481C380.073,139.947 379.903,137.704 379.565,136.753C379.225,135.799 378.628,135.044 377.772,134.483C376.915,133.922 375.841,133.641 374.55,133.641C373.069,133.641 371.747,134.002 370.583,134.721C369.419,135.44 368.567,136.525 368.028,137.974C367.489,139.423 367.219,141.566 367.219,144.401L367.219,161.286L358.3,161.286L358.3,114.757L367.219,114.757Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M418.063,141.545C417.999,138.963 417.333,137.001 416.063,135.657C414.794,134.314 413.249,133.641 411.428,133.641C409.483,133.641 407.874,134.351 406.604,135.768C405.335,137.186 404.712,139.112 404.733,141.545L418.063,141.545ZM417.555,150.558L426.442,152.049C425.3,155.308 423.495,157.79 421.03,159.493C418.565,161.196 415.481,162.047 411.778,162.047C405.917,162.047 401.579,160.132 398.766,156.303C396.544,153.235 395.432,149.363 395.432,144.686C395.432,139.1 396.893,134.726 399.813,131.563C402.733,128.4 406.425,126.818 410.889,126.818C415.905,126.818 419.86,128.474 422.76,131.785C425.659,135.096 427.044,140.17 426.917,147.004L404.573,147.004C404.637,149.649 405.356,151.707 406.731,153.176C408.108,154.647 409.821,155.382 411.874,155.382C413.27,155.382 414.444,155.002 415.397,154.24C416.348,153.478 417.067,152.252 417.555,150.558Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M452.307,161.286L452.307,114.757L486.807,114.757L486.807,122.629L461.702,122.629L461.702,132.944L485.061,132.944L485.061,140.783L461.702,140.783L461.702,153.446L487.696,153.446L487.696,161.286L452.307,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M517.786,161.286L517.786,156.239C516.557,158.038 514.943,159.456 512.945,160.493C510.946,161.529 508.835,162.047 506.614,162.047C504.348,162.047 502.317,161.55 500.52,160.555C498.721,159.561 497.421,158.165 496.616,156.366C495.811,154.568 495.409,152.082 495.409,148.908L495.409,127.58L504.329,127.58L504.329,143.068C504.329,147.808 504.493,150.712 504.821,151.78C505.147,152.849 505.745,153.695 506.614,154.319C507.481,154.944 508.581,155.255 509.915,155.255C511.438,155.255 512.802,154.838 514.008,154.002C515.214,153.167 516.039,152.13 516.482,150.891C516.927,149.654 517.151,146.623 517.151,141.798L517.151,127.58L526.069,127.58L526.069,161.286L517.786,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M543.843,161.286L534.925,161.286L534.925,127.58L543.208,127.58L543.208,132.372C544.624,130.108 545.899,128.617 547.032,127.897C548.163,127.178 549.448,126.818 550.887,126.818C552.919,126.818 554.876,127.379 556.76,128.501L553.999,136.276C552.495,135.303 551.098,134.816 549.809,134.816C548.559,134.816 547.503,135.16 546.635,135.847C545.766,136.536 545.085,137.779 544.589,139.577C544.091,141.376 543.843,145.142 543.843,150.876L543.843,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M567.678,144.432C567.678,147.776 568.471,150.337 570.057,152.114C571.645,153.891 573.602,154.78 575.93,154.78C578.257,154.78 580.21,153.891 581.786,152.114C583.362,150.337 584.149,147.754 584.149,144.37C584.149,141.069 583.362,138.53 581.786,136.753C580.21,134.975 578.257,134.087 575.93,134.087C573.602,134.087 571.645,134.975 570.057,136.753C568.471,138.53 567.678,141.089 567.678,144.432ZM558.538,143.957C558.538,140.995 559.266,138.128 560.727,135.356C562.186,132.584 564.255,130.468 566.932,129.009C569.608,127.547 572.596,126.818 575.897,126.818C580.997,126.818 585.177,128.474 588.434,131.785C591.692,135.096 593.323,139.281 593.323,144.338C593.323,149.437 591.677,153.664 588.387,157.017C585.096,160.371 580.956,162.047 575.962,162.047C572.872,162.047 569.927,161.349 567.122,159.953C564.319,158.556 562.186,156.509 560.727,153.811C559.266,151.114 558.538,147.829 558.538,143.957Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M608.874,143.862C608.874,147.649 609.624,150.447 611.128,152.256C612.63,154.065 614.46,154.969 616.618,154.969C618.692,154.969 620.415,154.139 621.792,152.478C623.167,150.818 623.854,148.093 623.854,144.305C623.854,140.772 623.145,138.149 621.727,136.434C620.309,134.721 618.553,133.864 616.46,133.864C614.28,133.864 612.471,134.705 611.032,136.387C609.593,138.069 608.874,140.561 608.874,143.862ZM600.052,127.58L608.366,127.58L608.366,132.531C609.446,130.839 610.905,129.464 612.747,128.405C614.587,127.347 616.628,126.818 618.872,126.818C622.786,126.818 626.108,128.352 628.837,131.421C631.567,134.488 632.932,138.762 632.932,144.243C632.932,149.871 631.555,154.246 628.805,157.366C626.055,160.487 622.721,162.047 618.807,162.047C616.946,162.047 615.259,161.677 613.745,160.936C612.233,160.196 610.639,158.926 608.969,157.128L608.969,174.108L600.052,174.108L600.052,127.58Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M660.036,141.545C659.971,138.963 659.305,137.001 658.036,135.657C656.766,134.314 655.221,133.641 653.401,133.641C651.456,133.641 649.846,134.351 648.577,135.768C647.307,137.186 646.684,139.112 646.706,141.545L660.036,141.545ZM659.528,150.558L668.415,152.049C667.272,155.308 665.468,157.79 663.003,159.493C660.538,161.196 657.454,162.047 653.751,162.047C647.889,162.047 643.552,160.132 640.739,156.303C638.516,153.235 637.405,149.363 637.405,144.686C637.405,139.1 638.866,134.726 641.786,131.563C644.706,128.4 648.397,126.818 652.862,126.818C657.878,126.818 661.833,128.474 664.733,131.785C667.632,135.096 669.016,140.17 668.889,147.004L646.546,147.004C646.61,149.649 647.329,151.707 648.704,153.176C650.081,154.647 651.794,155.382 653.846,155.382C655.243,155.382 656.417,155.002 657.37,154.24C658.321,153.478 659.04,152.252 659.528,150.558Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M694.757,145.099C693.614,145.48 691.805,145.935 689.331,146.463C686.854,146.993 685.235,147.511 684.473,148.019C683.311,148.844 682.729,149.891 682.729,151.161C682.729,152.41 683.194,153.489 684.126,154.399C685.055,155.308 686.241,155.763 687.68,155.763C689.288,155.763 690.821,155.235 692.282,154.176C693.36,153.373 694.069,152.388 694.409,151.225C694.641,150.463 694.757,149.014 694.757,146.877L694.757,145.099ZM682.823,137.863L674.731,136.403C675.639,133.145 677.206,130.733 679.428,129.167C681.649,127.601 684.95,126.818 689.331,126.818C693.307,126.818 696.27,127.289 698.218,128.23C700.163,129.173 701.534,130.369 702.327,131.817C703.12,133.266 703.516,135.926 703.516,139.799L703.423,150.21C703.423,153.172 703.565,155.356 703.85,156.763C704.135,158.171 704.671,159.678 705.454,161.286L696.63,161.286C696.397,160.694 696.112,159.815 695.772,158.651C695.626,158.123 695.518,157.774 695.456,157.604C693.932,159.086 692.303,160.196 690.567,160.936C688.833,161.677 686.981,162.047 685.014,162.047C681.544,162.047 678.809,161.106 676.809,159.223C674.809,157.339 673.809,154.96 673.809,152.082C673.809,150.177 674.264,148.479 675.175,146.988C676.085,145.496 677.36,144.353 678.999,143.56C680.639,142.766 683.003,142.074 686.093,141.481C690.26,140.699 693.149,139.968 694.757,139.292L694.757,138.403C694.757,136.688 694.335,135.466 693.487,134.737C692.641,134.006 691.044,133.641 688.696,133.641C687.108,133.641 685.87,133.954 684.981,134.578C684.093,135.203 683.374,136.297 682.823,137.863Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M742.968,161.286L734.05,161.286L734.05,144.084C734.05,140.445 733.86,138.09 733.479,137.022C733.098,135.954 732.479,135.123 731.622,134.53C730.764,133.938 729.733,133.641 728.528,133.641C726.983,133.641 725.596,134.065 724.37,134.911C723.143,135.757 722.302,136.879 721.846,138.276C721.391,139.672 721.165,142.254 721.165,146.02L721.165,161.286L712.245,161.286L712.245,127.58L720.53,127.58L720.53,132.531C723.469,128.722 727.173,126.818 731.637,126.818C733.606,126.818 735.403,127.172 737.034,127.881C738.663,128.591 739.895,129.496 740.731,130.594C741.567,131.695 742.149,132.944 742.477,134.34C742.803,135.737 742.968,137.736 742.968,140.338L742.968,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M770.073,114.757L779.468,114.757L779.468,139.958C779.468,143.957 779.583,146.548 779.817,147.733C780.218,149.637 781.177,151.167 782.688,152.32C784.202,153.473 786.27,154.049 788.893,154.049C791.559,154.049 793.569,153.505 794.925,152.415C796.278,151.326 797.093,149.987 797.368,148.4C797.643,146.813 797.78,144.178 797.78,140.497L797.78,114.757L807.175,114.757L807.175,139.196C807.175,144.782 806.921,148.728 806.413,151.034C805.905,153.34 804.969,155.288 803.604,156.874C802.239,158.462 800.415,159.725 798.13,160.667C795.844,161.608 792.86,162.079 789.178,162.079C784.735,162.079 781.366,161.566 779.071,160.54C776.774,159.514 774.96,158.181 773.628,156.541C772.294,154.901 771.417,153.182 770.993,151.383C770.38,148.717 770.073,144.782 770.073,139.577L770.073,114.757Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M847.673,161.286L838.755,161.286L838.755,144.084C838.755,140.445 838.563,138.09 838.182,137.022C837.802,135.954 837.182,135.123 836.327,134.53C835.469,133.938 834.438,133.641 833.231,133.641C831.686,133.641 830.302,134.065 829.075,134.911C827.846,135.757 827.005,136.879 826.552,138.276C826.096,139.672 825.868,142.254 825.868,146.02L825.868,161.286L816.95,161.286L816.95,127.58L825.233,127.58L825.233,132.531C828.175,128.722 831.878,126.818 836.343,126.818C838.309,126.818 840.108,127.172 841.737,127.881C843.366,128.591 844.598,129.496 845.434,130.594C846.27,131.695 846.852,132.944 847.18,134.34C847.509,135.737 847.673,137.736 847.673,140.338L847.673,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M856.718,161.286L856.718,127.58L865.635,127.58L865.635,161.286L856.718,161.286ZM856.718,123.009L856.718,114.757L865.635,114.757L865.635,123.009L856.718,123.009Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M881.854,144.432C881.854,147.776 882.649,150.337 884.235,152.114C885.823,153.891 887.778,154.78 890.106,154.78C892.434,154.78 894.385,153.891 895.962,152.114C897.538,150.337 898.327,147.754 898.327,144.37C898.327,141.069 897.538,138.53 895.962,136.753C894.385,134.975 892.434,134.087 890.106,134.087C887.778,134.087 885.823,134.975 884.235,136.753C882.649,138.53 881.854,141.089 881.854,144.432ZM872.714,143.957C872.714,140.995 873.444,138.128 874.905,135.356C876.364,132.584 878.432,130.468 881.108,129.009C883.786,127.547 886.774,126.818 890.075,126.818C895.175,126.818 899.352,128.474 902.612,131.785C905.87,135.096 907.499,139.281 907.499,144.338C907.499,149.437 905.854,153.664 902.563,157.017C899.274,160.371 895.132,162.047 890.139,162.047C887.05,162.047 884.102,161.349 881.3,159.953C878.495,158.556 876.364,156.509 874.905,153.811C873.444,151.114 872.714,147.829 872.714,143.957Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
      <path
        d="M945.139,161.286L936.221,161.286L936.221,144.084C936.221,140.445 936.032,138.09 935.651,137.022C935.27,135.954 934.651,135.123 933.794,134.53C932.936,133.938 931.905,133.641 930.7,133.641C929.155,133.641 927.768,134.065 926.542,134.911C925.315,135.757 924.473,136.879 924.018,138.276C923.563,139.672 923.337,142.254 923.337,146.02L923.337,161.286L914.417,161.286L914.417,127.58L922.702,127.58L922.702,132.531C925.641,128.722 929.344,126.818 933.809,126.818C935.778,126.818 937.575,127.172 939.206,127.881C940.835,128.591 942.067,129.496 942.903,130.594C943.739,131.695 944.321,132.944 944.649,134.34C944.975,135.737 945.139,137.736 945.139,140.338L945.139,161.286Z"
        style={{ fill: 'currentColor', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

export default EULogo;
