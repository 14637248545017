import { LayoutConfiguration } from '@livekatsomo/models';
import { deleteField, updateDoc } from 'firebase/firestore';
import { getCustomerDocRef } from './getCustomerDocRef';

/**
 * Updates the layout configuration for a given customer.
 * @param customerId - The ID of the customer to update.
 * @param layout - The new layout configuration to set for the customer. Pass `null` to remove the layout configuration.
 * @returns A promise that resolves when the update is complete.
 */
export async function updateCustomerLayout(
  customerId: string,
  layout: LayoutConfiguration | null,
): Promise<void> {
  const customerDocRef = getCustomerDocRef(customerId);

  await updateDoc(customerDocRef, {
    defaultLayout: layout ? layout : deleteField(),
  });
}
