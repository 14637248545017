import { VideoPlayerConfig, VideoPlayerControls } from '@livekatsomo/types';
import { createContext } from 'react';

type Unsubscribe = () => void;

/**
 * The model for the PlayerContext.
 */
export type PlayerContextModel = {
  playerControls: VideoPlayerControls | null;
  playerConfig?: VideoPlayerConfig;
  setPlayerControls: (controls: VideoPlayerControls) => void;
  onUnload: (callback: (timestamp: number) => void) => Unsubscribe;
  getCurrentVideoTime: () => Promise<Date | null>;
};

/**
 * The context for the PlayerContext.
 * This context is used to share the player controls between components.
 */
export const PlayerContext = createContext<PlayerContextModel | undefined>(
  undefined,
);
