import { getDoc } from 'firebase/firestore';
import { getFormDocRef } from './getFormDocRef';

/**
 * Retrieves the Firestore document snapshot for a given form ID.
 * @param eventId - The parent path of the form document.
 * @param formId - The ID of the form document to retrieve.
 * @returns A Promise that resolves with the Firestore DocumentSnapshot for the form document.
 */
export async function getFormDoc(eventId: string, formId?: string) {
  if (!formId) return null;
  const formRef = getFormDocRef(eventId, formId);
  const docSnapshot = await getDoc(formRef);
  return docSnapshot;
}
