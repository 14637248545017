/**
 * Authentication observables
 * ==========================
 * This file contains observables that emit the current user and their roles.
 *
 * The user$ observable emits the current user with their roles or null if there is no user.
 * The reloadUser function can be used to trigger a reload of the current user.
 * The user$ observable is triggered by changes to the Firebase user or the reloadSubject.
 * The reloadSubject is triggered by the reloadUser function.
 * The firebaseUser$ observable emits the current Firebase user or null if there is no user.
 * The claimsUpdated$ observable emits a boolean indicating whether the user's claims have been updated.
 * The claimsUpdated$ observable is triggered by changes to the Firebase user.
 * The claimsUpdated$ observable is only subscribed to for non-anonymous users.
 * The claimsUpdated$ observable is used to trigger a reload of the current user.
 * The userWithRoles$ observable emits the current user with their roles or null if there is no user.
 * The userWithRoles$ observable is triggered by changes to the Firebase user or the claimsUpdated$ observable.
 * The userWithRoles$ observable is only subscribed to for non-anonymous users.
 * The userWithRoles$ observable is used to trigger a reload of the current user.
 * The reloadUser$ observable emits the current Firebase user or null if there is no user.
 * The reloadUser$ observable is triggered by the reloadSubject.
 * The reloadUser$ observable is used to trigger a reload of the current user.
 * The reloadUser function triggers a reload of the current user.
 * The reloadUser function triggers the reloadUser$ observable.
 * The reloadUser function is used to trigger a reload of the current user.
 * The reloadSubject triggers a reload of the current user.
 * The reloadSubject is used to trigger a reload of the current user.
 * The reloadSubject is triggered by the reloadUser function.
 * The reloadSubject is triggered by the reloadUser$ observable.
 * The reloadSubject is used to trigger a reload of the current user.
 * The prevClaimsUpdated variable stores the previous claimsUpdated timestamp.
 * The prevClaimsUpdated variable is used to determine whether the user's claims have been updated.
 *
 * @packageDocumentation
 */
import { KatsomoFirestoreError } from '@livekatsomo/custom-errors';
import { Role, User } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import {
  Observable,
  Subject,
  from,
  map,
  merge,
  of,
  share,
  switchMap,
} from 'rxjs';
import { userConverter } from '../users/userConverter';
import { startTransition } from 'react';

// Variable to store the previous claimsUpdated timestamp
export let prevClaimsUpdated: Date | null = null;

// Variable to store the previous authorizationsUpdated timestamp
export let prevAuthorizationsUpdated: Date | null = null;

// Observable that emits the current Firebase user or null if there is no user
const firebaseUser$ = new Observable<FirebaseUser | null>((observer) => {
  const unsubscribe = getAuth().onAuthStateChanged(
    (user) => {
      console.log('Authetication status changed', user?.displayName);
      startTransition(() => {
        if (!user) {
          observer.next(null);
        } else {
          observer.next(user);
        }
      });
    },
    (error) => observer.error(error),
  );
  return unsubscribe;
}).pipe(share());

// Observable that emits a boolean indicating whether the user's claims have been updated
const claimsOrAuthorizationUpdated$ = (
  user: FirebaseUser | null,
): Observable<boolean> =>
  new Observable<boolean>((subscriber) => {
    // Don't subscribe to firestore if user is null
    if (!user) {
      prevClaimsUpdated = null;
      startTransition(() => {
        subscriber.next(false);
      });
      return () => undefined;
    }

    const firestore = getFirestore();
    const userDoc = doc(
      firestore,
      firestorePaths.userPath(user.uid),
    ).withConverter(userConverter);

    // Listen for changes to claimsUpdated field only for non-anonymous users.
    return onSnapshot(
      userDoc,
      (userDocSnapshot) => {
        const user = userDocSnapshot.data();
        const isUpdated =
          user?.claimsUpdated?.getTime() !== prevClaimsUpdated?.getTime() ||
          user?.authorizationsUpdated?.getTime() !==
            prevAuthorizationsUpdated?.getTime();

        if (isUpdated && user?.claimsUpdated) {
          console.log('claims updated', user.claimsUpdated.toString());
          prevClaimsUpdated = user.claimsUpdated;
        }
        if (isUpdated && user?.authorizationsUpdated) {
          console.log(
            'authorizations updated',
            user.authorizationsUpdated.toString(),
          );
          prevAuthorizationsUpdated = user.authorizationsUpdated;
        }
        subscriber.next(isUpdated);
      },
      (error) => {
        console.error('claimsUpdated$ error', error);
        subscriber.error(
          new KatsomoFirestoreError(
            'claimsUpdated$ error',
            error.code,
            getAuth(),
            error,
          ),
        );
      },
      () => subscriber.complete(),
    );
  });

// Observable that emits the current user with their roles or null if there is no user
const userWithRoles$: (
  firebaseUser: FirebaseUser | null,
) => Observable<User | null> = (firebaseUser) =>
  claimsOrAuthorizationUpdated$(firebaseUser).pipe(
    switchMap((claimsUpdated) => {
      return firebaseUser
        ? from(firebaseUser.getIdTokenResult(claimsUpdated)).pipe(
            map((idTokenResult) => {
              const user: User = {
                ...firebaseUser,
                roles: (idTokenResult.claims['roles'] as Role[]) || [],
              };
              return user;
            }),
          )
        : of(null);
    }),
  );

// Subject that triggers a reload of the current user
const reloadSubject = new Subject<void>();

// Observable that emits the current Firebase user or null if there is no user, triggered by the reloadSubject
const reloadUser$: Observable<FirebaseUser | null> = reloadSubject.pipe(
  map(() => {
    const auth = getAuth();
    return auth.currentUser;
  }),
);

// Observable that emits the current user with their roles or null if there is no user, triggered by changes to the Firebase user or the reloadSubject
export const user$: Observable<User | null> = merge(
  firebaseUser$,
  reloadUser$,
).pipe(switchMap((user) => userWithRoles$(user)));

// Function that triggers a reload of the current user
export function reloadUser() {
  reloadSubject.next();
}
