import {
  CustomFormValues,
  FormDoc,
  customFormSchema,
} from '@livekatsomo/models';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';
import {
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

/**
 * Firestore data converter for FormDoc objects.
 */
export const formConverter: FirestoreDataConverter<FormDoc> = {
  /**
   *
   * @param doc - Firestore document snapshot to convert.
   * @returns An FormDoc object.
   */
  fromFirestore: (doc: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = customFormSchema.parse(
      doc.data(options),
      getFirestoreDocErrorMap(doc),
    );

    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'form',
    };
  },
  /**
   * Converts an FormDoc object to Firestore data.
   * @param form - The FormDoc object to convert.
   * @returns The Firestore data for the FormDoc object.
   */
  toFirestore: (form: PartialWithFieldValue<CustomFormValues>) => {
    const data: PartialWithFieldValue<FormDoc> = {
      ...form,
      docType: 'form',
    };
    return data;
  },
};
