import { StreamFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { streamConverter } from './streamConverter';

/**
 * Adds a new stream to the Firestore database.
 * @param options - The options object.
 * @param options.customerId - The ID of the customer who owns the stream.
 * @param options.trackId - The ID of the track to which the stream belongs (optional).
 * @param options.streamFormData - The form data for the new stream.
 * @returns A promise that resolves when the stream has been added.
 */
export async function addStream({
  customerId,
  trackId,
  streamFormData,
}: {
  customerId: string;
  trackId?: string;
  streamFormData: StreamFormValues;
}) {
  const firestore = getFirestore();
  const streamCollection = collection(
    firestore,
    firestorePaths.streamCollectionPath(customerId),
  ).withConverter(streamConverter);
  console.log('Adding stream', streamFormData);

  await addDoc(streamCollection, {
    ...streamFormData,
    parentId: trackId ? trackId : customerId,
  });
}
