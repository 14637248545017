import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { themeConverter } from './themeConverter';

/**
 * Returns a Firestore document reference for the specified theme ID,
 * with a converter to deserialize the document data into a Theme object.
 * @param themeId The ID of the theme to retrieve.
 * @returns A Firestore document reference for the specified theme ID,
 * with a converter to deserialize the document data into a Theme object.
 */
export function getThemeDoc(themeId: string) {
  const firestore = getFirestore();
  const themeDoc = doc(
    firestore,
    firestorePaths.themePath(themeId),
  ).withConverter(themeConverter);
  return themeDoc;
}
