import { User, UserFirestore } from '@livekatsomo/models';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

/**
 * Firestore data converter for User objects.
 */
export const userConverter: FirestoreDataConverter<User> = {
  /**
   *
   * @param doc - Firestore document to convert to a User object.
   * @returns An user object.
   */
  fromFirestore: (doc) => {
    const data = doc.data() as UserFirestore;
    return {
      ...data,
      docType: 'user',
      createdAt: (data.createdAt as Timestamp | undefined)?.toDate(),
      updatedAt: (data.updatedAt as Timestamp | undefined)?.toDate(),
      deleteAccountRequested: (
        data.deleteAccountRequested as Timestamp | undefined
      )?.toDate(),
      claimsUpdated: (data.claimsUpdated as Timestamp | undefined)?.toDate(),
      authorizationsUpdated: (
        data.authorizationsUpdated as Timestamp | undefined
      )?.toDate(),
    };
  },
  /**
   * @param user - User object to convert to Firestore data.
   * @returns Firestore data for the user.
   */
  toFirestore: (user: User) => {
    const { displayName, email, photoURL, roles, phoneNumber } = user;
    return {
      displayName,
      email,
      photoURL,
      roles,
      phoneNumber,
      docType: 'user',
    };
  },
};
