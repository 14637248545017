import { Asset, AssetInDocument, SponsorFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getSponsorDocRef } from './getSponsorDocRef';

/**
 * Updates a sponsor document in Firestore.
 *
 * @param eventId - The ID of the event the sponsor belongs to.
 * @param sponsorFormValues - The updated sponsor data with the ID of the sponsor to update.
 * @param onUploadProgressChange - A function to track upload progress.
 * @returns A Promise that resolves when the sponsor document has been updated.
 */
export async function updateSponsor(
  eventId: string,
  sponsorFormValues: SponsorFormValues & { id: string },
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const { logo: logoField, ...sponsorData } = sponsorFormValues;

  const sponsorDocRef = getSponsorDocRef(eventId, sponsorData.id);

  let logo: AssetInDocument | undefined;

  if (Array.isArray(logoField)) {
    const file = logoField[0];
    if (file) {
      const assetDoc = await uploadImageFile({
        file,
        filename: file.name,
        uploadDir: firestorePaths.assetCollectionPath(
          firestorePaths.eventPath(eventId),
        ),
        assetData: {
          purpose: ['logo'],
          alt: `${sponsorData.name} logo`,
        } satisfies Partial<Asset>,
        onUploadProgressChange: onUploadProgressChange,
      });
      const asset = assetDoc?.data();
      logo =
        asset && assetDoc
          ? {
              ...asset,
              id: assetDoc.id,
              sourceDocRefPath: assetDoc.ref.path,
              originalUrl: asset.originalUrl,
            }
          : undefined;
    }
  } else {
    logo = logoField;
  }

  await updateDoc(sponsorDocRef, { ...sponsorData, logo });
}
