import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

/**
 * A component that allows the user to select a language and changes the locale of the app accordingly.
 */
export function LanguageSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const { pathname, query, asPath, locale } = router;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: 'en' | 'fi') => {
    Cookies.set('NEXT_LOCALE', language, {
      expires: 1 * 365 * 24 * 60 * 60, // 1 year
      sameSite: 'strict',
    });
    // change just the locale and maintain all other route information including href's query
    router.push({ pathname, query }, asPath, { locale: language });
    handleClose();
  };

  return (
    <div>
      <Button
        size="large"
        color="inherit"
        id="basic-button"
        variant="text"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {locale}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem value="en" onClick={() => handleLanguageChange('en')}>
          English
        </MenuItem>
        <MenuItem value="fi" onClick={() => handleLanguageChange('fi')}>
          Finnish
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LanguageSelector;
