import { z } from 'zod';
import { AssetDoc, assetInDocumentSchema } from './asset.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';

/**
 * Defines the schema for a chat message object.
 */
export const chatMessageSchema = z.object({
  /** The message text. */
  text: z.string(),
  /** The user ID of the message sender. */
  uid: z.string(),
  /** The URL of the sender's profile photo, or null if not available. */
  photoURL: z.string().nullish(),
  /** The timestamp of when the message was created. */
  createdAt: timestampOrDateSchema,
  /** The display name of the sender, or null if not available. */
  displayName: z.string().optional(),
  /** An optional asset object associated with the message. */
  asset: assetInDocumentSchema.optional(),
  /** The email address of the sender, or null if not available. */
  email: z.string().optional(),
  /** Whether the message has been accepted by a moderator, or null if not applicable. */
  accepted: z.boolean().nullable(),
  /** The user ID of the moderator who accepted the message, or null if not applicable. */
  moderatorId: z.string().nullable(),
});

/**
 * Represents a chat message. Inferred type from {@link chatMessageSchema}.
 */
export type ChatMessage = z.infer<typeof chatMessageSchema>;

/**
 * This exports a chat message document schema ({@link chatMessageSchema}) that merges a Firestore document schema ({@link firestoreDocSchema})
 * and extends it with a `docType` property that is set to `'chatMessage'`.
 */
export const chatMessageDocSchema = chatMessageSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is chatMessage */
    docType: z.literal('chatMessage'),
  });

/**
 * Represents a document of a chat message.
 * Uses the {@link chatMessageDocSchema} schema to infer the type.
 */
export type ChatMessageDoc = z.infer<typeof chatMessageDocSchema>;

/**
 * Defines the schema for a chat message image, which includes a thumbnail URL and an uploading flag.
 */
export const chatMessageImageSchema = z.object({
  /** The thumbnail URL of the image. */
  thumbnail: z.string(),
  /** Whether the image is currently being uploaded. */
  uploading: z.boolean(),
});

/**
 * Defines a schema for a chat message image ({@link chatMessageImageSchema}) document, which extends the Firestore document schema ({@link firestoreDocSchema}})
 * and includes a literal 'chatMessageImage' document type.
 */
export const chatMessageImageDocSchema = chatMessageImageSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is chatMessageImage */
    docType: z.literal('chatMessageImage'),
  });

/**
 * Type definition for a chat message image document, inferred from the {@link chatMessageImageDocSchema}.
 */
export type ChatMessageImageDoc = z.infer<typeof chatMessageImageDocSchema>;

/**
 * Represents the form values for a chat message.
 */
export interface ChatMessageFormValues extends Pick<ChatMessageDoc, 'text'> {
  /** The ID of the chat message. */
  id?: string;
  /** The image associeated to the message */
  asset?: File[] | AssetDoc;
}
