import { ThemeDoc } from '@livekatsomo/models';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

/**
 * Sets the given theme as the current theme in the specified Firestore document.
 * @param theme - The theme to set as the current theme.
 * @param targetPath - The path to the Firestore document where the current theme should be set.
 * @returns A Promise that resolves when the current theme has been successfully set.
 */
export async function setAsCurrentTheme(
  theme: ThemeDoc,
  targetPath: string,
): Promise<void> {
  const firestore = getFirestore();
  const docRef = doc(firestore, targetPath);

  const updateData: {
    theme: ThemeDoc;
  } = {
    theme: {
      ...theme,
    },
  };
  await updateDoc(docRef, updateData);
}
