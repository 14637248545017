import { Locale, format as fnsFormat } from 'date-fns';
import { enGB, fi } from 'date-fns/locale';

/**
 * Represents a type that can be either a `Date` object or a Unix timestamp.
 */
export type DateType = number | Date;

declare global {
  // eslint-disable-next-line no-var
  var __localeId__: string;
}
const locales: Record<string, Locale> = { fi, enGB };

/**
 * Formats a date or Unix timestamp using the specified format string and locale.
 * If no format string is provided, it defaults to 'PP'.
 *
 * @param date - The date or Unix timestamp to format.
 * @param formatStr - The format string to use. Defaults to 'PP'.
 * @returns The formatted date string.
 */
export const format = (date: DateType, formatStr = 'PP') => {
  const locale =
    typeof window !== 'undefined'
      ? locales[window.__localeId__]
      : locales[global.__localeId__]; // Check browser, server

  return fnsFormat(date, formatStr, {
    locale,
  });
};
