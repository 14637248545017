import { firestorePaths } from '@livekatsomo/shared/config';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

/**
 * Deletes a slide queue item from Firestore.
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param slideQueueItemId - The ID of the slide queue item.
 * @returns A Promise that resolves when the slide queue item is deleted.
 */
export function deleteSlideQueueItem(
  eventId: string,
  trackId: string,
  slideQueueItemId: string,
): Promise<void> {
  const firestore = getFirestore();
  const docRef = doc(
    firestore,
    firestorePaths.slideQueueItemPath(eventId, trackId, slideQueueItemId),
  );
  return deleteDoc(docRef);
}
