import { z } from 'zod';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { trackInDocumentSchema } from './track.model';
import { eventInDocumentSchema } from './event.model';

/**
 * Defines the schema for a track playback history object.
 */
export const trackPlaybackHistorySchema = z.object({
  /** The timestamp of the playback history entry. */
  timestamp: timestampOrDateSchema.nullable(),
  /** The timestamp of the video at the time of the playback history entry. */
  videoTimestamp: timestampOrDateSchema,
  /** The ID of the track being played back. */
  trackId: z.string(),
  /** Optional information about the track being played back. */
  track: trackInDocumentSchema.optional(),
  /** The ID of the event associated with the playback history entry. */
  eventId: z.string(),
  /** Optional information about the event associated with the playback history entry. */
  event: eventInDocumentSchema.optional(),
});

/**
 * Type definition for the track playback history object, inferred from the {@link trackPlaybackHistorySchema}.
 */
export type TrackPlaybackHistory = z.infer<typeof trackPlaybackHistorySchema>;

/**
 * Defines the schema for a Firestore document representing a playback history entry.
 * This schema extends the base {@link firestoreDocSchema} with a `docType` field set to literal value of `'playbackHistory'`.
 */
export const trackPlaybackhistoryDocSchema = trackPlaybackHistorySchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `playbackHistory` */
    docType: z.literal('playbackHistory'),
  });

/**
 * Type definition for a document representing the playback history of a track.
 * Inferred from the {@link trackPlaybackhistoryDocSchema}.
 */
export type TrackPlaybackHistoryDoc = z.infer<
  typeof trackPlaybackhistoryDocSchema
>;
