import { JournalEvent } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Adds a new journal event to the specified event's journal collection in Firestore.
 * @param eventId - The ID of the event to add the journal event to.
 * @param journalEvent - The journal event to add.
 * @returns A Promise that resolves when the journal event has been added.
 */
export async function addJournalEvent(
  eventId: string,
  journalEvent: JournalEvent,
): Promise<void> {
  const firestore = getFirestore();
  const eventCollection = collection(
    firestore,
    firestorePaths.journalCollectionPath(eventId),
  );
  await addDoc(eventCollection, {
    ...journalEvent,
    timestamp: serverTimestamp(),
  });
}
