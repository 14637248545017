/**
 * An object containing Firestore paths for invitations.
 */
export const firestorePathsForInvitation = {
  /**
   * Returns the path for a specific invitation document.
   * @param parentPath - The parent path of the invitation document.
   * @param invitationId - The ID of the invitation document.
   * @returns The path for the invitation document.
   */
  invitationPath: (parentPath: string, invitationId: string) =>
    `${parentPath}/invitation/${invitationId}`,

  /**
   * Returns the path for the collection of invitation documents.
   * @param parentPath - The parent path of the invitation collection.
   * @returns The path for the invitation collection.
   */
  invitationCollectionPath: (parentPath: string) => `${parentPath}/invitation`,

  /**
   * The ID of the invitation collection.
   */
  invitationCollectionId: 'invitation',
} as const;
