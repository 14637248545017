import { InvitationFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Sets an invitation document in Firestore with the provided invitation form data.
 * @param parentPath - The parent path of the invitation document.
 * @param invitationFormData - The invitation form data to set in the document.
 * @returns A Promise that resolves when the document has been successfully set.
 */
export async function setInvitation(
  parentPath: string,
  invitationFormData: InvitationFormValues,
): Promise<void> {
  const firestore = getFirestore();
  const auth = getAuth();
  const invitationDocRef = doc(
    firestore,
    firestorePaths.invitationPath(parentPath, invitationFormData.email),
  );
  return setDoc(
    invitationDocRef,
    {
      ...invitationFormData,
      inviterId: auth.currentUser?.uid,
      sentAt: null,
    },
    { merge: true },
  );
}
