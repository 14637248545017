import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Calls the Firebase Cloud Function responsible for verifying an invitation token.
 * @param token The invitation token to verify.
 * @returns A Promise that resolves with the result of the Cloud Function.
 */
export function verifyInvitiationCallable(token: string) {
  const functions = getFunctions(getApp(), REGION);

  return httpsCallable<{ token: string }, CallableFunctionResponse>(
    functions,
    `authorizationFunctions-${CloudFunctionNames.VERIFY_INVITATION}`,
  )({ token });
}
