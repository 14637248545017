import { PreAuthorizedModerator } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { preAuthorizedModeratorConverter } from './preAuthorizedModeratorConverter';

/**
 * Retrieves a snapshot of pre-authorized moderators from Firestore.
 *
 * @param options - The options for the query.
 * @param options.parentPath - The parent path of the collection to query.
 * @param options.queryConstraints - The query constraints to apply to the query.
 * @param observer - The observer to notify of the query results.
 * @returns A function that can be called to unsubscribe from the query.
 */
export function getPreAuthorizedModerators(
  options: {
    parentPath: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<PreAuthorizedModerator[]>,
) {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.preAuthorizedModeratorCollectionPath(
        options.parentPath,
      ),
      queryConstraints: options.queryConstraints,
    },
    preAuthorizedModeratorConverter,
  );
}
