import {
  AssetDoc,
  DownloadFileDoc,
  FormDoc,
  SlideDeckDoc,
  StreamDoc,
  ThemeDoc,
  UsedIn,
} from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { capitalize } from './capitalize';

/**
 * Props for the DeleteConfirmationDialog component.
 */
export interface DeleteConfirmationDialogProps {
  /**
   * The document to be deleted.
   */
  doc:
    | ThemeDoc
    | AssetDoc
    | SlideDeckDoc
    | StreamDoc
    | FormDoc
    | DownloadFileDoc
    | null;
  /**
   * Callback function to be called when the dialog is closed.
   * @param value - A boolean indicating whether the delete action was confirmed or not.
   */
  onClose: (value: boolean) => void;
  /**
   * An array of objects that represent where the document is used.
   * Can be null, 'checking', or an Error object.
   */
  usedInDocuments: UsedIn[] | null | 'checking' | Error;
}

/**
 * A dialog component that displays a confirmation message for deleting a theme, asset, or slide deck.
 *
 * @param doc - The document to be deleted.
 * @param onClose - A function that will be called when the dialog is closed.
 * @param usedInDocuments - An array of documents that use the document to be deleted, or a string indicating that the component is checking for such documents, or an error if there was a problem checking for such documents.
 *
 * @returns A React component that displays a confirmation dialog for deleting a document.
 */
export function DeleteConfirmationDialog({
  doc,
  onClose,
  usedInDocuments,
}: DeleteConfirmationDialogProps) {
  const { t } = useTranslation();
  let disabled = true;
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  let description: ReactNode = null;

  if (usedInDocuments === 'checking') {
    description = (
      <Box>
        <Typography>
          {t('Checking if {{docType}} is used in any documents...', {
            docType: doc?.docType,
          })}
        </Typography>
        <CircularProgress />
      </Box>
    );
  } else if (usedInDocuments instanceof Error) {
    description = (
      <Typography color="palette.error">
        {t(`Unable to check used in documents: {{message}}`, {
          message: usedInDocuments.message,
        })}
      </Typography>
    );
  } else if (usedInDocuments && usedInDocuments.length > 0) {
    description = (
      <>
        <Typography variant="h6">
          {t('{{docType}} is used in {{length}} documents', {
            docType: capitalize(doc?.docType || ''),
            length: usedInDocuments.length,
          })}
        </Typography>
        <Typography>
          {t('{{docType}} cannot be deleted when it is in use', {
            docType: capitalize(doc?.docType || ''),
          })}
        </Typography>
      </>
    );
  } else {
    description = doc
      ? t(
          '{{docType}} is not used in any documents. Are you sure you want to delete it?',
          { docType: capitalize(doc.docType) },
        )
      : '';
    disabled = false;
  }

  let name = '';

  switch (doc?.docType) {
    case 'theme':
      name = doc.name;
      break;
    case 'asset':
      name = doc.filename;
      break;
  }

  const title = `Delete: ${name}`;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={Boolean(doc)}
      aria-label={t('Confirmation Dialog') || 'Confirmation Dialog'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{description}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleOk} disabled={disabled}>
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
