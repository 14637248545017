import { GetSendgridTemplateCallableResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';

/**
 * Retrieves a Sendgrid email template by its ID.
 *
 * @param templateId - The ID of the Sendgrid email template to retrieve.
 * @returns A promise that resolves with the Sendgrid email template.
 * @throws If the template ID is not provided.
 */

export async function getSendgridTemplate(
  templateId: string,
): Promise<HttpsCallableResult<GetSendgridTemplateCallableResponse>> {
  if (!templateId) {
    throw new Error('Template ID is required');
  }
  const functions = getFunctions(getApp(), REGION);
  const result = await httpsCallable<
    {
      templateId: string;
    },
    GetSendgridTemplateCallableResponse
  >(
    functions,
    `authorizationFunctions-${CloudFunctionNames.GET_SENDGRID_TEMPLATE}`,
  )({ templateId });
  return result;
}
