import { StreamFormValues } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getStreamDocRef } from './getStreamDocRef';

/**
 * Updates a stream document in Firestore.
 *
 * @param customerId - The ID of the customer who owns the stream.
 * @param streamFormValues - The updated stream data, including the stream ID.
 * @returns A Promise that resolves when the stream document has been updated.
 */
export async function updateStream(
  customerId: string,
  streamFormValues: StreamFormValues & { id: string },
) {
  const streamDocRef = getStreamDocRef(customerId, streamFormValues.id);
  await updateDoc(streamDocRef, streamFormValues);
}
