import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Image from 'next/image';

/**
 * Props for the HeaderLogo component.
 */
export interface HeaderLogoProps extends BoxProps {
  theme: Theme;
}

/**
 * Renders the logo for the header.
 * @param {HeaderLogoProps} props - The props for the component.
 * @param {Theme} props.theme - The theme object containing the logo information.
 * @param {SxProps<Theme>} props.sx - The custom styling for the component.
 * @returns The JSX element for the header logo.
 */
export function HeaderLogo({ theme, style }: HeaderLogoProps) {
  if (!theme.katsomo?.header?.logo) return null;
  const imageUrl =
    theme.katsomo?.header?.logo.type === 'image/svg+xml' ||
    !theme.katsomo?.header?.logo.downloadUrls
      ? theme.katsomo?.header?.logo.originalUrl
      : theme.katsomo?.header?.logo.downloadUrls?.png500x500;
  if (!imageUrl) return null;
  return (
    // <Box sx={{
    //   position: 'relative',
    //   maxWidth: '100%',
    //   ...sx
    // }}
    //   style={{
    //     height: `calc(${theme?.mixins?.toolbar?.minHeight || 56}px - 16px`,
    //   }}
    // >
    <Box
      component={Image}
      src={imageUrl}
      alt={theme.katsomo?.header?.logo?.alt || 'Logo'}
      // style={{
      //   height: `calc(${theme?.mixins?.toolbar?.minHeight || 56}px - 16px`,
      // }}
      // style={{
      //   objectFit: 'contain',
      //   objectPosition: 'left'
      // }}
      sx={{
        objectFit: 'contain',
        objectPosition: {
          xs: 'center',
          md: 'left',
        },
      }}
      fill
      // width={theme.katsomo?.header?.logo?.width}
      // height={theme.katsomo?.header?.logo?.height}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
    // </Box>
  );
}

export default HeaderLogo;
