import Box from '@mui/material/Box';

/**
 * Props for the VideoBackground component.
 */
export interface VideoBackgroundProps {
  /**
   * The URL of the poster image to display before the video starts playing.
   */
  poster?: string;
  /**
   * The URL of the video to play in the background.
   */
  src: string;
}

/**
 * Renders a video background with an optional poster image.
 * @param poster - The URL of the poster image to display before the video starts playing.
 * @param src - The URL of the video file to play in the background.
 * @returns The video background component.
 */
export function VideoBackground({ poster, src }: VideoBackgroundProps) {
  return (
    <>
      <video
        style={{
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        playsInline
        autoPlay
        muted
        loop
        poster={poster}
      >
        <source src={src} type="video/mp4" />
      </video>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgb(0 0 0 / 72%)',
          zIndex: -1,
        }}
      />
    </>
  );
}

export default VideoBackground;
