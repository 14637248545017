import { ChatMessageDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
} from 'firebase/firestore';
import { chatMessageConverter } from './chatMessageConverter';

/**
 * Retrieves a snapshot of chat messages for a given parent path, sorted by creation date in descending order.
 *
 * @param options - The options object.
 * @param options.parentPath - The parent path for the chat messages.
 * @param options.queryConstraints - Additional query constraints to apply to the chat messages.
 * @param observer - The observer to notify of changes to the chat messages.
 * @returns A function to unsubscribe from changes to the chat messages.
 */
export const getChatMessages = (
  options: { parentPath: string; queryConstraints?: QueryConstraint[] },
  observer: Observer<ChatMessageDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.chatMessageCollectionPath(
        options.parentPath,
      ),
      queryConstraints: [
        orderBy('createdAt', 'desc'),
        ...(options.queryConstraints || []),
      ],
    },
    chatMessageConverter,
  );
};

/**
 * Retrieves a paginated list of chat messages from Firestore.
 * @param options - The options for the query.
 * @param options.parentPath - The parent path of the chat messages to retrieve.
 * @param options.startAfter - The chat message to start the query after.
 * @param options.limit - The maximum number of chat messages to retrieve.
 * @param observer - The observer to notify of changes to the chat messages.
 * @returns A function to unsubscribe from the observer.
 */
export const getChatMessagesWithPagination = (
  options: {
    parentPath: string;
    startAfter?: ChatMessageDoc;
    limit?: number;
  },
  observer: Observer<ChatMessageDoc[]>,
) => {
  const queryConstraints: QueryConstraint[] = [orderBy('createdAt', 'desc')];
  if (options.startAfter) {
    queryConstraints.push(startAfter(options.startAfter.createdAt));
  }
  if (options.limit) {
    queryConstraints.push(limit(options.limit));
  }
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.chatMessageCollectionPath(
        options.parentPath,
      ),
      queryConstraints,
    },
    chatMessageConverter,
  );
};
