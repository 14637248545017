import { SlideQueueItemDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

/**
 * Updates a slide queue item in Firestore.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param slideQueueItemId - The ID of the slide queue item.
 * @param slideQueueItem - The updated slide queue item data.
 * @returns A promise that resolves when the update is complete.
 */
export function updateSlideQueueItem(
  eventId: string,
  trackId: string,
  slideQueueItemId: string,
  slideQueueItem: Partial<SlideQueueItemDoc>,
) {
  const firestore = getFirestore();
  const docRef = doc(
    firestore,
    firestorePaths.slideQueueItemPath(eventId, trackId, slideQueueItemId),
  );
  return updateDoc(docRef, slideQueueItem);
}
