import { getDoc } from 'firebase/firestore';
import { getInvitationDocRef } from './getInvitationDocRef';

/**
 * Retrieves the Firestore document snapshot for a given invitation ID.
 * @param parentPath - The parent path of the invitation document.
 * @param invitationId - The ID of the invitation document to retrieve.
 * @returns A Promise that resolves with the Firestore DocumentSnapshot for the invitation document.
 */
export async function getInvitationDoc(
  parentPath: string,
  invitationId: string,
) {
  const invitationRef = getInvitationDocRef(parentPath, invitationId);
  const docSnapshot = await getDoc(invitationRef);
  return docSnapshot;
}
