import { AssetDoc, AssetFormValues } from '@livekatsomo/models';
import { getDoc, updateDoc } from 'firebase/firestore';
import { getAssetDocRef } from './getAssetDocRef';

/**
 * Updates an asset with the given ID and form values in Firestore.
 * @param assetId - The ID of the asset to update.
 * @param parentPath - The parent path of the asset to update.
 * @param assetFormValues - The form values to update the asset with.
 * @returns A promise that resolves with the updated asset document.
 */
export async function updateAsset(
  assetId: string,
  parentPath: string,
  assetFormValues: AssetFormValues,
): Promise<AssetDoc> {
  // Remove the files property from the form values, since it's not part of the asset document.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { files: _, ...assetData } = assetFormValues;

  const assetDocRef = getAssetDocRef(parentPath, assetId);

  await updateDoc(assetDocRef, assetData);
  return (await getDoc(assetDocRef)).data() as AssetDoc;
}
