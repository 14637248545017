import { Asset } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadSpeakerPhotoFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getSpeakerDocRef } from './getSpeakerDocRef';

/**
 * Uploads a photo for a speaker to Firebase Storage and updates the speaker's image field in Firestore.
 *
 * @param file - The file to upload.
 * @param eventId - The ID of the event the speaker is associated with.
 * @param speaker - The speaker object to update.
 * @param setProgress - A callback function to update the upload progress.
 * @returns A Promise that resolves when the speaker's image has been uploaded and the speaker document has been updated.
 */
export const uploadSpeakerPhoto: UploadSpeakerPhotoFunction = async (
  file,
  eventId,
  speaker,
  setProgress,
) => {
  const uploadDir = firestorePaths.assetCollectionPath(
    firestorePaths.eventPath(eventId),
  );

  const assetDoc = await uploadImageFile({
    file,
    filename: file.name,
    uploadDir,
    assetData: {
      purpose: ['speaker'],
      alt: `${speaker.name}`,
    } satisfies Partial<Asset>,
    onUploadProgressChange: setProgress,
  });

  await updateDoc(getSpeakerDocRef(eventId, speaker.id), {
    image: { ...assetDoc.data(), id: assetDoc.id },
  });
};
