import { User } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getChatMessageDocRef } from './getChatMessageDocRef';

/**
 * Rejects a chat message by updating its `accepted` field to `false` and setting the `moderatorId` to the ID of the moderator who rejected it.
 * @param parentPath - The parent path of the chat message.
 * @param moderatorUser - The user object of the moderator who rejected the chat message.
 * @param chatMessageId - The ID of the chat message to reject.
 * @returns A promise that resolves when the chat message has been rejected.
 */
export function rejectChatMessage(
  parentPath: string,
  moderatorUser: User,
  chatMessageId: string,
): Promise<void> {
  const chatMessageDocRef = getChatMessageDocRef(parentPath, chatMessageId);
  return updateDoc(chatMessageDocRef, {
    accepted: false,
    moderatorId: moderatorUser.uid,
  });
}
