import { EventDoc, Store } from '@livekatsomo/models';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the EventsPublicContext.
 */
export type EventsPublicContextModel = {
  store: Store<EventDoc[]>;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
};

/**
 * The context for the EventsPublicContext.
 */
export const EventsPublicContext = createContext<
  EventsPublicContextModel | undefined
>(undefined);
