import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { nettilippuStatusConverter } from './nettilippuStatusConverter';

/**
 * Returns a Firestore DocumentReference for the Nettilippu status document,
 * with a custom converter applied.
 * A Firestore DocumentReference for the Nettilippu status document.
 */
export function getNettilippuStatusDocRef() {
  const firestore = getFirestore();
  return doc(firestore, firestorePaths.nettilippuStatusPath).withConverter(
    nettilippuStatusConverter,
  );
}
