/**
 * An object containing Firestore paths for themes.
 */
export const firestorePathsForTheme = {
  /**
   * Returns the Firestore path for a specific theme.
   * @param themeId The ID of the theme.
   * @returns The Firestore path for the theme.
   */
  themePath: (themeId: string) => `/theme/${themeId}`,

  /**
   * The Firestore path for the collection of themes.
   */
  themeCollectionPath: '/theme',
} as const;
