import { UsedIn } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

/**
 * Retrieves a list of documents that reference the specified downloadFile ID.
 * @param downloadFileId - The ID of the downloadFile to search for.
 * @param parentPath - The parent path of the downloadFile.
 * @returns - A promise that resolves to an array of UsedIn objects.
 */
export async function getDownloadFileUsedIn(
  downloadFileId: string,
  parentPath: string,
) {
  const firestore = getFirestore();
  const usedInCollection = collection(
    firestore,
    firestorePaths.usedInCollectionPath(
      firestorePaths.downloadFilePath(parentPath, downloadFileId),
    ),
  );
  const docs = await getDocs(usedInCollection);
  return docs.docs.map((doc) => doc.data() as UsedIn);
}
