import { AssetDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { uploadImageFile } from '../files/uploadImageFile';

/**
 * Uploads a user's photo to Firebase Storage and creates an Asset document in Firestore.
 * @param userId - The ID of the user who owns the photo.
 * @param file - The photo file to upload.
 * @param setProgress - Optional function to track upload progress.
 * @returns A promise that resolves with the uploaded Asset document.
 */
export async function uploadUserPhoto(
  userId: string,
  file: File,
  setProgress?: UploadProgressFunction,
): Promise<AssetDoc> {
  const uploadDir = `${firestorePaths.userPath(userId)}/asset/`;

  const assetDoc = await uploadImageFile({
    file,
    filename: file.name,
    uploadDir,
    onUploadProgressChange: setProgress,
  });
  const asset = assetDoc.data();
  if (!asset) {
    throw new Error('Asset document was not created.');
  }
  return asset;
}
