import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { styled, SxProps } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

/**
 * A styled div that serves as the header for a Drawer component.
 */
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

/**
 * Props for the Drawer component.
 */
export interface DrawerProps {
  sx?: SxProps;
  children?: React.ReactNode;
}

/**
 * A component that renders a swipeable drawer with a header and children content.
 *
 * @param sx - The style props for the component container.
 * @param children - The content to be rendered inside the drawer.
 * @returns The Drawer component.
 */
export function Drawer({ sx, children }: DrawerProps) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState(open);
    };

  return (
    <Box sx={sx}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('menu') || 'menu'}
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <DrawerHeader>
            <IconButton onClick={() => toggleDrawer(false)}>
              <ChevronLeft />
            </IconButton>
          </DrawerHeader>
          {children}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default Drawer;
