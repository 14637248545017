import { getDoc } from 'firebase/firestore';
import { getSubmissionDocRef } from './getSubmissionDocRef';

/**
 * Retrieves the Firestore document snapshot for a given submission ID.
 * @param eventId - The parent path of the submission document.
 * @param formId - The ID of the form document where submission.
 * @param submissionId - The ID of the submission document to retrieve.
 * @returns A Promise that resolves with the Firestore DocumentSnapshot for the submission document.
 */
export async function getSubmissionDoc(
  eventId: string,
  formId: string,
  submissionId: string,
) {
  if (!submissionId) return null;
  const submissionRef = getSubmissionDocRef(eventId, formId, submissionId);
  const docSnapshot = await getDoc(submissionRef);
  return docSnapshot;
}
