import { UsedIn } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

/**
 * Retrieves an array of `UsedIn` objects representing the documents that reference the theme with the specified ID.
 * @param themeId The ID of the theme to retrieve references for.
 * @returns A Promise that resolves with an array of `UsedIn` objects representing the documents that reference the specified theme.
 */
export async function getThemeUsedIn(themeId: string) {
  const firestore = getFirestore();
  const usedInCollection = collection(
    firestore,
    firestorePaths.usedInCollectionPath(firestorePaths.themePath(themeId)),
  );
  const docs = await getDocs(usedInCollection);
  return docs.docs.map((doc) => doc.data() as UsedIn);
}
