import { SpeakerFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { uploadSpeakerPhoto } from './uploadSpeakerPhoto';
import { speakerConverter } from './speakerConverter';

/**
 * Adds a new speaker to the Firestore database for the specified event.
 *
 * @param eventId - The ID of the event to add the speaker to.
 * @param speakerFormData - The speaker's form data.
 * @param onUploadProgressChange - Optional callback function to track upload progress.
 * @returns - A Promise that resolves when the speaker has been added to the database.
 */
export async function addSpeaker(
  eventId: string,
  speakerFormData: SpeakerFormValues,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const firestore = getFirestore();
  const speakerCollection = collection(
    firestore,
    firestorePaths.speakerCollectionPath(eventId),
  ).withConverter(speakerConverter);
  const { image, ...speaker } = speakerFormData;

  const speakerDocRef = await addDoc(speakerCollection, {
    ...speaker,
    ...(image && !Array.isArray(image) && { image }),
  });
  if (Array.isArray(image) && image.length) {
    uploadSpeakerPhoto(
      image[0],
      eventId,
      { id: speakerDocRef.id, name: speaker.name },
      onUploadProgressChange,
    );
  }
}
