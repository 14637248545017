import { z } from 'zod';
import { firestoreDocSchema } from './firestore-doc.model';
import { statusSchema } from './asset.model';

export const downloadFileSchema = z.object({
  /** The type of the downloadFile (e.g. "image", "video", etc.). */
  type: z.string(),
  /** The name of the cloud storage bucket where the downloadFile is stored. */
  bucket: z.string(),
  /** The name of the file that represents the downloadFile. */
  filename: z.string(),
  /** The path to the file within the cloud storage bucket. */
  filePath: z.string(),
  /** The name of the download material. */
  name: z.string().optional(),
  /** An optional description of the downloadFile. */
  description: z.string().optional(),
  /** The download url for the document */
  downloadUrl: z.string().optional(),
  /** An optional upload status value for the downloadFile. */
  status: statusSchema.optional(),
});
export type DownloadFile = z.infer<typeof downloadFileSchema>;

/**
 * Defines the schema for an download file document in Firestore, which includes the file schema
 * and Firestore document schema, and extends it with a `docType` property set to `'downloadFile'`.
 */
export const downloadFileDocSchema = downloadFileSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is downloadFile */
    docType: z.literal('downloadFile'),
  });

export type DownloadFileDoc = z.infer<typeof downloadFileDocSchema>;

/**
 * Defines a schema for the form values of an downloadFile, which includes the downloadFile's description, alt text, and purpose,
 * as well as an array of image files associated with the downloadFile.
 */
export const downloadFileFormValuesSchema = downloadFileSchema
  .pick({
    description: true,
  })
  .extend({
    /** An array of image files associated with the downloadFile. */
    files: z.array(z.any()),
  });

/**
 * Type definition for the form values of an downloadFile.
 *
 * @remarks
 * This type is inferred from the {@link downloadFileFormValuesSchema} schema.
 */
export type DownloadFileFormValues = z.infer<
  typeof downloadFileFormValuesSchema
>;
