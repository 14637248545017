import { z } from 'zod';
import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';

/**
 * An array of supported stream types.
 */
const streamTypes = ['bitmovin', 'vimeo', 'youtube'] as const;
/**
 * Defines a schema for the stream type enum ({@link streamTypes}).
 */
const streamTypeSchema = z.enum(streamTypes);

/**
 * The type of a stream, inferred from the {@link streamTypeSchema}.
 */
export type StreamType = z.infer<typeof streamTypeSchema>;

/**
 * Defines the schema for a stream object.
 */
export const streamSchema = z.object({
  /** The name of the stream. */
  name: z.string().min(1),
  /** The type of the stream. */
  streamType: streamTypeSchema,
  /** The URL of the stream. */
  url: z.string().min(1).or(z.string().url()),
  /** The ID of the parent owning the stream. Cloud be customer or track*/
  parentId: z.string(),
  /** The ID of the track associated with the stream. */
  trackId: z.string().optional(),
  /** The ID of the event associated with the stream. */
  eventId: z.string().optional(),
});

/**
 * Represents a stream object inferred from the {@link streamSchema}.
 */
export type Stream = z.infer<typeof streamSchema>;

/**
 * This constant exports a merged schema for a stream document in Firestore.
 * The schema includes the {@link streamSchema} and {@link firestoreDocSchema} schemas, and extends them
 * with a `docType` property that is set to the literal string 'stream'.
 */
export const streamDocSchema = streamSchema.merge(firestoreDocSchema).extend({
  /** literal document type is `stream` */
  docType: z.literal('stream'),
});

/**
 * Represents a Stream document inferred from the {@link streamDocSchema}.
 */
export type StreamDoc = z.infer<typeof streamDocSchema>;

/**
 * Merges the {@link streamSchema} and {@link usedInPropertySchema} to create a new schema for a stream in a document.
 * @returns A new schema for a stream in a document.
 */
export const streamInDocumentSchema = streamSchema.merge(usedInPropertySchema);

/**
 * Type definition for the inferred type of the {@link streamInDocumentSchema}.
 */
export type StreamInDocument = z.infer<typeof streamInDocumentSchema>;

/**
 * A schema for creating a new stream object with only the necessary properties for a form.
 * @remarks
 * This schema is created by picking the `name`, `url`, `streamType`, `trackId`, and `eventId` properties from the {@link streamSchema}.
 * @see {@link streamSchema} for the full schema of a stream object.
 */
export const streamFormSchema = streamSchema.pick({
  name: true,
  url: true,
  streamType: true,
  trackId: true,
  eventId: true,
});

/**
 * Type definition for the form values of a stream object inferred from the {@link streamFormSchema}.
 */
export type StreamFormValues = z.infer<typeof streamFormSchema>;
