import { DocumentDoc, documentSchema } from '@livekatsomo/models';
import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

/**
 * Converts TipTap documents to and from `DocumentDoc` objects.
 */
export const documentConverter = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): DocumentDoc => {
    const data = documentSchema.parse(snapshot.data(options));
    return {
      ...data,
      id: snapshot.id,
      sourceDocRefPath: snapshot.ref.path,
      docType: 'document',
    };
  },
  toFirestore: (document: PartialWithFieldValue<DocumentDoc>): DocumentData => {
    return {
      ...document,
      content: JSON.stringify(document.content),
    };
  },
};
