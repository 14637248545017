import { FormDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';
import { formConverter } from './formConverter';

/**
 * Retrieves a snapshot of form documents from Firestore.
 *
 * @param options - The options for the query.
 * @param options.eventId - The eventId of the form collection.
 * @param options.queryConstraints - The query constraints to apply to the query.
 * @param observer - The observer to notify of query results.
 * @returns A function to unsubscribe from the query.
 */
export const getForms = (
  options: {
    eventId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<FormDoc[]>,
) => {
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.eventFormCollectionPath(options.eventId),
      queryConstraints: options.queryConstraints,
    },
    formConverter,
  );
};
