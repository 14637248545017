import { Store, User } from '@livekatsomo/models';
import {
  ApplyActionCode,
  CheckActionCode,
  ConfirmPasswordReset,
  CreateUser,
  FacebookSignIn,
  GetSignInMethods,
  GoogleSignIn,
  IsSignInWithEmailLink,
  Logout,
  ProfileUpdatedCallable,
  RequestDeletionCallable,
  RequestEmailVerification,
  SendPasswordResetEmail,
  SignInAnonymously,
  SignInWithEmailAndPassword,
  SignInWithEmailLink,
  SigninProcessStatus,
  TwitterSignIn,
  VerifyPasswordResetCode,
} from '@livekatsomo/types';
import {
  Auth,
  FacebookAuthProvider,
  User as FirebaseUser,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';
import { HttpsCallableResult } from 'firebase/functions';
import { createContext } from 'react';

/**
 * Type for the authentication modal
 */
export type AuthenticationModalType =
  | 'register'
  | 'signIn'
  | 'forgotPassword'
  | 'continueWithNick'
  | 'reauthenticate'
  | null;

/**
 * Model for the authentication context
 */
export type AuthenticationContextModel = {
  store: Store<User | null>;
  auth: Auth;
  authenticationModalVisible: AuthenticationModalType;
  processing: SigninProcessStatus;
  error: Error | null;
  providers: string[] | null;
  showModal: (
    modalType: AuthenticationModalType | null,
    onSuccesfullAuthentication?: null | (() => void),
  ) => void;
  redirectToLogin: () => void;
  redirectToEmailVerification: () => void;
  logout: Logout;
  createUser: CreateUser;
  facebookSignIn: FacebookSignIn;
  googleSignIn: GoogleSignIn;
  twitterSignIn: TwitterSignIn;
  signInWithEmailAndPassword: SignInWithEmailAndPassword;
  signInAnonymously: SignInAnonymously;
  updateDisplayName: (displayName: string) => void;
  signInWithEmailLink: SignInWithEmailLink;
  requestEmailVerification: RequestEmailVerification;
  verifyPasswordResetCode: VerifyPasswordResetCode;
  confirmPasswordReset: ConfirmPasswordReset;
  checkActionCode: CheckActionCode;
  applyActionCode: ApplyActionCode;
  sendPasswordResetEmail: SendPasswordResetEmail;
  profileUpdatedCallable: ProfileUpdatedCallable;
  requestDeletionCallable: RequestDeletionCallable;
  getSigninMethods: GetSignInMethods;
  isSignInWithEmailLink: IsSignInWithEmailLink;
  setProcessing: (processing: SigninProcessStatus) => void;
  setError: (error: Error | null) => void;
  reauthenticate: (email: string) => Promise<void>;
  reauthenticateWithPopup: (
    provider: 'google.com' | 'facebook.com' | 'twitter.com',
  ) => Promise<FirebaseUser>;
  reauthenticateWithCredential: (
    email: string,
    password: string,
  ) => Promise<FirebaseUser>;
  /**
   * Force reloads the user's authentication token and updates the user's information.
   */
  reloadUser: () => Promise<void>;
  linkWithProvider: (
    currentUser: FirebaseUser,
    provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider,
  ) => Promise<void>;
  signInWithProvider: (
    provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider,
  ) => Promise<FirebaseUser>;
  tawkToUserHashCallable: () => Promise<
    HttpsCallableResult<
      | {
          success: true;
          hash: string;
        }
      | {
          success: false;
          message: string;
        }
    >
  >;
  onSuccesfullAuthentication: (() => void) | null;
};

/**
 * Context for authentication
 */
export const AuthenticationContext = createContext<
  AuthenticationContextModel | undefined
>(undefined);
