import { Customer, CustomerFormValues } from '@livekatsomo/models';
import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

/**
 * Firestore data converter for converting Firestore documents to CustomerFormValues objects and vice versa.
 */
export const newCustomerConverter: FirestoreDataConverter<CustomerFormValues> =
  {
    /**
     *
     * @param doc - The document snapshot to convert.
     * @param options - The options object.
     * @returns A CustomerFormValues object.
     */
    fromFirestore: (doc: QueryDocumentSnapshot<Customer>, options) => ({
      ...doc.data(options),
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'customer',
    }),
    /**
     * Converts a CustomerFormValues object to a Firestore document.
     * @param customer - The CustomerFormValues object to convert.
     * @returns A Firestore document.
     */
    toFirestore: (customer) => {
      return {
        ...customer,
        docType: 'customer',
      };
    },
  };
