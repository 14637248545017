import { SlideDeck } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collection,
  CollectionReference,
  doc,
  getFirestore,
} from 'firebase/firestore';
import { slideDeckConverter } from './slideDeckConverter';

/**
 * Returns a Firestore document reference for a slide deck with the given IDs.
 *
 * @param eventId - The ID of the event the slide deck belongs to.
 * @param trackId - The ID of the track the slide deck belongs to.
 * @param slideDeckId - The ID of the slide deck.
 * @returns A Firestore document reference for the slide deck.
 */
export function getSlideDeckDocRef(
  eventId: string,
  trackId: string,
  slideDeckId: string,
) {
  const firestore = getFirestore();
  const slideDeckCollection = collection(
    firestore,
    firestorePaths.slideDecksCollectionPath(eventId, trackId),
  ) as CollectionReference<SlideDeck>;
  const slideDeckRef = doc(slideDeckCollection, slideDeckId).withConverter(
    slideDeckConverter,
  );
  return slideDeckRef;
}
