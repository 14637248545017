import { deleteDoc } from 'firebase/firestore';
import { getModeratorDoc } from './getModeratorDoc';

/**
 * Deletes a moderator document from Firestore.
 * @param parentPath - The parent path of the moderator document.
 * @param moderatorId - The ID of the moderator document to delete.
 * @returns A Promise that resolves when the moderator document is deleted.
 * @throws If the moderator document is not found.
 */
export async function deleteModerator(
  parentPath: string,
  moderatorId: string,
): Promise<void> {
  const docSnapshot = await getModeratorDoc(parentPath, moderatorId);
  if (docSnapshot.exists()) {
    await deleteDoc(docSnapshot.ref);
  } else {
    throw new Error('Moderator not found');
  }
}
