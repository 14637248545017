import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

/**
 * A fallback spinner component that displays a circular progress indicator.
 * @param props The props for the CircularProgress component.
 * @returns A React component that displays a fallback spinner.
 */
export function FallbackSpinner(props: CircularProgressProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        my: 2,
      }}
    >
      <CircularProgress {...props} />
    </Box>
  );
}
