import { AssetDoc, FromFirestore, ToFirestore } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for AssetDoc.
 */
export const assetConverter: FirestoreDataConverter<AssetDoc> = {
  /**
   * Converts Firestore document to AssetDoc.
   * @param doc - Firestore document to convert.
   * @returns Converted AssetDoc.
   */
  fromFirestore: (doc): AssetDoc => {
    const data = doc.data() as FromFirestore<AssetDoc>;
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
    };
  },

  /**
   * Converts AssetDoc to Firestore document.
   * @param asset - AssetDoc to convert.
   * @returns Converted Firestore document.
   */
  toFirestore: (asset: AssetDoc): ToFirestore<AssetDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, sourceDocRefPath: path, ...data } = asset;
    return {
      ...data,
      docType: 'asset',
    };
  },
};
