import { firestorePaths } from '@livekatsomo/shared/config';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

/**
 * Deletes a playback history item.
 *
 * @param userId
 * @param trackId
 * @returns
 */

export function deletePlaybackHistoryItem(
  userId: string,
  trackId: string,
): Promise<void> {
  const firestore = getFirestore();
  const playbackHistoryItemRef = doc(
    firestore,
    firestorePaths.trackPlaybackHistoryPath(userId, trackId),
  );
  return deleteDoc(playbackHistoryItemRef);
}
