import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

/**
 * Props for the HeaderTitle component.
 */
export interface HeaderTitleProps {
  /**
   * The title to be displayed in the header.
   */
  children: ReactNode;
  /**
   * The custom styling to be applied to the component.
   */
  sx?: SxProps<Theme>;
}

/**
 * Renders the title of the header.
 * @param props - The props for the component.
 * @param props.children - The title to be displayed.
 * @param props.sx - The custom styling for the component.
 * @returns The rendered component.
 */
export function HeaderTitle({ sx, children }: HeaderTitleProps) {
  return (
    <Typography
      variant="h6"
      data-test="header-title"
      sx={{
        flexGrow: 1,
        // textAlign: 'center',
        // minHeight: '3rem',
        // flexDirection: 'column',
        justifyContent: 'center',
        // minWidth: 'min-content',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

export default HeaderTitle;
