import { SlideDeck, SlideDeckFormValues } from '@livekatsomo/models';
import { PDF_BUCKET, firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { getDoc } from 'firebase/firestore';
import { uploadPDFFile } from '../files/uploadSlideDeck';

/**
 * Uploads a slide deck to the specified event and track.
 *
 * @param eventId - The ID of the event to upload the slide deck to.
 * @param trackId - The ID of the track to upload the slide deck to.
 * @param slideDeckFormData - The slide deck form data containing the files and title.
 * @param setProgress - Optional function to set the upload progress.
 * @returns A promise that resolves with the uploaded slide deck.
 */
export const uploadSlideDeck = (
  eventId: string,
  trackId: string,
  slideDeckFormData: Pick<SlideDeckFormValues, 'files' | 'title'>,
  setProgress?: UploadProgressFunction,
) => {
  const uploadDir = firestorePaths.slideDecksCollectionPath(eventId, trackId);
  const { files, ...slideDeck } = slideDeckFormData;

  return new Promise<SlideDeck>((resolve, reject) => {
    if (files && files.length) {
      uploadPDFFile({
        file: files[0],
        uploadDir,
        bucket: PDF_BUCKET,
        onComplete: async ({ slideDocRef }) => {
          const assetDoc = await getDoc(slideDocRef);
          const slideDeck = assetDoc.data() as SlideDeck;
          if (slideDeck) resolve(slideDeck);
          else reject(new Error('SlideDeck not found'));
        },
        onError: reject,
        slideData: {
          ...slideDeck,
          status: 'uploaded',
          type: 'application/pdf',
        } satisfies Partial<SlideDeck>,
        onUploadProgressChange: setProgress,
      });
    }
  });
};
