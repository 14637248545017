/**
 * An object containing Firestore paths for the "used-in" collection.
 */
export const firestorePathsForUsedIn = {
  /**
   * Returns the path for a "used-in" collection under a given parent path.
   * @param parentPath The parent path under which the "used-in" collection should be created.
   * @returns The path for the "used-in" collection.
   */
  usedInCollectionPath: (parentPath: string) => `${parentPath}/used-in`,

  /**
   * The ID for the "used-in" collection.
   */
  usedInCollectionId: 'used-in',
} as const;
