import { getDoc } from 'firebase/firestore';
import { getEventDocRef } from './getEventDocRef';

/**
 * Retrieves the Firestore document snapshot for a given event ID.
 * @param eventId - The ID of the event to retrieve.
 * @returns A Promise that resolves with the Firestore DocumentSnapshot for the given event ID.
 */
export async function getEventDoc(eventId: string) {
  const eventRef = getEventDocRef(eventId);
  const docSnapshot = await getDoc(eventRef);
  return docSnapshot;
}
