import { DownloadFileDoc, DownloadFileFormValues } from '@livekatsomo/models';
import { getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadFileDocRef } from './getDownloadFileDocRef';

/**
 * Updates an downloadFile with the given ID and form values in Firestore.
 * @param downloadFileId - The ID of the downloadFile to update.
 * @param parentPath - The parent path of the downloadFile to update.
 * @param downloadFileFormValues - The form values to update the downloadFile with.
 * @returns A promise that resolves with the updated downloadFile document.
 */
export async function updateDownloadFile(
  downloadFileId: string,
  parentPath: string,
  downloadFileFormValues: DownloadFileFormValues,
): Promise<DownloadFileDoc> {
  // Remove the files property from the form values, since it's not part of the downloadFile document.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { files: _, ...downloadFileData } = downloadFileFormValues;

  const downloadFileDocRef = getDownloadFileDocRef(parentPath, downloadFileId);

  await updateDoc(downloadFileDocRef, downloadFileData);
  return (await getDoc(downloadFileDocRef)).data() as DownloadFileDoc;
}
