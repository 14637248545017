import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { speakerConverter } from './speakerConverter';

/**
 * Returns a Firestore DocumentReference for a speaker with the given ID in the specified event.
 * @param eventId The ID of the event containing the speaker.
 * @param speakerId The ID of the speaker to retrieve.
 * @returns A Firestore DocumentReference for the speaker.
 */
export function getSpeakerDocRef(eventId: string, speakerId: string) {
  const firestore = getFirestore();
  const speakerDocRef = doc(
    firestore,
    firestorePaths.speakerPath(eventId, speakerId),
  ).withConverter(speakerConverter);
  return speakerDocRef;
}
