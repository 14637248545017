/**
 * Get an nested value from an object by a string path
 * e.g. resolvePath({a: {b: {c : 5}}}, 'a.b.c') would return 5
 *
 * @param object - an object with nested key value pairs to access
 * @param path   - a key path to access nested values
 * @param defaultValue - optional default value if path not found
 */
export function getByPath(
  object: object | null,
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any,
) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    path.split('.').reduce((o: any, p) => (o ? o[p] : defaultValue), object) ||
    defaultValue
  );
}
