import { SlideImageDoc, Store } from '@livekatsomo/models';
import { createContext } from 'react';
import { Swiper } from 'swiper/types';

/**
 * Represents the model for the SlideDeckContext.
 */
export type SlideDeckContextModel = {
  store: Store<SlideImageDoc[] | null>;
  setSlideDeckId: (slideDeckId: string) => void;
  setThumbsSwiper: (swiper: Swiper) => void;
  setMainSwiper: (swiper: Swiper) => void;
  thumbsSwiper?: Swiper;
  mainSwiper?: Swiper;
  currentSlideDeckId?: string;
};

/**
 * The context for the SlideDeckContext.
 */
export const SlideDeckContext = createContext<
  SlideDeckContextModel | undefined
>(undefined);
