import Box, { BoxProps } from '@mui/material/Box';

/**
 * Renders a header tabs component.
 * @param props - The props for the component.
 * @returns The rendered component.
 */
export function HeaderTabs(props: BoxProps) {
  return (
    <Box
      sx={{
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 'auto',
        minWidth: 0,
        display: 'flex',
        justifyContent: 'center',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}

export default HeaderTabs;
