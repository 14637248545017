import { AssetDoc } from '@livekatsomo/models';
import { ProfileFormValues, UploadProgressFunction } from '@livekatsomo/types';
import { getAuth, reload, updateProfile } from 'firebase/auth';
import { profileUpdatedCallable } from '../authentication/authentication-handlers';
import { uploadUserPhoto } from './uploadUserPhoto';
import { onUpdateEmail } from './onUpdateEmail';

/**
 * Updates the user profile with the given form values and uploads a new photo if provided.
 * @param reauthenticate - A function that reauthenticates the user with their email.
 * @param profileFormValues - An object containing the user's profile information.
 * @param onUploadProgressChange - A function that is called when the upload progress changes.
 * @returns A promise that resolves when the user profile has been updated.
 */
export async function updateUserProfile(
  reauthenticate: (email: string) => Promise<void>,
  profileFormValues: ProfileFormValues,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const auth = getAuth();

  if (!auth.currentUser) {
    throw new Error('User is not signed in');
  }

  const { file, ...user } = profileFormValues;

  let asset: AssetDoc | undefined;

  if (file && file.length) {
    asset = await uploadUserPhoto(user.uid, file[0], onUploadProgressChange);
  }

  // Update user profile to Firebase Auth
  await updateProfile(auth.currentUser, {
    ...user,
    ...(asset && {
      photoURL: asset.downloadUrls?.png500x500 || asset.originalUrl,
    }),
  });

  if (
    profileFormValues.email &&
    auth?.currentUser?.email &&
    profileFormValues.email !== auth.currentUser.email
  ) {
    await onUpdateEmail(
      auth.currentUser,
      auth.currentUser.email,
      profileFormValues.email,
      reauthenticate,
    );
  }

  await profileUpdatedCallable(auth.currentUser.uid);
  await reload(auth.currentUser);
}
