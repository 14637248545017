import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

/**
 * Retrieves the initial content and version number of a TipTap document from Firestore.
 * @param docPath - The path of the document to retrieve.
 * @returns A Promise that resolves to an object containing the content and version number of the document.
 * @throws Throws a KatsomoError if there was an error while fetching the document.
 * @example
 * ```
 * const { content, version } = await getInitialDocumentContent('myCollection/myDocument');
 * ```
 */
export async function getInitialDocumentContent(
  docPath: string,
): Promise<{ content: string | undefined; version: number }> {
  const firestore = getFirestore();
  const docRef = doc(firestore, docPath) as DocumentReference<{
    version: number;
    content: string;
  }>;
  try {
    const docSnapshot = await getDoc(docRef);
    const { content, version = 0 } = docSnapshot.data() || {};
    return { content, version };
  } catch (error) {
    throw new KatsomoError(
      'Error while fetching document',
      KatsomoErrorCodes.AUTHENTICATION_REQUIRED,
      undefined,
      error as Error,
    );
  }
}
