/**
 * An object containing Firestore paths for moderators.
 */
export const firestorePathsForModerator = {
  /**
   * Returns the path for a moderator document.
   * @param parentPath - The parent path of the document.
   * @param userId - The ID of the user.
   * @returns The path for the moderator document.
   */
  moderatorPath: (parentPath: string, userId: string) =>
    `${parentPath}/moderator/${userId}`,

  /**
   * Returns the path for the moderator collection.
   * @param parentPath - The parent path of the collection.
   * @returns The path for the moderator collection.
   */
  moderatorCollectionPath: (parentPath: string) => `${parentPath}/moderator`,

  /**
   * Returns the path for a pre-authorized moderator document.
   * @param parentPath - The parent path of the document.
   * @param email - The email of the pre-authorized moderator.
   * @returns The path for the pre-authorized moderator document.
   */
  preAuthorizedModerator: (parentPath: string, email: string) =>
    `${parentPath}/pre-authorized-moderator/${email}`,

  /**
   * Returns the path for the pre-authorized moderator collection.
   * @param parentPath - The parent path of the collection.
   * @returns The path for the pre-authorized moderator collection.
   */
  preAuthorizedModeratorCollectionPath: (parentPath: string) =>
    `${parentPath}/pre-authorized-moderator`,

  /**
   * The ID of the pre-authorized moderator collection.
   */
  preAuthorizedModeratorCollectionId: 'pre-authorized-moderator',
} as const;
