import { LayoutConfiguration } from '@livekatsomo/models';
import { deleteField, updateDoc } from 'firebase/firestore';
import { getTrackDocRef } from './getTrackDocRef';

/**
 * Updates the layout configuration for a given track in an event.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param layout - The new layout configuration for the track, or null to delete the current layout.
 * @returns A promise that resolves when the update is complete.
 */
export async function updateTrackLayout(
  eventId: string,
  trackId: string,
  layout: LayoutConfiguration | null,
): Promise<void> {
  const trackDocRef = getTrackDocRef(eventId, trackId);

  await updateDoc(trackDocRef, {
    defaultLayout: layout ? layout : deleteField(),
  });
}
