import { z } from 'zod';

/**
 * A schema for a discriminated union representing the response of a callable function.
 * The response can either be a success or a failure, and includes an optional message and log.
 */
export const callableFunctionResponseSchema = z.discriminatedUnion('success', [
  z.object({
    /** The literal value `true` is used to discriminate the union. */
    success: z.literal(true),
    /** An optional message. */
    message: z.string().optional(),
    /** An optional log. */
    log: z.array(z.string()).optional(),
  }),
  z.object({
    /** The literal value `false` is used to discriminate the union. */
    success: z.literal(false),
    /** An optional message. */
    message: z.string(),
    /** An optional log. */
    log: z.array(z.string()).optional(),
  }),
]);

/**
 * Represents the inferred type of the `callableFunctionResponseSchema`.
 * This type is used as the response type for callable functions.
 * The response can either be a success or a failure, and includes an optional message and log.
 */
export type CallableFunctionResponse = z.infer<
  typeof callableFunctionResponseSchema
>;
