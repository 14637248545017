import { Store, TrackDoc } from '@livekatsomo/models';
import {
  AddTrackFunction,
  DeleteTrackFunction,
  UpdateTrackFunction,
  UpdateTrackOrderFunction,
  ValidateTrackSlugFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the TracksContext.
 */
export type TracksContextModel = {
  store: Store<TrackDoc[]>;
  deleteTrack: DeleteTrackFunction;
  updateTrack: UpdateTrackFunction;
  addTrack: AddTrackFunction;
  updateOrder: UpdateTrackOrderFunction;
  validateTrackSlug: ValidateTrackSlugFunction;
};

/**
 * The context for the TracksContext.
 */
export const TracksContext = createContext<TracksContextModel | undefined>(
  undefined,
);
