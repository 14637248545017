import { z } from 'zod';

/**
 * A schema for validating an object containing an encrypted password and an initialization vector.
 */
const ticketMasterPasswordSchema = z.object({
  /** The encrypted password. */
  encryptedPassword: z.string(),
  /** The initialization vector. */
  iv: z.string(),
});

/**
 * Defines the schema for the system configuration object.
 */
export const systemConfigSchema = z.object({
  /** An optional email template ID for invitations. */
  invitationEmailTemplateId: z.string().optional(),
  /** An optional email template ID for welcome emails. */
  welcomeEmailTemplateId: z.string().optional(),
  /** An optional email template ID for moderator invitations. */
  moderatorInvitationTemplateId: z.string().optional(),
  /** An optional delay in seconds for video synchronization.
   * @deprecated This field is deprecated and should not be used.
   * Sync timestamp is readed from bitmovin player and it's not configurable.
   */
  videoSyncDelay: z.number().optional(),
  /** An optional flag indicating whether the system is in demo mode. */
  ticketMasterPassword: ticketMasterPasswordSchema.optional(),
  /** An optional flag indicating whether the system is in demo mode. */
  seeded: z.boolean().optional(),
});

/**
 * Represents the inferred type of the `systemConfigSchema` object schema inferred from the {@link systemConfigSchema}.
 */
export type SystemConfig = z.infer<typeof systemConfigSchema>;
