import {
  AuthorizationProvider,
  CallableFunctionResponse,
} from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Calls the Firebase Cloud Function responsible for validating an event code.
 * @param eventId The ID of the event to validate the code for.
 * @param code The code to validate.
 * @param authorizationProvider The authorization provider to use for the validation.
 * @returns A Promise that resolves with the result of the Cloud Function.
 */
export const validateEventCodeCallable = (
  eventId: string,
  code: string,
  authorizationProvider: AuthorizationProvider,
) => {
  const functions = getFunctions(getApp(), REGION);

  return httpsCallable<
    {
      eventId: string;
      code: string;
      authorizationProvider: AuthorizationProvider;
    },
    CallableFunctionResponse
  >(
    functions,
    `authorizationFunctions-${CloudFunctionNames.VALIDATE_EVENT_CODE}`,
  )({ eventId, code, authorizationProvider });
};
