import { envVariablesFrontEndSchema } from '@livekatsomo/models';
import { EnvironmentContext } from '@livekatsomo/web/contexts';

/**
 * The environment variables for the application. Next.js automatically
 * replaces process.env.NEXT_PUBLIC_* with the actual values during build time.
 */
const env = {
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST,
  NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST:
    process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST,
  NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST:
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST,
  NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST:
    process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST,
  NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_DATABASE_URL:
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  NEXT_PUBLIC_TAWK_PROPERTY_ID: process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID,
  NEXT_PUBLIC_TAWK_WIDGET_ID: process.env.NEXT_PUBLIC_TAWK_WIDGET_ID,
  NODE_ENV: process.env.NODE_ENV,
};

export type EnvironmentProviderProps = {
  children: JSX.Element;
};

/**
 * Provides the environment context for the application.
 * @param children - The child components to render.
 * @returns The environment context provider.
 */
export function EnvironmentProvider({ children }: EnvironmentProviderProps) {
  const parsedEnv = envVariablesFrontEndSchema.parse(env);

  return (
    <EnvironmentContext.Provider
      value={{
        ...parsedEnv,
        development: parsedEnv.NODE_ENV === 'development',
      }}
      children={children}
    />
  );
}
