import {
  ListenToRemoteCursorUpdates,
  RemoveCursorPositionOnDestroy,
  SendCurrentCursorPosition,
} from '@santicon/prosemirror-firebase-collaboration-cursor-plugin';
import {
  ListenToDocumentUpdates,
  GetInitialDocumentContentAndVersion,
  SaveDocumentContent,
  SendDocumentUpdateSteps,
} from '@santicon/prosemirror-firestore-collaboration-plugin';
import { createContext } from 'react';

/**
 * Represents the model for the DocumentEditorContext.
 * This context is used to share the document editor functionality
 */
export type DocumentEditorContextModel = {
  listenToDocumentUpdates: ListenToDocumentUpdates;
  getInitialDocumentContent: GetInitialDocumentContentAndVersion;
  saveDocumentContent: SaveDocumentContent;
  sendDocumentUpdateSteps: SendDocumentUpdateSteps;
  listenToRemoteCursorUpdates: ListenToRemoteCursorUpdates;
  removeCursorPositionOnDestroy: RemoveCursorPositionOnDestroy;
  sendCurrentCursorPosition: SendCurrentCursorPosition;
};

/**
 * The context for the DocumentEditorContext.
 */
export const DocumentEditorContext = createContext<
  DocumentEditorContextModel | undefined
>(undefined);
