import { LinkProps } from 'next/link';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import { NextLinkComposed } from './NextLinkComposed';

/**
 * A component that renders a button as a list item with a link.
 * @param props The props for the component.
 * @returns A ListItemButton component with a NextLinkComposed component and the provided props.
 */
export function ListItemLinkButton(
  props: ListItemButtonProps<
    typeof NextLinkComposed,
    { to: LinkProps['href'] }
  >,
) {
  return <ListItemButton component={NextLinkComposed} {...props} />;
}
