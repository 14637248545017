import { deleteDoc } from 'firebase/firestore';
import { getSpeakerDoc } from './getSpeakerDoc';
import { getSpeakerDocRef } from './getSpeakerDocRef';

/**
 * Deletes a speaker from the database.
 * @param eventId - The ID of the event the speaker belongs to.
 * @param speakerId - The ID of the speaker to delete.
 * @returns A Promise that resolves when the speaker is deleted.
 * @throws If the speaker is not found.
 */
export const deleteSpeaker = async (
  eventId: string,
  speakerId: string,
): Promise<void> => {
  const docSnapshot = await getSpeakerDoc(eventId, speakerId);
  const speakerRef = getSpeakerDocRef(eventId, speakerId);
  if (docSnapshot.exists()) {
    await deleteDoc(speakerRef);
  } else {
    throw new Error('Speaker not found');
  }
};
