import { NotificationMessageFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Sends a notification to a specific event and track.
 *
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param senderId - The ID of the sender.
 * @param notification - The notification message.
 * @returns A promise that resolves with the document reference of the added notification.
 */
export function sendNotification(
  eventId: string,
  trackId: string,
  senderId: string,
  notification: NotificationMessageFormValues,
) {
  const firestore = getFirestore();
  const collectionRef = collection(
    firestore,
    firestorePaths.notificationCollectionPath(eventId, trackId),
  );

  return addDoc(collectionRef, {
    ...notification,
    senderId,
    createdAt: serverTimestamp(),
  });
}
