import { SponsorDoc } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getEventDocRef } from '../events/getEventDocRef';

/**
 * Updates the order of sponsors for a given event.
 * @param eventId - The ID of the event to update.
 * @param sponsors - An array of SponsorDoc objects representing the new order of sponsors.
 * @returns A Promise that resolves when the update is complete.
 */
export function updateSponsorOrder(
  eventId: string,
  sponsors: SponsorDoc[],
): Promise<void> {
  return updateDoc(getEventDocRef(eventId), {
    sponsors: sponsors.map((sponsor) => sponsor.id),
  });
}
