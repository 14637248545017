import { UpdateSlideQueueFunction } from '@livekatsomo/types';
import { deleteSlideQueueItem } from './deleteSlideQueueItem';
import { addSlideQueueItem } from './addSlideQueueItem';
import { updateSlideQueueItem } from './updateSlideQueueItem';

/**
 * Updates the slide queue for a given event and track with the specified changes.
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param changes - The changes to apply to the slide queue.
 * @param changes.addedSlides - The slides to add to the queue.
 * @param changes.removedSlides - The slides to remove from the queue.
 * @param changes.changedSlides - The slides to update in the queue.
 * @returns A Promise that resolves when all changes have been applied.
 */
export const updateSlideQueue: (
  eventId: string,
  trackId: string,
  changes: Parameters<UpdateSlideQueueFunction>[0],
) => Promise<void> = async (
  eventId,
  trackId,
  { addedSlides = [], removedSlides = [], changedSlides = [] },
) => {
  await Promise.all([
    // Add new slides
    ...addedSlides.map((slideQueueItem) => {
      return addSlideQueueItem(eventId, trackId, slideQueueItem);
    }),
    // Remove removed slides
    ...removedSlides.map((slide) => {
      return slide.id && deleteSlideQueueItem(eventId, trackId, slide.id);
    }),
    // Update changed slides
    ...changedSlides.map((slide) => {
      return (
        slide.id && updateSlideQueueItem(eventId, trackId, slide.id, slide)
      );
    }),
  ]);
};
