import {
  AddThemeFormValues,
  CustomerThemeEditorFormValues,
  ThemeDoc,
  UsedIn,
} from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the CustomerThemesContext.
 */
export type CustomerThemesContextModel = {
  store: Store<ThemeDoc[]>;
  updateTheme: (
    themeId: string,
    theme: Partial<CustomerThemeEditorFormValues>,
  ) => Promise<string>;
  addTheme: (theme: AddThemeFormValues) => Promise<string>;
  copyTheme: (theme: ThemeDoc) => Promise<string>;
  setAsCurrent: (theme: ThemeDoc) => Promise<void>;
  getThemeUsedIn: (themeId: string) => Promise<UsedIn[]>;
  deleteTheme: (themeId: string) => Promise<void>;
};

/**
 * The context for the CustomerThemesContext.
 */
export const CustomerThemesContext = createContext<
  CustomerThemesContextModel | undefined
>(undefined);
