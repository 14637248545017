import { deleteDoc } from 'firebase/firestore';
import { getFormDoc } from './getFormDoc';

/**
 * Deletes an form document from Firestore.
 * @param parentPath - The path of the parent document.
 * @param formId - The ID of the form document to delete.
 * @returns A Promise that resolves when the document is deleted.
 * @throws If the form document is not found.
 */
export const deleteForm = async (
  parentPath: string,
  formId: string,
): Promise<void> => {
  const docSnapshot = await getFormDoc(parentPath, formId);
  if (docSnapshot?.exists()) {
    await deleteDoc(docSnapshot.ref);
  } else {
    throw new Error('Form not found');
  }
};
