import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from 'firebase/app';
import {
  Auth,
  connectAuthEmulator,
  signOut as firebaseSignOut,
  getAuth,
} from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  memoryLocalCache,
} from 'firebase/firestore';
import { Emulators } from '@livekatsomo/models';
import {
  CHAT_IMAGES_BUCKET,
  PDF_BUCKET,
  REGION,
} from '@livekatsomo/shared/config';
import { Analytics, getAnalytics } from 'firebase/analytics';
// import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import {
  Functions,
  connectFunctionsEmulator,
  getFunctions,
} from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
  // eslint-disable-next-line no-var
  var EMULATORS_STARTED: boolean;
  interface Window {
    signin: () => void;
    signout: () => void;
    auth: Auth;
  }
}

export let analytics: Analytics | undefined;

// Your web app's Firebase configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env['NEXT_PUBLIC_FIREBASE_API_KEY'],
  authDomain: process.env['NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN'],
  databaseURL: process.env['NEXT_PUBLIC_FIREBASE_DATABASE_URL'],
  projectId: process.env['NEXT_PUBLIC_FIREBASE_PROJECT_ID'],
  // When using Firebase Emulator Suite with custom project ID,
  // the default storage bucket in functions emulator is {projectId}.appspot.com.
  // This should be configured in the environment variable so that app and functions
  // emulator can use the same storage bucket.
  storageBucket: process.env['NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId: process.env['NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID'],
  appId: process.env['NEXT_PUBLIC_FIREBASE_APP_ID'],
  measurementId: process.env['NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID'],
};

const config = {
  authEmulatorHost: process.env['NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST'],
  firestoreEmulatorHost: process.env['NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST'],
  databaseEmulatorHost:
    process.env['NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST'],
  functionsEmulatorHost:
    process.env['NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST'],
  storageEmulatorHost:
    process.env['NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST'],
  // appCheckClientKey: process.env['NEXT_PUBLIC_FIREBASE_APPCHECK_KEY'],
  nodeEnv: process.env['NODE_ENV'],
  nxEnv: process.env['NEXT_NX_ENV'],
};

export function initializeFirebase(
  options?: FirebaseOptions,
  initialEmulators?: Emulators,
): void {
  const apps = getApps();
  if (apps.length) {
    console.info('Firebase already initialized');
    return;
  }
  if (config.nxEnv)
    console.info('Initializing Firebase using ENV from', config.nxEnv);
  const emulators: Emulators = {
    auth: initialEmulators?.auth || config.authEmulatorHost,
    firestore: initialEmulators?.firestore || config.firestoreEmulatorHost,
    database: initialEmulators?.database || config.databaseEmulatorHost,
    functions: initialEmulators?.functions || config.functionsEmulatorHost,
    storage: initialEmulators?.storage || config.storageEmulatorHost,
  };

  // Initialize Firebase App
  let app: FirebaseApp;
  try {
    app = initializeApp(
      emulators.firestore
        ? {
            ...firebaseConfig,
            apiKey: 'Something',
            ...options,
          }
        : { ...firebaseConfig },
    );
  } catch (error) {
    console.info('Firebase already initialized', error);
    app = getApp();
  }

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  if (
    typeof window !== 'undefined' &&
    (config.nodeEnv === 'development' || config.nxEnv === 'build_live')
  ) {
    console.info({ nxEnv: config.nxEnv });
    console.info({ nodeEnv: config.nodeEnv });

    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  // if (
  //   typeof window !== 'undefined' &&
  //   config.appCheckClientKey &&
  //   !emulators.firestore
  // ) {
  //   const appCheckClientKey = config.appCheckClientKey;
  //   console.log('Initializing App Check with token', appCheckClientKey);
  //   initializeAppCheck(app, {
  //     provider: new ReCaptchaV3Provider(appCheckClientKey),
  //     // Optional argument. If true, the SDK automatically refreshes App Check
  //     // tokens as needed.
  //     isTokenAutoRefreshEnabled: true,
  //   });
  // }

  if (
    typeof window !== 'undefined' &&
    !emulators.firestore &&
    firebaseConfig.measurementId
  ) {
    analytics = getAnalytics(app);
  }
  // if (!config.appCheckClientKey) {
  //   console.warn('NEXT_PUBLIC_FIREBASE_APPCHECK_KEY is not set');
  // }

  console.info('Application ID', app.name, firebaseConfig.projectId);
  if (Object.values(emulators).some(Boolean)) {
    console.info('Emulators in use:', emulators);
  } else {
    console.info('Emulators not in use');
  }

  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    experimentalAutoDetectLongPolling: Boolean(emulators.firestore),
    localCache: memoryLocalCache(),
  });

  // Initialize authentication
  const auth = getAuth();
  auth.useDeviceLanguage();

  if (typeof window !== 'undefined' && config.nodeEnv === 'development') {
    // Add signout function to window for development purposes
    window.auth = auth;
    window.signout = () => firebaseSignOut(auth);
  }

  // Initialize functions
  const functions = getFunctions(app, REGION);

  // Connect to Auth emulator if auth emulator is enabled
  connectEmulators(emulators, auth, functions, app);
}

function connectEmulators(
  emulators: Emulators,
  auth: Auth,
  functions: Functions,
  app: FirebaseApp,
) {
  // Connect to Auth emulator if auth emulator is enabled
  if (emulators.auth && !auth.emulatorConfig) {
    try {
      connectAuthEmulator(auth, `http://${emulators.auth}`, {
        disableWarnings: config.nodeEnv === 'test' ? true : false,
      });
    } catch (error) {
      console.error('Unable to connect to Auth Emulator', error);
    }
  }

  // Connect to Functions emulator if functions emulator is enabled
  if (emulators.functions) {
    try {
      const host = emulators.functions.split(':')[0];
      const port = parseInt(emulators.functions.split(':')[1]);
      connectFunctionsEmulator(functions, host, port);
    } catch (error) {
      console.info('Unable to connect to Functions Emulator', error);
    }
  }

  // Connect to Firestore emulator if emulator is enabled
  if (emulators.firestore) {
    try {
      const firestore = getFirestore(app);
      const host = emulators.firestore.split(':')[0];
      const port = parseInt(emulators.firestore.split(':')[1]);
      connectFirestoreEmulator(firestore, host, port);
    } catch (error) {
      console.info('Unable to connect to Firestore Emulator', error);
    }
  }

  // Connect to Storage emulator if emulator is enabled
  if (emulators.storage) {
    try {
      const host = emulators.storage.split(':')[0];
      const port = parseInt(emulators.storage.split(':')[1]);
      const storage = getStorage(app);
      connectStorageEmulator(storage, host, port);
      const pdfStorage = getStorage(app, `gs://${PDF_BUCKET}`);
      connectStorageEmulator(pdfStorage, host, port);
      const chatImageStorage = getStorage(app, `gs://${CHAT_IMAGES_BUCKET}`);
      connectStorageEmulator(chatImageStorage, host, port);
    } catch (error) {
      console.info('Unable to connect to Storage Emulators', error);
    }
  }

  // Connect to Database emulator if emulator is enabled
  if (emulators.database) {
    try {
      const host = emulators.database.split(':')[0];
      const port = parseInt(emulators.database.split(':')[1]);
      const database = getDatabase(app);
      connectDatabaseEmulator(database, host, port);
    } catch (error) {
      console.info('Unable to connect to Database Emulator', error);
    }
  }
}
