import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

/**
 * Props for the ThemedContainer component.
 */
export interface ThemedContainerProps {
  children: React.ReactNode;
}

/**
 * A container component that applies a theme and background image to its children.
 *
 * @param children The children to render within the container.
 */
export function ThemedContainer({ children }: ThemedContainerProps) {
  const theme = useTheme();
  return (
    <Box
      sx={[
        {
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => theme.palette.background.default,
        },
        Boolean(theme.katsomo?.backgroundImage) && {
          backgroundImage: (theme) =>
            theme.katsomo?.backgroundImage?.image
              ? `url(${
                  theme.katsomo?.backgroundImage?.image?.downloadUrls
                    ?.jpeg2000x2000 ||
                  theme.katsomo?.backgroundImage?.image?.originalUrl
                })`
              : '',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionX: (theme) =>
            theme.katsomo?.backgroundImage?.position?.x
              ? theme.katsomo?.backgroundImage?.position?.x + '%'
              : '',
          backgroundPositionY: (theme) =>
            theme.katsomo?.backgroundImage?.position?.y
              ? theme.katsomo?.backgroundImage?.position?.y + '%'
              : '',
          backgroundBlendMode: (theme) => theme.katsomo?.backgroundBlendMode,
          backgroundAttachment: 'fixed',
          '--swiper-theme-color': (theme) => theme.palette.primary.main,
        },
      ]}
    >
      {children}
    </Box>
  );
}

export default ThemedContainer;
