import { z } from 'zod';
import { assetInDocumentSchema } from './asset.model';
import { firestoreDocSchema } from './firestore-doc.model';
import { usedInPropertySchema } from './used-in.model';

/**
 * Defines the schema for a social media link, which includes a URL.
 */
export const socialMediaLinkSchema = z.object({
  url: z.string().url(),
});

/**
 * Defines the schema for a speaker object
 */
export const speakerSchema = z.object({
  /** The name of the speaker. */
  name: z.string(),
  /** A description of the speaker. */
  description: z.string().optional(),
  /** A biography of the speaker. */
  bio: z.string().optional(),
  /** The URL of the speaker's image. */
  image: assetInDocumentSchema.optional(),
  /** An array of social media links for the speaker. */
  socialMediaLinks: z.array(socialMediaLinkSchema).optional(),
  /** Optional sex of the speaker.
   * @Remarks This field is used only for fake data generation.
   */
  sex: z.union([z.literal('female'), z.literal('male')]).optional(),
});

/**
 * Represents a speaker object with properties defined by the {@link speakerSchema}.
 */
export type Speaker = z.infer<typeof speakerSchema>;

/**
 * Defines the schema for a speaker document in Firestore, which includes the {@link speakerSchema} and {@link firestoreDocSchema}.
 * Additionally, it extends the schema to include a `docType` property with a literal value of `'speaker'`.
 */
export const speakerDocSchema = speakerSchema.merge(firestoreDocSchema).extend({
  /** literal document type is `speaker` */
  docType: z.literal('speaker'),
});

/**
 * Merges the {@link speakerSchema} and {@link usedInPropertySchema} to create a new schema for a speaker in a document.
 */
export const speakerInDocumentSchema =
  speakerSchema.merge(usedInPropertySchema);

/**
 * Represents the type of a speaker document, inferred from the {@link speakerDocSchema}.
 */
export type SpeakerDoc = z.infer<typeof speakerDocSchema>;

/**
 * Represents a social media link inferred from the {@link socialMediaLinkSchema}.
 */
export type SocialMediaLink = z.infer<typeof socialMediaLinkSchema>;

/**
 * Extends the {@link speakerSchema} with optional `id` and `image` properties to be used for speaker form values.
 */
export const speakerFormValuesSchema = speakerSchema.extend({
  /** The ID of the speaker. Undefined when adding new speaker */
  id: z.string().optional(),
  /** The URL of the speaker's image. */
  image: assetInDocumentSchema
    .or(z.array(z.any()))
    .or(z.literal(''))
    .optional(),
});

/**
 * Represents the form values for a speaker inferred from the {@link speakerFormValuesSchema}.
 */
export type SpeakerFormValues = z.infer<typeof speakerFormValuesSchema>;
