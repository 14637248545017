import Chip, { ChipProps } from '@mui/material/Chip';
import { useTranslation } from 'next-i18next';

export function VodEnabledBadge({ sx, ...props }: ChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{
        ml: 1,
        ...sx,
      }}
      component="span"
      color="secondary"
      label={t('VOD')}
      size="small"
      {...props}
    />
  );
}
