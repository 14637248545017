import { CustomerDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { customerConverter } from './customerConverter';

/**
 * Retrieves a customer document from Firestore by ID.
 *
 * @param options - The options object.
 * @param options.customerId - The ID of the customer document to retrieve.
 * @param observer - The observer to notify of the document's state changes.
 * @returns A promise that resolves with the customer document.
 * @throws If customerId is not provided.
 */
export const getCustomerById = (
  options: { customerId: string },
  observer: Observer<CustomerDoc>,
) => {
  const { customerId } = options;
  console.log('getCustomerById', options);
  if (!customerId) throw new Error('customerId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.customerPath(customerId),
    },
    customerConverter,
  );
};
