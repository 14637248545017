/**
 * Recursively removes undefined and empty string values from an object.
 * @param obj - The object to remove undefined and empty string values from.
 * @returns A new object with undefined and empty string values removed.
 */
export const removeUndefinedAndEmptyString: <Value>(obj: Value) => Value = (
  obj,
) => {
  if (obj && typeof obj === 'object') {
    const newObj: Record<string, unknown> = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        const newValue = removeUndefinedAndEmptyString(value);
        if (newValue !== null && Object.keys(newValue).length > 0) {
          newObj[key] = newValue;
        }
        // will remove '' and undefined from object but not null
      } else if (value !== '' && value !== undefined) {
        newObj[key] = value;
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return newObj as any;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return obj as any;
  }
};
