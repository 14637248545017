/**
 * Defines the error codes for Katsomo authentication.
 */
export const KatsomoAuthErrorCodes = {
  NEED_CONFIRMATION: 'auth/account-exists-with-different-credential',
  UNKNOW_USER: 'auth/user-not-found',
  INVALID_PASSWORD: 'auth/wrong-password',
  EMAIL_EXISTS: 'auth/email-already-in-use',
  WEAK_PASSWORD: 'auth/weak-password',
  POPUP_CLOSED_BY_USER: 'auth/popup-closed-by-user',
  NO_EMAIL: 'auth/no-email',
  EMAIL_NOT_VERIFIED: 'auth/email-not-verified',
  GENERIC_AUTHERROR: 'auth/generic-auth-error',
  USER_CANCELLED_AUTH_ACTION: 'auth/user-cancelled-auth-action',
  AUTHENTICATION_REQUIRED: 'auth/authentication-required',
} as const;

export type KatsomoAuthErrorCode =
  (typeof KatsomoAuthErrorCodes)[keyof typeof KatsomoAuthErrorCodes];
