/**
 * An object containing the names of various cloud functions.
 */
export const CloudFunctionNames = {
  PROFILE_UPDATED: 'profileUpdated',
  REQUEST_DELETION: 'requestDeletion',
  GENERATE_DOWNLOAD_URLS: 'generateDownloadUrls',
  GENERATE_SLUGS: 'generateSlugs',
  HELLO_WORLD: 'helloWorld',
  VALIDATE_EVENT_CODE: 'validateEventCode',
  VERIFY_INVITATION: 'verifyInvitation',
  SEND_INVITATIONS: 'sendInvitations',
  GET_SENDGRID_TEMPLATE: 'getSendgridTemplate',
  ON_STORE_TICKETMASTER_PASSWORD: 'onStoreTicketMasterPassword',
  ON_GET_TAWK_TO_USER_HASH: 'onGetTawkToUserHash',
} as const;
