import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore } from 'firebase/firestore';
import { customerConverter } from './customerConverter';

/**
 * Returns a Firestore DocumentReference for the customer with the given ID,
 * with a converter to deserialize Firestore data into a Customer object.
 *
 * @param customerId The ID of the customer to retrieve.
 * @returns A Firestore DocumentReference for the customer.
 */
export const getCustomerDocRef = (customerId: string) => {
  const firestore = getFirestore();
  return doc(firestore, firestorePaths.customerPath(customerId)).withConverter(
    customerConverter,
  );
};
