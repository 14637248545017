/**
 * Capitalizes the first letter of each word in a string.
 * @param str - The string to be titleized.
 * @returns The titleized string.
 */
export function titleize(str: string) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
