import { CustomerThemeEditorFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';

/**
 * Updates an existing theme or creates a new one if no theme ID is provided.
 * @param themeId - The ID of the theme to update. If not provided, a new theme will be created.
 * @param themeValues - The updated values for the theme.
 * @returns Promise that resolves with the ID of the updated or newly created theme.
 */
export async function updateTheme(
  themeId: string,
  themeValues: Partial<CustomerThemeEditorFormValues>,
): Promise<string> {
  const firestore = getFirestore();

  if (themeId) {
    await updateDoc(
      doc(firestore, firestorePaths.themePath(themeId)),
      themeValues,
    );
    return themeId;
  } else {
    const newDoc = await addDoc(
      collection(firestore, firestorePaths.themeCollectionPath),
      themeValues,
    );
    return newDoc.id;
  }
}
