import { getDoc } from 'firebase/firestore';
import { getAssetDocRef } from './getAssetDocRef';

/**
 * Retrieves the document snapshot for a specific asset.
 * @param parentPath - The parent path of the asset.
 * @param assetId - The ID of the asset.
 * @returns A promise that resolves with the document snapshot of the asset.
 */
export async function getAssetDoc(parentPath: string, assetId: string) {
  const assetRef = getAssetDocRef(parentPath, assetId);
  const docSnapshot = await getDoc(assetRef);
  return docSnapshot;
}
