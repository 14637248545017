import { AnalyticsContext } from '@livekatsomo/web/contexts';
import { Analytics, logEvent as firebaseLogEvent } from 'firebase/analytics';
import type { CustomEventName } from 'firebase/analytics';

export function AnalyticsProvider({
  analytics,
  children,
}: {
  analytics?: Analytics;
  children: React.ReactNode;
}) {
  const logEvent = (
    eventName: CustomEventName<string>,
    eventParams?: Record<string, unknown>,
  ) =>
    analytics ? firebaseLogEvent(analytics, eventName, eventParams) : undefined;

  return (
    <AnalyticsContext.Provider value={{ logEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
