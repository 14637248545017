import { FromFirestore, InvitationDoc, ToFirestore } from '@livekatsomo/models';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

/**
 * Firestore data converter for InvitationDoc objects.
 */
export const invitationConverter: FirestoreDataConverter<InvitationDoc> = {
  /**
   *
   * @param doc - Firestore document snapshot to convert.
   * @returns An InvitationDoc object.
   */
  fromFirestore: (doc): InvitationDoc => {
    const { ...data } = doc.data() as FromFirestore<InvitationDoc> & {
      sentAt: Timestamp;
    };

    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'invitation',
      sentAt: data.sentAt?.toDate(),
    };
  },
  /**
   * Converts an InvitationDoc object to Firestore data.
   * @param invitation - The InvitationDoc object to convert.
   * @returns The Firestore data for the InvitationDoc object.
   */
  toFirestore: (invitation: InvitationDoc): ToFirestore<InvitationDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = invitation;
    return {
      ...data,
      docType: 'invitation',
    };
  },
};
