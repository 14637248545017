import { onSnapshot } from 'firebase/firestore';
import { NettilippuStatus } from '@livekatsomo/models';
import { Observer } from '@livekatsomo/types';
import { getNettilippuStatusDocRef } from './getNettilippuStatusDocRef';

/**
 * Retrieves the current status of the Nettilippu system.
 * @param options - Unused parameter.
 * @param observer - Observer object that listens for changes to the Nettilippu status.
 * @returns A function that can be called to unsubscribe from the observer.
 */
export function getNettilippuStatus(
  options: undefined,
  observer: Observer<NettilippuStatus | null>,
) {
  const systemConfigDocRef = getNettilippuStatusDocRef();
  return onSnapshot(systemConfigDocRef, {
    next: (docSnapshot) => {
      const data = docSnapshot.data();
      observer.next(data ?? null);
    },
    error: (error) => observer.error(error),
    complete: observer.complete,
  });
}
