import { z } from 'zod';
import { assetInDocumentSchema } from './asset.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';

/**
 * Defines the schema for a slide queue item, which represents a slide that is queued to be displayed in a session.
 */
export const slideQueueItemSchema = z.object({
  /** The ID of the slide queue item. */
  id: z.string().optional(),
  /** The asset to be displayed. */
  asset: assetInDocumentSchema,
  /** The timestamp of when the slide was queued. */
  timestamp: timestampOrDateSchema,
  /** The timestamp of when the slide should be displayed (optional). */
  start: timestampOrDateSchema.optional(),
  /** The delay in milliseconds before the slide should be displayed (optional). */
  // delay: z.number().optional(),
  /** The session number of the slide.
   * $deprecated This field is deprecated and should not be used.
   */
  sessionNumber: z.number(),
  /** The timestamp of when the slide should stop being displayed (optional). */
  end: timestampOrDateSchema.optional(),
  /** Whether the slide is enabled. */
  enabled: z.boolean(),
});

/**
 * Represents an item in the slide queue ({@link slideQueueItemSchema}).
 */
export type SlideQueueItem = z.infer<typeof slideQueueItemSchema>;

/**
 * Defines the schema for a Firestore document representing a slide queue item ({@link slideQueueItemSchema}).
 * This schema extends the {@link firestoreDocSchema} and adds a `docType` field with the literal value `'slideQueueItem'`.
 */
export const slideQueueItemDocSchema = slideQueueItemSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal document type is `slideQueueItem` */
    docType: z.literal('slideQueueItem'),
  });

/**
 * Type definition for a document representing a slide queue item ({@link slideQueueItemDocSchema}).
 */
export type SlideQueueItemDoc = z.infer<typeof slideQueueItemDocSchema>;
