/**
 * An object containing Firestore paths for system configuration.
 */
export const firestorePathsForSystem = {
  /**
   * The path to the system configuration document in Firestore.
   */
  systemConfigPath: 'config/system',

  /**
   * The path to the current system theme document in Firestore.
   */
  currentSystemTheme: 'config/current-theme',

  /**
   * The path to the nettilippu status document in Firestore.
   */
  nettilippuStatusPath: 'config/nettilippu-status',

  /**
   * The path to the Ticketmaster status document in Firestore.
   */
  ticketmasterStatusPath: 'config/ticketmaster-status',
} as const;
