import { NotificationMessage } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { notificationMessageConverter } from './notificationMessageConverter';

/**
 * Retrieves a list of notifications for a given event and track.
 *
 * @param options - An object containing the eventId, trackId, and optional query constraints.
 * @param options.eventId - The ID of the event.
 * @param options.trackId - The ID of the track.
 * @param options.queryConstraints - Optional query constraints to apply to the query.
 * @param observer - An observer that listens for changes to the notifications collection.
 * @returns A snapshot of the notifications collection.
 */
export function getNotifications(
  options: {
    eventId: string;
    trackId: string;
    queryConstraints?: QueryConstraint[];
  },
  observer: Observer<NotificationMessage[]>,
) {
  const { eventId, trackId } = options;
  return getCollectionSnapshot(
    observer,
    {
      collectionPath: firestorePaths.notificationCollectionPath(
        eventId,
        trackId,
      ),
      queryConstraints: [
        orderBy('createdAt', 'desc'),
        ...(options.queryConstraints || []),
      ],
    },
    notificationMessageConverter,
  );
}
