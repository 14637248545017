import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { startTransition } from 'react';
import { useEffect, useState } from 'react';

/**
 * A component that displays a cookie policy popup and sets a cookie to remember the user's acceptance.
 * @returns A React component that displays a cookie policy popup.
 */
export function CookiePolicyPopup() {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const hasAcceptedCookiePolicy = Cookies.get('cookiePolicyAccepted');
    startTransition(() => {
      setShowPopup(!hasAcceptedCookiePolicy);
    });
  }, []);

  const acceptCookiePolicy = () => {
    Cookies.set('cookiePolicyAccepted', 'true', { expires: 365 });
    setShowPopup(false);
  };

  return showPopup ? (
    <Paper
      sx={{
        zIndex: 100,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: (theme) => theme.spacing(2),
        backgroundColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <p>
        {t(
          'This website uses cookies. By using this site, you agree to our use of cookies.',
        )}
      </p>
      <Button
        variant="contained"
        color="secondary"
        onClick={acceptCookiePolicy}
      >
        {t('Accept')}
      </Button>
    </Paper>
  ) : null;
}

export default CookiePolicyPopup;
