import { SnapshotFunction } from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { getEventIdFromSlug } from './getEventIdFromSlug';

/**
 * Returns the ID of an event given its slug and customer ID.
 *
 * @param options - An object containing the customer ID, slug, and optional query constraints.
 * @param options.customerId - The ID of the customer.
 * @param options.slug - The slug of the event.
 * @param options.queryConstraints - Optional query constraints to apply to the query.
 * @param observer - An observer that receives the event ID or an error.
 * @param initialState - An optional initial state to emit before fetching the event ID.
 * @returns A function that can be called to cancel the operation.
 */
export const getEventIdBySlug: SnapshotFunction<
  { customerId: string; slug: string; queryConstraints?: QueryConstraint[] },
  string
> = (options, observer, initialState) => {
  console.log('getEventIdBySlug', options);
  if (initialState) {
    observer.next(initialState);
    return () => undefined;
  }
  const { customerId, slug } = options;
  getEventIdFromSlug(customerId, slug)
    .then(observer.next)
    .catch(observer.error);
  return () => undefined;
};
