import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, User, verifyBeforeUpdateEmail } from 'firebase/auth';

/**
 * Updates the email address of a user and handles reauthentication if necessary.
 * @param user - The user to update the email for.
 * @param oldEmail - The user's old email address.
 * @param newEmail - The new email address to update to.
 * @param reauthenticate - A function that reauthenticates the user with their old email address.
 * @returns A promise that resolves when the email has been updated.
 * @throws If there is an error updating the email address.
 */
export async function onUpdateEmail(
  user: User,
  oldEmail: string,
  newEmail: string,
  reauthenticate: (email: string) => Promise<void>,
): Promise<void> {
  try {
    await verifyBeforeUpdateEmail(user, newEmail);
  } catch (error) {
    if (
      error instanceof FirebaseError &&
      (error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN ||
        error.code === AuthErrorCodes.TOKEN_EXPIRED)
    ) {
      await reauthenticate(oldEmail);
      // try again
      // await updateEmail(user, newEmail);
    } else {
      throw error;
    }
  }
}
