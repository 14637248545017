import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

/**
 * Sets the eventId for a given event slug in Firestore.
 * @param customerId - The ID of the customer who owns the event.
 * @param slug - The slug of the event.
 * @param eventId - The ID of the event to set.
 * @returns A Promise that resolves when the document has been successfully written.
 */
export async function setEventSlugDoc(
  customerId: string,
  slug: string,
  eventId: string,
): Promise<void> {
  const firestore = getFirestore();
  const eventSlugRef = doc(
    firestore,
    firestorePaths.eventSlugPath(customerId, slug),
  );
  return setDoc(eventSlugRef, { eventId });
}
