import {
  GenerateMissingImagesFunction,
  GenerateSlugsFunction,
  GenericCallableFunction,
} from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the SuperAdminContext.
 */
export type SuperAdminContextModel = {
  generateMissingImagesFunction: GenerateMissingImagesFunction;
  generateSlugsFunction: GenerateSlugsFunction;
  helloWorldFunction: GenericCallableFunction;
  error: Error | null;
};

/**
 * The context for the SuperAdminContext.
 */
export const SuperAdminContext = createContext<
  SuperAdminContextModel | undefined
>(undefined);
