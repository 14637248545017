import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { agendaItemConverter } from './agendaItemConverter';

/**
 * Returns a Firestore document reference for a specific agenda item.
 * @param eventId - The ID of the event that the agenda item belongs to.
 * @param agendaItemId - The ID of the agenda item.
 * @returns A Firestore document reference for the agenda item.
 */
export const getAgendaItemDocRef = (eventId: string, agendaItemId: string) => {
  const firestore = getFirestore();
  const agendaItemDocRef = doc(
    firestore,
    firestorePaths.agendaItemPath(eventId, agendaItemId),
  ).withConverter(agendaItemConverter);
  return agendaItemDocRef;
};

/**
 * Retrieves the Firestore document for a specific agenda item within an event.
 * @param eventId The ID of the event containing the agenda item.
 * @param agendaItemId The ID of the agenda item to retrieve.
 * @returns A Promise containing the Firestore document for the specified agenda item.
 */
export const getAgendaItemDoc = (eventId: string, agendaItemId: string) => {
  return getDoc(getAgendaItemDocRef(eventId, agendaItemId));
};
