import { UploadProgressFunction } from '@livekatsomo/types';
import { uploadImageFile } from './uploadImageFile';

/**
 * Uploads an array of files to a specified parent path and sub-collection.
 * @param files - The array of files to upload.
 * @param parentPath - The parent path to upload the files to.
 * @param subCollection - The sub-collection to upload the files to.
 * @param setProgress - Optional function to track upload progress.
 * @returns A Promise that resolves when all files have been uploaded successfully.
 */
export const uploadFiles = async (
  files: File[],
  parentPath: string | undefined,
  subCollection: string,
  setProgress?: UploadProgressFunction,
): Promise<void> => {
  const uploadDir = `${parentPath}/${subCollection}/`;

  if (!parentPath) return Promise.resolve();

  if (!files || !files.length) {
    throw new Error('No files provided to upload files');
  }
  for (const file of files) {
    try {
      await uploadImageFile({
        file,
        uploadDir,
        onUploadProgressChange: setProgress,
      });
    } catch (error) {
      setProgress &&
        setProgress((prev) => ({
          ...prev,
          [file.name]: {
            file,
            progress: 0,
            error:
              error instanceof Error ? error.message : 'Unknown error occured',
          },
        }));
    }
  }
};
