import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import clsx from 'clsx';
import { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import {
  Anchor,
  NextLinkComposed,
  NextLinkComposedProps,
} from './NextLinkComposed';

/**
 * Props for the Link component.
 */
export type LinkProps = {
  /**
   * The class name to apply when the link is active.
   */
  activeClassName?: string;
  /**
   * The Next.js component to use for the link.
   */
  as?: NextLinkProps['as'];
  /**
   * The URL the link should navigate to.
   */
  href: NextLinkProps['href'];
  /**
   * The Next.js component to use for the link, useful when the as prop is shallow by styled().
   */
  linkAs?: NextLinkProps['as'];
  /**
   * Whether to apply default link styles or not.
   */
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

/**
 * A customizable link component that can be used with Next.js and MUI.
 * A styled version of the Next.js Link component:
 * https://nextjs.org/docs/api-reference/next/link
 * @param props The props for the Link component.
 * @param ref The ref for the Link component.
 * @returns A React component representing a link.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    const {
      activeClassName = 'active',
      as,
      className: classNameProps,
      href,
      linkAs: linkAsProp,
      locale,
      noLinkStyle,
      prefetch,
      replace,
      role, // Link don't have roles.
      scroll,
      shallow,
      ...other
    } = props;

    const router = useRouter();
    const pathname = typeof href === 'string' ? href : href.pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    const isExternal =
      typeof href === 'string' &&
      (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <Anchor className={className} href={href} ref={ref} {...other} />
        );
      }

      return <MuiLink className={className} href={href} ref={ref} {...other} />;
    }

    const linkAs = linkAsProp || as;
    const nextjsProps = {
      to: href,
      linkAs,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
    };

    if (noLinkStyle) {
      return (
        <NextLinkComposed
          className={className}
          ref={ref}
          {...nextjsProps}
          {...other}
        />
      );
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        className={className}
        ref={ref}
        {...nextjsProps}
        {...other}
      />
    );
  },
);

export default Link;
