import { CacheProvider, EmotionCache } from '@emotion/react';
import { defaultTheme } from '@livekatsomo/shared/config';
import { analytics, initializeFirebase } from '@livekatsomo/web/firebase';
import { AnalyticsProvider } from '@livekatsomo/web/providers/analytics';
import { DateFnsProvider } from '@livekatsomo/web/providers/date-fns';
import { EnvironmentProvider } from '@livekatsomo/web/providers/environment';
import ServerConnectionProvider from '@livekatsomo/web/providers/server-connection';
import { FallbackSpinner } from '@livekatsomo/web/ui-components/fallback-spinner';
import { CookiePolicyPopup } from '@livekatsomo/web/ui-components/layout';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'bitmovin-player/bitmovinplayer-ui.css';
import { Locale, enGB, fi } from 'date-fns/locale';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Suspense, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/virtual';
import createEmotionCache from '../createEmotionCache';
import './styles.css';
import './tiptap-styles.scss';
initializeFirebase();
function Loading() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleStart = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      console.log('handleStart', url, shallow);
      if (!shallow) setLoading(true);
    };
    const handleComplete = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      console.log('handleComplete', url, shallow);
      if (!shallow) setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });
  if (!loading) return null;
  return <LinearProgress color="secondary" sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100
  }} />;
}
const OnlineListener = dynamic(() => import('@livekatsomo/web/features/online-listener').then(mod => mod.OnlineListenerFeature), {
  ssr: false
});
const theme = createTheme(defaultTheme);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// enGB is used for en locale so that time picker is in 24h format
const localeMap: Record<string, Locale> = {
  fi,
  en: enGB
};
function CustomApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
  } = props;
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation();

  // console.log('locale', locale);
  // console.log('locales', locales);
  // console.log('defaultLocale', defaultLocale);
  return <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        <Loading />
        <EnvironmentProvider>
          <SnackbarProvider maxSnack={3} action={snackbarId => <Button sx={{
          color: 'inherit'
        }} onClick={() => closeSnackbar(snackbarId)}>
                {t('Dismiss')}
              </Button>}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale && localeMap[locale]}>
              <AnalyticsProvider analytics={analytics}>
                {/* <AuthenticationProvider> */}
                <ServerConnectionProvider>
                  {/* <ServerTimeOffsetProvider> */}
                  <DateFnsProvider locale={locale ? localeMap[locale] : undefined}>
                    {/* <ErrorBoundary FallbackComponent={FallbackFullpageViewForError}> */}
                    <CssBaseline />
                    <Suspense fallback={<FallbackSpinner />}>
                      <Component {...pageProps} />
                      <OnlineListener />
                    </Suspense>
                    {/* </ErrorBoundary> */}
                  </DateFnsProvider>
                  {/* </ServerTimeOffsetProvider> */}
                </ServerConnectionProvider>
                {/* </AuthenticationProvider> */}
              </AnalyticsProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </EnvironmentProvider>
        <CookiePolicyPopup />
      </ThemeProvider>
    </CacheProvider>;
}
export default appWithTranslation(CustomApp);