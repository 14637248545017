import { DownloadFileDoc, DownloadFileFormValues } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { uploadDownloadFile } from './uploadFile';

/**
 * Uploads an asset to Firebase Storage and creates a corresponding document in Firestore.
 * @param assetFormData - The form data for the asset, including the file(s) to upload.
 * @param parentPath - The parent path for the asset in Firestore.
 * @param setProgress - An optional function to track the upload progress.
 * @returns A promise that resolves with the uploaded asset document.
 * @throws An error if the parent path is not provided.
 */
export const uploadDownloadFileFromForm = async (
  assetFormData: DownloadFileFormValues,
  parentPath: string,
  setProgress?: UploadProgressFunction,
): Promise<DownloadFileDoc> => {
  const uploadDir = `${parentPath}/downloadFile/`;
  const { files, ...formValues } = assetFormData;

  if (!parentPath) throw new Error('Parent path is required to upload asset');

  if (!files || !files.length) {
    throw new Error('No files provided to upload asset');
  }

  const assetDoc = await uploadDownloadFile({
    file: files[0],
    uploadDir,
    onUploadProgressChange: setProgress,
  });

  await updateDoc(assetDoc.ref, formValues);

  const data = assetDoc.data();
  if (!data) throw new Error('DownloadFile document data is undefined');
  return data;
};
