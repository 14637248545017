import { PreAuthorizedUserDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getCollectionSnapshot } from '@livekatsomo/web/firebase';
import { QueryConstraint } from 'firebase/firestore';

/**
 * Retrieves a list of pre-authorized users from Firestore based on the provided query constraints.
 *
 * @param options - The options for the query.
 * @param options.queryConstraints - The constraints to apply to the query.
 * @param observer - The observer to notify of query results.
 * @returns A function that can be called to unsubscribe from the query.
 */
export function getPreAuthorizedUsers(
  options: {
    queryConstraints: QueryConstraint[];
  },
  observer: Observer<PreAuthorizedUserDoc[]>,
) {
  return getCollectionSnapshot(observer, {
    collectionPath: firestorePaths.preAuthorizedUserCollectionPath,
    queryConstraints: options.queryConstraints,
  });
}
