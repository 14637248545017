import { firestorePaths } from '@livekatsomo/shared/config';
import {
  DocumentData,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { submissionConverter } from './submissionConverter';

/**
 * Sets an form document in Firestore with the provided form form data.
 * @param eventId - The ID of the event the form belongs to.
 * @param formId - The ID of the form to be submitted.
 * @param uid - The ID of the user submitting the form.
 * @param formValues - The form data to be submitted.
 * @returns A Promise that resolves when the document has been successfully set.
 */
export async function submitForm(
  eventId: string,
  formId: string,
  uid: string,
  formValues: DocumentData,
): Promise<void> {
  const firestore = getFirestore();
  const formDocRef = doc(
    firestore,
    firestorePaths.eventFormSubmissionPath(eventId, formId, uid),
  ).withConverter(submissionConverter);

  await setDoc(formDocRef, {
    id: formDocRef.id,
    values: formValues,
    docType: 'formSubmission',
    sourceDocRefPath: formDocRef.path,
    createdAt: serverTimestamp(),
  });
}
