import { EventConfigDoc } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { UpdateEventConfigFunction } from '@livekatsomo/types';
import { createContext } from 'react';

/**
 * Represents the model for the EventConfigContext.
 */
export type eventConfigContextModel = {
  store: Store<EventConfigDoc>;
  updateEventConfig: UpdateEventConfigFunction;
};

/**
 * The context for the EventConfigContext.
 */
export const EventConfigContext = createContext<
  eventConfigContextModel | undefined
>(undefined);
