import { Asset, AssetInDocument, SponsorFormValues } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import {
  addDoc,
  arrayUnion,
  collection,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { uploadImageFile } from '../files/uploadImageFile';
import { getEventDocRef } from '../events/getEventDocRef';

/**
 * Adds a new sponsor to the specified event.
 *
 * @param eventId - The ID of the event to add the sponsor to.
 * @param sponsorFormData - The form data for the sponsor.
 * @param onUploadProgressChange - A function to call when the upload progress changes.
 * @returns A promise that resolves when the sponsor is added successfully.
 */
export async function addSponsor(
  eventId: string,
  sponsorFormData: SponsorFormValues,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const firestore = getFirestore();
  const sponsorCollection = collection(
    firestore,
    firestorePaths.sponsorCollectionPath(eventId),
  );
  const { logo: logoField, ...sponsor } = sponsorFormData;

  let logo: AssetInDocument | undefined;

  if (Array.isArray(logoField)) {
    const assetDoc = await uploadImageFile({
      file: logoField[0],
      uploadDir: firestorePaths.assetCollectionPath(
        firestorePaths.eventPath(eventId),
      ),
      assetData: {
        purpose: ['logo'],
        alt: `${sponsor.name} logo`,
      } satisfies Partial<Asset>,
      onUploadProgressChange,
    });
    const asset = assetDoc?.data();
    logo =
      asset && assetDoc
        ? {
            ...asset,
            id: assetDoc.id,
            sourceDocRefPath: assetDoc.ref.path,
            originalUrl: asset.originalUrl,
          }
        : undefined;
  } else {
    logo = logoField;
  }

  const sponsorDocRef = await addDoc(sponsorCollection, { ...sponsor, logo });

  return updateDoc(getEventDocRef(eventId), {
    sponsors: arrayUnion(sponsorDocRef.id),
  });
}
