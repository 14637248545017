import { Observer } from '@livekatsomo/types';
import { getDatabase, ref, onValue } from 'firebase/database';

/**
 * Returns the time offset between the client and the Firebase server.
 * @param options - Unused parameter.
 * @param observer - Observer to receive the time offset value.
 * @returns A function that can be used to unsubscribe from the observer.
 */
export function getServerTimeOffset(
  options: undefined,
  observer: Observer<number>,
) {
  const db = getDatabase();
  const connectionRef = ref(db, '.info/serverTimeOffset');
  return onValue(connectionRef, (snap) => {
    const offset = snap.val() as number;
    observer.next(offset);
  });
}
