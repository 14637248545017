import { FieldValue, Timestamp } from 'firebase/firestore';
import { z } from 'zod';
import { assetInDocumentSchema } from './asset.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { roleSchema } from './role.model';

/**
 * User schema object definition.
 * Defines the shape of a user object in the application.
 */
export const userSchema = z.object({
  /** The user ID. */
  uid: z.string(),
  /** The display name of the user. */
  displayName: z.string().nullable(),
  /** The email address of the user. */
  email: z.string().email().nullable(),
  /** The URL of the user's profile photo. */
  photoURL: z.string().nullable(),
  /** Whether the user's email address has been verified. */
  emailVerified: z.boolean().optional(),
  /** An array of roles assigned to the user. */
  roles: z.array(roleSchema).optional(),
  /** Whether the user is disabled. */
  disabled: z.boolean().optional(),
  /** Whether the user is anonymous. */
  isAnonymous: z.boolean().optional(),
  /** The phone number of the user. */
  phoneNumber: z.string().nullable(),
  /** The date the user was created. */
  createdAt: timestampOrDateSchema.optional(),
  /** The date the user was last updated. */
  updatedAt: timestampOrDateSchema.optional(),
  /** The date the user requested their account be deleted. */
  deleteAccountRequested: timestampOrDateSchema.optional(),
  /** The reason the user requested their account be deleted. */
  deleteReason: z.string().optional(),
  /** The date the user's claims were last updated. */
  claimsUpdated: timestampOrDateSchema.optional(),
  /** The profile photo of the user. */
  profilePhoto: assetInDocumentSchema.optional(),
  /** An array of provider data for the user. */
  providerData: z
    .array(
      z.object({
        /** Provider specific UID. */
        uid: z.string(),
        /** The display name specified in provider */
        displayName: z.string().nullable(),
        /** The email specified in provider */
        email: z.string().email().nullable(),
        /** The photo URL specified in provider */
        photoURL: z.string().nullable(),
        /** The phone number specified in provider */
        phoneNumber: z.string().nullable(),
        /** The provider ID. */
        providerId: z.string(),
      }),
    )
    .optional(),
  /** A record of claims for the user. */
  claims: z.record(z.boolean()).optional(),
  /** Authorizations updated */
  authorizationsUpdated: timestampOrDateSchema.optional(),
});

/**
 * Represents a user object, inferred from the userSchema object definition ({@link userSchema}).
 */
export type User = z.infer<typeof userSchema>;

/**
 * Represents an AdminUser, which is a User with additional roles.
 * The roles property is an array of strings that includes 'admin' as one of the element.
 */
export type AdminUser = User & { roles: ['admin', ...string[]] };

/**
 * A schema for a pre-authorized user object, which includes the user's display name, email, and roles.
 */
export const preAuthorizedUser = z.object({
  /** The display name of the pre-authorized user. */
  displayName: z.string(),
  /** The email address of the pre-authorized user. */
  email: z.string(),
  /** An array of roles assigned to the pre-authorized user. */
  roles: z.array(roleSchema),
});

/**
 * Type definition for a pre-authorized user, inferred from the {@link preAuthorizedUser} schema.
 */
export type PreAuthorizedUser = z.infer<typeof preAuthorizedUser>;

/**
 * Combines the {@link preAuthorizedUser} schema with the {@link firestoreDocSchema} and extends it with a `docType` property
 * set to the string literal `'preAuthorizedUser'`. This creates a new schema for pre-authorized user documents in Firestore.
 */
export const preAuthorizedUserDocSchema = preAuthorizedUser
  .merge(firestoreDocSchema)
  .extend({
    /** literal string value of `'preAuthorizedUser'` */
    docType: z.literal('preAuthorizedUser'),
  });

/**
 * Type definition for a pre-authorized user document.
 * This type is inferred from the {@link preAuthorizedUserDocSchema}.
 */
export type PreAuthorizedUserDoc = z.infer<typeof preAuthorizedUserDocSchema>;

/**
 * Represents a user in Firestore database, with additional fields for timestamps.
 */
export interface UserFirestore
  extends Omit<
    User,
    | 'createdAt'
    | 'updatedAt'
    | 'deleteAccountRequested'
    | 'claimsUpdated'
    | 'emailVerified'
    | 'docType'
  > {
  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deleteAccountRequested?: FieldValue | Timestamp;
  claimsUpdated?: FieldValue | Timestamp;
}
