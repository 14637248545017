import { EventSlug } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

/**
 * Validates if an event slug is available for use.
 * @param customerId - The ID of the customer.
 * @param slug - The slug to validate.
 * @param event - Optional event object containing an ID to exclude from validation (when updating existing event).
 * @returns A promise that resolves to undefined if the slug is available, or 'Slug already in use' if it is not.
 */
export const validateEventSlug = async (
  customerId: string,
  slug: string,
  event?: { id: string },
): Promise<'Slug already in use' | undefined> => {
  console.log('validateEventSlug', slug, event);
  const firestore = getFirestore();
  const eventSlugRef = doc(
    firestore,
    firestorePaths.eventSlugPath(customerId, slug),
  );
  const eventSlugDoc = await getDoc(eventSlugRef);
  if (!eventSlugDoc.exists()) {
    return;
  }
  const data = eventSlugDoc.data() as EventSlug;
  if (event?.id && data.eventId === event.id) {
    return;
  } else {
    return 'Slug already in use';
  }
};
