import { SlideDeck, SlideDeckFormValues } from '@livekatsomo/models';
import { PDF_BUCKET, firestorePaths } from '@livekatsomo/shared/config';
import { UploadProgressFunction } from '@livekatsomo/types';
import { getDoc } from 'firebase/firestore';
import { uploadPDFFile } from '../files/uploadSlideDeck';

/**
 * Updates a slide deck in Firestore and uploads a PDF file if provided.
 * @param eventId - The ID of the event the slide deck belongs to.
 * @param trackId - The ID of the track the slide deck belongs to.
 * @param slideDeckId - The ID of the slide deck to update.
 * @param slideDeckFormValues - The updated slide deck form values.
 * @param setProgress - Optional function to track upload progress.
 * @returns A promise that resolves with the updated slide deck.
 */
export const updateSlideDeck = async (
  eventId: string,
  trackId: string,
  slideDeckId: string,
  slideDeckFormValues: SlideDeckFormValues,
  setProgress?: UploadProgressFunction,
) => {
  const uploadDir = firestorePaths.slideDecksCollectionPath(eventId, trackId);
  const { files, ...slideDeck } = slideDeckFormValues;

  return new Promise<SlideDeck>((resolve, reject) => {
    if (files && files.length) {
      uploadPDFFile({
        file: files[0],
        filename: slideDeck.filename,
        uploadDir,
        bucket: PDF_BUCKET,
        onComplete: async ({ slideDocRef }) => {
          const assetDoc = await getDoc(slideDocRef);
          const slideDeck = assetDoc.data();
          if (slideDeck) resolve(slideDeck);
          else reject(new Error('SlideDeck not found'));
        },
        onError: reject,
        slideData: {
          ...slideDeck,
          status: 'uploaded',
          type: 'application/pdf',
        } satisfies Partial<SlideDeck>,
        onUploadProgressChange: setProgress,
      });
    }
  });
};
