import { LayoutConfiguration } from '@livekatsomo/models';
import { deleteField, updateDoc } from 'firebase/firestore';
import { getEventDocRef } from './getEventDocRef';

/**
 * Updates the layout configuration for a given event.
 *
 * @param eventId - The ID of the event to update.
 * @param layout - The new layout configuration to set. Pass `null` to delete the layout configuration.
 * @returns A promise that resolves when the update is complete.
 */
export async function updateEventLayout(
  eventId: string,
  layout: LayoutConfiguration | null,
): Promise<void> {
  const eventDocRef = getEventDocRef(eventId);

  await updateDoc(eventDocRef, {
    defaultLayout: layout ? layout : deleteField(),
  });
}
