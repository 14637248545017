import { getDoc } from 'firebase/firestore';
import { getSlideDeckDocRef } from './getSlideDeckDocRef';

/**
 * Retrieves the slide deck document with the given IDs.
 * @param eventId - The ID of the event.
 * @param trackId - The ID of the track.
 * @param slideDeckId - The ID of the slide deck.
 * @returns A promise that resolves with the slide deck document snapshot.
 */

export async function getSlideDeckDoc(
  eventId: string,
  trackId: string,
  slideDeckId: string,
) {
  const slideDeckRef = getSlideDeckDocRef(eventId, trackId, slideDeckId);
  const docSnapshot = await getDoc(slideDeckRef);
  return docSnapshot;
}
