/**
 * An object containing functions for constructing Firestore paths for a document.
 */
/**
 * An object containing functions to generate Firestore paths for a document.
 */
export const firestorePathsForDocument = {
  /**
   * Returns the path to the document collection for a given parent path.
   * @param parentPath The parent path for the document collection.
   * @returns The path to the document collection.
   */
  documentCollectionPath: (parentPath: string) => `${parentPath}/document`,

  /**
   * The ID of the document collection.
   */
  documentCollectionId: 'document',

  /**
   * Returns the path to a specific document for a given parent path and document ID.
   * @param parentPath The parent path for the document.
   * @param documentId The ID of the document.
   * @returns The path to the document.
   */
  documentPath: (parentPath: string, documentId: string) =>
    `${parentPath}/document/${documentId}`,
} as const;
