import { ThemeDoc, themeSchema } from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';
import { getFirestoreDocErrorMap } from '@livekatsomo/shared/utils';

/**
 * Firestore data converter for ThemeDoc objects.
 */
export const themeConverter: FirestoreDataConverter<ThemeDoc> = {
  /**
   * Function to convert a Firestore document into a ThemeDoc object.
   *
   * @param doc - Firestore document to convert.
   * @returns A ThemeDoc object.
   * @throws If the Firestore document does not match the ThemeDoc schema.
   */
  fromFirestore: (doc) => {
    const data = themeSchema.parse(doc.data(), getFirestoreDocErrorMap(doc));
    return {
      ...data,
      id: doc.id,
      docType: 'theme',
      sourceDocRefPath: doc.ref.path,
    };
  },
  /**
   * Function to convert a ThemeDoc object into a Firestore document.
   * @param theme - The ThemeDoc object to convert.
   * @returns A Firestore document.
   */
  toFirestore: (theme) => ({ ...theme, docType: 'theme' }),
};
