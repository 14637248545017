import {
  SlideDeck,
  FromFirestore,
  ToFirestore,
  SlideDeckDoc,
} from '@livekatsomo/models';
import { FirestoreDataConverter } from 'firebase/firestore';

/**
 * Firestore data converter for SlideDeckDoc objects.
 */
export const slideDeckConverter: FirestoreDataConverter<SlideDeckDoc> = {
  /**
   *
   * @param doc - Firestore document snapshot.
   * @returns A SlideDeckDoc object.
   */
  fromFirestore: (doc): SlideDeckDoc => {
    const data = doc.data() as FromFirestore<SlideDeck>;
    return {
      ...data,
      id: doc.id,
      sourceDocRefPath: doc.ref.path,
      docType: 'slideDeck',
    };
  },
  /**
   * @param slideDeck - A SlideDeckDoc object.
   * @returns A Firestore data object.
   */
  toFirestore: (slideDeck: SlideDeckDoc): ToFirestore<SlideDeckDoc> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, sourceDocRefPath: path, ...data } = slideDeck;
    return {
      ...data,
      docType: 'slideDeck',
    };
  },
};
