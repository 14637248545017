import { EventConfigDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { eventConfigConverter } from './eventConfigConverter';

/**
 * Retrieves the configuration for a given event.
 *
 * @param options - The options object.
 * @param options.eventId - The ID of the event to retrieve the configuration for.
 * @param observer - The observer to use for the Firestore query.
 * @returns A promise that resolves with the event configuration document.
 * @throws If the `eventId` parameter is not provided.
 */
export const getEventConfig = (
  options: { eventId: string },
  observer: Observer<EventConfigDoc>,
) => {
  const { eventId } = options;
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.eventConfigPath(eventId),
    },
    eventConfigConverter,
  );
};
