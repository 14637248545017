import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import Business from '@mui/icons-material/Business';
import Edit from '@mui/icons-material/Edit';
import People from '@mui/icons-material/People';
import Podcasts from '@mui/icons-material/Podcasts';
import Tv from '@mui/icons-material/Tv';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

interface AdminMenuQuery extends ParsedUrlQuery {
  customer: string | undefined;
  event: string | undefined;
  trackId: string | undefined;
}

/**
 * Renders the admin menu items for the layout.
 * @param props - The component props.
 * @returns The rendered admin menu items.
 */
export function AdminMenuItems() {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    customer: customerSlug,
    event: eventSlug,
    trackId,
  } = router.query as AdminMenuQuery;

  return (
    <>
      <ListSubheader inset>{t('Admin menu')}</ListSubheader>
      <ListItemButton LinkComponent={Link} href="/admin">
        <ListItemIcon>
          <AdminPanelSettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('Administration')} />
      </ListItemButton>
      {router.isReady &&
      customerSlug &&
      router.pathname !== '/admin/customers/[customer]' ? (
        <ListItemButton
          LinkComponent={Link}
          href={`/admin/customers/${customerSlug}`}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary={t('Edit current customer')} />
        </ListItemButton>
      ) : null}
      {router.isReady &&
      customerSlug &&
      router.pathname === '/admin/customers/[customer]' ? (
        <ListItemButton LinkComponent={Link} href={`/${customerSlug}`}>
          <ListItemIcon>
            <Podcasts />
          </ListItemIcon>
          <ListItemText primary={t('View Channel')} />
        </ListItemButton>
      ) : null}
      {router.isReady &&
      customerSlug &&
      eventSlug &&
      router.pathname !== '/admin/customers/[customer]/[event]' ? (
        <ListItemButton
          LinkComponent={Link}
          href={`/admin/customers/${customerSlug}/${eventSlug}`}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary={t('Edit current event')} />
        </ListItemButton>
      ) : null}
      {router.isReady &&
      customerSlug &&
      eventSlug &&
      router.pathname === '/admin/customers/[customer]/[event]' ? (
        <ListItemButton
          LinkComponent={Link}
          href={`/${customerSlug}/${eventSlug}`}
        >
          <ListItemIcon>
            <Tv />
          </ListItemIcon>
          <ListItemText primary={t('View Event')} />
        </ListItemButton>
      ) : null}
      {router.isReady &&
      customerSlug &&
      eventSlug &&
      trackId &&
      router.pathname !== '/admin/customers/[customer]/[event]/[track]' ? (
        <ListItemButton
          LinkComponent={Link}
          href={`/admin/customers/${customerSlug}/${eventSlug}/${trackId}`}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary={t('Edit current track')} />
        </ListItemButton>
      ) : null}
      {router.isReady &&
      customerSlug &&
      eventSlug &&
      trackId &&
      router.pathname.includes(
        '/admin/customers/[customer]/[event]/[track]',
      ) ? (
        <ListItemButton
          component={NextLinkComposed}
          to={{
            pathname: `/${customerSlug}/${eventSlug}`,
            query: { track: trackId },
          }}
        >
          <ListItemIcon>
            <Tv />
          </ListItemIcon>
          <ListItemText primary={t('View current track')} />
        </ListItemButton>
      ) : null}
      <Divider />
      <ListItemButton LinkComponent={Link} href="/admin/customers">
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary={t('Customers')} />
      </ListItemButton>

      <ListItemButton LinkComponent={Link} href="/admin/events">
        <ListItemIcon>
          <Tv />
        </ListItemIcon>
        <ListItemText primary={t('Events')} />
      </ListItemButton>

      <ListItemButton LinkComponent={Link} href="/admin/users">
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary={t('Users')} />
      </ListItemButton>
    </>
  );
}

export default AdminMenuItems;
