import {
  CallableFunctionResponse,
  InvitationDoc,
  InvitationFormValues,
  Store,
} from '@livekatsomo/models';
import { HttpsCallableResult } from 'firebase/functions';
import { createContext } from 'react';

/**
 * Represents the model for the InvitationsContext.
 */
export type InvitationsContextModel = {
  invitationsStore: Store<InvitationDoc[]>;
  setInvitation: (invitation: InvitationFormValues) => Promise<void>;
  addInvitations: (invitations: InvitationFormValues[]) => Promise<void>;
  deleteInvitation: (invitation: InvitationDoc) => Promise<void>;
  sendPendingInvitations: () => Promise<
    HttpsCallableResult<CallableFunctionResponse>
  >;
};

/**
 * The context for the InvitationsContext.
 */
export const InvitationsContext = createContext<
  InvitationsContextModel | undefined
>(undefined);
