import { firestorePaths } from '@livekatsomo/shared/config';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { getEventDoc } from '../events/getEventDoc';
import { UsedIn } from '@livekatsomo/models';

/**
 * Retrieves a list of documents that reference the specified form ID.
 * @param formId - The ID of the form to search for.
 * @param eventId - The parent path of the form.
 * @returns - A promise that resolves to an array of UsedIn objects.
 */
export async function getFormUsedIn(
  eventId: string,
  formId: string,
): Promise<UsedIn[]> {
  const firestore = getFirestore();
  const tracksWithForm = query(
    collection(firestore, firestorePaths.trackPrivateCollectionPath(eventId)),
    where('surveyFormId', '==', formId),
  );
  const trackDocs = await getDocs(tracksWithForm);
  const docs = [...trackDocs.docs.map((doc) => doc.data())];
  const event = (await getEventDoc(eventId)).data();
  if (event && event.authorization?.surveyFormId === formId) docs.push(event);
  return docs.map((doc) => ({
    targetDocRefPath: doc['sourceDocRefPath'],
    sourceDocRefPath: firestorePaths.eventFormPath(eventId, formId),
    property: 'surveyFormId',
  }));
}
