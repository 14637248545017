import { z } from 'zod';
import { timestampOrDateSchema } from './firestore-doc.model';

/**
 * Defines the schema for the status of a nettilippu sync status.
 */
export const nettilippuStatusSchema = z.object({
  /** The timestamp for latest cancelled tickets. */
  cancelledTimestamp: z.number().default(0),
  /** The timestamp for latest updated tickets. */
  updatedTimestamp: z.number().default(0),
  /** The timestamp when the nettilippu was last synced with the server. */
  syncTimestamp: timestampOrDateSchema.optional(),
});

/**
 * The type definition for the status of a Nettilippu sync, which is inferred from the {@link nettilippuStatusSchema}.
 */
export type NettilippuStatus = z.infer<typeof nettilippuStatusSchema>;

/**
 * Defines a schema for the nettilippu event status, which extends the nettilippu status schema ({@link nettilippuStatusSchema})
 * and adds an `eventId` property of type `number`.
 */
export const nettilippuEventStatusSchema = nettilippuStatusSchema.extend({
  /** Id of the event. */
  eventId: z.number(),
});

/**
 * Type definition for the status of a Nettilippu event sync, which is inferred from the {@link nettilippuEventStatusSchema}.
 */
export type NettilippuEventStatus = z.infer<typeof nettilippuEventStatusSchema>;

/**
 * Defines the schema for a nettilippu event object.
 */
export const nettlippuEventSchema = z.object({
  /** The name of the nettilippu event. */
  name: z.string(),
  /** The start date of the nettilippu event. */
  event_start: z.string(),
  /** The end date of the nettilippu event. */
  event_end: z.string(),
  /** The URL of the thumbnail image for the nettilippu event. */
  thumbnail_url: z.string(),
  /** The URL of the main image for the nettilippu event. */
  image_url: z.string(), // Tapahtuman pääkuva
});

/**
 * The type definition for a Nettilippu event, which is inferred from the {@link nettlippuEventSchema}.
 */
export type NettilippuEvent = z.infer<typeof nettlippuEventSchema>;

/**
 * Schema for a nettilippu event with an ID.
 * @extends nettlippuEventSchema
 */
export const nettilippuEventWithIdSchema = nettlippuEventSchema.extend({
  /** The ID of the nettilippu event. */
  id_event: z.number(),
});

/**
 * Type definition for a Nettilippu event with an ID, which is inferred from the {@link nettilippuEventWithIdSchema}.
 */
export type NettilippuEventWithId = z.infer<typeof nettilippuEventWithIdSchema>;

/**
 * Schema for a cancelled nettilippu ticket.
 */
export const cancelledNettilippuTicketSchema = z.object({
  /** The barcode of the ticket. */
  barcode: z.string(),
  /** The timestamp for when the ticket was cancelled. */
  cancelled_time: z.string().datetime(),
  /** The timestamp for when the ticket was cancelled (Unix timestamp). */
  _cancelled_time: z.number(),
});

/**
 * An object containing seat codes for different types of seats.
 *
 * @property STANDING - The seat code for standing tickets.
 * @property NO_SEAT - The seat code for tickets with no assigned seat.
 */
export const seatCode = {
  STANDING: -1,
  NO_SEAT: -2,
} as const;

/**
 * A schema for validating seat codes ({@link seatCode}).
 */
export const seatCodeSchema = z.nativeEnum(seatCode);

/**
 * Defines the schema for a nettilippu ticket, which represents a ticket for an event in the nettilippu system.
 */
export const nettilippuTicketSchema = z.object({
  /** The unique identifier for the ticket. */
  id_ticket: z.number(),
  /** The unique identifier for the event. */
  id_event: z.number(),
  /** The identifier for the stand, can be null if not specified. */
  id_stand: z.number().nullable(),
  /** If the ticket is a season ticket, refers to the "main ticket" (id_ticket) that contains the barcode. */
  season_id: z.number().nullable(),
  /** The unique barcode for the ticket, null for season tickets. */
  barcode: z.string().nullable(),
  /** When the ticket was last used, can be null if not used. */
  last_use: z.string().datetime().nullable(),
  /** The number of times the ticket has been used, normally max. 1. */
  times_used: z.number(),
  /** The seat number, -1 for standing area, -2 for no stand. */
  seat_number: z.number().positive().or(seatCodeSchema).nullable(),
  /** The row number, -1 for standing area, -2 for no stand. */
  row_number: z.number().positive().or(seatCodeSchema).nullable(),
  /** The name of the stand. */
  stand_name: z.string(),
  /** When the ticket was last updated. */
  updated: z.string().datetime(),
  /** When the payment was made. */
  payment_time: z.string().datetime(),
  /** The name of the customer on the ticket. */
  name: z.string().nullable(),
  /** The event associated with the ticket. */
  event: nettlippuEventSchema,
  /**
   * Whether the ticket checker has access to the event.
   * In the case of season tickets, the ticket checker may not have access to the main event from which the season ticket was purchased.
   * All tickets are returned in the API call, including season tickets that the ticket checker may not have access to.
   * Since the season ticket contains the barcode of the season ticket, it is returned in the API call.
   */
  allowed: z.boolean(),
  /** Unix timestamp for payment_time. */
  _payment_time: z.number(),
  /** Unix timestamp for last_use, can be null. */
  _last_use: z.number().nullable(),
  /** Unix timestamp for updated. */
  _updated: z.number(),
});

/**
 * Defines the schema for the response object returned by the nettilippu API.
 */
export const nettilippuResponseSchema = z.object({
  /** An array of available tickets. */
  available: nettilippuTicketSchema.array(),
  /** An array of cancelled tickets. */
  cancelled: cancelledNettilippuTicketSchema.array(),
  /** The timestamp for latest cancelled tickets. */
  cancelledTimestamp: z.number(),
  /** The timestamp for latest updated tickets. */
  updatedTimestamp: z.number(),
  /** The IDs of the events the ticket inspector has access to. */
  ticketInspectorEvents: z.number().array(),
  /** If the API call included a forceFetchEventIds parameter (Array), this contains the IDs of the events for which ALL tickets were fetched. */
  forceFetchedEventIds: z.number().array(),
});

/**
 * Schema for the nettilippu request object.
 * @param forceFetchEventIds - If defined, returns all tickets for the specified events, even if they have been previously fetched.
 */
export const nettilippuRequestSchema = z.object({
  /** If defined, returns all tickets for the specified events, even if they have been previously fetched. */
  forceFetchEventIds: z.number().array().optional(),
});

/**
 * Type definition for a cancelled nettilippu ticket, inferred from the {@link cancelledNettilippuTicketSchema}.
 */
export type CancelledNettilippuTicket = z.infer<
  typeof cancelledNettilippuTicketSchema
>;

/**
 * Type definition for a Nettilippu ticket inferred from the {@link nettilippuTicketSchema}.
 */
export type NettilippuTicket = z.infer<typeof nettilippuTicketSchema>;

/**
 * Type definition for the response of a nettilippu API call, inferred from the {@link nettilippuResponseSchema}.
 */
export type NettilippuResponse = z.infer<typeof nettilippuResponseSchema>;

/**
 * Type definition for the request body of a Nettilippu API call, inferred from the {@link nettilippuRequestSchema}.
 */
export type NettilippuRequestBody = z.infer<typeof nettilippuRequestSchema>;
