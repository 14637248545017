import { Auth } from 'firebase/auth';
import { FirestoreError, FirestoreErrorCode } from 'firebase/firestore';
import { KatsomoError } from './errors';

/**
 * Represents an error that occurred in Firestore.
 */
export class KatsomoFirestoreError extends KatsomoError {
  /**
   * Creates a new instance of KatsomoFirestoreError.
   * @param message The error message.
   * @param code The Firestore error code.
   * @param auth The Firebase authentication instance.
   * @param cause The cause of the error.
   */
  constructor(
    message: string,
    public override readonly code: FirestoreErrorCode,
    public override readonly auth: Auth,
    cause?: FirestoreError,
  ) {
    super(message, code, auth, undefined, cause);
  }
}
