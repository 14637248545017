import { UpdateEventThemeFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { getEventDocRef } from './getEventDocRef';

/**
 * Updates the theme of an event in Firestore.
 *
 * @param eventId - The ID of the event to update.
 * @param themeFormValues - The new theme values to apply to the event.
 * @returns A Promise that resolves when the update is complete.
 */
export const updateEventTheme: UpdateEventThemeFunction = async (
  eventId,
  themeFormValues,
) => {
  const { baseTheme, ...themeOverrides } = themeFormValues;

  const eventDocRef = getEventDocRef(eventId);

  await updateDoc(eventDocRef, { baseTheme, themeOverrides });
};
