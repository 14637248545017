import { Asset, ChatMessageFormValues } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { updateDoc } from 'firebase/firestore';
import { getChatMessageDocRef } from './getChatMessageDocRef';
import { uploadChatImage } from './uploadChatImage';

/**
 * Updates a chat message in Firestore.
 *
 * @param parentPath - The parent path of the chat message.
 * @param chatMessageId - The ID of the chat message to update.
 * @param chatMessage - The updated chat message data.
 * @param onUploadProgressChange - An optional function to track upload progress.
 * @returns A Promise that resolves when the chat message is updated.
 */
export async function updateChatMessage(
  parentPath: string,
  chatMessageId: string,
  chatMessage: Partial<ChatMessageFormValues>,
  onUploadProgressChange?: UploadProgressFunction,
): Promise<void> {
  const chatMessageDocRef = getChatMessageDocRef(parentPath, chatMessageId);
  let asset: Asset | File[] | undefined = chatMessage.asset;
  const { text } = chatMessage;

  if (Array.isArray(asset) && asset.length) {
    asset = await uploadChatImage(
      chatMessageDocRef.path,
      asset[0],
      text,
      onUploadProgressChange,
    );
  }
  return updateDoc(chatMessageDocRef, {
    text,
    ...(asset && !Array.isArray(asset) && { asset }),
  });
}
