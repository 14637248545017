import { ThemeDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import {
  addDoc,
  collection,
  deleteField,
  getFirestore,
} from 'firebase/firestore';

/**
 * Copies a theme document to a new document with a modified name.
 * Removes any references to other documents in the copied theme.
 * @param sourceTheme - The theme document to copy.
 * @returns Promise that resolves with the ID of the newly created theme document.
 */
export async function copyTheme(sourceTheme: ThemeDoc): Promise<string> {
  const firestore = getFirestore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...theme } = sourceTheme;
  const themesCollection = collection(
    firestore,
    firestorePaths.themeCollectionPath,
  );
  if (theme.katsomo?.header?.logo?.usedInDocRefPath)
    theme.katsomo.header.logo.usedInDocRefPath = deleteField() as never;
  if (theme.katsomo?.backgroundImage?.image?.usedInDocRefPath)
    theme.katsomo.backgroundImage.image.usedInDocRefPath =
      deleteField() as never;

  const newDoc = await addDoc(themesCollection, {
    ...theme,
    name: `${theme.name} (copy)`,
  });
  return newDoc.id;
}
