import { Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the ModeratorContext.
 */
export type ModeratorContextModel = {
  store: Store<boolean>;
};

/**
 * The context for the ModeratorContext.
 */
export const ModeratorContext = createContext<
  ModeratorContextModel | undefined
>(undefined);
