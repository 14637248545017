import { TrackPlaybackHistory } from '@livekatsomo/models';
import { Store } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the TrackPlaybackHistoryContext.
 */
export type TrackPlaybackHistoryContextModel = {
  store: Store<TrackPlaybackHistory | null> | null;
  deletePlaybackHistoryItem: (id: string) => Promise<void>;
};

/**
 * The context for the TrackPlaybackHistoryContext.
 */
export const TrackPlaybackHistoryContext = createContext<
  TrackPlaybackHistoryContextModel | undefined
>(undefined);
