import {
  ModeratorDoc,
  ModeratorFormValues,
  PreAuthorizedModerator,
  Store,
} from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Represents the model for the ModeratorsContext.
 */
export type ModeratorsContextModel = {
  moderatorsStore: Store<ModeratorDoc[]>;
  preAuthorizedModeratorsStore: Store<PreAuthorizedModerator[]>;
  addModerator: (moderator: ModeratorFormValues) => Promise<void>;
  deleteModerator: (moderator: ModeratorDoc) => Promise<void>;
  deletePreAuthorizedModerator: (
    preAuthorizedModerator: PreAuthorizedModerator,
  ) => Promise<void>;
};

/**
 * The context for the ModeratorsContext.
 */
export const ModeratorsContext = createContext<
  ModeratorsContextModel | undefined
>(undefined);
