import { SponsorDoc } from '@livekatsomo/models';
import { firestorePaths } from '@livekatsomo/shared/config';
import { Observer } from '@livekatsomo/types';
import { getById } from '@livekatsomo/web/firebase';
import { sponsorConverter } from './sponsorConverter';

/**
 * Retrieves a sponsor document from Firestore.
 *
 * @param options - An object containing the `eventId` and `sponsorId` of the sponsor document to retrieve.
 * @param options.eventId - The ID of the event the sponsor belongs to.
 * @param options.sponsorId - The ID of the sponsor to retrieve.
 * @param observer - An observer that listens for changes to the retrieved sponsor document.
 * @returns A function that can be called to unsubscribe from the query.
 * @throws If `sponsorId` or `eventId` is not provided.
 */
export function getSponsor(
  options: { eventId: string; sponsorId: string },
  observer: Observer<SponsorDoc>,
) {
  const { eventId, sponsorId } = options;
  if (!sponsorId) throw new Error('sponsorId is required');
  if (!eventId) throw new Error('eventId is required');

  return getById(
    observer,
    {
      documentPath: firestorePaths.sponsorPath(eventId, sponsorId),
    },
    sponsorConverter,
  );
}
