import { getDoc } from 'firebase/firestore';
import { getSponsorDocRef } from './getSponsorDocRef';

/**
 * Retrieves the document for a specific sponsor associated with an event.
 * @param eventId The ID of the event the sponsor is associated with.
 * @param sponsorId The ID of the sponsor to retrieve the document for.
 * @returns A Promise that resolves with the sponsor document.
 */
export function getSponsorDoc(eventId: string, sponsorId: string) {
  return getDoc(getSponsorDocRef(eventId, sponsorId));
}
