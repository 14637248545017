import { EventDoc, Store } from '@livekatsomo/models';
import {
  AddEventFunction,
  DeleteEventFunction,
  ValidateEventSlugFunction,
} from '@livekatsomo/types';
import { QueryConstraint } from 'firebase/firestore';
import { createContext } from 'react';

/**
 * Represents the model for the EventsContext.
 */
export type EventsContextModel = {
  store: Store<EventDoc[]>;
  addEvent: AddEventFunction;
  deleteEvent: DeleteEventFunction;
  validateEventSlug: ValidateEventSlugFunction;
  setQueryConstraints: (queryConstraints: QueryConstraint[]) => void;
};

/**
 * The context for the EventsContext.
 */
export const EventsContext = createContext<EventsContextModel | undefined>(
  undefined,
);
