import { z } from 'zod';
import { assetInDocumentSchema, imageFileSchema } from './asset.model';
import {
  firestoreDocSchema,
  timestampOrDateSchema,
} from './firestore-doc.model';
import { layoutConfigurationSchema } from './layout.model';
import { slidoSchema } from './slido.model';
import { speakerInDocumentSchema } from './speaker.model';
import { streamInDocumentSchema } from './stream.model';
import { themeDocSchema, themeOverridesSchema } from './theme.model';
import { usedInPropertySchema } from './used-in.model';
import { summarySchema } from './summary.model';

/**
 * An array of available track modes.
 * @readonly
 *
 * @remarks
 * The available track modes are:
 * - pre-show
 * - live
 * - on-demand
 */
export const trackMode = ['pre-show', 'live', 'on-demand'] as const;

/**
 * Defines a schema for the track mode enum ({@link trackMode}).
 */
export const trackModeSchema = z.enum(trackMode);

/**
 * The type of the track mode, inferred from the {@link trackModeSchema}.
 */
export type TrackMode = z.infer<typeof trackModeSchema>;

/**
 * A schema that defines the features available for a track.
 */
export const trackFeaturesSchema = z.object({
  /** Whether the agenda is enabled for the track. */
  agendaEnabled: z.boolean().optional(),
  /** Whether the chat is enabled for the track. */
  chatEnabled: z.boolean().optional(),
  /** Whether the countdown is enabled for the track. */
  countdownEnabled: z.boolean().optional(),
  /** Whether the event details are enabled for the track. */
  eventDetailsEnabled: z.boolean().optional(),
  /** Whether the slides are enabled for the track. */
  slidesEnabled: z.boolean().optional(),
  /** Whether the slide thumbs are enabled for the track. */
  slideThumbsEnabled: z.boolean().optional(),
  /** Whether Slido is enabled for the track. */
  slidoEnabled: z.boolean().optional(),
  /** Whether the speakers are enabled for the track. */
  speakersEnabled: z.boolean().optional(),
  /** Whether the track speakers are shown only for the track. */
  showOnlyTrackSpeakers: z.boolean().optional(),
  /** Whether the sponsors are enabled for the track. */
  sponsorsEnabled: z.boolean().optional(),
  /** Whether the stream is enabled for the track. */
  streamEnabled: z.boolean().optional(),
  /** Whether the summary is enabled for the track. */
  summaryEnabled: z.boolean().optional(),
  /** Whether the track summary is enabled for the track */
  trackSummaryEnabled: z.boolean().optional(),
  /** Whether Walls.io is enabled for the track. */
  wallsioEnabled: z.boolean().optional(),
  /** Whether the speed test is enabled for the track. */
  speedTestEnabled: z.boolean().optional(),
  /** Whether the browser test is enabled for the track. */
  browserTestEnabled: z.boolean().optional(),
  /** Whether anonymous chat is allowed for the track. */
  allowAnonymousChat: z.boolean().optional(),
});

export type TrackFeatures = z.infer<typeof trackFeaturesSchema>;

/**
 * Defines the schema for a track object.
 */
export const trackSchema = z
  .object({
    /** The actual start time of the track. */
    actualStartTime: timestampOrDateSchema.nullish(),
    /** The actual end time of the track. */
    actualEndTime: timestampOrDateSchema.nullish(),
    /** The program start time of the track. */
    programStartTime: timestampOrDateSchema.nullish(),
    /** The stream associated with the track. */
    stream: streamInDocumentSchema.nullish(),
    /** The start time delta in milliseconds of the track related to the event start time */
    startTimeDelta: z.number(),
    /** The name of the track. */
    name: z.string(),
    /** The lowercase name of the track. */
    nameLower: z.string().optional(),
    /** The mode of the track. */
    mode: trackModeSchema,
    /** An array of speakers associated with the track. */
    speakers: z.array(speakerInDocumentSchema).nullish(),
    /** The slug of the track. */
    slug: z.string(),
    /** The Slido object associated with the track. */
    slido: z.union([slidoSchema, z.null()]).optional(),
    /** The Walls.io code associated with the track. */
    wallsioCode: z.union([z.string(), z.null()]).optional(),
    /** The poster associated with the track. If not specified, the event poster is used. */
    poster: assetInDocumentSchema.optional(),
    /** The ID of the event associated with the track. */
    eventId: z.string(),
    /** The ID of the customer associated with the track. */
    customerId: z.string(),
    /** The base theme associated with the track. */
    baseTheme: themeDocSchema.optional(),
    /** The theme overrides associated with the track. */
    themeOverrides: themeOverridesSchema.optional(),
    /** An array of agenda item IDs associated with the track. */
    agendaItems: z.array(z.string()).optional(),
    /** The ID of the current slide deck associated with the track. */
    currentSlideDeckId: z.string().optional(),
    /** The current slide index associated with the track. */
    currentSlideIndex: z.number().optional(),
    /** The session number associated with the track. */
    sessionNumber: z.number().optional(),
    /** The ID of the current agenda item associated with the track. */
    currentAgendaItemId: z.string().optional(),
    /** The default layout associated with the track. */
    defaultLayout: layoutConfigurationSchema.optional(),
    /** The summary of the track. */
    summary: summarySchema.optional(),
    /** If survey form is enables */
    surveyFormEnabled: z.boolean().optional(),
    /** Id for survey form */
    surveyFormId: z.string().nullable().optional(),
    /** live mode start time */
    liveModeStartTime: timestampOrDateSchema.nullish(),
    /** Wether to show video and slide when in preshow */
    preshowVideoAndSlidesEnabled: z.boolean().optional(),
    /** Wether to show downlaod files */
    downloadFilesEnabled: z.boolean().optional(),
  })
  .merge(trackFeaturesSchema);

/**
 * Defines the schema for a track document in Firestore, which includes the track schema ({@link trackSchema}})
 * and the Firestore document schema ({@link firestoreDocSchema}). Additionally, it extends the schema to include a `docType`
 * property with a literal string value of `'track'`.
 */
export const trackDocSchema = trackSchema.merge(firestoreDocSchema).extend({
  /** literal string value of `'track'` */
  docType: z.literal('track'),
});

/**
 * Merges the {@link usedInPropertySchema} and {@link trackDocSchema} schemas to create a new schema for a track document.
 * @returns The merged schema for a track document.
 */
export const trackInDocumentSchema = usedInPropertySchema.merge(trackDocSchema);

/**
 * Type definition for a Track document, inferred from the {@link trackDocSchema}.
 */
export type TrackDoc = z.infer<typeof trackDocSchema>;

/**
 * Represents a track object with properties defined by the {@link trackSchema}.
 */
export type Track = z.infer<typeof trackSchema>;

/**
 * This exports a public schema for the track model, which includes only the necessary properties for public display.
 */
export const trackPublicSchema = trackDocSchema.pick({
  slug: true,
  eventId: true,
  name: true,
  nameLower: true,
  baseTheme: true,
  themeOverrides: true,
  poster: true,
});

/**
 * Type definition for a public track object.
 * This type is inferred from the `trackPublicSchema` schema.
 */
export type TrackPublic = z.infer<typeof trackPublicSchema>;

/**
 * This exports a schema for a public track document in Firestore.
 * It merges the {@link trackPublicSchema} and {@link firestoreDocSchema} and extends it with a `docType` property
 * set to the literal string 'trackPublic'.
 */
export const trackPublicDocSchema = trackPublicSchema
  .merge(firestoreDocSchema)
  .extend({
    /** literal string value of `'trackPublic'` */
    docType: z.literal('trackPublic'),
  });

/**
 * Type definition for a public track document inferred from the {@link trackPublicDocSchema}.
 */
export type TrackPublicDoc = z.infer<typeof trackPublicDocSchema>;

/**
 * Defines the schema for a track form, including its required and optional fields.
 */
export const trackFormSchema = z.object({
  /** The name of the track. */
  name: z.string().nonempty('Name is required'),
  /** The slug of the track. */
  slug: z.string().nonempty('Slug is required'),
  /** The start time delta in milliseconds of the track related to the event start time */
  startTimeDelta: z.number().nonnegative('Track cannot start before event'),
  /** The poster associated with the track. If not specified, the event poster is used. */
  poster: imageFileSchema.or(assetInDocumentSchema).optional(),
  /** The ID of the event associated with the track. */
  eventId: z.string().nonempty('Event is required'),
  /** The ID of the customer associated with the track. */
  customerId: z.string().nonempty('Customer is required'),
  /** The mode of the track. */
  mode: trackModeSchema,
});

/**
 * Type definition for the form values of a track object inferred from the {@link trackFormSchema}.
 */
export type TrackFormValues = z.infer<typeof trackFormSchema>;
