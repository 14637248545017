import { useEffect, useMemo, useRef, useSyncExternalStore } from 'react';
import { BehaviorSubject, Observable, tap } from 'rxjs';

/**
 * Restricts a type to an array type.
 *
 * @typeParam T The type to be restricted.
 * @returns {T extends any[] ? T : []} The restricted array type.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RestrictArray<T> = T extends any[] ? T : [];

type ResultBox<T> = { v: T };

/**
 * Returns a memoized value that is created by invoking the provided function.
 * The value is only created once and then cached for future use.
 * @typeParam T The type of the value returned by the provided function.
 * @param {() => T} fn The function that returns the value to be memoized.
 * @returns {T} The memoized value.
 */
export default function useConstant<T>(fn: () => T): T {
  const ref = useRef<ResultBox<T>>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}

/**
 * A function that takes an observable of state and returns an observable of state.
 *
 * @typeParam State The type of the state object.
 * @param state$ The observable of state.
 * @returns An observable of state.
 */
export type InputFactory<State> = (
  state$: Observable<State>,
) => Observable<State>;

/**
 * A function that takes an observable of state and an observable of inputs, and returns an observable of state.
 *
 * @typeParam State The type of the state object.
 * @typeParam Inputs The type of the input object.
 * @param {Observable<State>} state$ The observable of state.
 * @param {Observable<RestrictArray<Inputs>>} inputs$ The observable of inputs.
 * @returns {Observable<State>} An observable of state.
 */
export type InputFactoryWithInputs<State, Inputs> = (
  state$: Observable<State>,
  inputs$: Observable<RestrictArray<Inputs>>,
) => Observable<State>;

/**
 * A hook that takes an observable factory function and returns the latest state of the observable.
 *
 * @typeParam State The type of the state object.
 * @typeParam Inputs The type of the input object.
 * @param {InputFactory<State, Inputs>} inputFactory The observable factory function.
 * @param {State} [initialState] The initial state of the observable.
 * @param {Inputs} [inputs] The input object of the observable.
 * @returns {State | null} The latest state of the observable.
 */
export function useObservable<State>(
  inputFactory: InputFactory<State>,
): State | null;
export function useObservable<State>(
  inputFactory: InputFactory<State>,
  initialState: State,
): State;
export function useObservable<State, Inputs>(
  inputFactory: InputFactoryWithInputs<State, Inputs>,
  initialState: State,
  inputs: RestrictArray<Inputs>,
): State;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useObservable<State, Inputs extends ReadonlyArray<any>>(
  inputFactory: InputFactoryWithInputs<State, Inputs>,
  initialState?: State,
  inputs?: RestrictArray<Inputs>,
): State | null {
  const state$ = useConstant(
    () => new BehaviorSubject<State | undefined>(initialState),
  );
  const inputs$ = useConstant(
    () => new BehaviorSubject<RestrictArray<Inputs> | undefined>(inputs),
  );

  useEffect(() => {
    return () => {
      state$.complete();
      inputs$.complete();
    };
  }, [inputs$, state$]);

  useEffect(() => {
    inputs$.next(inputs);
  }, [inputs, inputs$]);

  const subscribe = useMemo(() => {
    let output$: Observable<State>;
    if (inputs) {
      output$ = (
        inputFactory as (
          state$: Observable<State | undefined>,
          inputs$: Observable<RestrictArray<Inputs> | undefined>,
        ) => Observable<State>
      )(state$, inputs$);
    } else {
      output$ = (
        inputFactory as unknown as (
          state$: Observable<State | undefined>,
        ) => Observable<State>
      )(state$);
    }
    return (onStorageChange: () => void) => {
      const subscription = output$
        .pipe(tap((s) => state$.next(s)))
        .subscribe(onStorageChange);
      return () => subscription.unsubscribe();
    };
  }, [inputFactory, inputs, inputs$, state$]);

  const getSnapShot = useMemo(() => {
    return () => state$.getValue() ?? null;
  }, [state$]);

  return useSyncExternalStore(subscribe, getSnapShot, getSnapShot);
}
