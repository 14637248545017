import { SpeakerDoc } from '@livekatsomo/models';
import { updateDoc } from 'firebase/firestore';
import { getEventDocRef } from '../events/getEventDocRef';

/**
 * Updates the order of speakers for a given track in an event.
 *
 * @param eventId - The ID of the event.
 * @param speakers - Array of the speakers items in new order.
 * @returns A Promise that resolves when the update is complete.
 */
export function updateSpeakersOrder(eventId: string, speakers: SpeakerDoc[]) {
  return updateDoc(getEventDocRef(eventId), {
    speakers: speakers.map((speaker) => speaker.id),
  });
}
