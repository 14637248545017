/**
 * An object containing functions that return Firestore paths for notifications.
 */
export const firestorePathsforNotification = {
  /**
   * Returns the Firestore path for a specific notification.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @param notificationId The ID of the notification.
   * @returns The Firestore path for the notification.
   */
  notificationPath: (
    eventId: string,
    trackId: string,
    notificationId: string,
  ) => `event/${eventId}/track/${trackId}/notification/${notificationId}`,

  /**
   * Returns the Firestore path for a collection of notifications.
   * @param eventId The ID of the event.
   * @param trackId The ID of the track.
   * @returns The Firestore path for the notification collection.
   */
  notificationCollectionPath: (eventId: string, trackId: string) =>
    `event/${eventId}/track/${trackId}/notification`,
} as const;
