import { deleteDoc } from 'firebase/firestore';
import { getEventDocRef } from './getEventDocRef';
import { getEventDoc } from './getEventDoc';

/**
 * Deletes an event with the given ID.
 * @param eventId - The ID of the event to delete.
 * @returns A Promise that resolves when the event is deleted.
 * @throws If the event with the given ID is not found.
 */
export const deleteEvent = async (eventId: string): Promise<void> => {
  const docSnapshot = await getEventDoc(eventId);
  const eventRef = getEventDocRef(eventId);
  if (docSnapshot.exists()) {
    await deleteDoc(eventRef);
  } else {
    throw new Error('Event not found');
  }
};
