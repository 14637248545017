import { Asset, AssetDoc } from '@livekatsomo/models';
import { UploadProgressFunction } from '@livekatsomo/types';
import { getApp } from 'firebase/app';
import { DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { getAssetDocRefFromFile } from './getAssetDocRefFromFile';
import { getFilePath } from './getFilePath';
import { getImageDimensions } from './getImageDimensions';
import { assetConverter } from '../assets/assetConverter';

/**
 * Uploads a file to Firebase Storage and saves its metadata to Firestore.
 *
 * @param options - The options object.
 * @param options.file - The file to upload.
 * @param options.uploadDir - The directory to upload the file to.
 * @param options.filename - The name to give the uploaded file. If not provided, the original filename will be used.
 * @param options.bucket - The name of the Firebase Storage bucket to upload the file to. If not provided, the default bucket will be used.
 * @param options.assetData - Additional metadata to save with the uploaded file.
 * @param options.onUploadProgressChange - A function that will be called with the upload progress.
 * @param options.onComplete - A function that will be called when the upload is complete.
 * @param options.onError - A function that will be called if an error occurs during the upload.
 * @returns A Promise that resolves with the metadata of the uploaded file.
 */
export async function uploadImageFile({
  file,
  filename,
  uploadDir,
  bucket,
  assetData,
  onUploadProgressChange,
}: {
  file: File;
  uploadDir: string;
  filename?: string;
  bucket?: string;
  assetData?: Partial<Asset>;
  onUploadProgressChange?: UploadProgressFunction;
}): Promise<DocumentSnapshot<AssetDoc>> {
  const storage = getStorage(getApp(), bucket);
  const filePath = getFilePath(uploadDir, filename, file);
  const assetDocRef =
    getAssetDocRefFromFile(filePath).withConverter(assetConverter);

  const assetDoc = await getDoc(assetDocRef);
  if (assetDoc.exists()) {
    const data = assetDoc.data();
    if (data?.status && data.status !== 'processed') {
      throw new Error(
        `Asset with path ${filePath} already exists and is still processing.`,
      );
    }
  }

  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        // update progress
        onUploadProgressChange &&
          onUploadProgressChange((state) => ({
            ...state,
            [file.name]: { file, progress: percent },
          }));
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          // upload complete
          // Save asset to firestore
          const storageRef = uploadTask.snapshot.ref;
          const originalUrl = await getDownloadURL(storageRef);

          const asset = {
            filename: file.name,
            filePath,
            originalUrl,
            bucket: storageRef.bucket,
            type: file.type,
            status: 'uploaded',
            // If file is an image then save width and height
            ...(file.type.startsWith('image/') &&
              (await getImageDimensions(file))),
            ...assetData,
          } satisfies Asset;

          await setDoc(assetDocRef, asset, { merge: true });
          onUploadProgressChange &&
            onUploadProgressChange((state) => ({
              ...state,
              [file.name]: { ...state[file.name], progress: 100 },
            }));
          const assetDoc = await getDoc(assetDocRef);
          resolve(assetDoc);
        } catch (error) {
          reject(error);
        }
      },
    );
  });
}
